import { Mutex } from 'async-mutex';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ErrorData {
  message: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  credentials: 'include',
});

const refreshTokenMutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);

  const errorData = result?.error?.data as ErrorData;
  const isJWTValidationError = errorData?.message?.match(/^Unable to validate JWT/);
  if (result.error && result.error.status === 401 && isJWTValidationError) {
    const release = await refreshTokenMutex.acquire();

    try {
      const refreshResult = await baseQuery({ url: '/api/auth/jwt-refresh', method: 'POST' }, api, extraOptions);
      if (refreshResult?.meta?.response?.status !== 204 && (args as FetchArgs).url !== 'api/me') {
        window.location.assign(`${process.env.REACT_APP_BASE_URL}/api/auth/logout`);
      } else {
        result = await baseQuery(args, api, extraOptions);
      }
    } finally {
      release();
    }
  }
  return result;
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'ApiKeys',
    'Apps',
    'BillingAccounts',
    'LinkCodes',
    'LinkCodeAccess',
    'Connections',
    'Domains',
    'Env',
    'Environments',
    'Events',
    'Integrations',
    'IntegrationsConnectionsAccess',
    'Members',
    'NetInfo',
    'OrganizationAccess',
    'OrganizationExecutionContext',
    'Organizations',
    'ProjectAccess',
    'Projects',
    'PublicKeys',
    'Segments',
    'TeamAccess',
    'TeamMembers',
    'Teams',
    'Toggles',
  ],
  // This api has endpoints injected in adjacent files.
  endpoints: () => ({}),
});
