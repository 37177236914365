import { ConnectionType } from '@hyphen/nucleus/dist/types/connections';
import { IntegrationType } from '../types/integrations';

export default function getIntegrationConnectionInputLabel(
  connectionType: ConnectionType,
  integrationType: IntegrationType,
) {
  switch (connectionType) {
    case ConnectionType.User:
      if (integrationType === IntegrationType.aws) {
        return 'Account ID';
      }
      if (integrationType === IntegrationType.github || integrationType === IntegrationType.npm) {
        return 'Username';
      }
      return 'User ID';
    case ConnectionType.Channel:
      return 'Channel Name';
    case ConnectionType.DistributionList:
      return `Distribution List`;
    case ConnectionType.CodeRepository:
      return `Repository Name`;
    case ConnectionType.ProjectBoard:
      if (integrationType === IntegrationType.jira) {
        return 'Project Name';
      }
      return 'Project Board ID';
    case ConnectionType.PermissionGroup:
      if (
        integrationType === IntegrationType.microsoftTeams ||
        integrationType === IntegrationType.azure ||
        integrationType === IntegrationType.office365
      ) {
        return 'Group ID';
      }
      if (integrationType === IntegrationType.npm || integrationType === IntegrationType.github) {
        return 'Team Name';
      }
      // aws, newRelic, jira
      return 'Group Name';
    default:
      return `Unknown`;
  }
}
