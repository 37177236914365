import { useBreakpoint, Box, Tooltip, TooltipTrigger, TooltipContent } from '@hyphen/hyphen-components';
import { FontColor } from '@hyphen/hyphen-design-tokens/build/types';
import { TOGGLE_TEXT } from '../../constants/toggle';
import { ToggleSummary } from '../../types/toggle';

const ToggleUsageStatus = ({ toggleData }: { toggleData: ToggleSummary['toggles'][string] }) => {
  const { isHd } = useBreakpoint();

  let statusText = 'no usage';
  let statusColor: FontColor = 'disabled';
  let tooltipContent: string | undefined = TOGGLE_TEXT.noUsageTooltip;

  const { meta, count } = toggleData;

  const toggleInUse = count > 0;
  const isNew = !(meta && meta.hasOwnProperty('updatedAt')) && !toggleInUse;

  if (isNew) {
    statusText = 'new';
    statusColor = 'info';
    tooltipContent = '';
  } else if (toggleInUse) {
    statusText = 'active';
    statusColor = 'success';
    tooltipContent = TOGGLE_TEXT.activeTooltip;
  }

  return (
    <Box fontSize={{ base: '2xs', hd: 'xs' }} width={isHd ? '75px' : '50px'} minWidth={isHd ? '75px' : '50px'}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Box
            as={tooltipContent ? 'button' : 'span'}
            display="inline-block"
            padding="0"
            borderWidth="0"
            background="transparent"
            color={statusColor}
            textAlign="left"
          >
            {statusText}
          </Box>
        </TooltipTrigger>
        {tooltipContent && <TooltipContent className="mw-7xl text-align-center">{tooltipContent}</TooltipContent>}
      </Tooltip>
    </Box>
  );
};

export default ToggleUsageStatus;
