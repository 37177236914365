import { createMongoAbility, MongoAbility } from '@casl/ability';
import { useGetExecutionContextQuery } from '../../services/executionContext';
import { createContext, PropsWithChildren, useContext } from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import { EntityTypes } from '@hyphen/nucleus/dist/types';
import { parseEntityType } from '@hyphen/nucleus/dist/common/entity';

export const OrganizationAbilityContext = createContext({} as MongoAbility);

// TODO: Consider importing nucleus to take advantage of not having to redefine these things
const detectSubjectType = (obj: { id: string; __caslSubjectType__?: EntityTypes } & Object): EntityTypes => {
  if (obj.__caslSubjectType__) {
    return obj.__caslSubjectType__;
  }

  try {
    return parseEntityType(obj.id);
  } catch (error) {
    console.error(`Unknown subject type for ${obj.id}:`);
    return 'unknown' as any;
  }
};

export const OrganizationAbilityProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const { id } = useOrganization();
  const { data } = useGetExecutionContextQuery(id);
  const rules = data?.member?.rules || [];
  const ability = createMongoAbility(rules, { detectSubjectType });

  // Debugging example - if you cannot figure out why an ability is not working as expected.
  // console.log(ability.relevantRuleFor('read', 'Article'));

  return <OrganizationAbilityContext.Provider value={ability}>{children}</OrganizationAbilityContext.Provider>;
};

export const useOrganizationAbilityContext = () => {
  return useContext(OrganizationAbilityContext);
};
