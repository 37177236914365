import { AccessEntry, ListResponse } from './types';
import { api } from './api';
import { cleanRequest } from '../utils/requestUtils';
import { Member, ReplaceMembers, Team, TeamDetails } from '../types/teams';
import { ProjectRoles } from '../types/roles';

export const teamsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTeams: build.query<
      ListResponse<Team>,
      { organizationId: string; name?: string; pageSize?: number; pageNum?: number }
    >({
      query: ({ organizationId, name, pageNum, pageSize }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (name && name.trim().length) params.append('name', name.trim());

        return {
          url: `api/organizations/${organizationId}/teams?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }) => [
        ...result.data.map(({ id }) => ({ type: 'Teams' as const, id })),
        { type: 'Teams', id: 'LIST' },
      ],
    }),
    getTeam: build.query<TeamDetails, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `api/organizations/${orgId}/teams/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [{ type: 'Teams', id }],
    }),
    getTeamMembers: build.query<ListResponse<Member>, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `api/organizations/${orgId}/teams/${id}/members`,
        method: 'GET',
      }),
      providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }, error, { id: teamId }) => [
        ...result.data.map(({ id }) => ({ type: 'TeamMembers' as const, id: `${teamId}:${id}` })),
        { type: 'TeamMembers', id: `${teamId}:LIST` },
      ],
    }),
    deleteTeamMember: build.mutation<void, { orgId: string; teamId: string; memberId: string }>({
      query: ({ orgId, teamId, memberId }) => ({
        url: `api/organizations/${orgId}/teams/${teamId}/members/${memberId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { teamId, memberId }) => [
        { type: 'TeamMembers', id: `${teamId}:${memberId}` },
        { type: 'TeamMembers', id: `${teamId}:LIST` },
        { type: 'Teams', id: teamId },
      ],
    }),
    createTeam: build.mutation<Team, { orgId: string; team: Omit<Team, 'id' | 'memberCount'> }>({
      query: ({ orgId, team }) => ({
        url: `api/organizations/${orgId}/teams`,
        method: 'POST',
        body: team,
      }),
      invalidatesTags: (_result, _error, { orgId }) => [
        { type: 'Teams', id: 'LIST' },
        { type: 'OrganizationExecutionContext', id: orgId },
      ],
    }),
    updateTeam: build.mutation<
      Team,
      { orgId: string; id: string; data: Omit<TeamDetails, 'distributionList' | 'id'> }
    >({
      query: ({ orgId, id, data }) => ({
        url: `api/organizations/${orgId}/teams/${id}`,
        method: 'PATCH',
        body: cleanRequest(data),
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Teams', id },
              { type: 'TeamMembers', id: `${id}:LIST` },
            ]
          : [],
    }),
    deleteTeam: build.mutation<void, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `api/organizations/${orgId}/teams/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Teams', id }],
    }),
    replaceTeamMembers: build.mutation<void, { orgId: string; teamId: string; data: ReplaceMembers }>({
      query: ({ orgId, teamId, data }) => ({
        url: `api/organizations/${orgId}/teams/${teamId}/members`,
        method: 'PUT',
        body: cleanRequest(data),
      }),
      invalidatesTags: (_result, _error, { teamId }) => [
        { type: 'TeamMembers', id: `${teamId}:LIST` },
        { type: 'Teams', id: teamId },
      ],
    }),
    getTeamProjectAccess: build.query<
      ListResponse<AccessEntry>,
      { teamId: string; organizationId: string; pageNum: number; pageSize: number }
    >({
      query: ({ teamId, organizationId, pageNum, pageSize }) => {
        const params = new URLSearchParams();
        params.append('assignmentIds', teamId);
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (pageSize) params.append('pageSize', pageSize.toString());
        return {
          url: `api/organizations/${organizationId}/access?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, { teamId }) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Projects' as const, id: id })),
              { type: 'Projects', id: `${teamId}-LIST` },
            ]
          : [],
    }),
    updateTeamProjectAccess: build.mutation<
      AccessEntry,
      { organizationId: string; accessId: string; roles: ProjectRoles[] }
    >({
      query: ({ organizationId, accessId, roles }) => ({
        url: `api/organizations/${organizationId}/access/${accessId}`,
        method: 'PATCH',
        body: { roles },
      }),
      invalidatesTags: (result, _error, { accessId }) =>
        result
          ? [
              { type: 'Projects', id: `${result.assignment.id}-LIST` },
              { type: 'ProjectAccess', id: accessId },
            ]
          : [],
    }),
    getTeamAccess: build.query<
      ListResponse<AccessEntry>,
      { teamId: string; organizationId: string; pageNum: number; pageSize: number }
    >({
      query: ({ teamId, organizationId, pageNum, pageSize }) => {
        const params = new URLSearchParams();
        params.append('entityIds', teamId);
        params.append('entityIds', organizationId);
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (pageSize) params.append('pageSize', pageSize.toString());
        return {
          url: `api/organizations/${organizationId}/access?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, { teamId }) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'TeamAccess' as const, id: id })),
              { type: 'TeamAccess', id: `${teamId}-LIST` },
            ]
          : [],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useLazyGetTeamsQuery,
  useGetTeamQuery,
  useGetTeamMembersQuery,
  useDeleteTeamMemberMutation,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useReplaceTeamMembersMutation,
  useGetTeamProjectAccessQuery,
  useUpdateTeamProjectAccessMutation,
  useGetTeamAccessQuery,
} = teamsApi;
