import { useOpenClose, toast, Box, Icon } from '@hyphen/hyphen-components';
import { useCallback, useMemo } from 'react';
import { CONNECTION_NAME_MAP, INTEGRATION_NAME_MAP } from '../../../constants/integrations';
import { useDeleteConnectionMutation } from '../../../services/connections';
import { Connection, ConnectionType } from '../../../types/connections';
import { ConfirmModal } from '../../common/ConfirmModal';
import { buildConnectionUrl } from './ConnectionLink';
import { Link } from 'react-router-dom';

interface DeleteConnectionProps {
  connection: Connection;
  children: (onClick: () => void, isDeleting: boolean) => React.ReactNode;
}

export const DeleteConnection = ({ connection, children }: DeleteConnectionProps) => {
  const { isOpen, handleOpen, handleClose } = useOpenClose();
  const [deleteConnection, { isLoading: isDeleting, error }] = useDeleteConnectionMutation();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteConnection({
      organizationId: connection.organization.id,
      integrationId: connection.organizationIntegration.id,
      connectionId: connection.id,
    });
    if (!error) {
      toast.success('Connection removed');
      handleClose();
    }
  }, [
    deleteConnection,
    connection.organization.id,
    connection.organizationIntegration.id,
    connection.id,
    handleClose,
  ]);

  const integrationName = INTEGRATION_NAME_MAP[connection.organizationIntegration.type];
  const entityName = connection.entity.name;
  const connectionType = CONNECTION_NAME_MAP[connection.type];
  const connectionLink = useMemo(() => buildConnectionUrl(connection), [connection]);
  const isErrored = connection.status === 'Error';

  let msg = `This will disconnect the connection from the ${connection.entity.type}.`;

  if (!isErrored) {
    switch (connection.type) {
      case ConnectionType.Channel:
        msg = `The ${connectionLink?.label} channel will not be modified.`;
        break;
      case ConnectionType.DistributionList:
        msg = `Members of the team will no longer be kept in sync as recipients of ${connectionLink?.label}.`;
        break;
      case ConnectionType.PermissionGroup:
        msg = `Members of the team will no longer be kept in sync with the ${connectionType} in ${integrationName}.`;
        break;
      default:
        break;
    }
  }

  return (
    <>
      {children(handleOpen, isDeleting)}
      <ConfirmModal
        message={msg}
        confirmButtonLabel="Disconnect"
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Disconnect ${integrationName} ${connectionType} from ${entityName}?`}
        error={error}
      >
        <Box gap="xs">
          {!isErrored && (
            <Box direction="row" alignItems="center" gap="sm">
              View in {integrationName} <Icon name="launch-app" />
            </Box>
          )}
          {connectionLink && (
            <Link to={connectionLink.href} target="_blank" rel="noopener noreferrer">
              <Box
                fontSize="sm"
                className="lh-heading tdl-underline"
                color="secondary"
                style={{ wordBreak: 'break-word' }}
                cursor="pointer"
              >
                {connectionLink?.href}
              </Box>
            </Link>
          )}
        </Box>
      </ConfirmModal>
    </>
  );
};
