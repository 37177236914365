import { Helmet } from 'react-helmet';
import { useToggle } from '../../../providers/ToggleProvider';
import ToggleUsageList from '../../../components/toggles/ToggleUsageList';

export const ToggleUsage = () => {
  const { toggle } = useToggle();

  return (
    <>
      <Helmet title={`${toggle?.key} Usage`} />
      <ToggleUsageList showPagination pageSize={50} />
    </>
  );
};

export default ToggleUsage;
