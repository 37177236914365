import { Box, Button, Icon, Modal, toast, useOpenClose } from '@hyphen/hyphen-components';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Organization, useUploadOrganizationLogoMutation } from '../../../services/organization';
import React, { useCallback } from 'react';
import { ApiError } from '../../../components/ApiError';
import { ErrorMessage, Form, Formik } from 'formik';

const organizationAvatarUploadSchema = yup.object().shape({
  file: yup.mixed<File>()
    .test('fileRequired', 'File is required', (file) => !!file)
    .test('fileSize', 'The file size must be less than 1MB', (file) => {
      return file && file.size <= 1024 * 1024;
  })
});

type OrganizationAvatarUploadSchema = InferType<typeof organizationAvatarUploadSchema>;

const initialValues: OrganizationAvatarUploadSchema = { file: undefined };

export default function OrganizationAvatarUpload({ organization }: { organization: Organization }) {
  const { name, logoUrl } = organization;
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const handleCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const [uploadOrganizationLogo, { isLoading: isUploadingLogo, error }] = useUploadOrganizationLogoMutation();

  const handleUpload = useCallback(async (values: OrganizationAvatarUploadSchema) => {
    if (organization && values.file) {
      const { error } = await uploadOrganizationLogo({ id: organization.id, file: values.file });

      if(!error) {
        toast.success('Logo saved');
        closeModal();
      }
    }
  }, [closeModal, organization, uploadOrganizationLogo]);

  return (
    <Box direction={{ base: 'column', tablet: 'row' }} gap={logoUrl && 'xl'}>
      <Box width={logoUrl ? '128px' : '100'} height="128px" background="secondary" radius="sm" overflow="hidden">
        <Box
          borderWidth="sm"
          borderColor="subtle"
          style={{ borderStyle: 'dashed' }}
          width="100"
          height="100"
          radius="sm"
          alignItems="center"
          justifyContent="center"
          onClick={openModal}
          cursor="pointer"
          color="secondary"
          hover={{ color: 'base', borderColor: 'default' }}
          as="button"
          background="secondary"
          fontSize="sm"
          gap="lg"
        >
          {logoUrl ? (
            <Box width="128px" maxHeight="128px">
              <img src={logoUrl} alt={`logo for the ${name} organization`} />
            </Box>
          ) : (
            <>
              <Icon color="tertiary" name="upload" size="3xl" />
              <Box>Upload a Logo</Box>
            </>
          )}
        </Box>
      </Box>
      {logoUrl && (
        <Button variant="secondary" onClick={openModal} iconPrefix="upload">
          Replace logo
        </Button>
      )}
      <Modal maxWidth="9xl" ariaLabel="Organization Avatar" isOpen={isModalOpen} onDismiss={handleCancel}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleUpload}
          validationSchema={organizationAvatarUploadSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form noValidate>
              <Box gap={{ base: '2xl', tablet: '4xl' }}>
                <Modal.Header title="Upload Organization Logo" id="organization-avatar" onDismiss={handleCancel} />
                <Modal.Body gap="2xl">
                  <Box gap="sm">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      disabled={isSubmitting}
                      accept="image/*"
                      onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                        const file = event.currentTarget.files?.[0];
                        if (file) {
                         await setFieldValue('file', file);
                        }
                      }}
                    />
                    <ErrorMessage name="file" component="div" className="error-message" />
                    <Box direction="row" gap="sm" fontSize="sm" alignItems="flex-start">
                      <Box width="md">
                        <Icon name="c-warning" color="info" />
                      </Box>
                      256px x 256px recommended image size
                    </Box>
                  </Box>
                  {error && <ApiError error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button isLoading={isUploadingLogo} variant="secondary" onClick={handleCancel} isDisabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button isLoading={isUploadingLogo} type="submit">
                    Upload
                  </Button>
                </Modal.Footer>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </Box>
  );
}
