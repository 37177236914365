import {
  Box,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Icon,
  DropdownMenuSeparator,
} from '@hyphen/hyphen-components';
import { Link, useNavigate } from 'react-router-dom';
import { Connection } from '../../../types/connections';
import { useOrganizationAbilityContext } from '../../auth/OrganizationAbilityProvider';
import { buildConnectionUrl, ConnectionUrl } from './ConnectionLink';
import { DeleteConnection } from './DeleteConnection';
import IntegrationIcon from './IntegrationIcon';
import ConnectionStatusBadge from './ConnectionStatusBadge';
import { useCallback } from 'react';
import { useOrganization } from '../../../providers/OrganizationProvider';

interface ConnectionSummaryItemProps {
  connection: Connection;
}

export default function ConnectionSummaryItem({ connection }: ConnectionSummaryItemProps) {
  const ability = useOrganizationAbilityContext();
  const canManageConnection = ability.can('manage', connection);
  const connectionLink = buildConnectionUrl(connection);

  const hasErrors = connection.status === 'Error' && (connection.errors?.length ?? 0) > 0;
  const showDetails = hasErrors || Object.keys(connection.config || {}).length > 0;

  if (connection.status === 'Verifying' && connection.verification?.emails.length) {
    return <ConnectionStatusVerifyingRow connection={connection} canManageConnection={canManageConnection} />;
  }

  if (connection.status !== 'Ready') {
    // Error, Pending, Verifying with no emails
    return <ConnectionStatusRow connection={connection} canManageConnection={canManageConnection} />;
  }

  return (
    <ConnectionReadyRow
      connection={connection}
      connectionLink={connectionLink}
      canManageConnection={canManageConnection}
      showDetails={showDetails}
    />
  );
}

function ConnectionStatusVerifyingRow({
  connection,
  canManageConnection,
}: {
  connection: Connection;
  canManageConnection: boolean;
}) {
  const { id: orgId } = useOrganization();
  const navigate = useNavigate();

  const handleOpenVerifyModal = useCallback(() => {
    navigate(`/${orgId}/integrations/${connection.organizationIntegration.id}/connections/${connection.id}?verify`);
  }, [orgId, connection, navigate]);

  return (
    <Box direction="row" gap="sm" alignItems="flex-start" padding="lg md" background="warning">
      <IntegrationIcon type={connection.organizationIntegration.type} isMobile />
      <Box alignItems="flex-start" gap="xs">
        <ConnectionStatusBadge status={connection.status} />
        <Box fontSize="xs" gap="md" color="base" style={{ wordBreak: 'break-all' }}>
          Verification code sent to {connection.verification?.emails.join(', ')}
          <div>
            <Box
              display="inline-flex"
              alignItems="center"
              gap="xs"
              direction="row"
              as="button"
              background="transparent"
              padding="0"
              borderWidth="0"
              cursor="pointer"
              size="sm"
              variant="secondary"
              onClick={handleOpenVerifyModal}
              className="tdl-underline"
            >
              Verify
              <Icon name="arrow-right" />
            </Box>
          </div>
        </Box>
      </Box>
      {canManageConnection && (
        <Box alignSelf="center">
          <DeleteConnection connection={connection}>
            {(handleOpen) => (
              <Button
                variant="tertiary"
                size="sm"
                onClick={handleOpen}
                iconPrefix="c-remove"
                aria-label="disconnect"
              />
            )}
          </DeleteConnection>
        </Box>
      )}
    </Box>
  );
}

function ConnectionStatusRow({
  connection,
  canManageConnection,
}: {
  connection: Connection;
  canManageConnection: boolean;
}) {
  return (
    <Box width="100" direction="row" justifyContent="space-between" alignItems="center">
      <Box display="block" color="tertiary" fontSize="xs">
        {connection.errors ? (
          <Box direction="row" gap="sm" alignItems="center">
            <IntegrationIcon type={connection.organizationIntegration.type} isMobile />
            <Tooltip>
              <TooltipTrigger asChild>
                <ConnectionStatusBadge status={connection.status} as="button" />
              </TooltipTrigger>
              <TooltipContent>
                {connection.errors.map((error, index) => (
                  <span key={index}>{error}</span>
                ))}
              </TooltipContent>
            </Tooltip>
          </Box>
        ) : (
          <Box direction="row" gap="sm" alignItems="center">
            <IntegrationIcon type={connection.organizationIntegration.type} isMobile />
            <ConnectionStatusBadge status={connection.status} />
          </Box>
        )}
      </Box>
      {canManageConnection && connection.status !== 'Pending' && (
        <DeleteConnection connection={connection}>
          {(handleOpen) => (
            <Button
              variant="tertiary"
              size="sm"
              onClick={handleOpen}
              iconPrefix="c-remove"
              aria-label="disconnect"
            />
          )}
        </DeleteConnection>
      )}
    </Box>
  );
}

function ConnectionReadyRow({
  connection,
  connectionLink,
  canManageConnection,
  showDetails,
}: {
  connection: Connection;
  connectionLink: ConnectionUrl;
  canManageConnection: boolean;
  showDetails: boolean;
}) {
  return (
    <Box width="100" direction="row" justifyContent="space-between" alignItems="center" gap="lg">
      <Box flex="auto" alignItems="flex-start">
        <Link
          to={connectionLink?.href || ''}
          className="display-flex flex-direction-row g-xs align-items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IntegrationIcon type={connection.organizationIntegration.type} isMobile />
          <div className="truncate">{connectionLink?.label}</div>
        </Link>
      </Box>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="open menu" />
        </DropdownMenuTrigger>
        <ConnectionActions
          connection={connection}
          canManageConnection={canManageConnection}
          showDetails={showDetails}
        />
      </DropdownMenu>
    </Box>
  );
}

function ConnectionActions({
  connection,
  canManageConnection,
  showDetails,
}: {
  connection: Connection;
  canManageConnection: boolean;
  showDetails: boolean;
}) {
  return (
    // Wrap the entire DropdownMenuContent so that DeleteConnection modal is not mounted inside the dropdown
    <DeleteConnection connection={connection}>
      {(handleOpen, isDeleting) => (
        <DropdownMenuContent align="end" side="bottom">
          {showDetails && (
            <DropdownMenuItem asChild>
              <Link
                to={`/${connection.organization.id}/integrations/${connection.organizationIntegration.id}/connections/${connection.id}/`}
              >
                <Icon name="c-info" color="tertiary" /> Details
              </Link>
            </DropdownMenuItem>
          )}
          {canManageConnection && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={handleOpen} disabled={isDeleting} className="font-color-danger">
                <Icon name="c-remove" /> Disconnect
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      )}
    </DeleteConnection>
  );
}
