import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Box, Button, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { forbiddenWords } from '../../utils/validations';
import { ProjectEnvironment } from '../../providers/ProjectEnvironmentsProvider';
import { useUpdateProjectEnvironmentMutation } from '../../services/environments';
import { ApiError } from '../ApiError';

const environmentSchema = yup.object().shape({
  id: yup.string().required('ID is required'),
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Alternate ID')
    .notOneOf(forbiddenWords, `Can not contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
  color: yup
    .string()
    .matches(/^#[0-9A-F]{6}$/i, 'Invalid color format')
    .required('Color is required'),
});

export type EnvironmentSchema = InferType<typeof environmentSchema>;

export default function ProjectEnvironmentForm({ environment }: { environment: ProjectEnvironment }) {
  const [updateProjectEnvironment, { error: updateError }] = useUpdateProjectEnvironmentMutation();

  const initialValues = {
    id: environment?.id || '',
    name: environment?.name || '',
    alternateId: environment?.alternateId || '',
    color: environment?.color || '#ffc800',
  };

  const handleEdit = async (values: EnvironmentSchema) => {
    const { data, error } = await updateProjectEnvironment({
      projectId: environment.project.id,
      organizationId: environment.organization.id,
      environmentId: environment.id,
      data: values,
    });
    if (!error && data) {
      toast.success('Environment updated successfully');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={environmentSchema}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={handleEdit}
    >
      {({ errors, isSubmitting, touched }) => {
        return (
          <Form noValidate>
            <Box gap="2xl">
              <Field
                label="Environment Name"
                helpText="Used only as a human-readable reference"
                name="name"
                id="name"
                autoComplete="off"
                component={FormikTextInput}
                error={errors.name}
                isDisabled={isSubmitting}
              />
              <Field
                label="Alternate ID"
                helpText="Unique identifier for the environment. Can not be modified after creation."
                name="alternateId"
                id="alternateId"
                autoComplete="off"
                component={FormikTextInput}
                error={errors.alternateId}
                isDisabled={true}
              />
              <Box>
                <Box as="label" htmlFor="color" margin="0 0 xs" fontSize="sm">
                  Environment Color
                </Box>
                <Field type="color" name="color" id="color" disabled={isSubmitting} />
                {errors.color && touched.color && (
                  <Box color="danger" fontSize="sm" margin="sm 0 0 0">
                    {typeof errors.color === 'string' && errors.color}
                  </Box>
                )}
              </Box>
              {updateError && <ApiError error={updateError} title="Error updating project environment" />}
              <Box display="block">
                <Button variant="primary" size="sm" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
