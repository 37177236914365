import { cleanRequest, createFormData } from '../../utils/requestUtils';
import { api } from '../api';
import { AccessEntry, ListResponse } from '../types';
import {
  CodeStats,
  CreateCodeRequestBody,
  GetCodesQueryParams,
  LinkCode,
  QRCode,
  QRCodeRequestBody,
  UpdateCodeRequestBody,
} from '../../types/domain';
import { addQueryParams } from '../../utils/addQueryParams';

export const codeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createLinkCode: builder.mutation<LinkCode, { codeBody: CreateCodeRequestBody; organizationId: string }>({
      query: ({ codeBody, organizationId }) => ({
        url: `api/organizations/${organizationId}/link/codes`,
        method: 'POST',
        body: cleanRequest(codeBody),
      }),
      invalidatesTags: (_result, _error, { organizationId }) => [
        { type: 'LinkCodes', id: 'LIST' },
        { type: 'OrganizationExecutionContext', id: organizationId },
      ],
    }),
    getLinkCodes: builder.query<ListResponse<LinkCode>, GetCodesQueryParams>({
      query: ({ pageSize, pageNum, tags, search, organizationId } = {}) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (tags && tags.length) params.append('tags', tags.join(','));
        if (search && search.trim().length) params.append('search', search.trim());

        return {
          url: `api/organizations/${organizationId}/link/codes?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result?.data
          ? [...result.data.map(({ id }) => ({ type: 'LinkCodes' as const, id })), { type: 'LinkCodes', id: 'LIST' }]
          : [],
    }),
    getLinkCode: builder.query<LinkCode, { id: string; organizationId?: string }>({
      query: ({ id, organizationId }) => `api/organizations/${organizationId}/link/codes/${id}`,
      providesTags: (result) => (result ? [{ type: 'LinkCodes', id: result.id }] : []),
    }),
    getLinkCodeStats: builder.query<
      CodeStats,
      { id: string; organizationId?: string; startDate?: string; endDate?: string }
    >({
      query: ({ id, organizationId, startDate, endDate }) =>
        addQueryParams(`/api/organizations/${organizationId}/link/codes/${id}/stats`, { startDate, endDate }),
      providesTags: (result, _error, id) => (result ? [{ type: 'LinkCodes', id: `${id}-STATS-LIST` }] : []),
    }),
    deleteLinkCode: builder.mutation<void, { codeId: string; organizationId?: string }>({
      query: ({ codeId, organizationId }) => ({
        url: `api/organizations/${organizationId}/link/codes/${codeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error) => [{ type: 'LinkCodes', id: 'LIST' }],
    }),
    updateLinkCode: builder.mutation<
      LinkCode,
      { codeId: string; updateData: UpdateCodeRequestBody; organizationId: string }
    >({
      query: ({ codeId, updateData, organizationId }) => ({
        url: `api/organizations/${organizationId}/link/codes/${codeId}`,
        method: 'PATCH',
        body: cleanRequest(updateData),
      }),
      invalidatesTags: (_result, _error, { codeId }) => [
        { type: 'LinkCodes', id: codeId },
        { type: 'LinkCodes', id: 'TAGS-LIST' },
        { type: 'LinkCodes', id: 'LIST' },
      ],
    }),
    getTags: builder.query<string[], string | undefined>({
      query: (organizationId) => `/api/organizations/${organizationId}/link/codes/tags`,
      providesTags: (result) => (result ? [{ type: 'LinkCodes', id: 'TAGS-LIST' }] : []),
    }),
    getQrsCode: builder.query<ListResponse<QRCode>, { codeId: string; organizationId?: string }>({
      query: ({ codeId, organizationId }) => `/api/organizations/${organizationId}/link/codes/${codeId}/qrs`,
      providesTags: (result, _error, id) => (result ? [{ type: 'LinkCodes', id: id.codeId }] : []),
    }),
    createQrCode: builder.mutation<QRCode, { codeId: string; data: QRCodeRequestBody }>({
      query: ({ codeId, data }) => ({
        url: `/api/organizations/${data?.organizationId}/link/codes/${codeId}/qrs`,
        method: 'POST',
        body: createFormData(data),
      }),
      invalidatesTags: (_result, _error, { codeId }) => [{ type: 'LinkCodes', id: codeId }],
    }),
    deleteQrCode: builder.mutation<void, { codeId: string; qrId: string; organizationId?: string }>({
      query: ({ codeId, qrId, organizationId }) => ({
        url: `/api/organizations/${organizationId}/link/codes/${codeId}/qrs/${qrId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { codeId }) => [{ type: 'LinkCodes', id: codeId }],
    }),
    getLinkCodeAccess: builder.query<
      ListResponse<AccessEntry>,
      { codeId: string; organizationId: string; pageNum: number; pageSize: number }
    >({
      query: ({ codeId, organizationId, pageNum, pageSize }) => {
        const params = new URLSearchParams();
        params.append('entityIds', codeId);
        params.append('entityIds', organizationId);
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (pageSize) params.append('pageSize', pageSize.toString());
        return {
          url: `api/organizations/${organizationId}/access?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, { codeId }) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'LinkCodeAccess' as const, id: id })),
              { type: 'LinkCodeAccess', id: `${codeId}-LIST` },
            ]
          : [],
    }),
  }),
});

export const {
  useCreateLinkCodeMutation,
  useDeleteLinkCodeMutation,
  useGetLinkCodesQuery,
  useGetLinkCodeQuery,
  useGetLinkCodeStatsQuery,
  useGetTagsQuery,
  useUpdateLinkCodeMutation,
  useGetQrsCodeQuery,
  useCreateQrCodeMutation,
  useDeleteQrCodeMutation,
  useGetLinkCodeAccessQuery,
} = codeApi;
