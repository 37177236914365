import { Box, Button, Card, DrawerProvider, DrawerTrigger } from '@hyphen/hyphen-components';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import SegmentRuleDrawer from './SegmentRuleDrawer';
import { Fragment, useCallback, useEffect, useState } from 'react';
import SegmentRule from './SegmentRule';
import LogicalOperator from '../toggles/LogicalOperator';
import { useSegment } from '../../providers/SegmentProvider';
import { Skeleton } from '../common/Skeleton';
import { SEGMENT_TEXT } from '../../constants/segments';
import TooltipButton from '../common/TooltipButton';
import TooltipIcon from '../common/TooltipIcon';
import { copyToClipboard } from '../../utils/copyToClipboard';

export default function SegmentRules() {
  const { segment, isLoading } = useSegment();
  const ability = useOrganizationAbilityContext();
  const canUpdateEntity = segment && ability.can('update', segment);

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [highlightTimeout, setHighlightTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (highlightedIndex !== null) {
      if (highlightTimeout) clearTimeout(highlightTimeout);
      const timer = setTimeout(() => {
        setHighlightedIndex(null);
      }, 2100);
      setHighlightTimeout(timer);
      return () => clearTimeout(timer);
    }
    // do not need to include highlightTimeout in the dependency array since it is only used for cleanup and does not change the effect behavior.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedIndex]);

  const handleUpdate = useCallback((updatedIndex: number) => {
    setHighlightedIndex(updatedIndex);
  }, []);

  const handleCopyJsonLogic = useCallback(() => {
    if (segment) {
      copyToClipboard(JSON.stringify(segment.logic));
    }
  }, [segment]);

  const cardHeaderProps = isLoading
    ? {
        title: <Skeleton height="20px" width="200px" background="primary" />,
        description: <Skeleton height="16px" width="100px" background="primary" />,
        children: <Skeleton height="32px" width="75px" background="primary" />,
      }
    : {
        title: (
          <Box direction="row" alignItems="center" gap="xs">
            {SEGMENT_TEXT.segmentRuleHeader}
            <TooltipIcon name="c-question" size="md" content={SEGMENT_TEXT.segmentRuleDescription} />
          </Box>
        ),
        children: (
          <Box direction="row" gap="sm">
            <TooltipButton
              size="sm"
              variant="secondary"
              iconPrefix="copy-document"
              content="Copy segment JsonLogic"
              onClick={handleCopyJsonLogic}
            />
            {canUpdateEntity && (
              <DrawerProvider>
                <DrawerTrigger asChild>
                  <Button size="sm" variant="primary">
                    {SEGMENT_TEXT.segmentRuleButtonLabel}
                  </Button>
                </DrawerTrigger>
                <SegmentRuleDrawer onUpdateItems={handleUpdate} />
              </DrawerProvider>
            )}
          </Box>
        ),
      };

  return (
    <Card background="secondary" padding="0" radius="md" borderWidth="0" shadow="0">
      <Card.Header borderWidth="0 0 sm 0" alignItems="center" {...cardHeaderProps}></Card.Header>

      {isLoading ? (
        <Box padding={{ base: '2xl', tablet: '3xl' }}>
          <Skeleton height="65px" width="100%" background="primary" />
        </Box>
      ) : segment?.parsedLogic?.conditions?.length ? (
        <Box as="ol" gap={{ base: 'lg', desktop: '2xl' }} padding={{ base: '2xl', tablet: '3xl' }} fontSize="xs">
          {segment?.parsedLogic?.conditions?.map((condition, index) => (
            <Fragment key={index}>
              <SegmentRule
                condition={condition}
                ruleIndex={index}
                handleUpdateItems={handleUpdate}
                isHighlighted={index === highlightedIndex}
              />
              {index < (segment?.parsedLogic?.conditions?.length || 0) - 1 && (
                <LogicalOperator isDragging={false} operator={(segment.parsedLogic?.conjunction as any) || 'OR'} />
              )}
            </Fragment>
          ))}
        </Box>
      ) : (
        <Box padding="2xl" fontSize="sm" color="tertiary">
          {SEGMENT_TEXT.segmentRuleEmpty}
        </Box>
      )}
    </Card>
  );
}
