import {
  Box,
  Card,
  DateInput,
  SelectInput,
  SelectInputOptions,
  SimulatedEventPayloadType,
  Spinner,
} from '@hyphen/hyphen-components';
import { ParentSize } from '@visx/responsive';
import BarChart from '../BarChart';
import { VerticalBarChart } from '../VerticalBarChart';
import { useEffect, useState } from 'react';
import { useGetLinkCodeStatsQuery } from '../../services/link/codes';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useParams } from 'react-router-dom';
import { CodeStats } from '../../types/domain';
import { formatDateToUTC, last14Days, last30Days, last7Days } from '../../utils/dateUtils';

type DateOption = {
  value: string;
  label: string;
  dateRange: [Date, Date];
};

const dateOptions: DateOption[] = [
  { value: '7days', label: 'Last 7 days', dateRange: [last7Days, new Date()] },
  { value: '14days', label: 'Last 14 days', dateRange: [last14Days, new Date()] },
  { value: '30days', label: 'Last 30 days', dateRange: [last30Days, new Date()] },
  { value: 'custom', label: 'Custom Range', dateRange: [new Date(), new Date()] },
];

export const LinkDetailStats = () => {
  const { organization } = useOrganization();
  const organizationId = organization?.id;
  const { id } = useParams<{ id: any }>();

  const initialDateFilter = () => {
    const storedFilters = localStorage.getItem('codeStatsFilter');
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      const matchingFilter = parsedFilters[id];

      if (matchingFilter) {
        const currentDate = new Date();

        const dateRanges: { [key: string]: [Date, Date] } = {
          '7days': [last7Days, currentDate],
          '14days': [last14Days, currentDate],
          '30days': [last30Days, currentDate],
        };

        if (dateRanges[matchingFilter.value]) {
          matchingFilter.dateRange = dateRanges[matchingFilter.value];

          parsedFilters[id] = matchingFilter;
          localStorage.setItem('codeStatsFilter', JSON.stringify(parsedFilters));
        }

        return matchingFilter;
      }
    }
    return dateOptions[0];
  };

  const initialDateRange = (): [Date, Date] => {
    const filter = initialDateFilter();

    if (filter?.dateRange && filter.dateRange.length === 2) {
      return [new Date(filter.dateRange[0]), new Date(filter.dateRange[1])];
    }
    return dateOptions[3].dateRange;
  };

  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [dateRange, setDateRange] = useState<[Date, Date]>(initialDateRange);
  const [referralsData, setReferralsData] = useState<{ name: string; total: number }[]>([]);

  const {
    data: codeStatsData,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetLinkCodeStatsQuery({
    id,
    organizationId,
    startDate: formatDateToUTC(dateRange[0]),
    endDate: formatDateToUTC(dateRange[1]),
  });

  useEffect(() => {
    if (codeStatsData) {
      updatedReferrals(codeStatsData);
    }
  }, [codeStatsData]);

  const updatedReferrals = (codeStats: CodeStats) => {
    if (codeStats?.referrals) {
      const transformedReferrals = codeStats.referrals.map((referral) => ({
        name: referral.url,
        total: referral.total,
      }));
      setReferralsData(transformedReferrals);
    }
  };

  const handlePredefinedDate = (e: SimulatedEventPayloadType) => {
    const selectedValue = e.target.value as SelectInputOptions & DateOption;
    const newDateFilter = selectedValue?.value === 'custom' ? { ...selectedValue, dateRange } : selectedValue;

    setDateRange(newDateFilter.dateRange);
    setDateFilter(newDateFilter);

    const linksWithDateFilter = localStorage.getItem('codeStatsFilter');
    const dateFilteredParsed = linksWithDateFilter ? JSON.parse(linksWithDateFilter) : {};

    dateFilteredParsed[id] = { ...newDateFilter };

    localStorage.setItem('codeStatsFilter', JSON.stringify(dateFilteredParsed));

    refetch();
  };

  const handleCustomDate = ([startDate, endDate]: [Date, Date]) => {
    const storedFilters = localStorage.getItem('codeStatsFilter');
    const storedFiltersParsed = storedFilters ? JSON.parse(storedFilters) : {};

    const endDateFilter = endDate ?? new Date();

    storedFiltersParsed[id] = {
      ...storedFiltersParsed[id],
      dateRange: [startDate, endDateFilter],
    };

    localStorage.setItem('codeStatsFilter', JSON.stringify(storedFiltersParsed));

    setDateRange([startDate, endDate]);
    refetch();
  };

  const EmptyData = () => (
    <Box margin="2xl 0 0 0" color="disabled" fontSize="sm">
      No data
    </Box>
  );

  return (
    <>
      <Card>
        <Card.Header
          title={
            <Box
              width="100"
              gap="xs"
              direction={{ base: 'column', tablet: 'row' }}
              alignItems={{ base: 'stretch', tablet: 'center' }}
            >
              <Box width="140px" fontWeight="semibold">
                Clicks Over Time
              </Box>
              <Box direction="row" gap="sm">
                <Box width="6xl">
                  <SelectInput
                    id="time"
                    /*@ts-ignore*/
                    onChange={(event) => handlePredefinedDate(event)}
                    label={''}
                    options={dateOptions}
                    value={dateFilter}
                    hideLabel
                    size="sm"
                  />
                </Box>
                {dateFilter.value === 'custom' && (
                  <Box width="50">
                    <DateInput
                      textInputProps={{
                        id: 'date',
                        name: 'date',
                        placeholder: 'Select a Date Range',
                        hideLabel: true,
                      }}
                      datePickerProps={{
                        // @ts-ignore
                        onChange: handleCustomDate,
                        selected: dateRange[0],
                        startDate: dateRange[0],
                        endDate: dateRange[1],
                        selectsRange: true,
                        maxDate: new Date(),
                      }}
                      size="sm"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          }
        />
        <Card.Section
          gap="sm"
          className="fade-in"
          padding="0 2xl"
          fontSize={{ base: 'lg', tablet: 'xl', desktop: '3xl' }}
        >
          <Box direction="row" gap="2xl" alignItems="baseline" fontWeight="bold">
            <Box direction="row" gap="sm" alignItems="baseline">
              <Box>{codeStatsData?.clicks?.total?.toLocaleString('en-US')}</Box>
              <Box width="xl" height="xl" style={{ backgroundColor: 'var(--color-background-chart-1)' }} />
              <Box fontSize="sm" color="tertiary" fontWeight="semibold">
                Total
              </Box>
            </Box>
            <Box direction="row" gap="sm" alignItems="baseline">
              <Box>{codeStatsData?.clicks?.unique?.toLocaleString('en-US')}</Box>

              <Box width="xl" height="xl" style={{ backgroundColor: 'var(--color-background-chart-2)' }} />
              <Box fontSize="sm" color="tertiary" fontWeight="semibold">
                Unique
              </Box>
            </Box>
          </Box>
        </Card.Section>
        <Card.Section padding="0 2xl 2xl 2xl" borderWidth="0">
          <Box display="flex" direction="row" gap="md" margin="0 0 xl"></Box>

          <div style={{ width: '100%', minHeight: '240px', height: '240px', position: 'relative' }}>
            {(isLoading || isFetching) && (
              <div className="loading-mask">
                <Spinner size="xl" />
              </div>
            )}
            {error && (
              <Box margin="2xl 0 0 0" color="disabled" fontSize="sm">
                {(error as any)?.error || 'Failed to load data'}
              </Box>
            )}
            {codeStatsData?.clicks?.byDay && (
              <ParentSize>
                {({ width, height }) => (
                  <BarChart width={width} height={height} data={codeStatsData?.clicks?.byDay} />
                )}
              </ParentSize>
            )}
          </div>
        </Card.Section>
      </Card>

      <Box gap={{ base: '2xl', tablet: '4xl' }} direction={{ base: 'column', tablet: 'row' }}>
        <Card>
          <Card.Section title="Referral URLs">
            {referralsData.length > 0 ? (
              <VerticalBarChart margin="2xl 0 0 0" tableData={referralsData} />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
        <Card>
          <Card.Section title="User Locations">
            {codeStatsData && codeStatsData?.locations?.length > 0 ? (
              <VerticalBarChart
                margin="2xl 0 0 0"
                tableData={codeStatsData?.locations?.map((l: any) => ({ ...l, name: l.country }))}
              />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
      </Box>

      <Box gap={{ base: '2xl', tablet: '4xl' }} direction={{ base: 'column', tablet: 'row' }}>
        <Card>
          <Card.Section title="Browser Usage">
            {codeStatsData && codeStatsData.browsers?.length > 0 ? (
              <VerticalBarChart margin="2xl 0 0 0" tableData={codeStatsData.browsers} />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
        <Card>
          <Card.Section title="Device Usage">
            {codeStatsData && codeStatsData.devices?.length > 0 ? (
              <VerticalBarChart margin="2xl 0 0 0" tableData={codeStatsData.devices} />
            ) : (
              <EmptyData />
            )}
          </Card.Section>
        </Card>
      </Box>
    </>
  );
};
