import { Box, Button, Card, Pagination, Spinner } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import { DomainItem } from '../../components/domains/DomainItem';
import { useGetDomainsQuery } from '../../services/link/domains';
import { useEffect, useState } from 'react';
import { DnsStatus, Domain, SslStatus } from '../../types/domain';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import { subject } from '@casl/ability';

const PAGE_SIZE = 5;

export default function Domains() {
  const [pageNum, setPageNumber] = useState<number>(1);
  const { organization } = useOrganization();

  const ability = useOrganizationAbilityContext();
  const canCreateDomain = ability.can(
    'create',
    subject(EntityNames.Domain, { organization: { id: organization?.id } }),
  );
  const canManageDomain = ability.can(
    'manage',
    subject(EntityNames.Domain, { organization: { id: organization?.id } }),
  );

  const organizationId = organization?.id;
  const [pollingInterval, setPollingInterval] = useState(3000);
  const {
    data: domains,
    error,
    isLoading,
  } = useGetDomainsQuery({ pageSize: PAGE_SIZE, pageNum, organizationId }, { pollingInterval });

  useEffect(() => {
    if (
      domains &&
      domains.data.length &&
      domains.data.some(
        (domain) =>
          domain.dnsStatus === DnsStatus.Verified &&
          (domain.sslStatus === SslStatus.Pending || domain.sslStatus === SslStatus.Generating),
      )
    ) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(0);
    }
  }, [setPollingInterval, domains]);

  const domainsData = domains?.data || [];

  useEffect(() => {
    if (!isLoading && !error && domains) {
      setPageNumber(domains.pageNum);
    }
  }, [domains, error, isLoading]);

  return (
    <Box width="100" maxWidth="10xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Domains</title>
      </Helmet>
      <PageHeader title="Domains" description="Add a domain in order to create short links" />
      <BreadCrumbPortal />
      {canCreateDomain && (
        <Button asChild variant="primary">
          <Link to="add">Add Domain</Link>
        </Button>
      )}
      {isLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner size="md" />
          Loading...
        </Box>
      ) : (
        <>
          <Box width="100" gap="lg">
            <Card>
              {domainsData.length === 0 && <Box padding="3xl">No domains added yet</Box>}
              {domainsData.map((domain: Domain) => (
                <DomainItem
                  key={domain.id}
                  domain={domain}
                  organization={organization}
                  canManageDomain={canManageDomain}
                />
              ))}
              {domainsData.length > 0 && (domains?.total ?? 0) > PAGE_SIZE && (
                <Pagination
                  activePage={pageNum}
                  itemsPerPage={PAGE_SIZE}
                  onChange={setPageNumber}
                  totalItemsCount={domains?.total ?? 0}
                  isCompact
                  arePagesVisible
                  numberOfPagesDisplayed={3}
                />
              )}
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
}
