import { api } from './api';
import { ListResponse } from './types';

export interface BillingInvoice {
  providerId: string;
  amount: number;
  createdDate: string;
  subTotal: number;
  total: number;
  discount: number;
  status: InvoiceStatus;
  periodEnd: string;
  periodStart: string;
  cycleId?: number;
  tax?: number;
  dueDate?: string;
}

export interface BillingCycle {
  id: number;
  startDate: Date;
  endDate: Date;
}

export enum InvoiceStatus {
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  PastDue = 'PastDue',
}

export const isInvoiceStatus = (value: string): value is InvoiceStatus => {
  return Object.values(InvoiceStatus).includes(value as InvoiceStatus);
};

export enum SubscriptionStatus {
  Active = 'Active',
  Cancelling = 'Cancelling',
  Cancelled = 'Cancelled',
  PastDue = 'PastDue',
}

export interface BillingAccountResponse {
  id: string;
  name: string;
  email: string;
  phone?: string;
  address?: {
    streetAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
  };
  provider: string;
  providerId: string;
  providerData: any;
  paymentMethods: BillingAccountPaymentMethodResponse[];
  subscription: {
    status: SubscriptionStatus;
    providerId: Date;
    startDate: Date;
    cycles?: BillingCycle[];
  };
  owners: BillingAccountOwner[];
  invoices?: BillingInvoice[];
}

export interface BillingCycle {
  id: number;
  startDate: Date;
  endDate: Date;
  invoicePreviewAmountDue?: number;
}
export interface BillingAccountPaymentMethodResponse {
  providerId: string;
  type: 'Card' | 'ACH';
  last4?: string;
  cardType?: 'Visa' | 'Mastercard' | 'Amex' | 'Discover';
  expiration?: string;
  isDefault: boolean;
}

export interface BillingAccountOwner {
  userId: string;
  email: string;
  name: string;
}
export interface OrganizationBillingAccount {
  organization: {
    id: string;
    name: string;
  };
  lastUpdated: string;
  total: {
    env: {
      pull: number;
      push: number;
    };
    link: {
      created: number;
    };
    netInfo: {
      requests: number;
    };
    toggle: {
      evaluations: number;
    };
    user: {
      active: number;
    };
  };
}

export interface BillingAccountUsageResponse {
  billingCycle: number;
  remainingDays: number;
  totalDays: number;
  startDate: string;
  endDate: string;
  lastUpdated: string;
  organizations: OrganizationBillingAccount[];
  total: {
    env: {
      pull: number;
      push: number;
    };
    link: {
      created: number;
    };
    netInfo: {
      requests: number;
    };
    toggle: {
      evaluations: number;
    };
    user: {
      active: number;
    };
  };
}

export interface UpdateSubscriptionStatusResponse {
  id: string;
  status: SubscriptionStatus;
  message: string;
}

export const billingAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPaymentMethod: builder.mutation<
      BillingAccountPaymentMethodResponse,
      { id: string; confirmationToken: string }
    >({
      query: ({ id, confirmationToken }) => ({
        url: `api/billing-accounts/${id}/payment-methods`,
        method: 'POST',
        body: { confirmationToken },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: `${id}_BillingAccount` },
        { type: 'BillingAccounts', id },
        { type: 'BillingAccounts', id: 'LIST' },
      ],
    }),
    createOwner: builder.mutation<void, { id: string; email: string }>({
      query: ({ id, email }) => ({
        url: `api/billing-accounts/${id}/owners`,
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: `${id}_BillingAccount` },
        { type: 'BillingAccounts', id },
        { type: 'BillingAccounts', id: 'LIST' },
      ],
    }),
    getBillingAccountById: builder.query<BillingAccountResponse, string>({
      query: (id) => `api/billing-accounts/${id}`,
      providesTags: (result) => (result ? [{ type: 'BillingAccounts', id: result.id }] : []),
    }),
    getBillingAccounts: builder.query<ListResponse<BillingAccountResponse>, void>({
      query: () => 'api/billing-accounts',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map((d) => ({ type: 'BillingAccounts' as const, id: d.id })),
              { type: 'BillingAccounts', id: 'LIST' },
            ]
          : [],
    }),
    getBillingAccountUsage: builder.query<BillingAccountUsageResponse, { id: string; billingCycle?: number }>({
      query: ({ id, billingCycle }) => {
        const params = new URLSearchParams();
        if (billingCycle) params.append('billingCycle', billingCycle.toString());
        return {
          url: `api/billing-accounts/${id}/usage?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) => (result ? [{ type: 'BillingAccounts', id: 'USAGE' }] : []),
    }),
    updateBillingAccount: builder.mutation<
      BillingAccountResponse,
      { id: string; updates: Partial<Pick<BillingAccountResponse, 'name' | 'email' | 'phone' | 'address'>> }
    >({
      query: ({ id, updates }) => ({
        url: `api/billing-accounts/${id}`,
        method: 'PATCH',
        body: updates,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: `${id}_BillingAccount` },
        { type: 'BillingAccounts', id },
        { type: 'BillingAccounts', id: 'LIST' },
      ],
    }),
    updateSubscriptionStatus: builder.mutation<
      UpdateSubscriptionStatusResponse,
      { id: string; status: SubscriptionStatus }
    >({
      query: ({ id, status }) => ({
        url: `api/billing-accounts/${id}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: `${id}_BillingAccount` },
        { type: 'BillingAccounts', id },
        { type: 'BillingAccounts', id: 'LIST' },
      ],
    }),
    setDefaultPaymentMethod: builder.mutation<void, { id: string; providerId: string }>({
      query: ({ id, providerId }) => ({
        url: `api/billing-accounts/${id}/payment-methods/${providerId}`,
        method: 'PATCH',
        body: { isDefault: true },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: `${id}_BillingAccount` },
        { type: 'BillingAccounts', id },
        { type: 'BillingAccounts', id: 'LIST' },
      ],
    }),
    deletePaymentMethod: builder.mutation<void, { id: string; providerId: string }>({
      query: ({ id, providerId: paymentMethodId }) => ({
        url: `api/billing-accounts/${id}/payment-methods/${paymentMethodId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: `${id}_BillingAccount` },
        { type: 'BillingAccounts', id },
        { type: 'BillingAccounts', id: 'LIST' },
      ],
    }),
    deleteOwner: builder.mutation<void, { id: string; userId: string }>({
      query: ({ id, userId }) => ({
        url: `api/billing-accounts/${id}/owners/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Organizations', id: `${id}_BillingAccount` },
        { type: 'BillingAccounts', id },
        { type: 'BillingAccounts', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useCreatePaymentMethodMutation,
  useCreateOwnerMutation,
  useGetBillingAccountByIdQuery,
  useGetBillingAccountsQuery,
  useLazyGetBillingAccountUsageQuery,
  useUpdateBillingAccountMutation,
  useUpdateSubscriptionStatusMutation,
  useSetDefaultPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useDeleteOwnerMutation,
  useLazyGetBillingAccountsQuery,
} = billingAccountApi;
