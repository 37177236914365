import { Box, Button, Card, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import { ApiError } from '../ApiError';
import { useCallback } from 'react';
import { useUpdateTeamMutation } from '../../services/teams';
import { DynamicTeamsForm } from './DynamicTeamsForm';
import * as yup from 'yup';
import { InferType } from 'yup';
import { generateCriteriaSchema } from '../../schema/teams';
import { useNavigate } from 'react-router-dom';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { TeamDetails } from '../../types/teams';

export default function EditTeamForm({ data, organizationId }: { data: TeamDetails; organizationId: string }) {
  const ability = useOrganizationAbilityContext();
  const [updateTeam, { error }] = useUpdateTeamMutation();
  const navigate = useNavigate();

  const canUpdateTeam = ability.can('update', data);

  const initialValues = {
    name: data.name,
    teamHandle: 'test',
    criteria: data.criteria,
  };

  const editTeamSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    teamHandle: yup
      .string()
      .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid team alias')
      .required('Team handle is required'),
    ...generateCriteriaSchema('UPDATE', data.type),
  });

  type EditTeamSchema = InferType<typeof editTeamSchema>;

  const handleOnSubmit = useCallback(
    async (values: EditTeamSchema) => {
      const body = {
        name: values.name,
        type: data.type,
        memberCount: data.memberCount,
        channels: data.channels,
      };

      if (data.type === 'dynamic') {
        // @ts-ignore
        body.criteria = values.criteria;
      }
      const { error, data: responseData } = await updateTeam({
        id: data.id,
        orgId: organizationId,
        data: body,
      });
      if (!error && responseData) {
        toast.success('Team updated', { duration: 5000 });
        navigate(`/${organizationId}/teams/${data.id}`);
      }
    },
    [data.type, data.memberCount, data.channels, data.id, updateTeam, organizationId, navigate],
  );

  if (!canUpdateTeam)
    return (
      <Box fontSize="sm" color="secondary">
        You do not have permission to view this teams settings.
      </Box>
    );

  return (
    <Card>
      <Card.Section>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={editTeamSchema}
          enableReinitialize
        >
          {({ isSubmitting, errors, values }) => {
            return (
              <Form noValidate>
                <Box gap="2xl">
                  <Field
                    id="name"
                    name="name"
                    label="Team Name"
                    component={FormikTextInput}
                    error={errors.name}
                    isRequired
                  />

                  {data.type === 'dynamic' && (
                    <Box gap="xl">
                      <Card>
                        <Card.Section>
                          <Box as="h4" fontSize="md" fontWeight="medium">
                            Team Membership Criteria
                          </Box>
                          <Box as="p" color="secondary" fontSize="sm">
                            Membership for this team is automatically organized based on:
                          </Box>
                        </Card.Section>
                        <Card.Section gap="xl">
                          <DynamicTeamsForm values={values} isSubmitting={isSubmitting} />
                        </Card.Section>
                      </Card>
                    </Box>
                  )}

                  {error && <ApiError error={error} />}
                  <Box direction="row">
                    <Button variant="primary" type="submit" isLoading={isSubmitting}>
                      Save
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Card.Section>
    </Card>
  );
}
