import { useBreakpoint, Box, FormikTextInput, Toggle } from '@hyphen/hyphen-components';
import { useFormikContext, Field } from 'formik';
import { ConnectionType, ConnectionTypeLabel } from '../../types/connections';
import { Integration, IntegrationType } from '../../types/integrations';
import getIntegrationConnectionInputLabel from '../../utils/getIntegrationConnectionInputLabel';
import getIntegrationConnectionInputPlaceholder from '../../utils/getIntegrationConnectionInputPlaceholder';
import IntegrationIcon from './connections/IntegrationIcon';
import { useState } from 'react';
import TooltipIcon from '../common/TooltipIcon';

const IntegrationConnectionCheckbox = ({
  integration,
  connectionType,
}: {
  integration: Integration;
  connectionType: ConnectionType;
}) => {
  const { isSubmitting, errors, setFieldValue, values } = useFormikContext<{
    integrations: { id: string; input: string | undefined }[];
  }>();

  const [useExisting, setUseExisting] = useState<boolean>(false);
  const { isPhone } = useBreakpoint();

  const integrationIndex = values.integrations.findIndex(
    (item) => item.id === `${integration.id}-${connectionType}`,
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    if (checked) {
      setFieldValue('integrations', [...values.integrations, { id: value, input: '' }]);
    } else {
      setFieldValue(
        'integrations',
        values.integrations.filter((item) => item.id !== value),
      );
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (integrationIndex !== -1) {
      const updatedIntegrations = [...values.integrations];
      updatedIntegrations[integrationIndex].input = value;
      setFieldValue('integrations', updatedIntegrations);
    }
  };

  const isChecked = values.integrations.some((item) => item.id === `${integration.id}-${connectionType}`);

  const inputRequired =
    // npm code registry requires existing package name
    (connectionType === ConnectionType.CodeRegistry && integration.type === IntegrationType.npm) ||
    // user connections require input
    connectionType === ConnectionType.User;

  const inputLabel = getIntegrationConnectionInputLabel(connectionType, integration.type);

  return (
    <Box
      display="grid"
      width="100"
      style={
        isPhone && isChecked ? { gridTemplateRows: '32px 1fr' } : isPhone ? {} : { gridTemplateColumns: '200px 1fr' }
      }
      gap={{ base: 'xs', tablet: 'md' }}
      alignItems={{ tablet: 'center' }}
      padding="sm lg"
      radius="sm"
      background="secondary"
      minHeight="48px"
    >
      <Box as="label" key={integration.id} direction="row" gap="sm">
        <Field
          type="checkbox"
          name={`integrations.${integration.id}.id`}
          value={`${integration.id}-${connectionType}`}
          disabled={isSubmitting}
          error={errors.integrations}
          onChange={handleCheckboxChange}
          checked={isChecked}
        />
        <IntegrationIcon type={integration.type} isMobile={false} />
      </Box>

      {isChecked && (
        <Box direction={{ base: 'column' }} gap={{ base: 'xs', tablet: 'sm' }}>
          {!inputRequired && (
            <Box color="secondary" fontSize="xs" width="110px">
              <Toggle
                id={`integrations.${integrationIndex}.useExisting`}
                isChecked={useExisting}
                onChange={() => setUseExisting(!useExisting)}
                label="Use existing"
                size="sm"
              />
            </Box>
          )}
          {(inputRequired || useExisting) && (
            <Box flex="auto" direction="row" gap="sm" alignItems="center">
              <Box flex="auto" maxWidth="360px">
                <Field
                  autoComplete="off"
                  component={FormikTextInput}
                  label={inputLabel}
                  hideLabel
                  placeholder={getIntegrationConnectionInputPlaceholder(connectionType, integration.type)}
                  name={`integrations.${integrationIndex}.input`}
                  error={errors.integrations}
                  onChange={handleInputChange}
                  size="sm"
                />
              </Box>
              <TooltipIcon
                name="c-question"
                content={`Enter the existing ${ConnectionTypeLabel[connectionType].toLowerCase()} to connect`}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default IntegrationConnectionCheckbox;
