import { useMemo, useRef } from 'react';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { useGetProjectEnvironmentQuery } from '../../../services/environments';
import Error from '../../../Error';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Route, Routes, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { Skeleton } from '../../../components/common/Skeleton';
import { Box } from '@hyphen/hyphen-components';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import ProjectEnvironmentOverview from './ProjectEnvironmentOverview';
import ProjectEnvironmentSettings from './ProjectEnvironmentSettings';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import EnvironmentConnections from '../../../components/env/EnvironmentConnections';
import { useBooleanFlagValue } from '@openfeature/react-sdk';

export default function ProjectEnvironmentContainer() {
  const layoutRef = useRef<HTMLDivElement>(null);
  const { id: orgId } = useOrganization();
  const { projectId, pevrId } = useParams();
  const ability = useOrganizationAbilityContext();
  const showConnectionsTab = useBooleanFlagValue('connections-tab', false);

  const {
    data: environment,
    isLoading,
    error,
  } = useGetProjectEnvironmentQuery({
    organizationId: orgId,
    projectId: projectId!,
    environmentId: pevrId!,
  });

  const canUpdateProjectEnvironment = useMemo(
    () => environment && ability.can('update', environment),
    [ability, environment],
  );

  const pageHeader = useMemo(() => {
    if (isLoading) {
      return <Skeleton height="20px" width="300px" />;
    }

    return (
      <Box
        direction="row"
        gap="sm"
        alignItems="center"
        fontWeight="semibold"
        as="h1"
        fontSize={{ base: 'md', tablet: 'lg', desktop: 'xl' }}
      >
        <span>
          {environment?.project && (
            <Link to={`/${environment.organization.id}/${environment.project.alternateId}`}>
              {environment.project.name}
            </Link>
          )}
          :
        </span>
        {environment?.color && (
          <Box
            style={{ backgroundColor: environment.color }}
            width="2xl"
            height="2xl"
            minWidth="2xl"
            minHeight="2xl"
            radius="xs"
            borderColor="subtle"
            borderWidth="sm"
          />
        )}
        <Box fontFamily="monospace">{environment?.name}</Box>
      </Box>
    );
  }, [isLoading, environment]);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout ref={layoutRef} fullWidth>
      <Helmet title={environment?.alternateId} />

      {environment && <BreadCrumbPortal entity={environment} />}

      {pageHeader}

      {isLoading ? (
        <Skeleton width="306px" height="48px" />
      ) : (
        canUpdateProjectEnvironment && (
          <TabNav>
            <TabNavLink to="">Overview</TabNavLink>
            {showConnectionsTab && <TabNavLink to="connections">Connections</TabNavLink>}
            <TabNavLink to="settings">Settings</TabNavLink>
          </TabNav>
        )
      )}

      <Routes>
        <Route path="/*" element={<ProjectEnvironmentOverview environment={environment} isLoading={isLoading} />} />
        {environment && (
          <Route path="connections" element={<EnvironmentConnections environment={environment} orgId={orgId} />} />
        )}
        {canUpdateProjectEnvironment && (
          <Route
            path="settings"
            element={<ProjectEnvironmentSettings environment={environment} isLoading={isLoading} />}
          />
        )}
      </Routes>
    </ScrollableMainLayout>
  );
}
