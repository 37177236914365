import { Route, Routes, useParams } from 'react-router-dom';
import { useGetBillingAccountByIdQuery } from '../services/billingAccount';
import { Spinner } from '@hyphen/hyphen-components';
import { ErrorPage } from './Error';
import { TabNav, TabNavLink } from '../components/TabNav';
import { BillingAccountDetails } from '../components/billing/BillingAccountDetails';
import { Helmet } from 'react-helmet';
import PageHeader from '../components/PageHeader';
import { UserSidebar } from '../components/sidebar/UserSidebar';
import { BillingAccountUsage } from '../components/billing/usage/BillingAccountUsage';
import { BillingAccountAccessDetails } from '../components/billing/access/BillingAccountAccessDetails';
import { BillingAccountInvoices } from '../components/billing/invoices/BillingAccountInvoices';
import Error from '../Error';
import ErrorBoundary from '../components/ErrorBoundary';
import { BillingAccountBanner } from '../components/billing/BillingAlertBanner';

export const Billing = () => {
  const { billingAccountId } = useParams();

  const { isLoading, error, data } = useGetBillingAccountByIdQuery(billingAccountId!);

  const renderBody = () => {
    if (isLoading && !data) {
      return <Spinner />;
    }

    if (error || !data) {
      return <ErrorPage />;
    }

    return (
      <>
        <TabNav>
          <TabNavLink to="">Overview</TabNavLink>
          <TabNavLink to="usage">Usage</TabNavLink>
          <TabNavLink to="invoices">Invoices</TabNavLink>
          <TabNavLink to="access">Access</TabNavLink>
        </TabNav>
        <ErrorBoundary>
          <Routes>
            <Route path="" element={<BillingAccountDetails billingAccount={data} mode="edit" />} />
            <Route path="usage" element={<BillingAccountUsage />} />
            <Route
              path="invoices"
              element={
                <BillingAccountInvoices
                  invoices={data.invoices}
                  billingCycle={data.subscription?.cycles?.[data.subscription.cycles.length - 1]}
                />
              }
            />
            <Route
              path="access"
              element={<BillingAccountAccessDetails billingAccountId={data.id} owners={data.owners} mode="edit" />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </ErrorBoundary>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <UserSidebar>
        <PageHeader title="Billing" description="Manage your billing information and usage" />
        <BillingAccountBanner />
        {renderBody()}
      </UserSidebar>
    </>
  );
};

export default Billing;
