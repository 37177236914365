import {
  OrganizationBillingAccount,
  useGetBillingAccountByIdQuery,
  useLazyGetBillingAccountUsageQuery,
} from '../../../services/billingAccount';
import { useEffect, useRef, useState } from 'react';
import { Box, Heading, SelectInputNative, Spinner } from '@hyphen/hyphen-components';
import { formatDateInUTC, formatDateWithTime } from '../../../utils/dateUtils';
import { UsageMetric } from './OrganizationUsage';
import { UsageDetails } from './UsageDetails';
import { ApiError } from '../../ApiError';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../auth/authProvider';

export const BillingAccountUsage = () => {
  const { billingAccountId } = useParams<{ billingAccountId: any }>();
  const { user } = useAuth();

  const {
    data: billingData,
    error: billingAccountError,
    isLoading: billingAccountLoading,
  } = useGetBillingAccountByIdQuery(billingAccountId);

  const [triggerBillingUsage, { data: usageData, error: usageError, isLoading: usageLoading }] =
    useLazyGetBillingAccountUsageQuery();

  const currentCycleRef = useRef<number | null>(null);
  const [selectedCycle, setSelectedCycle] = useState(currentCycleRef.current ?? 1);

  const isLoading = billingAccountLoading || usageLoading;

  useEffect(() => {
    if (billingData) {
      const isOwner = billingData.owners.some((owner) => owner.userId === user?.userId);
      if (isOwner) {
        triggerBillingUsage({ id: billingData.id });
      }
    }
  }, [billingData, triggerBillingUsage, user?.userId]);

  useEffect(() => {
    if (usageData?.billingCycle) {
      if (currentCycleRef.current === null) {
        currentCycleRef.current = usageData.billingCycle;
      }
      setSelectedCycle(usageData.billingCycle);
    }
  }, [usageData]);

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    await triggerBillingUsage({ id: billingAccountId, billingCycle: Number(selectedValue) }).unwrap();
    setSelectedCycle(Number(selectedValue));
  };

  const CycleOptions = currentCycleRef.current
    ? Array.from({ length: currentCycleRef.current }, (_, i) => ({
        value: i + 1,
        label: `Billing Cycle ${i + 1} ${i + 1 === currentCycleRef.current ? ' [Current]' : ''}`,
      })).reverse()
    : [{ value: 1, label: 'Billing Cycle 1' }];

  const showingCurrentCycle = currentCycleRef.current === selectedCycle;

  if (isLoading) return <Spinner />;

  return (
    <Box gap="4xl">
      {usageData && (
        <>
          <Box gap="sx">
            <SelectInputNative
              id="SelectCycle"
              name="SelectCycle"
              label="Billing Cycle"
              hideLabel
              value={selectedCycle}
              options={CycleOptions}
              onChange={handleChange}
            />
            <Box as="p" color="secondary">
              Each billing cycle is a month
            </Box>
          </Box>

          <Box gap="xl">
            <Box gap="md" direction="row" alignItems="baseline">
              {usageData.startDate && (
                <Heading as="h2" size="sm">
                  Total usage for {formatDateInUTC(usageData.startDate)} -{' '}
                  {usageData.endDate?.split('T')[0] === new Date().toISOString().split('T')[0]
                    ? 'Today'
                    : formatDateInUTC(usageData.endDate)}
                </Heading>
              )}
              {showingCurrentCycle && (
                <Box color="secondary">{`${usageData.remainingDays} day${
                  usageData.remainingDays > 1 ? 's' : ''
                } remain${usageData.remainingDays === 1 ? 's' : ''} in current cycle`}</Box>
              )}
            </Box>
            <Box display="grid" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }} gap="xs">
              <UsageMetric value={usageData.total.user.active} label="Active Users" />
              <UsageMetric value={usageData.total.env.pull} label="ENV Pulls" />
              <UsageMetric value={usageData.total.env.push} label="ENV Pushes" />
              <UsageMetric value={usageData.total.link.created} label="Links Created" />
              <UsageMetric value={usageData.total.netInfo.requests} label="Net.Info Requests" />
              <UsageMetric value={usageData.total.toggle.evaluations} label="Toggle Evaluations" />
            </Box>
            {usageData.lastUpdated !== usageData.endDate && (
              <Box color="secondary" fontSize="sm">
                Last Updated: {formatDateWithTime(usageData.lastUpdated)}
              </Box>
            )}
          </Box>

          <Box gap="xl" margin={'xl 0'}>
            <Heading as="h3" size="md">
              Organizations
            </Heading>
            {usageData.organizations?.map((org: OrganizationBillingAccount) => (
              <UsageDetails org={org} key={org.organization.id} />
            ))}
          </Box>
        </>
      )}
      {(billingAccountError || usageError) && (
        <ApiError
          error={billingAccountError || usageError}
          customMessages={{ default: 'An unexpected error occurred' }}
        />
      )}
    </Box>
  );
};
