import { BadgeProps, Badge, Tooltip, TooltipContent, TooltipTrigger } from '@hyphen/hyphen-components';

export interface BadgeTooltipProps extends BadgeProps {
  tooltipContent: string;
}

export default function BadgeTooltip({ tooltipContent, ...badgeProps }: BadgeTooltipProps) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Badge as="button" {...badgeProps} />
      </TooltipTrigger>
      <TooltipContent>{tooltipContent}</TooltipContent>
    </Tooltip>
  );
}
