import { Box, Tooltip, TooltipTrigger, TooltipContent } from '@hyphen/hyphen-components';
import { ConnectionType, ConnectionTypeLabel } from '../../types/connections';
import { INTEGRATION_CONNECTION_TYPE_DESCRIPTION } from '../../constants/integrations';
import { Integration } from '../../types/integrations';
import { useMemo } from 'react';
import IntegrationConnectionCheckbox from './IntegrationConnectionCheckbox';

interface IntegrationConnectionsFieldProps {
  integrations: Integration[];
  connectionTypes: ConnectionType[];
  isSubmitting: boolean;
  errors: any;
}

const IntegrationConnectionsField = ({
  integrations,
  isSubmitting,
  errors,
  connectionTypes,
}: IntegrationConnectionsFieldProps) => {
  const applicableIntegrationsByType = useMemo(() => {
    return connectionTypes.reduce((acc, connectionType) => {
      acc[connectionType] = integrations.filter((integration) =>
        integration.provides?.some((p) => p.connectionType === connectionType),
      );
      return acc;
    }, {} as Record<ConnectionType, Integration[]>);
  }, [connectionTypes, integrations]);

  return (
    <>
      {connectionTypes.map((connectionType) => (
        <ConnectionTypeSection
          key={connectionType}
          connectionType={connectionType}
          applicableIntegrations={applicableIntegrationsByType[connectionType] || []}
          isSubmitting={isSubmitting}
          errors={errors}
        />
      ))}
    </>
  );
};
export const ConnectionTypeSection = ({
  connectionType,
  applicableIntegrations,
  isSubmitting,
  errors,
}: {
  connectionType: ConnectionType;
  applicableIntegrations: Integration[];
  isSubmitting: boolean;
  errors: any;
}) => {
  return (
    <Box key={connectionType} gap="xl" direction={{ base: 'column', tablet: 'row' }}>
      <Box width={{ base: '100', tablet: '6xl' }} alignItems="flex-start" padding="xl 0 0 0">
        <Tooltip>
          <TooltipTrigger asChild>
            <Box
              as="button"
              display="inline-block"
              background="transparent"
              borderWidth="0"
              padding="0"
              color="secondary"
              className="tdl-underline tds-dotted"
              style={{ textDecorationColor: 'var(--color-font-tertiary)' }}
              fontSize="xs"
            >
              {ConnectionTypeLabel[connectionType]}
            </Box>
          </TooltipTrigger>
          <TooltipContent className="mw-8xl">
            {INTEGRATION_CONNECTION_TYPE_DESCRIPTION[connectionType]}
          </TooltipContent>
        </Tooltip>
      </Box>
      <Box gap="xs" flex="auto">
        {applicableIntegrations?.length ? (
          applicableIntegrations.map((orgIntegration) => {
            return (
              <IntegrationConnectionCheckbox
                key={`${orgIntegration.id}-${connectionType}`}
                integration={orgIntegration}
                connectionType={connectionType}
              />
            );
          })
        ) : (
          <Box color="tertiary" fontSize="xs" height="48px" minHeight="48px" justifyContent="center">
            No integrations available
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default IntegrationConnectionsField;
