import { Box, Button, Card, FormikSelectInputInset, FormikTextInputInset, toast } from '@hyphen/hyphen-components';
import { LabelValue } from '../../LabelValue';
import { BillingAccountResponse, useUpdateBillingAccountMutation } from '../../../services/billingAccount';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useCallback } from 'react';
import { ApiError } from '../../ApiError';
import { phoneRegExp } from '../../../utils/validations';
import { stateList } from '../../../utils/stateList';

interface ProfileDetailsProps {
  billingAccount: BillingAccountResponse;
  mode?: 'view' | 'edit';
}

const editProfileSchema = yup.object().shape({
  name: yup.string().required('Company name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').optional(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zipCode: yup.string(),
    })
    .test('all-or-none', 'All address fields must be provided', (value) => {
      if (!value) return true;
      const { streetAddress, city, state, zipCode } = value;
      const allFieldsProvided = !!streetAddress && !!city && !!state && !!zipCode;
      const noFieldsProvided = !streetAddress && !city && !state && !zipCode;
      return allFieldsProvided || noFieldsProvided;
    })
    .optional(),
});

type EditProfileForm = yup.InferType<typeof editProfileSchema>;

export const ProfileDetails = ({ mode, billingAccount }: ProfileDetailsProps) => {
  const initialValues = {
    name: billingAccount.name,
    email: billingAccount.email,
    phone: billingAccount.phone,
    address: {
      streetAddress: billingAccount.address?.streetAddress,
      city: billingAccount.address?.locality,
      state: billingAccount.address?.region,
      zipCode: billingAccount.address?.postalCode,
    },
  };

  const [updateBillingAccount, { error }] = useUpdateBillingAccountMutation();

  const handleOnSubmit = useCallback(
    async (values: EditProfileForm) => {
      const updates = {
        email: values.email,
        phone: values.phone,
        name: values.name,
        ...(values.address?.streetAddress !== undefined &&
        values.address?.city !== undefined &&
        values.address?.state !== undefined &&
        values.address?.zipCode !== undefined
          ? {
              address: {
                streetAddress: values.address.streetAddress,
                locality: values.address.city,
                region: values.address.state,
                postalCode: values.address.zipCode,
                country: 'US',
              },
            }
          : {}),
      };

      const { error, data } = await updateBillingAccount({
        id: billingAccount.id,
        updates,
      });
      if (!error && data) {
        toast.success('Profile Updated');
      }
    },
    [billingAccount.id, updateBillingAccount],
  );

  const renderBody = () => {
    if (mode === 'view') {
      return (
        <>
          <LabelValue label="Account Name" value={billingAccount.name} />
          <LabelValue label="Phone" value={billingAccount.phone} />
          <LabelValue label="Email" value={billingAccount.email} />
          <LabelValue
            label="Address"
            value={
              billingAccount.address ? (
                <>
                  <Box as="p">{billingAccount.address.streetAddress}</Box>
                  <Box as="p">
                    {[
                      billingAccount.address.locality,
                      billingAccount.address.region,
                      billingAccount.address.postalCode,
                      billingAccount.address.country,
                    ].join(', ')}
                  </Box>
                </>
              ) : (
                <></>
              )
            }
          />
        </>
      );
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={editProfileSchema}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ errors, isSubmitting }) => (
          <Form noValidate>
            <Box gap="2xl">
              <Field
                type="text"
                label="Name"
                name="name"
                id="name"
                autoComplete="off"
                component={FormikTextInputInset}
                error={errors.name}
                isDisabled={isSubmitting}
              />
              <Field
                type="text"
                label="Email"
                name="email"
                id="email"
                autoComplete="off"
                component={FormikTextInputInset}
                error={errors.email}
                isDisabled={isSubmitting}
              />
              <Field
                type="text"
                label="Phone"
                name="phone"
                id="phone"
                autoComplete="off"
                component={FormikTextInputInset}
                error={errors.phone}
                isDisabled={isSubmitting}
              />
              <Field
                type="text"
                label="Street Address"
                name="address.streetAddress"
                id="address.streetAddress"
                component={FormikTextInputInset}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
              <Field
                type="text"
                label="City"
                name="address.city"
                id="address.city"
                component={FormikTextInputInset}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
              <Box display="flex" direction="row" gap="xl">
                <Field
                  label="State"
                  name="address.state"
                  id="address.state"
                  options={stateList}
                  component={FormikSelectInputInset}
                  isDisabled={isSubmitting}
                />
                <Field
                  type="text"
                  label="Zip Code"
                  name="address.zipCode"
                  id="address.zipCode"
                  component={FormikTextInputInset}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
              </Box>
              {error && <ApiError error={error} title="Error updating profile" />}
              <ErrorMessage name="address" component="div" className="error-message" />
              <Box display="block">
                <Button size="sm" variant="primary" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };
  return (
    <Card>
      <Card.Header title="Billing Profile" borderWidth="0 0 sm 0" />
      <Card.Section gap="xl">{renderBody()}</Card.Section>
    </Card>
  );
};
