import { Box, Card } from '@hyphen/hyphen-components';
import { ApiKey } from '../../services/apiKeys';
import DeleteApiKeySecret from './DeleteApiKeySecret';
import EmptyList from '../common/EmptyList';
import GridHeader from '../common/GridHeader';
import GridCell from '../common/GridCell';
import DateTimeDisplay from '../DateTime';
import { Fragment } from 'react/jsx-runtime';

export default function ApiKeySecretsList({ apiKey, orgId }: { apiKey: ApiKey; orgId: string }) {
  if (apiKey.secrets.length === 0) {
    return <EmptyList title="No secrets exist for this API key" />;
  }

  return (
    <Card>
      <Box display="grid" fontSize="sm" style={{ gridTemplateColumns: '1fr 1fr 72px' }}>
        <GridHeader>Secret (last 6 chars)</GridHeader>
        <GridHeader>Last Used</GridHeader>
        <GridHeader />
        {apiKey.secrets.map((secret) => (
          <Fragment key={secret.id}>
            <GridCell>
              <code>{secret.hint}</code>
            </GridCell>
            <GridCell color="secondary" fontFamily="monospace">
              {secret.lastUsed ? <DateTimeDisplay relative value={secret.lastUsed} /> : 'never'}
            </GridCell>
            <GridCell>
              <DeleteApiKeySecret apiKeyId={secret.id} secret={secret} organizationId={orgId} />
            </GridCell>
          </Fragment>
        ))}
      </Box>
    </Card>
  );
}
