import { Box, Button, Popover, useOpenClose } from '@hyphen/hyphen-components';
import { copyToClipboard } from '../utils/copyToClipboard';

type CodeBlockProps = {
  children: string;
  showCopyLabel?: boolean;
  className?: string;
};

export const CodeBlock = ({ children, showCopyLabel = false, className }: CodeBlockProps) => {
  const { isOpen: isCopyPopoverOpen, handleClose: closeCopyPopover, handleOpen: openCopyPopover } = useOpenClose();

  const handleCopy = () => {
    copyToClipboard(children);
  };

  return (
    <Box
      flex="auto"
      radius="lg"
      background="primary"
      borderColor="subtle"
      borderWidth="sm"
      overflow="hidden"
      shadow="2xs"
      padding="2xl xl"
      className={className}
    >
      <Box direction="row" alignItems="center" justifyContent="space-between" gap="lg">
        <Box
          style={{ lineHeight: 'var(--size-line-height-text)' }}
          overflow="auto"
          fontFamily="monospace"
          fontSize="xs"
        >
          <pre>
            <code>{children}</code>
          </pre>
        </Box>
        <Popover
          content="copy"
          isOpen={isCopyPopoverOpen}
          placement="top"
          offsetFromTarget={4}
          contentContainerProps={{
            padding: 'md',
            color: 'inverse',
            background: 'inverse',
            fontSize: 'xs',
          }}
        >
          <Button
            onMouseOver={openCopyPopover}
            onMouseOut={closeCopyPopover}
            onFocus={openCopyPopover}
            onBlur={closeCopyPopover}
            variant={showCopyLabel ? 'secondary' : 'tertiary'}
            aria-label="copy code block"
            iconPrefix="copy-document"
            size="sm"
            onClick={handleCopy}
          >
            {showCopyLabel && 'Copy'}
          </Button>
        </Popover>
      </Box>
    </Box>
  );
};
