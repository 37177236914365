import { BarStack, Line } from '@visx/shape';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import { AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import tokens from '@hyphen/hyphen-design-tokens/build/json/variables.json';
import { Box, useTheme } from '@hyphen/hyphen-components';
import { formatDateInUTC } from '../../utils/dateUtils';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useMemo } from 'react';
import { ColorDot } from '../common/ColorDot';

export type BarChartProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  keys: Array<string>;
  data: Record<string, Record<string, number>>;
  maxValue: number;
};

interface DataType {
  x: string;
  y: number;
}

const color1 = tokens.color.background['brand-light-purple'].value;
const color2 = tokens.color.background['chart-2'].value;
const color3 = tokens.color.background['chart-3'].value;
const color4 = tokens.color.background['brand-cyan'].value;
const color5 = tokens.color.background['brand-yellow'].value;
const color6 = tokens.color.background['brand-magenta'].value;
const color7 = tokens.color.background['brand-orange'].value;
const color8 = tokens.color.background['brand-light-purple'].value;
const color9 = tokens.color.background['brand-medium-purple'].value;
const color10 = tokens.color.background['brand-dark-purple'].value;

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };

const parseDate = timeParse('%m/%d/%Y');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

const getDate = (d: any) => formatDateInUTC(d.date);

interface Accessors {
  xAccessor: (d: DataType) => string;
  yAccessor: (d: DataType) => number;
}

const accessors: Accessors = {
  xAccessor: (d) => d.x as string,
  yAccessor: (d) => d.y as number,
};

let tooltipTimeout: number;

export const StackedBarChart = ({
  width,
  height,
  events = false,
  margin = defaultMargin,
  data,
  keys,
  maxValue,
}: BarChartProps) => {
  const { isDarkMode } = useTheme();
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<{
    bar: {
      data: DataType;
    };
    key: string;
  }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({ scroll: true });

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'var(--color-background-tooltip)',
    color: 'var(--color-font-tooltip)',
    padding: 'var(--size-spacing-lg)',
  };

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: keys.map((_, index) => {
      const colors = [color1, color2, color3, color4, color5, color6, color7, color8, color9, color10];
      return colors[index % colors.length]; // if there are more than 10, restart the colors
    }),
  });

  const background = isDarkMode ? tokens.color.background.primary.darkValue : tokens.color.background.primary.value;

  const graphData = useMemo(() => {
    return Object.keys(data).map((key) => {
      return { ...data[key], date: key } as any as DataType;
    });
  }, [data]);

  const dateScale = scaleBand<string>({
    domain: graphData.map(getDate),
    padding: 0.2,
  });

  const valueScale = scaleLinear<number>({
    domain: [0, maxValue],
    nice: true,
  });

  if (width < 10) return null;

  // bounds
  const xMax = width;
  const yMax = height - margin.top - 28;

  dateScale.rangeRound([0, xMax]);
  valueScale.range([yMax, 0]);

  return width < 10 ? null : (
    <Box style={{ position: 'relative' }}>
      <svg ref={containerRef} width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={valueScale}
          width={xMax}
          height={yMax}
          stroke={background}
          xOffset={dateScale.bandwidth() / 2}
        />
        <Group top={margin.top}>
          <BarStack<DataType, string>
            data={graphData}
            keys={keys}
            x={getDate}
            xScale={dateScale}
            yScale={valueScale}
            color={colorScale}
            {...accessors}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => {
                  return (
                    <rect
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      height={bar.height}
                      width={bar.width}
                      fill={bar.color}
                      onClick={() => {
                        if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                      }}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={(event) => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        const eventSvgCoords = localPoint(event);
                        const left = bar.x + bar.width / 2;
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: left,
                        });
                      }}
                    />
                  );
                }),
              )
            }
          </BarStack>
        </Group>
        {tooltipData && (
          <Line
            from={{ x: tooltipLeft, y: margin.top }}
            to={{ x: tooltipLeft, y: height - margin.top - margin.bottom + margin.top }}
            stroke={tokens.color.border.subtle.value}
            strokeWidth={1}
            pointerEvents="none"
            strokeDasharray="5,2"
          />
        )}
        <AxisBottom
          top={yMax + margin.top}
          scale={dateScale}
          stroke="var(--color-border-default)"
          tickStroke="var(--color-border-default)"
          numTicks={30}
          tickFormat={(value, index) => {
            if (width < 400) {
              return index % 6 === 0 ? formatDate(value) : '';
            }
            return index % 4 === 0 ? formatDate(value) : '';
          }}
          tickLabelProps={{
            fill: 'var(--color-font-base)',
            fontSize: 'var(--size-font-size-xs)',
            textAnchor: 'middle',
          }}
        />
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <Box gap="sm">
            <Box fontSize="xs">
              {formatDate(getDate(tooltipData.bar.data))} | {tooltipData.key}
            </Box>
            <Box direction="row" gap="sm" alignItems="baseline">
              <ColorDot color={colorScale(tooltipData.key)} />
              <Box fontSize="lg" fontWeight="semibold">
                {(tooltipData.bar.data as any)[tooltipData.key]}
              </Box>
            </Box>
          </Box>
        </TooltipInPortal>
      )}
    </Box>
  );
};

export default StackedBarChart;
