import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  FormikTextInput,
  Icon,
} from '@hyphen/hyphen-components';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { ApiError } from '../ApiError';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetBillingAccountsQuery } from '../../services/billingAccount';
import { useAuth } from '../auth/authProvider';

interface SelectOption {
  label: string;
  value: string;
}

const createOrganizationSchema = yup.object().shape({
  name: yup.string().required('Organization name is required'),
  billingAccountId: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required('Billing account is required'),
  email: yup.string().test('email-required', 'Email is required', function (value) {
    const { billingAccountId } = this.parent;
    if (!billingAccountId || billingAccountId.value === 'new') {
      return !!value;
    }
    return true;
  }),
  promoCode: yup.string().default(''),
});

export type CreateOrganizationFormSchema = {
  name: string;
  billingAccountId: SelectOption | null;
  email: string;
  promoCode: string;
};

interface CreateNewOrganizationFormProps {
  handleSubmit: (values: CreateOrganizationFormSchema, options: FormikHelpers<CreateOrganizationFormSchema>) => void;
  error?: any;
}

export const CreateOrganizationForm = ({ handleSubmit, error }: CreateNewOrganizationFormProps) => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { user } = useAuth();
  const { data: billingAccounts, isLoading } = useGetBillingAccountsQuery();

  const ownedAccounts = useMemo(() => {
    return (billingAccounts?.data || []).filter(
      (account) =>
        account.owners.some((owner) => owner.userId === user?.userId) &&
        account.paymentMethods &&
        account.paymentMethods.length > 0,
    );
  }, [billingAccounts, user]);
  
  const initialValues: CreateOrganizationFormSchema = {
    name: query.get('orgName') || '',
    billingAccountId:
      ownedAccounts.length > 0
        ? {
            value: ownedAccounts[0].id,
            label: ownedAccounts[0].name,
          }
        : { value: 'new', label: 'Create New Billing Account' },
    email: query.get('billingEmail') || '',
    promoCode: query.get('code') || '',
  };

  return (
    <Box
      width="420px"
      maxWidth="100%"
      height="100"
      gap="4xl"
      justifyContent="center"
      padding={{ base: '2xl', tablet: '0' }}
      className="zoomIn"
    >
      <Box gap="2xl">
        <Box as="h1" fontSize={{ base: 'lg', tablet: 'xl' }} textAlign="center" fontWeight="bold">
          Activate your organization
        </Box>
        <Box as="p" color="secondary" fontSize="xs" textAlign="center">
          An organization is a shared workspace for managing projects and team collaboration under one billing
          account.
        </Box>
      </Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={createOrganizationSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form noValidate className="w-100">
            <Box gap="xl" width="100">
              <Field
                type="text"
                label="Organization Name"
                hideLabel
                placeholder="Organization Name"
                name="name"
                id="name"
                component={FormikTextInput}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
              {(isLoading || ownedAccounts.length > 0) && (
                <>
                  <Box as="label" htmlFor="billingAccountId" fontSize="sm" fontWeight="medium">
                    Billing Account
                  </Box>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        iconSuffix="caret-down"
                        variant="primary"
                        isLoading={isLoading}
                        fullWidth
                        style={{ justifyContent: 'space-between' }}
                        aria-label="Select Billing Account"
                      >
                        {values.billingAccountId?.label ?? 'Select Billing Account'}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start" side="bottom">
                      <Box overflow="auto" maxHeight="8xl" className="scroll-bar-thin">
                        {isLoading ? (
                          <Box padding="xs sm" fontSize="xs">
                            Loading...
                          </Box>
                        ) : (
                          ownedAccounts.map((account) => (
                            <DropdownMenuItem
                              key={account.id}
                              onSelect={() =>
                                setFieldValue('billingAccountId', {
                                  value: account.id,
                                  label: account.name,
                                })
                              }
                            >
                              {account.name}
                            </DropdownMenuItem>
                          ))
                        )}
                      </Box>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onSelect={() =>
                          setFieldValue('billingAccountId', { value: 'new', label: 'Create New Billing Account' })
                        }
                      >
                        <Icon name="add" />
                        <span>Create New Billing Account</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </>
              )}

              {(!values.billingAccountId || values.billingAccountId.value === 'new') && (
                <>
                  <Field
                    type="text"
                    label="Billing Email"
                    hideLabel
                    placeholder="Billing Email"
                    name="email"
                    id="email"
                    component={FormikTextInput}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                  />
                  <Field
                    type="text"
                    label="Promo Code"
                    hideLabel
                    placeholder="Promo Code"
                    name="promoCode"
                    id="promoCode"
                    component={FormikTextInput}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                  />
                </>
              )}

              {error && (
                <Box width="100">
                  <ApiError error={error} title="Error creating organization" />
                </Box>
              )}
              <Button
                fullWidth
                variant="primary"
                type="submit"
                isLoading={isSubmitting}
                className="registration-button"
              >
                Continue
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
