import { Box, Button, Heading, Table, toast } from '@hyphen/hyphen-components';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useVerifySslMutation } from '../../services/link/domains';
import { Domain } from '../../types/domain';
import { Guide } from '../guide/Guide';
import { GuideStep } from '../guide/GuideStep';
import { ApiError } from '../ApiError';
import { Column } from '@hyphen/hyphen-components/dist/types';

interface DomainVerificationProps {
  domain: Domain;
  isBeingVerified?: boolean;
  setStep?: (step: string) => void;
}

const columnConfig: Column[] = [
  {
    heading: 'type',
    dataKey: 'type',
    width: 75,
  },
  {
    heading: 'hostname',
    dataKey: 'hostname',
  },
  {
    heading: 'value',
    dataKey: 'value',
  },
];

const errorMessage = {
  default: 'Failed to verify domain. Please try again later.',
};

export const DomainVerification = ({ domain, isBeingVerified, setStep }: DomainVerificationProps) => {
  const { organization } = useOrganization();
  const [verifySsl, { error }] = useVerifySslMutation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleDomainVerification = useCallback(async () => {
    setIsLoading(true);
    const { data } = (await verifySsl({ domainId: domain?.id, organizationId: organization?.id })) || {};
    if (data?.verified) {
      toast.success('Domain verified successfully');
      navigate(`/${organization?.id}/settings/domains`);
    }
    setIsLoading(false);
  }, [domain.id, verifySsl, navigate, organization?.id]);

  return (
    <>
      <Box as="p">
        Confirm you own the domain by setting up a DNS record with the following details with your domain name
        registrant.
      </Box>

      <Guide title="DNS Setup Guide">
        <GuideStep title="Sign in to your DNS">
          <Box as="span">
            Sign in to your DNS provider and navigate to the DNS entries for the domain you will be adding to Hyphen.
          </Box>
        </GuideStep>
        <GuideStep title="Add DNS records">
          <Box as="span">
            Add the following DNS records to your DNS provider. If you are unsure how to add DNS records, contact
            your DNS provider for assistance.
          </Box>
          <Box gap="4xl">
            <Box gap="sm">
              <Heading as="h4" size="md">
                Ownership Verification
              </Heading>
              <Box as="p">In order to verify ownership of the domain, add the following CNAME record.</Box>
              <Box fontSize="sm" width="100">
                <Table
                  rowKey="id"
                  columns={columnConfig}
                  rows={[
                    {
                      type: 'CNAME',
                      hostname: domain.domain,
                      value: process.env.REACT_APP_CUSTOM_DOMAIN_HOST,
                      id: 1,
                    },
                  ]}
                  className="domain-verification-table"
                />
              </Box>
            </Box>
            <Box gap="sm">
              <Heading as="h4" size="md">
                SSL Renewal Verification
              </Heading>
              <Box as="p">
                In order to automatically renew the SSL certificate of the domain, add the following CNAME record. If
                your DNS provider supports proxying DNS records, make sure the proxy is off for this record.
              </Box>
              <Box fontSize="sm" width="100">
                <Table
                  rowKey="id"
                  columns={columnConfig}
                  rows={[
                    {
                      type: 'CNAME',
                      hostname: `_acme-challenge.${domain.domain}`,
                      value: `${domain.domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_ACME_POSTFIX}`,
                      id: 1,
                    },
                  ]}
                  className="domain-verification-table"
                />
              </Box>
            </Box>
          </Box>
        </GuideStep>
        <GuideStep title="Verify your domain">
          <Box direction="row" justifyContent="space-between" gap="sm">
            {!isBeingVerified && setStep ? (
              <Button variant="secondary" fullWidth onClick={() => setStep('1')} isDisabled={isLoading}>
                Previous
              </Button>
            ) : null}
            <Button
              variant="primary"
              fullWidth={!isBeingVerified}
              onClick={handleDomainVerification}
              isLoading={isLoading}
            >
              Verify Domain
            </Button>
          </Box>
          {error ? <ApiError error={error} customMessages={errorMessage} /> : null}
        </GuideStep>
      </Guide>
    </>
  );
};
