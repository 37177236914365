import { Box } from '@hyphen/hyphen-components';
import { useGetConnectionsQuery } from '../../../services/connections';
import { useEffect, useMemo, useState } from 'react';
import { Connection, ConnectionType, ConnectionTypeLabel } from '../../../types/connections';
import { Skeleton } from '../../common/Skeleton';
import { ApiError } from '../../ApiError';
import ConnectionSummaryItem from './ConnectionSummaryItem';
import { useOrganizationAbilityContext } from '../../auth/OrganizationAbilityProvider';
import SetupIntegrationConnection from './SetupIntegrationConnection';
import { EntityTypes } from '@hyphen/nucleus/dist/types';
import { useGetIntegrationsQuery } from '../../../services/integrations';
import { Integration } from '../../../types/integrations';

// Currently, it's unlikely that an Entity will have more than 50 connections
const PAGE_SIZE = 50;
const PAGE_NUM = 1;
const POLL_COUNT_LIMIT = 20;

interface ConnectionsSummaryProps {
  entity: {
    id: string;
    name: string;
  };
  orgId: string;
  connectionTypes: ConnectionType[];
  entityType: EntityTypes;
}

export default function ConnectionsSummary({ entity, orgId, connectionTypes, entityType }: ConnectionsSummaryProps) {
  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery(orgId);
  const [pollCount, setPollCount] = useState(0);
  const ability = useOrganizationAbilityContext();

  const {
    data: connections,
    isLoading: isConnectionsLoading,
    error,
    refetch,
  } = useGetConnectionsQuery({
    organizationId: orgId,
    entityIds: [entity.id],
    types: connectionTypes,
    integrationTypes: undefined,
    pageSize: PAGE_SIZE,
    pageNum: PAGE_NUM,
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (
      connections?.data.some((connection) => connection.status === 'Pending' || connection.isRetryable) &&
      pollCount < POLL_COUNT_LIMIT
    ) {
      interval = setInterval(() => {
        refetch();
        setPollCount((prev) => prev + 1);
      }, 3000);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [connections, pollCount, refetch]);

  const isLoading = isIntegrationsLoading || isConnectionsLoading;

  const canUpdateEntityConnections = ability.can('manage', entity);

  const categorizedConnections = useMemo(() => {
    return connectionTypes.reduce((acc, type) => {
      acc[type] = connections?.data.filter((connection) => connection.type === type) || [];
      return acc;
    }, {} as Record<ConnectionType, Connection[]>);
  }, [connections, connectionTypes]);

  return (
    <Box fontSize="xs">
      <Box fontSize="sm" fontWeight="semibold" color="secondary" borderWidth="0 0 sm 0" padding="sm 0">
        {isLoading ? <Skeleton width="98px" height="20px" /> : 'Integration Connections'}
      </Box>

      {!isLoading && error && <ApiError error={error} />}

      {connectionTypes.map((type) => {
        const applicableIntegrations = integrations?.data.filter((integration) =>
          integration.provides.some((p) => p.connectionType === type),
        );

        return (
          <ConnectionSection
            key={type}
            title={ConnectionTypeLabel[type]}
            connectionType={type}
            entityType={entityType}
            connections={categorizedConnections[type]}
            isLoading={isLoading}
            canSetup={canUpdateEntityConnections}
            integrations={applicableIntegrations}
            {...{ entity, orgId }}
          />
        );
      })}
    </Box>
  );
}

interface ConnectionSectionProps {
  title: string;
  connections: Connection[];
  connectionType: ConnectionType;
  entityType: EntityTypes;
  isLoading: boolean;
  canSetup: boolean;
  entity: {
    id: string;
    name: string;
  };
  integrations?: Integration[];
}

function ConnectionSection({
  title,
  connections,
  connectionType,
  entityType,
  isLoading,
  canSetup,
  entity,
  integrations,
}: ConnectionSectionProps) {
  const hasConnection = connections && connections.length > 0;

  // we show the setup button even if there are no integrations so we can show the user
  // a message to contact their org admin to setup an integration
  const showSetup = canSetup && connections.length < (integrations?.length || 1);

  const usedIntegrationIds = new Set(connections.map((c) => c.organizationIntegration.id));
  const remainingIntegrations = integrations?.filter((integration) => !usedIntegrationIds.has(integration.id)) || [];

  const renderHeader = () => (
    <Box direction="row" justifyContent="space-between" alignItems="center" padding="md 0">
      {title}
      {showSetup && (
        <SetupIntegrationConnection
          entity={{
            id: entity.id,
            type: entityType,
            name: entity.name,
          }}
          connectionType={connectionType}
          integrations={remainingIntegrations}
        />
      )}
    </Box>
  );

  return (
    <Box padding="0" borderWidth="0 0 sm 0">
      <Box color="tertiary" fontWeight="semibold">
        {isLoading ? <Skeleton width="100px" height="16px" margin="md 0" /> : renderHeader()}
      </Box>
      {hasConnection && (
        <Box padding="0 0 md 0">
          {connections.map((connection) => (
            <ConnectionSummaryItem connection={connection} key={connection.id} />
          ))}
        </Box>
      )}
    </Box>
  );
}
