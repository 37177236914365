import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useBreakpoint } from '@hyphen/hyphen-components';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useGetConnectionsQuery } from '../../services/connections';
import { useOrganization } from '../../providers/OrganizationProvider';
import { IntegrationConnectionId } from '@hyphen/nucleus/dist/types';
import { getEntityUrl } from '../../utils/getEntityUrl';
import { ScrollableMainLayout } from '../ScrollableMainLayout';
import { Skeleton } from '../common/Skeleton';
import PageHeader from '../PageHeader';

export const ConnectionLoader = ({ entityId, internalPath }: { entityId: string, internalPath?: string }) => {
  const navigate = useNavigate();
  const { id: orgId } = useOrganization();
    const { isPhone, isTablet } = useBreakpoint();

  const { data: connections, isLoading, error } = useGetConnectionsQuery(
    { organizationId: orgId, connectionIds: [entityId as IntegrationConnectionId] },
    { skip: !orgId }
  );

  useEffect(() => {
    if(isLoading) return;

    if (!connections || !connections.data.length || error) {
      throw new Response('Not Found', { status: 404 });
    }
    
    if (connections?.data.length) {
      const integrationId = connections.data[0].organizationIntegration.id;

      let url = getEntityUrl(orgId, EntityNames.IntegrationConnection, entityId, undefined, integrationId);
      if (internalPath) url = `${url}/${internalPath}`;
      navigate(url, { replace: true });
    }
  }, [connections, navigate, orgId, entityId, internalPath, isLoading, error]);

  const pageHeaderProps = {
    title: <Skeleton width="250px" height={isPhone ? '25px' : isTablet ? '35px' : '40px'} />,
    iconUrl: 'loading',
    description: <Skeleton width="150px" height="xl" />,
  };

  return (
    <ScrollableMainLayout>
      <PageHeader {...pageHeaderProps} />
       <Box>
        <Skeleton width="100" height="180px" />
       </Box>
    </ScrollableMainLayout>
  );
};
