import { Fragment } from 'react';
import { Link, useLocation, useParams, NavLink } from 'react-router-dom';
import {
  Box,
  Icon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useBreakpoint,
} from '@hyphen/hyphen-components';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { createPortal } from 'react-dom';
import { Project } from '../../services/projects';
import { App } from '../../services/apps';
import { Toggle } from '../../types/toggle';
import { TeamDetails } from '../../types/teams';
import { LinkCode } from '../../types/domain';
import { IntegrationType } from '../../types/integrations';
import { INTEGRATION_NAME_MAP } from '../../constants/integrations';
import { IconName } from '@hyphen/hyphen-design-tokens/build/types';
import { ApiKey } from '../../services/apiKeys';
import { Connection } from '../../types/connections';
import { buildConnectionUrl } from '../integrations/connections/ConnectionLink';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { Segment } from '../../types/segments';
import { ProjectEnvironment } from '../../providers/ProjectEnvironmentsProvider';

type EntityTypes =
  | Project
  | App
  | Toggle
  | Segment
  | TeamDetails
  | LinkCode
  | ApiKey
  | Connection
  | { type: IntegrationType }
  | ProjectEnvironment;

const Breadcrumb = ({ entity }: { entity?: EntityTypes }) => {
  const { id: orgId, organization } = useOrganization();
  const { projectId, appId, toggleId, segmentId } = useParams<{
    projectId: string;
    appId: string;
    toggleId: string;
    segmentId: string;
  }>();
  const location = useLocation();
  const ability = useOrganizationAbilityContext();

  const { isPhone } = useBreakpoint();

  // const entityType = entity && 'id' in entity ? parseEntityType(entity.id) : undefined;

  const renderBreadcrumb = (items: { to?: string; label: string; icon?: IconName; color?: string }[]) => (
    <BreadcrumbList>
      {isPhone ? ( // only show the last item on phones
        <BreadcrumbItem>
          {items[items.length - 1].icon && <Icon name={items[items.length - 1].icon as IconName} color="tertiary" />}
          <div>{items[items.length - 1].label}</div>
        </BreadcrumbItem>
      ) : (
        items.map((item, index) => (
          <Fragment key={item.label}>
            <BreadcrumbItem>
              {item.icon && <Icon name={item.icon} color="tertiary" />}
              {item.color && (
                <Box
                  style={{ backgroundColor: item.color }}
                  width="xl"
                  height="xl"
                  minWidth="xl"
                  minHeight="xl"
                  radius="xs"
                  borderColor="subtle"
                  borderWidth="sm"
                />
              )}
              {item.to ? <Link to={item.to}>{item.label}</Link> : <div>{item.label}</div>}
            </BreadcrumbItem>
            {index < items.length - 1 && <BreadcrumbSeparator />}
          </Fragment>
        ))
      )}
    </BreadcrumbList>
  );

  const listPages = [
    { path: `/${orgId}/teams`, label: 'Teams', icon: 'users' },
    { path: `/${orgId}/link`, label: 'Link', icon: 'logo-link' },
    { path: `/${orgId}/toggles`, label: 'Toggle', icon: 'logo-toggle' },
    { path: `/${orgId}/env`, label: 'ENV', icon: 'logo-env' },
    { path: `/${orgId}/integrations`, label: 'Integrations', icon: 'integrations' },
    { path: `/${orgId}/events`, label: 'Events', icon: 'document' },
  ];

  const createPages = [
    { path: '/projects/create', parentLabel: 'Dashboard', label: 'Project' },
    { path: '/teams/create', parentLabel: 'Teams', label: 'Team', basePath: `/${orgId}/teams` },
    { path: '/link/create', label: 'Link', basePath: `/${orgId}/link` },
  ];

  const orgSettingsLinks = [
    { to: `/${organization?.id}/settings`, label: 'Settings' },
    { to: `/${organization?.id}/settings/domains`, label: 'Domains' },
    { to: `/${organization?.id}/settings/environments`, label: 'Environments' },
    {
      to: `/${organization?.id}/settings/api-keys`,
      label: 'API Keys',
      condition: ability.can('read', 'ApiKey'),
    },
    {
      to: `/${organization?.id}/settings/members`,
      label: 'Members',
      condition: ability.can('read', 'Member'),
    },
    { to: `/${organization?.id}/settings/billing`, label: 'Billing' },
  ];

  // Create Segment
  if (location.pathname.includes('/segments/create')) {
    if (entity && 'name' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}`, label: 'Projects' },
        { to: `/${orgId}/${projectId}/segments`, label: entity.name },
        { label: 'Create Segment' },
      ]);
    }
  }

  // Create Toggle
  if (location.pathname.includes('/toggles/create')) {
    // Inside project context
    if (projectId && entity && 'name' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}`, label: 'Projects' },
        { to: `/${orgId}/${projectId}/toggles`, label: entity.name },
        { label: 'Create Feature Toggle' },
      ]);
    }
    return renderBreadcrumb([{ to: `/${orgId}/toggles`, label: 'Toggles' }, { label: 'Create Feature Toggle' }]);
  }

  // DETAIL PAGES
  if (entity) {
    if (location.pathname.includes('/environments') && projectId && 'project' in entity && 'name' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}`, label: 'Dashboard' },
        { to: `/${orgId}/${entity.project?.alternateId}`, label: entity.project?.name || 'Project' },
        {
          label: 'alternateId' in entity ? entity.alternateId : 'Environment',
          color: 'color' in entity ? entity.color : undefined,
        },
      ]);
    }

    if (location.pathname.includes('/app/') && appId && 'project' in entity && 'name' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}`, label: 'Dashboard' },
        { to: `/${orgId}/${entity.project.alternateId}`, label: entity.project.name },
        { label: entity.name, icon: 'block' },
      ]);
    }

    if (location.pathname.includes('/segments') && segmentId && 'name' in entity && 'project' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}`, label: 'Dashboard' },
        { to: `/${orgId}/${entity.project.alternateId}/segments`, label: entity.project.name },
        { label: entity.name, icon: 'path-intersect' },
      ]);
    }

    if (location.pathname.includes('/toggles') && toggleId && 'key' in entity && 'project' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}`, label: 'Dashboard' },
        { to: `/${orgId}/${entity.project.alternateId}/toggles`, label: entity.project.name },
        { label: entity.key, icon: 'logo-toggle' },
      ]);
    }

    if (projectId && location.pathname.includes(projectId) && 'name' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}`, label: 'Dashboard' },
        { label: entity.name, icon: 'blocks' },
      ]);
    }

    if (location.pathname.includes('teams') && 'name' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}/teams`, label: 'Teams' },
        { label: entity.name, icon: 'users' },
      ]);
    }

    if (location.pathname.includes('/link/code_') && 'title' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}/link`, label: 'Link' },
        { label: entity.title, icon: 'logo-link' },
      ]);
    }

    if (location.pathname.includes('/integrations/oint_') && 'type' in entity) {
      if (location.pathname.includes('/connections/conn_')) {
        // link to Integration if the member can view org integrations
        if (ability.can('view', EntityNames.OrganizationIntegration) && 'organizationIntegration' in entity) {
          return renderBreadcrumb([
            { to: `/${orgId}/integrations`, label: 'Integrations' },
            {
              to: `/${orgId}/integrations/${entity.organizationIntegration.id}`,
              label: `${INTEGRATION_NAME_MAP[entity.organizationIntegration.type as IntegrationType]}`,
            },
            { label: buildConnectionUrl(entity)?.label || entity.config.name },
          ]);
        }
        if ('entity' in entity) {
          // link to app
          if (entity.entity.type === 'App') {
            return renderBreadcrumb([
              {
                to: `/${orgId}/${entity.project?.alternateId}/app/${entity.entity.id}`,
                label: entity.entity.name || 'App',
              },
              {
                to: `/${orgId}/${entity.project?.alternateId}/app/${entity.entity.id}/connections`,
                label: 'Connections',
              },
              { label: buildConnectionUrl(entity)?.label || entity.config.name },
            ]);
          }
          // link to project
          if (entity.entity.type === 'Project' && 'project' in entity && 'config' in entity) {
            return renderBreadcrumb([
              {
                to: `/${orgId}/${entity.project?.alternateId}`,
                label: entity.project?.name || 'Project',
              },
              {
                to: `/${orgId}/${entity.project?.alternateId}/connections`,
                label: 'Connections',
              },
              { label: buildConnectionUrl(entity)?.label || entity.config.name },
            ]);
          }
        }
      }
      return renderBreadcrumb([
        { to: `/${orgId}/integrations`, label: 'Integrations' },
        { label: `${INTEGRATION_NAME_MAP[entity.type as IntegrationType]}` },
      ]);
    }
    if (location.pathname.includes('/integrations/setup') && 'type' in entity) {
      return renderBreadcrumb([
        { to: `/${orgId}/integrations`, label: 'Integrations' },
        { label: `${INTEGRATION_NAME_MAP[entity.type as IntegrationType]} Setup` },
      ]);
    }
  }

  for (const page of listPages) {
    if (location.pathname === page.path || location.pathname === `${page.path}/`) {
      return renderBreadcrumb([{ to: page.path, label: page.label, icon: page.icon as IconName }]);
    }
  }

  for (const page of createPages) {
    if (location.pathname.includes(page.path)) {
      return renderBreadcrumb([
        { to: page.basePath || `/${orgId}`, label: page.parentLabel || page.label },
        { label: `Create ${page.label}` },
      ]);
    }
  }

  // Dashboard (all projects)
  if (location.pathname === `/${orgId}`) {
    return renderBreadcrumb([{ to: `/${orgId}`, label: 'Dashboard', icon: 'dashboard' }]);
  }

  // Org Setting API Key Detail
  if (location.pathname.includes('/settings/api-keys/api_') && entity && 'name' in entity) {
    return renderBreadcrumb([
      { to: `/${orgId}/settings/api-keys`, label: 'API Keys' },
      { label: entity.name, icon: 'key' },
    ]);
  }

  // Org Settings we show DropdownMenu for quick navigation
  const orgSettingsPath = `/${organization?.id}/settings`;
  if (location.pathname.startsWith(orgSettingsPath)) {
    const visibleLinks = orgSettingsLinks.filter((link) => link.condition !== false);

    const currentLabel =
      visibleLinks
        .filter((link) => location.pathname.startsWith(link.to))
        .sort((a, b) => b.to.length - a.to.length)[0]?.label || 'Menu';

    return (
      <BreadcrumbList>
        <BreadcrumbItem>
          <DropdownMenu>
            <DropdownMenuTrigger className="display-flex flex-direction-row align-items-center g-xs font-weight-semibold background-color-transparent bw-0 p-0 font-size-sm font-color-base">
              <span>{currentLabel}</span>
              <Icon name="caret-sm-down" color="secondary" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" side="bottom">
              {visibleLinks.map((link) => (
                <DropdownMenuItem asChild key={link.label}>
                  <NavLink key={link.to} className="navlink" to={link.to} end={link.label === 'Settings'}>
                    {link.label}
                  </NavLink>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </BreadcrumbItem>
      </BreadcrumbList>
    );
  }

  return null;
};

const BreadcrumbList = ({ children }: { children: React.ReactNode }) => (
  <nav aria-label="breadcrumb">
    <Box
      as="ol"
      display="flex"
      wrap
      fontSize="sm"
      direction="row"
      alignItems="center"
      gap="sm"
      borderColor="default"
      borderWidth={{ base: '0 0 0 sm', desktop: '0' }}
      padding={{ base: '0 0 0 lg', desktop: '2xl' }}
    >
      {children}
    </Box>
  </nav>
);

const BreadcrumbSeparator = () => (
  <Box as="li" role="presentation" aria-hidden="true">
    <Icon name="caret-sm-right" color="tertiary" />
  </Box>
);

const BreadcrumbItem = ({ children }: { children: React.ReactNode }) => (
  <Box as="li" display="flex" direction="row" gap="xs" alignItems="center">
    {children}
  </Box>
);

const BreadCrumbPortal = ({ entity }: { entity?: EntityTypes }) => {
  if (document.getElementById('breadcrumbContainer')) {
    return (
      <>
        {createPortal(<Breadcrumb entity={entity} />, document.getElementById('breadcrumbContainer') as HTMLElement)}
      </>
    );
  }
  return null;
};

export { BreadCrumbPortal, Breadcrumb, BreadcrumbList, BreadcrumbSeparator, BreadcrumbItem };
