import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Button, FormikSelectInputNative, FormikTextInput, Modal, toast } from '@hyphen/hyphen-components';
import { ModalProps } from '../../types/modal';
import { ApiError } from '../../ApiError';
import { InferType } from 'yup';
import { ConnectionType } from '../../../types/connections';
import { EntityTypes } from '@hyphen/nucleus/dist/types';
import { useMemo, useRef, useState } from 'react';
import { useCreateConnectionMutation } from '../../../services/connections';
import { Integration } from '../../../types/integrations';
import { CONNECTION_NAME_MAP } from '../../../constants/integrations';

const connectionSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
  entity: yup.string().required('Entity is required'),
  input: yup.string(),
});

export type ConnectionSchema = InferType<typeof connectionSchema>;

interface ConnectionModalProps extends ModalProps {
  title?: string;
  message?: string;
  organizationId: string;
  integration: Integration;
  entity?: {
    type: EntityTypes;
    id: string;
    name: string;
  };
}

export const ConnectionAddModal = ({
  isOpen,
  onClose,
  title = 'Create Connection',
  integration,
  entity,
  message,
  organizationId,
}: ConnectionModalProps) => {
  const [CreateConnection] = useCreateConnectionMutation();

  const modalRef = useRef<HTMLFormElement>(null);
  const [apiError, setApiError] = useState<any>(null);

  const typeOptions = useMemo(() => {
    const applicableProvides = entity?.type
      ? integration.provides.filter((provides) => provides.for.includes(entity.type))
      : integration.provides;
    return applicableProvides.map((provides) => ({
      label: CONNECTION_NAME_MAP[provides.connectionType],
      value: provides.connectionType,
    }));
  }, [integration, entity?.type]);

  const entityOptions = [
    {
      label: entity?.name || 'Entity',
      value: entity?.id || '',
    },
  ];

  const initialValues = {
    type: typeOptions.length === 1 ? typeOptions[0].value : '',
    entity: entity ? entity.id : '',
    input: undefined,
  };

  const handleSubmit = async (values: ConnectionSchema) => {
    const { type, entity, input } = values;
    const { error } = await CreateConnection({
      organizationId,
      integrationId: integration.id,
      connection: {
        type: type as ConnectionType,
        entity: {
          id: entity,
        },
        input,
      },
    });

    if (error) {
      setApiError(error);
    } else {
      toast.success('Connection created');
      onClose();
    }
  };

  return (
    <Modal
      ariaLabelledBy="connectionModal"
      maxWidth="9xl"
      isOpen={isOpen}
      onDismiss={() => {
        onClose();
        setApiError(null);
      }}
      ref={modalRef}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={connectionSchema}
        enableReinitialize
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, resetForm }) => {
          return (
            <Form noValidate>
              <Box gap="3xl">
                <Modal.Header
                  id="connectionModal"
                  title={title}
                  onDismiss={() => {
                    onClose();
                    resetForm();
                    setApiError(null);
                  }}
                />
                <Modal.Body gap="2xl">
                  {message && (
                    <Box as="p" color="secondary" fontSize="sm">
                      {message}
                    </Box>
                  )}
                  <Field
                    label="Connection Type"
                    name="type"
                    id="type"
                    options={typeOptions}
                    component={FormikSelectInputNative}
                    error={errors.type}
                    isDisabled={isSubmitting}
                  />
                  <Field
                    label="Connection Input"
                    name="input"
                    id="input"
                    component={FormikTextInput}
                    error={errors.input}
                    isDisabled={isSubmitting}
                  />
                  {!entity && (
                    <Field
                      label="Entity"
                      name="entity"
                      id="entity"
                      options={entityOptions}
                      component={FormikSelectInputNative}
                      error={errors.entity}
                      isRequired
                      isDisabled={isSubmitting}
                    />
                  )}
                  {apiError && <ApiError error={apiError} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      onClose();
                      resetForm();
                      setApiError(null);
                    }}
                    isDisabled={isSubmitting}
                    shadow="sm"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" isLoading={isSubmitting} type="submit" shadow="sm">
                    Save
                  </Button>
                </Modal.Footer>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
