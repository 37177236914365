import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import CreateToggleForm from '../../../components/toggles/CreateToggleForm';
import { useGetProjectsQuery } from '../../../services/projects';
import { Spinner } from '@hyphen/hyphen-components';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { TOGGLE_TEXT } from '../../../constants/toggle';

const PAGE_SIZE = 50;

export const CreateToggle: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { id: orgId, isLoading: isOrgLoading } = useOrganization();

  const { data, isLoading } = useGetProjectsQuery({ pageSize: PAGE_SIZE, pageNum: 1, organizationId: orgId });

  // Generate project options if data is available
  const projectOptions =
    data?.data.map((project) => ({
      label: project.name,
      value: project.id,
    })) || [];

  // Find the selected project if projectId is defined
  const selectedProject = projectId ? data?.data.find((project) => project.alternateId === projectId) : undefined;

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>Create Feature Toggle</title>
      </Helmet>
      <PageHeader title={TOGGLE_TEXT.createTitle} description={TOGGLE_TEXT.description} />
      <BreadCrumbPortal entity={selectedProject} />
      {isLoading || isOrgLoading ? (
        <Spinner />
      ) : (
        <CreateToggleForm organizationId={orgId} selectedProject={selectedProject} projectOptions={projectOptions} />
      )}
    </ScrollableMainLayout>
  );
};

export default CreateToggle;
