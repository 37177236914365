import { Segment, SegmentLogic } from '../types/segments';

// Type definitions
type VarOperand = { var: string };
type SimpleValue = string | number | boolean;
type ArrayValue = string[];

// Tuple types for different operations
type StandardOperation = [VarOperand, SimpleValue];
type ArrayInOperation = [VarOperand, ArrayValue];
type ContainsOperation = [SimpleValue, VarOperand];

type OperandType =
  | {
      var: string;
    }
  | string
  | number
  | boolean
  | string[];

export type LogicCondition = {
  '=='?: StandardOperation;
  '!='?: StandardOperation;
  '>='?: StandardOperation;
  '<='?: StandardOperation;
  in?: ArrayInOperation | ContainsOperation;
  [key: string]: [OperandType, OperandType] | undefined;
};

type Logic = {
  and?: LogicCondition[];
  or?: LogicCondition[];
};

export type ParsedCondition = {
  key: string;
  operator: string;
  value: string;
};

export enum Operator {
  equal = '==',
  notEqual = '!=',
  in = 'in',
  notIn = 'not in',
  contains = 'contains',
  greaterEqual = '>=',
  lessEqual = '<=',
  segment = 'segment',
}

export type ConditionInput =
  | {
      key: string;
      operator:
        | Operator.equal
        | Operator.notEqual
        | Operator.greaterEqual
        | Operator.lessEqual
        | Operator.contains
        | Operator.segment;
      value: SimpleValue;
    }
  | {
      key: string;
      operator: Operator.in | Operator.notIn;
      value: string;
    };

export const toJsonLogic = (conditions: ConditionInput[]): string => {
  const logic = toJsonLogicObject(conditions);
  return JSON.stringify(logic);
};

export const toJsonLogicObject = (conditions: ConditionInput[]): Logic => {
  const logicConditions: LogicCondition[] = conditions.map((condition) => {
    if (condition.operator === Operator.segment) {
      return {
        segment: [condition.key, condition.value],
      };
    }
    if (condition.operator === Operator.contains) {
      return {
        in: [String(condition.value), { var: condition.key }],
      };
    } else if (condition.operator === Operator.in) {
      return {
        in: [{ var: condition.key }, condition.value.split(',').map((v) => v.trim())],
      };
    } else {
      return {
        [condition.operator]: [{ var: condition.key }, condition.value],
      };
    }
  });

  const logic: Logic = {
    and: logicConditions,
  };

  return logic;
};

export const parseJsonLogic = (logic: string): ParsedCondition[] => {
  let parsedLogic: Logic;

  try {
    parsedLogic = JSON.parse(logic);
  } catch (error) {
    return [];
  }

  const conditions = parsedLogic.and || [];

  return conditions.map((condition: LogicCondition) => {
    const operator = Object.keys(condition)[0];
    const operands = condition[operator] as [OperandType, OperandType];

    if (!operands || !Array.isArray(operands) || operands.length !== 2) {
      throw new Error('Invalid logic format');
    }

    if (operator === 'segment') {
      return {
        operator: Operator.segment,
        value: operands[1].toString(),
        key: operands[0].toString(),
      };
    }

    if (operator === 'in') {
      // Case 1: Regular 'in' operator with array
      if (isVarOperand(operands[0]) && Array.isArray(operands[1])) {
        return {
          key: operands[0].var,
          operator: 'in',
          value: operands[1].join(','),
        };
      }
      // Case 2: String contains operation
      else if (typeof operands[0] === 'string' && isVarOperand(operands[1])) {
        return {
          key: operands[1].var,
          operator: 'contains',
          value: operands[0],
        };
      }
    }

    // Handle standard operators
    if (isVarOperand(operands[0])) {
      return {
        key: operands[0].var,
        operator,
        value: String(operands[1]),
      };
    }

    return {
      key: 'unknown',
      operator: 'unknown',
      value: logic,
    };
  });
};

// Type guard functions
function isVarOperand(operand: OperandType): operand is VarOperand {
  return typeof operand === 'object' && operand !== null && 'var' in operand;
}

export const parseSegmentLogic = (logic: string): SegmentLogic => {
  try {
    const json = JSON.parse(logic);
    // going to assume there is only one top level conjunction otherwise treat it as unknown.
    const conjunction = Object.keys(json)[0] as 'and' | 'or';

    const conditions = json[conjunction] || [];

    const parsedConditions = conditions.map((condition: any) => {
      const result = parseJsonLogic(JSON.stringify(condition));
      return result;
    });

    return {
      conjunction,
      conditions: parsedConditions,
    };
  } catch {
    return {} as SegmentLogic;
  }
};

export const segmentToJsonLogic = (segment: Segment): string => {
  const logicConditions = segment.parsedLogic?.conditions.map((rule) => {
    const jsonLogic = toJsonLogicObject(rule as any);
    return jsonLogic;
  });

  const jsonLogic = {
    [segment.parsedLogic?.conjunction || 'or']: logicConditions,
  };

  return JSON.stringify(jsonLogic);
};
