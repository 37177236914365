import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import CreateSegmentForm from '../../../components/segments/CreateSegmentForm';
import { Alert, Spinner } from '@hyphen/hyphen-components';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { useProject } from '../../../providers/ProjectProvider';
import { SEGMENT_TEXT } from '../../../constants/segments';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { useMemo } from 'react';
import { subject } from '@casl/ability';

export const CreateSegment: React.FC = () => {
  const { project, isLoading } = useProject();
  const ability = useOrganizationAbilityContext();

  const pageTitle = useMemo(() => SEGMENT_TEXT.createTitle(project?.name || ''), [project?.name]);

  const canCreateSegment = useMemo(() => {
    if (isLoading) return false;

    return project
      ? ability.can(
          'create',
          subject('Segment', {
            project: { id: project.id },
            organization: { id: project.organization.id },
          }),
        )
      : ability.can('create', 'Segment');
  }, [ability, isLoading, project]);

  if (isLoading) {
    return (
      <ScrollableMainLayout>
        <Spinner />
      </ScrollableMainLayout>
    );
  }

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageHeader title={pageTitle} description={SEGMENT_TEXT.definition} />
      <BreadCrumbPortal entity={project} />

      {canCreateSegment && project ? (
        <CreateSegmentForm organizationId={project.organization.id} projectId={project.id} />
      ) : (
        <Alert
          alignSelf="flex-start"
          variant="danger"
          hasIcon
          title={SEGMENT_TEXT.noCreatePermission(project?.name || '')}
        />
      )}
    </ScrollableMainLayout>
  );
};

export default CreateSegment;
