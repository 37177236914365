import { Box, Button, Card, CheckboxInput, Pagination, Popover, useOpenClose } from '@hyphen/hyphen-components';
import { ConnectionType } from '../../../types/connections';
import { EntityTypes } from '@hyphen/nucleus/dist/types';
import { useGetConnectionsQuery } from '../../../services/connections';
import { ConnectionItem } from './ConnectionItem';
import { ConnectionAddButton } from './ConnectionAddButton';
import { Outlet } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { IntegrationType } from '../../../types/integrations';
import { ConnectionItemSkeleton } from './ConnectionItemSkeleton';

const PAGE_SIZE = 10;

const CONNECTION_TYPES_OPTIONS = [
  { value: ConnectionType.DistributionList, label: 'Distribution List', isChecked: false },
  { value: ConnectionType.Channel, label: 'Channel', isChecked: false },
  { value: ConnectionType.PermissionGroup, label: 'Permission Group', isChecked: false },
  { value: ConnectionType.Folder, label: 'Folder', isChecked: false },
  { value: ConnectionType.Tag, label: 'Tag', isChecked: false },
  { value: ConnectionType.CloudWorkspace, label: 'Cloud Workspace', isChecked: false },
  { value: ConnectionType.CodeRepository, label: 'Code Repository', isChecked: false },
  { value: ConnectionType.ProjectBoard, label: 'Project Board', isChecked: false },
  { value: ConnectionType.CodeRegistry, label: 'Package Registry', isChecked: false },
  { value: ConnectionType.User, label: 'User Account', isChecked: false },
  { value: ConnectionType.IncidentManagement, label: 'Incident Management', isChecked: false },
];

interface ConnectionsListProps {
  organizationId: string;
  filter?: {
    type?: ConnectionType;
    entityType?: EntityTypes;
    entity?: {
      type: EntityTypes;
      id: string;
      name: string;
    };
    project?: {
      id: string;
      name: string;
    };
    integrationTypes?: IntegrationType[];
  };
  showManualFilter?: boolean;
  showAddButton?: boolean;
  showEntity?: boolean;
}

export const ConnectionsList = ({
  organizationId,
  filter,
  showManualFilter = true,
  showAddButton = true,
  showEntity = false,
}: ConnectionsListProps) => {
  const [pollingInterval, setPollingInterval] = useState(0);
  const { isOpen: isTypesFilterOpen, handleClose: closeTypesFilter, handleOpen: openTypesFilter } = useOpenClose();
  const [pageNum, setPageNumber] = useState<number>(1);
  const [types, setTypes] =
    useState<{ value: ConnectionType; label: string; isChecked: boolean }[]>(CONNECTION_TYPES_OPTIONS);
  const [selectedTypes, setSelectedTypes] = useState<ConnectionType[]>([]);

  const { data: connections, isLoading: isConnectionsLoading } = useGetConnectionsQuery(
    {
      organizationId,
      entityIds: filter?.entity?.id ? [filter?.entity?.id] : undefined,
      types: selectedTypes,
      integrationTypes: filter?.integrationTypes,
      pageSize: PAGE_SIZE,
      pageNum,
    },
    { pollingInterval },
  );

  useEffect(() => {
    if (connections?.data.some((connection) => connection.status === 'Pending' || connection.isRetryable)) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(0);
    }
  }, [connections]);

  const handleOnTypeChange = useCallback(
    (typeChanged: { value: ConnectionType; label: string; isChecked: boolean }) => {
      setTypes((prevTypes) =>
        prevTypes.map((conn) =>
          conn.value === typeChanged.value ? { ...conn, isChecked: !typeChanged.isChecked } : conn,
        ),
      );
    },
    [],
  );

  const applyTypesFilter = useCallback(() => {
    const flatSelectedTypes = types.filter((item) => item.isChecked).map((item) => item.value);
    setSelectedTypes(flatSelectedTypes);
    closeTypesFilter();
  }, [types, closeTypesFilter]);

  const typesFilterContent = (
    <Box gap="lg">
      <Box gap="sm" overflow="auto" className="scroll-bar-thin" maxHeight="7xl">
        {types.map((conn) => (
          <CheckboxInput
            id={`conn-${conn.value}`}
            label={conn.label}
            value={conn.value}
            key={conn.value}
            size="sm"
            isChecked={conn.isChecked}
            onChange={() => handleOnTypeChange(conn)}
          />
        ))}
      </Box>
      <Box direction="row" justifyContent="space-between">
        <Button variant="primary" size="sm" onClick={applyTypesFilter} disabled={isConnectionsLoading}>
          Apply
        </Button>
        <Button
          variant="tertiary"
          size="sm"
          onClick={() => {
            setSelectedTypes([]);
            setTypes(types.map((type) => ({ ...type, isChecked: false })));
            closeTypesFilter();
          }}
        >
          Clear
        </Button>
      </Box>
    </Box>
  );

  const renderNoResults = () => (
    <Box
      minHeight="6xl"
      width="100"
      height="100"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gap="2xl"
      className="fade-in"
    >
      <Box gap="xs" alignItems="center">
        <Box fontSize="sm" fontWeight="bold" as="h3">
          No matches
        </Box>
        <Box display="block" fontSize="xs" color="secondary" padding="0 4xl">
          Try a different filter
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box width="100" gap="xl">
      <Card>
        <Card.Header
          borderWidth="0 0 sm 0"
          alignItems="center"
          title="Integration Connections"
          description={
            showManualFilter && (
              <Box direction="row" gap="xs" alignItems="center" justifyContent="flex-start" margin="sm 0 0 0">
                <Popover
                  content={typesFilterContent}
                  isOpen={isTypesFilterOpen}
                  placement="bottom-start"
                  offsetFromTarget={4}
                  onClickOutside={closeTypesFilter}
                  hasArrow={false}
                  contentContainerProps={{
                    padding: 'sm',
                    gap: 'xs',
                    borderWidth: 'sm',
                    borderColor: 'default',
                    width: '100',
                    maxWidth: '7xl',
                  }}
                >
                  <Button
                    variant="secondary"
                    size="sm"
                    iconSuffix="caret-sm-down"
                    onClick={!isTypesFilterOpen ? openTypesFilter : closeTypesFilter}
                  >
                    {selectedTypes.length > 0 && `(${selectedTypes.length}) `}type
                  </Button>
                </Popover>
                {selectedTypes.length > 0 && (
                  <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => {
                      setSelectedTypes([]);
                      setTypes(types.map((type) => ({ ...type, isChecked: false })));
                    }}
                    iconPrefix="c-remove"
                  >
                    clear filters
                  </Button>
                )}
              </Box>
            )
          }
        >
          {showAddButton && <ConnectionAddButton organizationId={organizationId} entity={filter?.entity} />}
        </Card.Header>

        {isConnectionsLoading ? (
          <ConnectionItemSkeleton />
        ) : (
          connections && (
            <>
              {selectedTypes.length >= 1 || connections.data.length > 0 ? (
                <>
                  <Card.Section padding="0">
                    {connections.data.length === 0
                      ? renderNoResults()
                      : connections.data.map((connection) => (
                          <ConnectionItem key={connection.id} connection={connection} showEntity={showEntity} />
                        ))}
                  </Card.Section>
                  {connections.data.length > 0 && (connections.total ?? 0) > PAGE_SIZE && (
                    <Card.Section>
                      <Pagination
                        activePage={pageNum}
                        itemsPerPage={PAGE_SIZE}
                        onChange={setPageNumber}
                        totalItemsCount={connections.total ?? 0}
                        isCompact
                        arePagesVisible
                        numberOfPagesDisplayed={3}
                      />
                    </Card.Section>
                  )}{' '}
                </>
              ) : (
                <Card.Section padding="0">
                  <Box
                    minHeight="6xl"
                    width="100"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    gap="2xl"
                    className="fade-in"
                    padding="2xl"
                  >
                    <Box gap="xs" alignItems="center">
                      <Box fontSize="sm" fontWeight="bold" as="h3">
                        No connections
                      </Box>
                      <Box display="block" fontSize="xs" color="secondary" padding="0 4xl">
                        No connections with an integration exist
                      </Box>
                    </Box>
                  </Box>
                </Card.Section>
              )}
            </>
          )
        )}
      </Card>

      <Outlet />
    </Box>
  );
};
