import { Alert, Card } from '@hyphen/hyphen-components';
import { BillingAccountOwner } from '../../../services/billingAccount';
import { BillingAccountAccessItem } from './BillingAccountAccessItem';
import { CreateBillingAccountOwnerButton } from './CreateBillingAccountOwnerButton';

interface BillingAccountAccessDetailsProps {
  mode?: 'view' | 'edit';
  billingAccountId: string;
  owners: BillingAccountOwner[];
}

export const BillingAccountAccessDetails = ({
  mode = 'view',
  billingAccountId,
  owners,
}: BillingAccountAccessDetailsProps) => {
  return (
    <Card>
      <Card.Header
        title="Billing Account Owners"
        description="Can manage the payment and view usage of an organization for purposes of billing, but they cannot
              directly access organizations."
        borderWidth="0 0 sm 0"
        alignItems="center"
      >
        {mode === 'edit' && <CreateBillingAccountOwnerButton billingAccountId={billingAccountId} />}
      </Card.Header>

      {owners.length ? (
        <Card.Section padding="0">
          {owners.map((owner) => (
            <BillingAccountAccessItem
              key={owner.userId}
              mode={mode}
              billingAccountId={billingAccountId}
              owner={owner}
            />
          ))}
        </Card.Section>
      ) : (
        <Card.Section>
          <Alert hasIcon variant="warning" title="No owner found" />
        </Card.Section>
      )}
    </Card>
  );
};
