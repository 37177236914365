import { useMemo } from 'react';
import { Box, Button, Card, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import { InferType } from 'yup';
import { ApiError } from '../ApiError';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useSegment } from '../../providers/SegmentProvider';
import * as yup from 'yup';
import { useUpdateSegmentMutation } from '../../services/segments';

const editSegmentFormSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string(),
});

type EditSegmentSchema = InferType<typeof editSegmentFormSchema>;

export const EditSegmentForm = () => {
  const { id: orgId } = useOrganization();
  const { segment } = useSegment();

  const [updateSegment, { error }] = useUpdateSegmentMutation();

  const handleSubmit = async (values: EditSegmentSchema) => {
    if (!segment) return;

    const { error, data } = await updateSegment({
      organizationId: orgId,
      projectId: segment.project.alternateId,
      segmentId: segment.alternateId,
      body: { description: values.description || '', name: values.name },
    });

    if (!error && data) {
      toast.success('Segment updated');
    }
  };

  const initialValues = useMemo(
    () => ({
      name: segment?.name || '',
      description: segment?.description,
    }),
    [segment],
  );

  if (!segment) return null;

  return (
    <Card>
      <Card.Section gap="xl">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={editSegmentFormSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, isSubmitting }) => (
            <Form noValidate>
              <Box gap="2xl">
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  id="name"
                  autoComplete="off"
                  component={FormikTextInput}
                  error={errors.name}
                  isDisabled={isSubmitting}
                />
                <Field
                  type="text"
                  label="Description (optional)"
                  name="description"
                  id="description"
                  autoComplete="off"
                  component={FormikTextInput}
                  error={errors.description}
                  isDisabled={isSubmitting}
                />
                {error && <ApiError error={error} title="Error updating segment" />}
                <Box display="block">
                  <Button variant="primary" type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Card.Section>
    </Card>
  );
};
