import { parseEntityType } from '@hyphen/nucleus/dist/common/entity';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useParams, useSearchParams } from 'react-router-dom';
import { ProjectLoader } from '../components/loaders/ProjectLoader';
import { TeamLoader } from '../components/loaders/TeamLoader';
import { IntegrationLoader } from '../components/loaders/IntegrationLoader';
import { AppLoader } from '../components/loaders/AppLoader';
import { ConnectionLoader } from '../components/loaders/ConnectionLoader';
import { MemberLoader } from '../components/loaders/MemberLoader';

export const LoaderPage = () => {
  const { entityId } = useParams<{ entityId: string }>();
  const [searchParams] = useSearchParams();
  const internalPath = searchParams.get('p') || undefined;

  if (!entityId) {
    throw new Response('Not Found', { status: 404 });
  }

  try {
    const entityType = parseEntityType(entityId);

    switch (entityType) {
      case EntityNames.Project:
        return <ProjectLoader entityId={entityId} internalPath={internalPath} />;
      case EntityNames.App:
        return <AppLoader entityId={entityId} internalPath={internalPath} />;
      case EntityNames.Team:
        return <TeamLoader entityId={entityId} internalPath={internalPath} />;
      case EntityNames.OrganizationIntegration:
        return <IntegrationLoader entityId={entityId} />;
      case EntityNames.IntegrationConnection:
        return <ConnectionLoader entityId={entityId} internalPath={internalPath} />;
      case EntityNames.Member:
        return <MemberLoader entityId={entityId} internalPath={internalPath} />;
      default:
        throw new Response('Not Found', { status: 404 });
    }
  } catch (error) {
    throw new Response('Not Found', { status: 404 });
  }
};

export default LoaderPage;
