import { useCallback } from 'react';
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  toast,
  useIsMobile,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { useDeleteDomainMutation } from '../../services/link/domains';
import { Link } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { DnsStatus, Domain, SslStatus } from '../../types/domain';
import { ConfirmModal } from '../common/ConfirmModal';
import { EditDomainNotFoundRedirectUrlModal } from './EditDomainNotFoundRedirectUrlModal';
import InfoBox from '../common/InfoBox';

const customErrorMessages = {
  default: 'Failed to remove domain',
};

export const DomainItem = ({
  domain,
  organization,
  canManageDomain,
}: {
  domain: Domain;
  organization?: Organization;
  canManageDomain: boolean;
}) => {
  const [deleteDomain, { isLoading: isDeleting, error }] = useDeleteDomainMutation();

  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();

  const isMobile = useIsMobile();

  const handleRemove = useCallback(async () => {
    const result = await deleteDomain({ domainId: domain.id, organizationId: organization?.id });
    if (!result.error) {
      toast.success('Domain removed!');
      closeRemoveModal();
    }
  }, [closeRemoveModal, deleteDomain, domain.id, organization?.id]);

  const isVerified = domain.dnsStatus === DnsStatus.Verified;

  const renderMenu = () =>
    canManageDomain ? (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant={isMobile ? 'secondary' : 'tertiary'} size="sm" iconPrefix="dots" aria-label="open menu" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" side="bottom">
          <DropdownMenuGroup>
            {isVerified ? (
              <DropdownMenuItem onSelect={openEditModal}>
                <Icon name="pencil" />
                <span>Edit</span>
              </DropdownMenuItem>
            ) : (
              <DropdownMenuItem asChild>
                <Link to={`/${organization?.id}/settings/domains/${domain.id}/add`} state={{ domain }}>
                  <span>Verify DNS</span>
                </Link>
              </DropdownMenuItem>
            )}

            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={openRemoveModal} className="font-color-danger">
              <Icon name="c-remove" />
              <span>Remove</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    ) : (
      <Box width="36px" />
    );

  return (
    <Box className="row-item" borderWidth="sm 0 0 0" borderColor="default">
      <ConfirmModal
        message={`Are you sure you want to remove ${domain.domain}?`}
        confirmButtonLabel="Remove Domain"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={handleRemove}
        isLoading={isDeleting}
        title={`Remove ${domain.domain}`}
        description="Short links that use this domain will no longer work."
        error={error}
        customErrorMessages={customErrorMessages}
      />
      <EditDomainNotFoundRedirectUrlModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        domainId={domain.id}
        organizationId={organization?.id}
        currentNotFoundRedirectUrl={domain.notFoundRedirectUrl}
      />
      <Box
        direction={{ base: 'column', desktop: 'row' }}
        display="grid"
        style={!isMobile ? { gridTemplateColumns: '1fr 72px 1fr 1fr 36px' } : undefined}
        gap={{ base: 'lg', desktop: '2xl' }}
        padding="2xl"
        alignItems="center"
      >
        <Box fontSize="sm" fontWeight="semibold" direction="row" alignItems="center" gap="sm">
          <Box flex="auto">{domain.domain}</Box>
          {isMobile && renderMenu()}
        </Box>
        <InfoBox title="DNS" skeletonWidth="50px" skeletonHeight="20px" isLoading={false} background="transparent">
          <Box fontSize="xs" fontFamily="monospace" color={isVerified ? 'success' : 'warn'}>
            {domain.dnsStatus}
          </Box>
        </InfoBox>
        <InfoBox title="SSL" skeletonWidth="50px" skeletonHeight="20px" isLoading={false} background="transparent">
          {isVerified ? (
            <Box
              fontSize="xs"
              fontFamily="monospace"
              color={
                domain.sslStatus === SslStatus.Pending
                  ? 'secondary'
                  : domain.sslStatus === SslStatus.Generating
                  ? 'warn'
                  : 'success'
              }
            >
              {domain.sslStatus === SslStatus.Pending ? 'pending' : domain.sslStatus}
            </Box>
          ) : canManageDomain ? (
            <Box fontSize="xs">
              <Button asChild variant="secondary" size="sm" iconSuffix="arrow-right">
                <Link to={`/${organization?.id}/settings/domains/${domain.id}/add`} state={{ domain }}>
                  Pending DNS verification
                </Link>
              </Button>
            </Box>
          ) : (
            <Box fontSize="xs" fontFamily="monospace" color="warn">
              Pending DNS Verification
            </Box>
          )}
        </InfoBox>
        <InfoBox
          title="Link Not Found Redirect"
          skeletonWidth="50px"
          skeletonHeight="20px"
          isLoading={false}
          background="transparent"
          helpText="The URL of a custom page to redirect to when a short link is not found"
        >
          <Box fontSize="xs">{domain.notFoundRedirectUrl || <>&mdash;</>}</Box>
        </InfoBox>
        {!isMobile && renderMenu()}
      </Box>
    </Box>
  );
};
