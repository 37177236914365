import { Box, Button, Card, useBreakpoint } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ListItem } from '../../../components/ListItem';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { useProject } from '../../../providers/ProjectProvider';
import { Skeleton } from '../../../components/common/Skeleton';
import { useMemo } from 'react';
import EmptyList from '../../../components/common/EmptyList';
import ConnectionsSummary from '../../../components/integrations/connections/ConnectionsSummary';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames } from '@hyphen/nucleus/dist/types';
import { useOrganization } from '../../../providers/OrganizationProvider';

export default function ProjectOverview() {
  const { project, isLoading } = useProject();
  const { id: orgId } = useOrganization();
  const ability = useOrganizationAbilityContext();
  const { isPhone } = useBreakpoint();

  const canUpdateProject = useMemo(
    () => (!isLoading && project ? ability.can('update', project) : false),
    [project, ability, isLoading],
  );

  const hasApps = useMemo(() => (project?.apps?.length ?? 0) > 0, [project]);

  return (
    <Box
      gap={{ base: '2xl', tablet: '4xl' }}
      alignItems="flex-start"
      display="grid"
      style={{ gridTemplateColumns: isPhone ? '1fr' : '2fr minmax(200px, var(--size-dimension-8xl))' }}
    >
      <Box alignItems="flex-start" gap="xl">
        {isLoading ? (
          <Skeleton width="88px" height="32px" />
        ) : canUpdateProject ? (
          <Button asChild variant="primary" size="sm">
            <Link to={`/${project?.organization.id}/app/create?projectId=${project?.id}`}>Create App</Link>
          </Button>
        ) : null}

        <Box
          width="100"
          display="grid"
          gap={{ base: 'xl', desktop: '2xl' }}
          direction="row"
          style={{
            ...(isLoading || hasApps ? { gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))' } : {}),
          }}
        >
          {isLoading ? (
            <>
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} width="100" height="58px" />
              ))}
            </>
          ) : hasApps ? (
            project?.apps?.map((app) => (
              <Card key={app.id} className="fade-in">
                <ListItem
                  title={{
                    label: app.name,
                    link: `/${project?.organization?.id}/${project?.alternateId}/app/${app.alternateId}`,
                  }}
                  reserveDropdownBtnSpace={false}
                />
              </Card>
            ))
          ) : (
            <EmptyList title="No apps" description="No apps exist in this project" iconName="block" />
          )}
        </Box>
      </Box>
      {!isLoading && project && (
        <ConnectionsSummary
          entity={project}
          orgId={orgId}
          entityType={EntityNames.Project}
          connectionTypes={ENTITY_CONNECTION_TYPE_MAP[EntityNames.Project]}
        />
      )}
    </Box>
  );
}
