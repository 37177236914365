import { useMemo } from 'react';
import { Project } from '../../services/projects';
import { useGetTogglesQuery, useGetOrganizationalToggleSummaryQuery } from '../../services/toggle';
import { ApiError } from '../ApiError';
import ProjectToggleUsageList from '../toggles/ProjectToggleUsageList';
import { mergeToggleData } from '../toggles/Toggles';

export default function ProjectTogglesList({ project, orgId }: { project: Project; orgId: string }) {
  const {
    data: projectToggles,
    isLoading: projectTogglesLoading,
    error: projectTogglesError,
  } = useGetTogglesQuery({
    organizationId: orgId,
    projectId: project?.alternateId,
  });

  const shouldFetchUsageData = !projectTogglesLoading && projectToggles && projectToggles.data.length > 0;

  const {
    data: usageData,
    isLoading: isUsageLoading,
    error: usageError,
  } = useGetOrganizationalToggleSummaryQuery(
    {
      organizationId: orgId,
      projects: [project.alternateId],
    },
    { skip: !shouldFetchUsageData },
  );

  const error = projectTogglesError || usageError;
  const isLoading = projectTogglesLoading || isUsageLoading;

  const mergedData = useMemo(() => {
    if (!isLoading && projectToggles) {
      return mergeToggleData(
        projectToggles.data.map((toggle: any) => ({ ...toggle, project: project })) || [],
        usageData,
      );
    }
    return [];
  }, [isLoading, projectToggles, usageData, project]);

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <ProjectToggleUsageList
      orgId={orgId}
      project={mergedData[0]?.project}
      toggles={mergedData[0]?.toggles}
      showAllToggles
      isLoading={isLoading}
    />
  );
}
