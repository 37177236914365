import { useCallback, useMemo } from 'react';
import { Box, Card, toast } from '@hyphen/hyphen-components';
import { useParams } from 'react-router-dom';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useGetQrsCodeQuery, useDeleteQrCodeMutation } from '../../../services/link/codes';
import { LinkDetailStats } from '../../../components/link/LinkDetailStats';
import QrCodeItem from '../../../components/link/QrCodeItem';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import Error from '../../../Error';
import ShortLinkInfoCard from '../../../components/link/ShortLinkInfoCard';
import { LinkCode } from '../../../types/domain';

const deleteQrCodeMessage = {
  default: 'Failed to remove QR code',
};

const ShortLinkOverview = ({ code, toggleRedirect }: { code: LinkCode; toggleRedirect: () => void }) => {
  const { id: organizationId } = useOrganization();
  const { id } = useParams<{ id: any }>();
  const {
    data: qrCodes,
    error: qrCodesError,
    isLoading: isQrCodesLoading,
  } = useGetQrsCodeQuery({ codeId: id, organizationId });

  const [deleteQrCode, { isLoading: isDeletingQrCode, error: deleteQrCodeError }] = useDeleteQrCodeMutation();

  const ability = useOrganizationAbilityContext();
  const canEditLink = useMemo(() => (code ? ability.can('update', code) : false), [ability, code]);

  const handleQrCodeDelete = useCallback(
    async (qrId: string) => {
      const result = await deleteQrCode({ codeId: code?.id ?? '', qrId, organizationId: organizationId });
      if (!result.error) {
        toast.success('QR code deleted');
      }
    },
    [code?.id, deleteQrCode, organizationId],
  );

  if (qrCodesError) return <Error />;

  return (
    <>
      <ShortLinkInfoCard code={code} isLoading={isQrCodesLoading} toggleRedirect={toggleRedirect} />

      {qrCodes && qrCodes.data.length > 0 && (
        <Card>
          <Card.Section title="QR Codes">
            <Box direction="row" wrap gap="xl">
              {qrCodes.data.map((qrCode) => (
                <QrCodeItem
                  key={qrCode.id}
                  qrCode={qrCode}
                  isDeleting={isDeletingQrCode}
                  handleQrCodeDelete={handleQrCodeDelete}
                  error={deleteQrCodeError}
                  deleteQrCodeMessage={deleteQrCodeMessage}
                  canDeleteQrCode={canEditLink}
                />
              ))}
            </Box>
          </Card.Section>
        </Card>
      )}

      <LinkDetailStats />
    </>
  );
};

export default ShortLinkOverview;
