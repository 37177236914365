import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef } from 'react';
import { Segment } from '../types/segments';
import { useParams } from 'react-router-dom';
import { useOrganization } from './OrganizationProvider';
import { useLazyGetSegmentQuery } from '../services/segments';
import { useProject } from './ProjectProvider';

interface ISegmentContext {
  isLoading: boolean;
  error: any;
  segment?: Segment;
}

const SegmentContext = createContext<ISegmentContext>({
  isLoading: false,
  error: null,
  segment: undefined,
});

export interface SegmentProviderProps extends PropsWithChildren {}

export const SegmentProvider = ({ children }: SegmentProviderProps) => {
  const { segmentId } = useParams<{ segmentId: string }>();
  const { id: organizationId } = useOrganization();
  const { project, isLoading: isProjectLoading, error: projectError } = useProject();

  const [trigger, { data: segment, isLoading: isSegmentLoading, error: segmentError }] = useLazyGetSegmentQuery();

  const lastSegmentIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (!organizationId || !project?.id || !segmentId) return;

    if (lastSegmentIdRef.current === segmentId) return;

    lastSegmentIdRef.current = segmentId;
    trigger({ organizationId, projectId: project.id, segmentId });
  }, [organizationId, project, segmentId, trigger]);

  const isLoading = isSegmentLoading || isProjectLoading;
  const error = segmentError || projectError;

  const value = useMemo(
    () => ({
      isLoading,
      error,
      segment:
        segment && project && organizationId
          ? {
              ...segment,
              project: { id: project.id, alternateId: project.alternateId, name: project.name },
              organization: {
                id: organizationId,
              },
            }
          : undefined,
    }),
    [isLoading, error, segment, project, organizationId],
  );

  return <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>;
};

export const useSegment = () => {
  const context = useContext(SegmentContext);
  if (context === undefined) {
    throw new Error('useSegment must be used within a SegmentProvider');
  }
  return context;
};
