import { Box } from '@hyphen/hyphen-components';
import { Skeleton } from '../../common/Skeleton';

export const ConnectionItemSkeleton = () => (
  <Box className="row-item">
    <Box
      alignItems="center"
      direction="row"
      gap={{ base: 'lg', tablet: '2xl' }}
      padding={{ base: 'xl 2xl', tablet: '2xl' }}
      fontSize="sm"
    >
      <Box
        flex="auto"
        direction={{ base: 'column', tablet: 'row' }}
        gap={{ base: 'md', tablet: 'lg', desktop: '2xl' }}
        alignItems={{ tablet: 'center' }}
      >
        <Skeleton width="81px" height="21px" />
        <Box flex="auto" childGap="xs" alignItems="flex-start">
          <Skeleton width="120px" height="16px" />
          <Skeleton width="50px" height="14px" />
        </Box>
      </Box>
      <Skeleton width="36px" height="32px" />
      <Skeleton width="36px" height="32px" />
    </Box>
  </Box>
);
