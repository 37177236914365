import { Modal, Box, FormikSelectInputNative, FormikTextInput, Button } from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import { ApiError } from '../ApiError';
import { editCidrAccessControlSchema, EditCidrAccessControlSchema } from './AddFirewallRuleButton';

interface FirewallRuleModalProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedType: 'allow' | 'deny' | null;
  environmentOptions?: { value: string; label: string }[];
  selectedEnvironmentId?: string;
  error?: any;
  handleSave: (data: EditCidrAccessControlSchema) => Promise<{ error?: any }>;
  handleCancel: () => void;
  initialValues?: EditCidrAccessControlSchema;
}

export default function FirewallRuleModal({
  isOpen,
  handleClose,
  selectedType,
  environmentOptions,
  selectedEnvironmentId,
  error,
  handleSave,
  handleCancel,
  initialValues,
}: FirewallRuleModalProps) {
  return (
    <Modal
      id="addRuleModal"
      ariaLabelledBy="addRuleModalHeader"
      isOpen={isOpen}
      onDismiss={handleClose}
      maxWidth="420px"
    >
      <Modal.Header
        id="addRuleModalHeader"
        onDismiss={handleClose}
        title={`Add ${selectedType?.toLocaleUpperCase()} Rule`}
      />
      <Modal.Body overflow="visible">
        {selectedType && (
          <Formik
            initialValues={
              initialValues || {
                environmentId: selectedEnvironmentId || 'default',
                description: '',
                cidr: '',
                type: selectedType,
              }
            }
            validationSchema={editCidrAccessControlSchema}
            validateOnBlur={false}
            onSubmit={handleSave}
          >
            {({ errors, isSubmitting }) => (
              <Form noValidate>
                <Box gap="2xl" maxWidth="9xl">
                  {environmentOptions ? (
                    <Field
                      id="environmentId"
                      name="environmentId"
                      label="Environment"
                      component={FormikSelectInputNative}
                      options={environmentOptions}
                      error={errors.environmentId}
                      autoFocus
                    />
                  ) : (
                    <>{selectedEnvironmentId}</>
                  )}
                  <Field
                    id="description"
                    name="description"
                    label="Label"
                    helpText="A short description of the CIDR range"
                    placeholder="e.g. 'Office VPN' or 'Alex's Home'"
                    component={FormikTextInput}
                    error={errors.description}
                    autoFocus
                  />
                  <Field
                    id="cidr"
                    name="cidr"
                    label="CIDR"
                    component={FormikTextInput}
                    error={errors.cidr}
                    helpText="A range of IP addresses"
                    placeholder="e.g. 192.0.2.0/24"
                  />
                  {error && <ApiError error={error} title="Error saving access control rule" />}
                  <Box direction="row" gap="sm">
                    <Button type="submit" key="save" isLoading={isSubmitting}>
                      Save
                    </Button>
                    <Button type="button" onClick={handleCancel} variant="tertiary" isDisabled={isSubmitting}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
