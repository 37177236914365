import { Box, useBreakpoint } from '@hyphen/hyphen-components';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { Project } from '../../services/projects';
import { App } from '../../services/apps';
import { useCallback, useEffect, useMemo } from 'react';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { subject } from '@casl/ability';
import { BackgroundColor, FontColor } from '@hyphen/hyphen-design-tokens/build/types';

type AccessMessageType = {
  text: string;
  color: FontColor;
  background: BackgroundColor;
};

export default function AccessBanner({
  entity,
  layoutRef,
  entityType,
}: {
  entity: Project | App;
  layoutRef: React.RefObject<HTMLDivElement>;
  entityType: 'Project' | 'App';
}) {
  const entityLabel = entityType.toLowerCase();

  const AccessMessage: { [key: string]: AccessMessageType } = {
    MANAGER: { text: `You have full ${entityLabel} access`, color: 'info', background: 'info' },
    COLLABORATOR: { text: `You are a ${entityLabel} collaborator`, color: 'info', background: 'info' },
    LIMITED: { text: `You have limited ${entityLabel} access`, color: 'warn', background: 'warning' },
  };
  const { isPhone } = useBreakpoint();
  const ability = useOrganizationAbilityContext();

  const canUpdateEntity = useMemo(() => (entity ? ability.can('update', entity) : false), [ability, entity]);

  const updateStyleOnLayout = useCallback(
    (action: 'add' | 'remove') => {
      if (layoutRef.current) {
        layoutRef.current.style.paddingTop = action === 'add' ? '0' : '';
      }
    },
    [layoutRef],
  );

  const canManageEntityEnv = useMemo(
    () =>
      entity
        ? ability.can(
            'manage',
            subject(EntityNames.Env, {
              project: { id: 'project' in entity ? entity.project?.id : entity.id },
              organization: { id: entity.organization.id },
            }),
          )
        : false,
    [ability, entity],
  );

  const canManageEntityToggle = useMemo(
    () =>
      entity
        ? ability.can(
            'manage',
            subject(EntityNames.Toggle, {
              project: { id: 'project' in entity ? entity.project?.id : entity.id },
              organization: { id: entity.organization.id },
            }),
          )
        : false,
    [ability, entity],
  );

  const isProjectCollaborator = canManageEntityEnv && canManageEntityToggle;

  const message = canUpdateEntity
    ? AccessMessage.MANAGER
    : isProjectCollaborator
    ? AccessMessage.COLLABORATOR
    : AccessMessage.LIMITED;

  const marginSpacing = isPhone ? 'calc(-1 * var(--size-spacing-3xl))' : 'calc(-1 * var(--size-spacing-5xl))';

  useEffect(() => {
    updateStyleOnLayout('add');

    // Cleanup: Remove the style when AccessBanner is unmounted
    return () => {
      updateStyleOnLayout('remove');
    };
  }, [updateStyleOnLayout]);

  return (
    <Box
      background={message.background}
      position="sticky"
      style={{
        top: '0',
        left: '0',
        right: '0',
        zIndex: 'var(--size-z-index-sticky)',
        marginLeft: marginSpacing,
        marginRight: marginSpacing,
      }}
      padding="lg"
      fontSize="sm"
      textAlign="center"
      direction="row"
      alignItems="center"
      justifyContent="center"
      color={message.color}
      gap="xs"
      shadow="2xs"
      fontWeight="bold"
    >
      {message.text}
    </Box>
  );
}
