import { Box } from '@hyphen/hyphen-components';
import { useProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';
import { ColorDot } from '../common/ColorDot';

const EnvironmentData = ({ data, projectId }: { data: Record<string, number>; projectId: string }) => {
  const { projectEnvironments: orgProjectEnvironments } = useProjectEnvironments();
  const projectEnvironments = orgProjectEnvironments?.[projectId];
  const { date, ...environments } = data;

  return (
    <Box direction="row" wrap style={{ columnGap: 'var(--size-spacing-xl)', rowGap: 'var(--size-spacing-sm)' }}>
      {Object.entries(environments).map(([key, value]) => (
        <Box as="span" key={key} display="inline-flex" alignItems="center" direction="row" gap="xs" fontSize="xs">
          <ColorDot color={projectEnvironments?.[key]?.color || 'red'} />
          <span className="font-family-monospace">{key}</span> {value.toLocaleString('en-US')}
        </Box>
      ))}
    </Box>
  );
};

export default EnvironmentData;
