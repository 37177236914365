import { Box, Button, Tooltip, TooltipContent, TooltipTrigger } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { App } from '../../services/apps';
import { ColorDot } from './ColorDot';
import LinkInsetFill from './LinkInsetFill';

interface EnvironmentItemProps {
  id?: string;
  app?: App;
  color?: string;
  name: string;
  secrets?: any;
  hideSecrets?: boolean;
}

function EnvironmentItem(props: EnvironmentItemProps) {
  const { color, name, secrets, hideSecrets, app, id = '*' } = props;

  return (
    <Box
      borderWidth="0"
      width="100"
      height="100"
      padding="md md md xl"
      background="secondary"
      direction="row"
      gap="xs"
      alignItems="center"
      radius="md"
      // hover={{ background: 'tertiary' }}
      position="relative"
    >
      {color && <ColorDot color={color} />}
      <Box fontFamily="monospace" flex="auto" alignItems="flex-start">
        <Link to={`/${app?.organization?.id}/${app?.project?.alternateId}/app/${app?.alternateId}/${id}`}>
          <LinkInsetFill />
          {name}
        </Link>
      </Box>
      {hideSecrets ? (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="tertiary" size="sm" iconPrefix="ban" aria-label="no access to ENV secrets" />
          </TooltipTrigger>
          <TooltipContent>You do not have permission to access ENV secrets</TooltipContent>
        </Tooltip>
      ) : secrets ? (
        <Box
          borderWidth="0"
          fontSize="xs"
          background="primary"
          radius="sm"
          padding="sm"
          gap="xs"
          textAlign="right"
          color={secrets.countVariables < 1 ? 'secondary' : 'base'}
          style={{ zIndex: 1 }}
        >
          <Link to={`/${app?.organization?.id}/${app?.project?.alternateId}/app/${app?.alternateId}/${id}/versions`}>
            <Box>{secrets.countVariables} secrets</Box>
            {secrets.countVariables > 0 && <Box color="secondary">{`v${secrets.version} - ${secrets.size}`}</Box>}
          </Link>
        </Box>
      ) : (
        <Box color="tertiary">no secrets</Box>
      )}
    </Box>
  );
}

export default EnvironmentItem;
