import { Link, Outlet } from 'react-router-dom';
import { EnvsList } from '../../../components/env/EnvList';
// import { EventList } from '../../../components/env/EventList';
import { App } from '../../../services/apps';
import { Box, Card, useBreakpoint } from '@hyphen/hyphen-components';
import ConnectionsSummary from '../../../components/integrations/connections/ConnectionsSummary';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames } from '@hyphen/nucleus/dist/types';
import { Skeleton } from '../../../components/common/Skeleton';
import InfoBox from '../../../components/common/InfoBox';
import ClickToCopy from '../../../components/common/ClickToCopy';
import { EventReferenceType, EventType } from '../../../types/events';
import LinkInsetFill from '../../../components/common/LinkInsetFill';
import LastEvent from '../../../components/toggles/LastEvent';

export default function AppOverview({
  app,
  organizationId,
  isLoading,
}: {
  app?: App;
  organizationId: string;
  isLoading: boolean;
}) {
  const { isPhone } = useBreakpoint();

  return (
    <>
      <Card>
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          fontSize="xs"
          style={{ gap: '1px', backgroundColor: 'var(--color-border-subtle)' }}
        >
          <InfoBox
            title="Alternate ID"
            helpText="Reference the app in your apps or services with this identifier"
            isLoading={isLoading || !app}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          >
            {app && <ClickToCopy fontSize="xs" text={app?.alternateId} />}
          </InfoBox>
          <InfoBox
            title="Project"
            isLoading={isLoading || !app}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            gap="sm"
            hover={{ background: 'secondary' }}
          >
            <Link to={`/${app?.organization.id}/${app?.project.alternateId}`}>
              <LinkInsetFill />
              {app?.project.name}
            </Link>
          </InfoBox>
          <InfoBox
            title="Last event"
            isLoading={isLoading || !app}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            gap="sm"
            hover={{ background: 'secondary' }}
          >
            {app && (
              <LastEvent
                references={[{ id: app.id, type: EventReferenceType.App }]}
                eventTypes={[EventType.CreateApp, EventType.DeleteApp, EventType.UpdateApp]}
              />
            )}
          </InfoBox>
        </Box>
      </Card>
      <Box
        gap={{ base: '2xl', tablet: '4xl' }}
        alignItems="flex-start"
        display="grid"
        style={{ gridTemplateColumns: isPhone ? '1fr' : '2fr minmax(200px, var(--size-dimension-8xl))' }}
      >
        <Box gap={{ base: '2xl', tablet: '4xl' }} alignItems="flex-start">
          <Card>
            <Card.Header
              title={isLoading ? <Skeleton width="106px" height="20px" /> : 'ENV Secrets'}
              description={
                isLoading ? <Skeleton width="270px" height="14px" /> : 'Access logs and ENV secret version history'
              }
              borderWidth="0 0 sm 0"
            />
            <Card.Section>
              <EnvsList isLoading={isLoading} app={app} />
            </Card.Section>
          </Card>
          {/* {app && <EventList appId={app.id} title="Event Log" />} */}
        </Box>
        {!isLoading && app && (
          <ConnectionsSummary
            entity={app}
            orgId={organizationId}
            entityType={EntityNames.App}
            connectionTypes={ENTITY_CONNECTION_TYPE_MAP[EntityNames.App]}
          />
        )}
      </Box>

      <Outlet />
    </>
  );
}
