export const SEGMENT_TEXT = {
  title: 'Segments',
  createTitle: (project: string) => `Create ${project} Segment`,
  segmentKeyDescription: 'Unique identifier used to reference the segment in your toggle target',
  noCreatePermission: (project: string) => `You do not have permission to create a segment in project ${project}`,
  emptyTitle: 'No segments',
  definition: 'A segment is a target context that multiple feature toggles can reference within a project',
  alternateIdDescription:
    'Unique identifier used to reference the segment in your toggle target. They are permanent and cannot be modified.',
  cannotDelete: 'You can not delete this segment because it is currently in use by one or more feature toggles.',
  segmentRuleHeader: 'Segment Rules',
  segmentRuleDescription: 'Rules that determine if a context is in this segment',
  segmentRuleButtonLabel: 'Add Rule',
  segmentRuleEmpty: 'No rules defined',
  segmentInUse: 'In use by',
  segmentNotInUse: 'Segment not in use by any',
  segmentFormHeader: 'Define segment rules',
};
