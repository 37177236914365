import { Helmet } from 'react-helmet';
import { Skeleton } from '../../../components/common/Skeleton';
import { ProjectEnvironment } from '../../../providers/ProjectEnvironmentsProvider';
import { Card } from '@hyphen/hyphen-components';

import * as yup from 'yup';
import { InferType } from 'yup';
import { forbiddenWords } from '../../../utils/validations';
import ProjectEnvironmentForm from '../../../components/projectEnvironments/ProjectEnvironmentForm';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { useMemo } from 'react';
import DeleteProjectEnvironment from '../../../components/projectEnvironments/DeleteProjectEnvironment';
import Error from '../../../Error';

const environmentSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Alternate ID')
    .notOneOf(forbiddenWords, `Can not contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
  color: yup
    .string()
    .matches(/^#[0-9A-F]{6}$/i, 'Invalid color format')
    .required('Color is required'),
});

export type EnvironmentSchema = InferType<typeof environmentSchema>;

export default function ProjectEnvironmentSettings({
  environment,
  isLoading,
}: {
  environment?: ProjectEnvironment;
  isLoading: boolean;
}) {
  const ability = useOrganizationAbilityContext();

  const canDeleteEnvironment = useMemo(
    () => (!isLoading && environment ? ability.can('delete', environment) : false),
    [ability, environment, isLoading],
  );

  if (isLoading) {
    return (
      <>
        <Helmet title="Loading Settings" />
        <Skeleton height="162px" width="100" />
        <Skeleton height="80px" width="100" />
      </>
    );
  }

  if (environment) {
    return (
      <>
        <Helmet title={`${environment?.alternateId || 'Environment'} Settings`} />
        <Card>
          <Card.Section>{environment && <ProjectEnvironmentForm environment={environment} />}</Card.Section>
        </Card>
        {canDeleteEnvironment && <DeleteProjectEnvironment environment={environment} />}
      </>
    );
  }

  return <Error />;
}
