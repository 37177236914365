import {
  Badge,
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  toast,
  useOpenClose,
} from '@hyphen/hyphen-components';
import {
  BillingAccountPaymentMethodResponse,
  useDeletePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from '../../../services/billingAccount';
import { CreditCardDetails } from './CreditCardDetails';
import { ConfirmModal } from '../../common/ConfirmModal';
import { useCallback } from 'react';

interface PaymentMethodItemProps {
  mode?: 'view' | 'edit';
  billingAccountId: string;
  paymentMethod: BillingAccountPaymentMethodResponse;
}

export const PaymentMethodItem = ({ mode = 'view', billingAccountId, paymentMethod }: PaymentMethodItemProps) => {
  const {
    isOpen: isSetDefaultModalOpen,
    handleOpen: openSetDefaultModal,
    handleClose: closeSetDefaultModal,
  } = useOpenClose();
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const [SetDefaultPaymentMethod, { isLoading: isSetDefaultLoading, error: setDefaultError }] =
    useSetDefaultPaymentMethodMutation();
  const [DeletePaymentMethod, { isLoading: isDeleteLoading, error: deleteError }] = useDeletePaymentMethodMutation();

  const handleSetDefault = useCallback(async () => {
    const { error } = await SetDefaultPaymentMethod({ id: billingAccountId, providerId: paymentMethod.providerId });

    if (!error) {
      toast.success('Payment Method set as default!');
      closeSetDefaultModal();
    }
  }, [SetDefaultPaymentMethod, billingAccountId, closeSetDefaultModal, paymentMethod.providerId]);

  const handleDelete = useCallback(async () => {
    const { error } = await DeletePaymentMethod({ id: billingAccountId, providerId: paymentMethod.providerId });

    if (!error) {
      toast.success('Payment Method removed!');
      closeDeleteModal();
    }
  }, [DeletePaymentMethod, billingAccountId, closeDeleteModal, paymentMethod.providerId]);

  return (
    <>
      <Box className="row-item" borderWidth="sm 0 0 0" borderColor="subtle">
        <Box alignItems="center" direction="row" gap={{ base: 'lg', tablet: '2xl' }}>
          <Box
            flex="auto"
            direction={{ base: 'column', desktop: 'row' }}
            gap={{ base: 'md', tablet: 'lg', desktop: '2xl' }}
            alignItems={{ desktop: 'center' }}
          >
            <Box color="secondary" width={{ tablet: '7xl' }}>
              <Box direction="row" gap="sm" alignItems="center">
                {paymentMethod.type === 'Card' ? 'Credit Card' : 'Bank Account'}
                {paymentMethod.isDefault && <Badge variant="green" message="Default" size="sm" />}
              </Box>
            </Box>
            <Box>
              {
                <>
                  {paymentMethod.type === 'Card' ? (
                    <CreditCardDetails paymentMethod={paymentMethod} />
                  ) : (
                    <Box
                      direction="column"
                      gap="sm"
                      alignItems={{ base: 'flex-start', tablet: 'flex-end' }}
                    >{`****************${paymentMethod.last4}`}</Box>
                  )}
                </>
              }
            </Box>
          </Box>
          <Box>
            {mode === 'edit' && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="open menu" />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" side="bottom">
                  <DropdownMenuGroup>
                    <DropdownMenuItem onSelect={openSetDefaultModal}>
                      <Icon name="star" /> <span>Set as Default</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                      <Icon name="c-remove" /> <span>Delete</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </Box>
        </Box>
      </Box>
      <ConfirmModal
        message={`Are you sure you want to set this payment method as the default?`}
        confirmButtonLabel="Confirm"
        isOpen={isSetDefaultModalOpen}
        onClose={closeSetDefaultModal}
        onConfirm={handleSetDefault}
        isLoading={isSetDefaultLoading}
        title={`Set Default Payment Method`}
        error={setDefaultError}
      />
      <ConfirmModal
        message={`Are you sure you want to delete this payment method?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleteLoading}
        title={`Delete Payment Method`}
        error={deleteError}
      />
    </>
  );
};
