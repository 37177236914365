import { Box, Icon, useBreakpoint } from '@hyphen/hyphen-components';
import DeleteTeamMember from './DeleteTeamMember';
import { Link } from 'react-router-dom';
import { TeamDetails } from '../../types/teams';

interface TeamMemberItemProps {
  organizationId: string;
  team: TeamDetails;
  member: any;
  isStaticTeam: boolean;
  canUpdateTeam: boolean;
}

const TeamMemberItem = ({ organizationId, team, member, isStaticTeam, canUpdateTeam }: TeamMemberItemProps) => {
  const { isPhone } = useBreakpoint();

  return (
    <>
      {!isPhone && (
        <Box className="grid__item">
          <Icon name="user" color="tertiary" />
        </Box>
      )}
      <Box alignItems="flex-start" className="grid__item lh-heading">
        <Link to={`${member.id}`}>
          <Box fontWeight="semibold">
            {member.firstName} {member.lastName}
          </Box>

          <Box fontSize="xs" color="secondary" className="truncate">
            {member.email}
          </Box>
        </Link>
      </Box>

      {/* TODO: display team member role once the api supports it
      <Box fontSize="xs" color="secondary" className="grid__item background-color-warning">
        [owner or member]
      </Box> */}

      <Box className="grid__item">
        {isStaticTeam && canUpdateTeam && (
          <DeleteTeamMember organizationId={organizationId} teamId={team.id} memberId={member.id} />
        )}
      </Box>
    </>
  );
};

export default TeamMemberItem;
