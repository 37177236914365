import { Box, Card, useBreakpoint } from '@hyphen/hyphen-components';
import { EventList } from '../../../components/env/EventList';
import { EventReferenceType, EventType } from '../../../types/events';
import { Environment } from '../../../services/environments';
import ConnectionsSummary from '../../../components/integrations/connections/ConnectionsSummary';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames } from '@hyphen/nucleus/dist/types';
import ClickToCopy from '../../../components/common/ClickToCopy';
import LastEvent from '../../../components/toggles/LastEvent';
import InfoBox from '../../../components/common/InfoBox';
import { Link } from 'react-router-dom';
import LinkInsetFill from '../../../components/common/LinkInsetFill';

interface ProjectEnvironmentOverviewProps {
  environment?: Environment;
  isLoading: boolean;
}

const ProjectEnvironmentEventTypes = [
  EventType.CreateEnvData,
  EventType.GetEnvData,
  EventType.GetEnvDataDeny,
  EventType.DeleteEnvData,
  EventType.CreateProjectEnvironment,
  EventType.DeleteProjectEnvironment,
  EventType.UpdateProjectEnvironment,
];

export default function ProjectEnvironmentOverview({ environment, isLoading }: ProjectEnvironmentOverviewProps) {
  const { isPhone } = useBreakpoint();

  return (
    <>
      <Card>
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          fontSize="xs"
          style={{ gap: '1px', backgroundColor: 'var(--color-border-subtle)' }}
        >
          <InfoBox
            title="Project Environment ID"
            helpText="Reference the project environment in your apps or services with this identifier"
            isLoading={isLoading || !environment}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          >
            {environment && <ClickToCopy fontSize="xs" text={environment?.alternateId} />}
          </InfoBox>
          <InfoBox
            title="Project"
            isLoading={isLoading || !environment}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            gap="sm"
            hover={{ background: 'secondary' }}
          >
            <Link to={`/${environment?.organization.id}/${environment?.project.alternateId}`}>
              <LinkInsetFill />
              {environment?.project.name}
            </Link>
          </InfoBox>
          <InfoBox
            title="Last event"
            isLoading={isLoading || !environment}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            gap="sm"
            hover={{ background: 'secondary' }}
          >
            {environment && (
              <LastEvent
                references={[{ id: environment.id, type: EventReferenceType.ProjectEnvironment }]}
                eventTypes={ProjectEnvironmentEventTypes}
              />
            )}
          </InfoBox>
        </Box>
      </Card>

      {environment && (
        <Box
          gap={{ base: '2xl', tablet: '4xl' }}
          alignItems="flex-start"
          display="grid"
          style={{ gridTemplateColumns: isPhone ? '1fr' : '2fr minmax(200px, var(--size-dimension-8xl))' }}
        >
          <Box fontSize="xs" gap="4xl">
            <EventList
              title="ENV Access Log"
              pevrId={environment.id}
              showMap
              types={[
                EventType.GetEnvData,
                EventType.CreateEnvData,
                EventType.DeleteEnvData,
                EventType.GetEnvDataDeny,
              ]}
            />
          </Box>
          <ConnectionsSummary
            entity={environment}
            orgId={environment.organization.id}
            entityType={EntityNames.ProjectEnvironment}
            connectionTypes={ENTITY_CONNECTION_TYPE_MAP[EntityNames.ProjectEnvironment]}
          />
        </Box>
      )}
    </>
  );
}
