import { Alert, Box, Card, Spinner } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import ApiKeySecretsList from '../../components/apiKeys/ApiKeySecretsList';
import CreateApiKeySecret from '../../components/apiKeys/CreateApiKeySecret';
import DeleteApiKey from '../../components/apiKeys/DeleteApiKey';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetApiKeyQuery } from '../../services/apiKeys';
import { useParams } from 'react-router-dom';
import { ApiError } from '../../components/ApiError';
import { DateCreated } from '../../components/DateCreated';

export default function ApiKey() {
  const { id: orgId } = useOrganization();
  const { id = '' } = useParams();

  const { isLoading, error, data: apiKey } = useGetApiKeyQuery({ orgId, apiKeyId: id });

  if (isLoading) return <Spinner />;
  if (error) return <ApiError title="Unable to retrieve API Key" error={error} />;
  if (!apiKey) return <Alert variant="danger" title="API Key not found" />;

  return (
    <Box width="100" maxWidth="10xl" gap="4xl">
      <Helmet>
        <title>{`${apiKey.name} Api Key`}</title>
      </Helmet>
      <BreadCrumbPortal entity={apiKey} />

      <PageHeader title={apiKey.name} description={<DateCreated dateCreated={apiKey.createdAt} />} />

      <CreateApiKeySecret organizationId={orgId} apiKeyId={id} />

      <ApiKeySecretsList apiKey={apiKey} orgId={orgId} />

      <Card borderColor="danger">
        <Card.Header
          title="Delete API Key"
          description="Permanently delete the API Key to your organization"
          alignItems="center"
        >
          <DeleteApiKey organizationId={orgId} apiKeyId={id} />
        </Card.Header>
      </Card>
    </Box>
  );
}
