import { Box, useBreakpoint } from '@hyphen/hyphen-components';
import { AccessEntry } from '../../services/types';
import AccessListItem from './AccessListItem';

export interface AccessWithType extends AccessEntry {
  accessType: string;
}

interface AccessListProps {
  access: AccessWithType[];
  revokeAccess?: boolean;
}

function AccessList({ access, revokeAccess }: AccessListProps) {
  const { isPhone } = useBreakpoint();

  // do not display the Organization in the list
  const displayAccessList = access.filter((item) => item.assignment.type !== 'Organization');

  const cssGridTemplateColumns =
    revokeAccess && isPhone
      ? '80px 1fr 67px'
      : revokeAccess
      ? '100px minmax(200px, 1fr) 165px 67px'
      : isPhone
      ? '100px 1fr'
      : '100px minmax(200px, 1fr) 165px';

  return (
    <Box
      display="grid"
      style={{
        gridTemplateColumns: cssGridTemplateColumns,
      }}
    >
      {displayAccessList.map((item) => (
        <AccessListItem key={item.id} item={item} isPhone={isPhone} revokeAccess={revokeAccess} />
      ))}
    </Box>
  );
}

export default AccessList;
