import { Box, Card, Pagination, useBreakpoint } from '@hyphen/hyphen-components';
import useQueryStringState from '../../hooks/useQueryStringState';
import { useGetTeamProjectAccessQuery } from '../../services/teams';
import { TeamDetails } from '../../types/teams';
import { ApiError } from '../ApiError';
import React, { useCallback, useMemo } from 'react';
import { Skeleton } from '../common/Skeleton';
import AddProjectAccess from './AddProjectAccess';
import TeamProjectAccessItem from './TeamProjectAccessItem';
import TeamProjectAccessDescription from './TeamProjectAccessDescription';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';

export default function TeamProjectAccessList({ team, orgId }: { team: TeamDetails; orgId: string }) {
  const ability = useOrganizationAbilityContext();
  const canManageTeam = ability.can('manage', team);
  const { isPhone } = useBreakpoint();

  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const {
    data: access,
    isLoading,
    error,
  } = useGetTeamProjectAccessQuery({
    teamId: team.id,
    organizationId: orgId,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize,
  });

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState((prev: { pageNum: number; pageSize: number }) => ({ pageNum, pageSize: prev.pageSize }));
    },
    [setQsState],
  );

  // only show access to projects for now
  // e.g. don't show team access to itself
  const accessProjects = useMemo(
    () => access?.data.filter((item) => item.entity.type === 'Project') || [],
    [access],
  );

  if (!isLoading && error) return <ApiError error={error} />;

  return (
    <Card>
      <Card.Header title="Project Access" description={<TeamProjectAccessDescription />}>
        {canManageTeam && <AddProjectAccess team={team} accessProjects={accessProjects} />}
      </Card.Header>
      {isLoading ? (
        <TeamProjectAccessListSkeleton />
      ) : access && accessProjects.length ? (
        <>
          <Box
            display="grid"
            style={{
              gridTemplateColumns: isPhone ? 'minmax(100px, 1fr) 1fr 72px' : '32px minmax(100px, 1fr) 1fr 72px',
            }}
            overflow="hidden"
          >
            {accessProjects.map((item) => {
              return <TeamProjectAccessItem key={item.id} item={item} orgId={orgId} canEdit={canManageTeam} />;
            })}
          </Box>
          {
            // subtracting 1 from total because we don't want to show the access to itself
            access.total - 1 > qsState.pageSize && (
              <Box padding="lg 2xl lg 2xl" borderWidth="sm 0 0 0">
                <Pagination
                  activePage={qsState.pageNum}
                  itemsPerPage={qsState.pageSize}
                  onChange={handlePageNumberChange}
                  totalItemsCount={access?.total ?? 0}
                  numberOfPagesDisplayed={3}
                  isCompact
                  arePagesVisible
                />
              </Box>
            )
          }
        </>
      ) : (
        <Card.Section padding="0">
          <Box
            width="100"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            gap="2xl"
            className="fade-in"
            padding="4xl 2xl"
            borderWidth="sm 0 0 0"
          >
            <Box gap="xs" alignItems="center">
              <Box fontSize="sm" fontWeight="bold" as="h3">
                No projects
              </Box>
              <Box display="block" fontSize="xs" color="secondary" padding="0 4xl">
                No additional project access roles have been granted to the team
              </Box>
            </Box>
          </Box>
        </Card.Section>
      )}
    </Card>
  );
}

const TeamProjectAccessListSkeleton = React.memo(() => (
  <Box display="grid" style={{ gridTemplateColumns: 'minmax(100px, 1fr) 1fr' }} overflow="hidden">
    <Box padding="lg 2xl" className="grid__item">
      <Box direction="row" gap="sm" alignItems="center">
        <Skeleton width="16px" height="16px" />
        <Skeleton width="140px" height="16px" />
      </Box>
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Skeleton width="120px" height="16px" />
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Box direction="row" gap="sm" alignItems="center">
        <Skeleton width="16px" height="16px" />
        <Skeleton width="140px" height="16px" />
      </Box>
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Skeleton width="120px" height="16px" />
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Box direction="row" gap="sm" alignItems="center">
        <Skeleton width="16px" height="16px" />
        <Skeleton width="140px" height="16px" />
      </Box>
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Skeleton width="120px" height="16px" />
    </Box>
  </Box>
));
