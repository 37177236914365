import { Box, Card, toast } from '@hyphen/hyphen-components';
import Targets from './Targets';
import { useToggle } from '../../providers/ToggleProvider';
import DailyUsageCard from './DailyUsageCard';
import ToggleUsageList from './ToggleUsageList';
import { EventType } from '../../types/events';
import { useUpdateToggleTargetsMutation } from '../../services/toggle';
import { ToggleTarget } from '../../types/toggle';
import { TOGGLE_TEXT } from '../../constants/toggle';
import { ApiError } from '../ApiError';
import InfoCard from '../common/InfoCard';

export const ToggleStatus = () => {
  const { toggle, usage, isLoading, error } = useToggle();
  const [updateToggleTargets] = useUpdateToggleTargetsMutation();

  const handleDragEnd = async (newTargetOrder: ToggleTarget[]) => {
    try {
      if (toggle) {
        const { error, data } = await updateToggleTargets({
          organizationId: toggle.organization.id,
          projectId: toggle.project.alternateId,
          toggleKey: toggle.key,
          body: { targets: newTargetOrder },
          type: toggle.type,
        });

        if (!error && data) {
          toast.success('Order updated');
        } else {
          toast.error('Order update failed');
        }
      }
    } catch (err) {
      throw err;
    }
  };

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <Box gap="4xl">
      <InfoCard
        isLoading={isLoading}
        entity={toggle}
        entityType="toggle"
        keyIdDescription={TOGGLE_TEXT.keyIdDescription}
        eventTypes={[EventType.CreateToggle, EventType.UpdateToggle, EventType.DeleteToggle]}
      />
      <Card position="relative">
        <DailyUsageCard />
        <Card.Section padding="0">
          {(usage?.counts?.total ?? 0) > 0 && <ToggleUsageList showPagination={false} pageSize={5} />}
        </Card.Section>
      </Card>
      <Targets entity={toggle} isLoading={isLoading} onDragEnd={handleDragEnd} />
    </Box>
  );
};
