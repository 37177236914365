import { Box, Button, Modal, useOpenClose, useBreakpoint } from '@hyphen/hyphen-components';
import { CreateBillingAccountOwnerForm } from './CreateBillingAccountOwnerForm';

interface CreateBillingAccountOwnerButtonProps {
  billingAccountId: string;
}

export const CreateBillingAccountOwnerButton = ({ billingAccountId }: CreateBillingAccountOwnerButtonProps) => {
  const { isOpen, handleClose, handleOpen } = useOpenClose();
  const { isPhone } = useBreakpoint();
  return (
    <Box direction="row">
      <Button
        onClick={handleOpen}
        size="sm"
        iconPrefix={isPhone ? 'add' : undefined}
        aria-label="add billing account owner"
      >
        {!isPhone && 'Add'}
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} onDismiss={handleClose} maxWidth="9xl">
        <Modal.Header id="owner" title="Add Billing Account Owner" onDismiss={handleClose} />
        <Modal.Body>
          <CreateBillingAccountOwnerForm billingAccountId={billingAccountId} onComplete={handleClose} />
        </Modal.Body>
      </Modal>
    </Box>
  );
};
