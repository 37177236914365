import { Card, Pagination } from '@hyphen/hyphen-components';
import { Project, useGetAccessQuery } from '../../services/projects';
import useQueryStringState from '../../hooks/useQueryStringState';
import { useCallback } from 'react';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { ApiError } from '../ApiError';
import GiveAccess from '../access/GiveAccess';
import AccessList from '../access/AccessList';
import { EntityNames } from '@hyphen/nucleus/dist/types';

export default function ProjectAccessList({ project }: { project: Project }) {
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const {
    data: access,
    isLoading,
    error,
  } = useGetAccessQuery({
    projectId: project.id,
    organizationId: project.organization.id,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize,
  });

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState({ pageNum, pageSize: qsState.pageSize });
    },
    [qsState.pageSize, setQsState],
  );

  const ability = useOrganizationAbilityContext();
  const canManageProject = ability.can('manage', project);

  const accessWithType = (access?.data || []).map((item: any) => ({
    ...item,
    accessType:
      (item.entity.type === 'Project' && item.entity.id === '*') || item.entity.type === EntityNames.Organization
        ? 'inherited'
        : 'direct',
  }));

  const sortedAccessWithType = [...accessWithType].sort((a, b) => {
    return a.accessType === 'direct' ? -1 : 1;
  });

  if (!isLoading && error) return <ApiError error={error} />;

  return (
    <Card>
      <Card.Header
        title="Access"
        description="Members, teams and API keys that can manage or view this project"
        alignItems="center"
      >
        <GiveAccess entity={project} />
      </Card.Header>

      <Card.Section padding="0">
        {!isLoading && access && <AccessList access={sortedAccessWithType} revokeAccess={canManageProject} />}
      </Card.Section>

      {access?.total && access?.total > qsState.pageSize ? (
        <Card.Section>
          <Pagination
            activePage={qsState.pageNum}
            itemsPerPage={qsState.pageSize}
            onChange={handlePageNumberChange}
            totalItemsCount={access?.total ?? 0}
            numberOfPagesDisplayed={3}
            isCompact
            arePagesVisible
          />
        </Card.Section>
      ) : null}
    </Card>
  );
}
