import {
  useOpenClose,
  toast,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@hyphen/hyphen-components';
import { useState, useCallback } from 'react';
import * as yup from 'yup';
import FirewallRuleModal from './FirewallRuleModal';

export const editCidrAccessControlSchema = yup.object().shape({
  description: yup.string().required('Description is required'),
  cidr: yup
    .string()
    .trim()
    .matches(
      /^([0-9]{1,3}\.){3}[0-9]{1,3}\/[0-9]{1,2}$|^([0-9a-fA-F]{0,4}:){2,7}[0-9a-fA-F]{0,4}\/[0-9]{1,3}$/,
      'Invalid CIDR format',
    )
    .required('CIDR is required'),
  type: yup.string().oneOf(['allow', 'deny']).required('Type is required'),
  environmentId: yup.string().required('Environment is required'),
});

export type EditCidrAccessControlSchema = yup.InferType<typeof editCidrAccessControlSchema>;

interface AddFirewallRuleProps {
  onSave: (data: EditCidrAccessControlSchema) => Promise<{ error?: any }>;
  onCancel?: () => void;
  environmentOptions: { value: string; label: string }[];
  error?: any;
  initialValues?: EditCidrAccessControlSchema;
}

export default function AddFirewallRuleButton({
  onSave,
  onCancel,
  environmentOptions,
  error,
  initialValues,
}: AddFirewallRuleProps) {
  const { isOpen, handleOpen, handleClose } = useOpenClose();
  const [selectedType, setSelectedType] = useState<'allow' | 'deny' | null>(null);

  const handleOpenWithType = (type: 'allow' | 'deny') => {
    setSelectedType(type);
    handleOpen();
  };

  const handleSave = useCallback(
    async (data: EditCidrAccessControlSchema): Promise<{ error?: any }> => {
      const result = await onSave(data);
      if (!result.error) handleClose();
      else toast.error('Failed to save');
      return result;
    },
    [onSave, handleClose],
  );

  const handleCancel = useCallback(() => {
    setSelectedType(null);
    onCancel?.();
    handleClose();
  }, [onCancel, handleClose]);

  return (
    <>
      <FirewallRuleModal
        isOpen={isOpen}
        handleClose={handleClose}
        selectedType={selectedType}
        environmentOptions={environmentOptions}
        error={error}
        handleSave={handleSave}
        handleCancel={handleCancel}
        initialValues={initialValues}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="sm" variant="primary" iconSuffix="caret-down">
            Add
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="bottom" align="end">
          <DropdownMenuItem onSelect={() => handleOpenWithType('allow')}> Allow</DropdownMenuItem>
          <DropdownMenuItem onSelect={() => handleOpenWithType('deny')}> Deny </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
