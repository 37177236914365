import { useMemo } from 'react';
import { ConnectionsList } from '../../../components/integrations/connections/ConnectionsList';
import { Helmet } from 'react-helmet';
import { useProject } from '../../../providers/ProjectProvider';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Box } from '@hyphen/hyphen-components';
import { subject } from '@casl/ability';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';

export default function ProjectConnections() {
  const { project, isLoading } = useProject();
  const { id: orgId } = useOrganization();

  const ability = useOrganizationAbilityContext();

  const canReadProjectConnections = useMemo(
    () =>
      !isLoading && project
        ? ability.can(
            'read',
            subject(EntityNames.IntegrationConnection, {
              project: { id: project.id },
              organization: { id: project.organization.id },
            }),
          )
        : false,
    [ability, isLoading, project],
  );

  if (!canReadProjectConnections) {
    return (
      <>
        <Helmet title="No permission" />
        <Box fontSize="sm" color="secondary">
          You do not have access to this project's integration connections.
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet title={`${project?.name} Integration Connections`} />
      <ConnectionsList
        organizationId={orgId}
        filter={{
          entity: {
            type: 'Project',
            id: project?.id ?? '',
            name: project?.name ?? '',
          },
          entityType: 'Project',
        }}
      />
    </>
  );
}
