import { Box, Tooltip, TooltipTrigger, TooltipContent } from '@hyphen/hyphen-components';
import { PROJECTS_TEXT } from '../../constants/projects';

export default function TeamProjectAccessDescription() {
  return (
    <Box className="lh-text" color="secondary" display="block" fontSize={{ base: 'xs', tablet: 'sm' }}>
      Assign the team{' '}
      <Tooltip>
        <TooltipTrigger asChild>
          <Box
            as="button"
            display="inline"
            color="base"
            className="tdl-underline tds-dotted"
            background="transparent"
            borderWidth="0"
            padding="0"
            style={{
              textDecorationColor: 'var(--color-font-tertiary)',
            }}
          >
            project collaborator
          </Box>
        </TooltipTrigger>
        <TooltipContent className="mw-8xl font-weight-medium">
          {PROJECTS_TEXT.projectCollaboratorDescription}
        </TooltipContent>
      </Tooltip>{' '}
      or{' '}
      <Tooltip>
        <TooltipTrigger asChild>
          <Box
            as="button"
            display="inline"
            color="base"
            className="tdl-underline tds-dotted"
            background="transparent"
            borderWidth="0"
            padding="0"
            style={{
              textDecorationColor: 'var(--color-font-tertiary)',
            }}
          >
            project owner
          </Box>
        </TooltipTrigger>

        <TooltipContent className="mw-8xl font-weight-medium">
          {PROJECTS_TEXT.projectOwnerDescription}
        </TooltipContent>
      </Tooltip>{' '}
      access to projects. Members of the team will inherit the access level of the team.
    </Box>
  );
}
