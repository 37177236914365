import React, { createContext, useContext, PropsWithChildren, useMemo } from 'react';
import { Toggle, ToggleDailyUsage } from '../types/toggle';
import { useGetToggleDailyUsageQuery, useGetToggleQuery } from '../services/toggle';
import { parseJsonLogic } from '../utils/parseJsonLogic';
import { useGetProjectQuery } from '../services/projects';
import { useParams } from 'react-router-dom';
import { useOrganization } from './OrganizationProvider';

interface IToggleContext {
  isLoading: boolean;
  error: any;
  toggle?: Toggle;
  refetch: () => void;
  usage?: ToggleDailyUsage;
}

const ToggleContext = createContext<IToggleContext>({
  isLoading: false,
  error: null,
  refetch: () => {},
  toggle: undefined,
  usage: undefined,
});

export interface ToggleProviderProps extends PropsWithChildren {}

export const ToggleProvider = ({ children }: ToggleProviderProps) => {
  const { projectId, toggleId } = useParams<{ projectId: string; toggleId: string }>();
  const { organization } = useOrganization();

  const {
    data: project,
    error: projectError,
    isLoading: projectIsLoading,
  } = useGetProjectQuery({
    projectId: projectId ?? '',
    organizationId: organization?.id ?? '',
  });

  const {
    data: toggle,
    error: toggleError,
    isLoading: toggleIsLoading,
    refetch: refetchToggle,
  } = useGetToggleQuery({
    toggleKey: toggleId ?? '',
    projectId: projectId ?? '',
    organizationId: organization?.id ?? '',
  });

  const {
    data: usage,
    error: usageError,
    isLoading: usageIsLoading,
  } = useGetToggleDailyUsageQuery({
    organizationId: organization?.id ?? '',
    projectId: projectId ?? '',
    toggleKey: toggleId ?? '',
  });

  const isLoading = projectIsLoading || toggleIsLoading || usageIsLoading;
  const error = projectError || toggleError || usageError;

  const value = useMemo(
    () => ({
      isLoading,
      error,
      toggle:
        toggle && project && organization
          ? {
              ...toggle,
              targets: toggle.targets?.length
                ? toggle.targets.map((target, index) => ({
                    ...target,
                    id: `${Date.now()}-${Math.random()}-${index}`,
                    parsedLogic: parseJsonLogic(target.logic),
                  }))
                : [],
              project: { id: project.id, alternateId: project.alternateId, name: project.name },
              organization: { id: organization.id },
            }
          : undefined,
      refetch: refetchToggle,
      usage: usage ? usage : undefined,
    }),
    [isLoading, error, toggle, project, organization, refetchToggle, usage],
  );

  return <ToggleContext.Provider value={value}>{children}</ToggleContext.Provider>;
};

export const useToggle = (): IToggleContext => {
  const context = useContext(ToggleContext);
  if (!context) {
    throw new Error('useToggle must be used within a ToggleProvider');
  }
  return context;
};
