import { Card, Box, useBreakpoint } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { EventReferenceType, EventType } from '../../types/events';
import CreatedBy from '../common/CreatedBy';
import LinkInsetFill from '../common/LinkInsetFill';
import LastEvent from '../toggles/LastEvent';
import InfoBox from '../common/InfoBox';
import { useOrganization } from '../../providers/OrganizationProvider';
import { LinkCode } from '../../types/domain';

interface ShortLinkInfoCardProps {
  code?: LinkCode;
  isLoading: boolean;
  toggleRedirect: () => void;
}

export default function ShortLinkInfoCard({ code, isLoading, toggleRedirect }: ShortLinkInfoCardProps) {
  const { id: organizationId } = useOrganization();
  const { isPhone } = useBreakpoint();
  return (
    <Card>
      <Box
        display="grid"
        direction={{ base: 'column', tablet: 'row' }}
        fontSize="xs"
        style={{
          gridTemplateColumns: isPhone ? '1fr' : '1fr max-content max-content',
          gap: '1px',
          backgroundColor: 'var(--color-border-subtle)',
        }}
      >
        <InfoBox
          title="Destination Url"
          isLoading={isLoading}
          skeletonWidth="120px"
          skeletonHeight="30px"
          padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          hover={{ background: 'secondary' }}
          onEdit={toggleRedirect}
        >
          {code && (
            <Link
              to={code.long_url}
              target="_blank"
              rel="noopener noreferrer"
              className="tdl-underline"
              style={{ textDecorationColor: 'var(--color-font-tertiary)', wordBreak: 'break-all' }}
            >
              <LinkInsetFill />
              {code.long_url}
            </Link>
          )}
        </InfoBox>
        <InfoBox
          title="Created by"
          isLoading={isLoading}
          skeletonWidth="120px"
          skeletonHeight="30px"
          padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          gap="sm"
          position="inherit"
        >
          <CreatedBy
            organizationId={organizationId}
            memberId={code?.meta?.createdBy || ''}
            createdAt={code?.meta?.createdAt || ''}
          />
        </InfoBox>
        <InfoBox
          title="Last update"
          isLoading={isLoading}
          skeletonWidth="120px"
          skeletonHeight="30px"
          padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          gap="sm"
          hover={{ background: 'secondary' }}
        >
          {code && (
            <LastEvent
              references={[{ id: code.id, type: EventReferenceType.LinkCode }]}
              eventTypes={[
                EventType.CreateLinkCode,
                EventType.UpdateLinkCode,
                EventType.DeleteLinkCode,
                EventType.CreateLinkQrCode,
                EventType.DeleteLinkQrCode,
              ]}
            />
          )}
        </InfoBox>
      </Box>
    </Card>
  );
}
