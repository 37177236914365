import { api } from './api';
import { EventsResponse, GetEventsBody } from '../types/events';
import { ListResponse } from './types';

export const eventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<ListResponse<EventsResponse, 'Cursor'>, GetEventsBody>({
      query: ({ orgId, body }) => ({ url: `api/events/${orgId}/search`, method: 'POST', body }),
      providesTags: (result) => (result ? [{ type: 'Events', id: 'LIST' }] : []),
      serializeQueryArgs: ({ queryArgs }) => {
        const { body, ...rest } = queryArgs;
        const { pageCursor, ...baseBody } = body;
        return { ...rest, body: baseBody };
      },
      merge: (currentCache, newItems, { arg: { body } }) => {
        if (body.pageCursor) {
          return {
            ...newItems,
            data: [...(currentCache?.data || []), ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.body?.pageCursor !== previousArg?.body?.pageCursor;
      },
    }),
  }),
});

export const { useGetEventsQuery } = eventsApi;
