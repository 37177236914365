import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  useOpenClose,
  Modal,
  Icon,
  DrawerProvider,
  DrawerTrigger,
  Card,
} from '@hyphen/hyphen-components';
import { Fragment } from 'react/jsx-runtime';
import { Target } from './Target';
import LogicalOperator from './LogicalOperator';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import ToggleTargetDrawer from './ToggleTargetDrawer';
import DefaultReturnValueCard from './DefaultReturnValueCard';
import { Skeleton } from '../common/Skeleton';
import { Toggle, ToggleTarget } from '../../types/toggle';
import { TOGGLE_TEXT } from '../../constants/toggle';
import TooltipIcon from '../common/TooltipIcon';

interface TargetProps {
  entity?: Toggle;
  isLoading: boolean;
  onDragEnd: (newTargetOrder: ToggleTarget[]) => void;
}

function Targets({ entity, isLoading, onDragEnd }: TargetProps) {
  const { isOpen, handleOpen: showErrorModal, handleClose } = useOpenClose();
  const ability = useOrganizationAbilityContext();
  const canUpdateEntity = entity && ability.can('update', entity);

  const [items, setItems] = useState(entity && 'targets' in entity ? entity?.targets : []);
  const [previousItems, setPreviousItems] = useState(entity && 'targets' in entity ? entity?.targets : []);
  const [isDragging, setIsDragging] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [highlightTimeout, setHighlightTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setItems(entity && 'targets' in entity ? entity?.targets : []);
  }, [entity]);

  useEffect(() => {
    if (highlightedIndex !== null) {
      if (highlightTimeout) clearTimeout(highlightTimeout);
      const timer = setTimeout(() => {
        setHighlightedIndex(null);
      }, 2100);
      setHighlightTimeout(timer);
      return () => clearTimeout(timer);
    }
    // do not need to include highlightTimeout in the dependency array since it is only used for cleanup and does not change the effect behavior.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedIndex]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragStart = () => {
    if (!canUpdateEntity) return;
    setIsDragging(true);
    setPreviousItems(items);
  };

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (!canUpdateEntity) return;

      const { active, over } = event;

      if (!over || active.id === over.id) return;

      const newTargetOrder = arrayMove(
        items,
        items.findIndex((item) => item.id === active.id),
        items.findIndex((item) => item.id === over.id),
      );

      if (JSON.stringify(newTargetOrder) === JSON.stringify(items)) return;

      setItems(newTargetOrder);

      try {
        await onDragEnd(newTargetOrder);
        setIsDragging(false);
      } catch (err) {
        setItems(previousItems);
        showErrorModal();
      }
    },
    [canUpdateEntity, items, onDragEnd, previousItems, showErrorModal],
  );

  const handleUpdateItems = (highlightedIndex: number | null = null) => {
    setHighlightedIndex(highlightedIndex);
  };

  if (isLoading || !entity) return <Skeleton radius="md" background="secondary" width="100" minHeight="210px" />;

  const returnType = 'type' in entity ? entity.type : undefined;
  const defaultValue = 'defaultValue' in entity ? entity.defaultValue : undefined;

  return (
    <Card background="secondary" padding="0" radius="md" borderWidth="0" shadow="0">
      <Card.Header
        title={
          <Box direction="row" alignItems="center" gap="xs">
            Targets
            <TooltipIcon name="c-question" size="md" content={TOGGLE_TEXT.targetsDescription} />
          </Box>
        }
        borderWidth="0 0 sm 0"
        alignItems="center"
      >
        {canUpdateEntity && (
          <DrawerProvider>
            <DrawerTrigger asChild>
              <Button size="sm" variant="primary" style={{ whiteSpace: 'nowrap' }}>
                Add Target
              </Button>
            </DrawerTrigger>
            <ToggleTargetDrawer onUpdateItems={handleUpdateItems} />
          </DrawerProvider>
        )}
      </Card.Header>
      <Card.Section padding={{ base: '2xl', tablet: '3xl' }} fontSize="xs">
        <Box as="ol" gap="lg" padding="0">
          {canUpdateEntity ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items.map((targetContext: any, index: number) => (
                  <Fragment key={index}>
                    <Target
                      isDraggable
                      key={targetContext.id}
                      returnType={returnType}
                      targetContext={targetContext}
                      handleUpdateItems={handleUpdateItems}
                      isHighlighted={index === highlightedIndex}
                    />

                    {index < items.length - 1 && <LogicalOperator isDragging={isDragging} operator="OR" />}
                  </Fragment>
                ))}
              </SortableContext>
              {defaultValue !== undefined && items.length > 0 && (
                <LogicalOperator isDragging={false} operator="ELSE" />
              )}
              {defaultValue !== undefined && (
                <DefaultReturnValueCard defaultReturnValue={defaultValue} canUpdateToggle={canUpdateEntity} />
              )}
            </DndContext>
          ) : (
            <>
              {items.map((targetContext: any, index: number) => (
                <Fragment key={index}>
                  <Target
                    isHighlighted={false}
                    isDraggable={false}
                    returnType={returnType}
                    targetContext={targetContext}
                  />
                  {index < items.length - 1 && <LogicalOperator isDragging={isDragging} operator="OR" />}
                </Fragment>
              ))}
              {defaultValue !== undefined && items.length > 0 && (
                <LogicalOperator isDragging={false} operator="ELSE" />
              )}
              {defaultValue !== undefined && <DefaultReturnValueCard defaultReturnValue={defaultValue} />}
            </>
          )}
        </Box>
        <Modal
          isOpen={isOpen}
          onDismiss={handleClose}
          background="danger"
          maxWidth="9xl"
          ariaLabelledBy="orderError"
        >
          <Modal.Body gap="lg" textAlign="center" alignItems="center">
            <Icon name="t-warning" size="4xl" color="danger" />
            <Box as="p" id="orderError" margin="0 0 lg 0">
              Failed to update. Reverting to previous order.
            </Box>
            <Button onClick={handleClose}>Acknowledge</Button>
          </Modal.Body>
        </Modal>
      </Card.Section>
    </Card>
  );
}

export default Targets;
