import { Box, Button, Heading, Table } from '@hyphen/hyphen-components';
import { Column, UnknownPropertiesObjType } from '@hyphen/hyphen-components/dist/types';
import { NetInfoResponse } from '../../types/netInfo';

interface NetInfoTableContentProps {
  netInfo?: NetInfoResponse;
  tableData: UnknownPropertiesObjType[];
  columnConfig: Column[];
  setNextPageCursor: (cursor: string | undefined) => void;
  isLoading: boolean;
}

export const NetInfoTableContent = ({
  netInfo,
  tableData,
  columnConfig,
  setNextPageCursor,
  isLoading,
}: NetInfoTableContentProps) => {
  const netInfoData = netInfo?.data || [];

  const handleLoadMore = () => {
    if (netInfo?.nextPageCursor) {
      setNextPageCursor(netInfo.nextPageCursor);
    }
  };

  if (netInfoData.length === 0 && tableData.length === 0 && !isLoading)
    return (
      <Box width="100" height="7xl" alignItems="center" justifyContent="center" gap="md" textAlign="center">
        <Heading as="h2" size={{ base: 'sm', tablet: 'md' }}>
          No net.info requests have been logged
        </Heading>
        <Box as="p" fontSize="sm" color="secondary">
          You'll see requests here when your applications use the net.info service
        </Box>
      </Box>
    );

  return (
    <Box gap="xl">
      <Table
        isScrollable={{
          x: true,
          y: false,
        }}
        rowKey="id"
        isLoading={isLoading}
        columns={columnConfig}
        rows={tableData}
      />
      {netInfo?.nextPageCursor ? (
        <Box alignItems="flex-start">
          <Button onClick={handleLoadMore} size="sm" variant="secondary">
            Load more
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
