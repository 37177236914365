import { Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useDeleteApiKeyMutation } from '../../services/apiKeys';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';
import { useNavigate } from 'react-router-dom';

export default function DeleteApiKey({ organizationId, apiKeyId }: { organizationId: string; apiKeyId: string }) {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const navigate = useNavigate();

  const [deleteApiKey, { isLoading: isDeleting }] = useDeleteApiKeyMutation();

  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteApiKey({ orgId: organizationId, apiKeyId });
      if (!result.error) {
        toast.success(`API Key deleted`);
        closeDeleteModal();
        navigate(`/${organizationId}/settings/api-keys`, { replace: true });
      } else {
        console.error(`Delete API key failed`, result.error);
        toast.error(`Delete API key failed`);
      }
    } catch (e) {
      console.error(`Delete API key failed`, e);
      toast.error(`Delete API key failed`);
    }
  }, [apiKeyId, closeDeleteModal, deleteApiKey, organizationId, navigate]);

  return (
    <div>
      <ConfirmModal
        message={`Are you sure you want to remove this api key'?`}
        confirmButtonLabel="Delete API Key"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title="Delete API Key"
        description="You will no longer be able to use this secret to authenticate with our API."
      />
      <Button size="sm" variant="danger" onClick={openDeleteModal}>
        Delete
      </Button>
    </div>
  );
}
