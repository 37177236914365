import { Box } from '@hyphen/hyphen-components';

import { BillingAccountResponse, BillingCycle } from '../../../services/billingAccount';
import { InvoiceList } from '../invoices/InvoiceList';
import { UpcomingInvoiceDetails } from '../upcomingInvoice/UpcomingInvoiceDetails';

interface BillingAccountInvoicesProps {
  invoices: BillingAccountResponse['invoices'];
  billingCycle?: BillingCycle;
}

export const BillingAccountInvoices = ({ invoices, billingCycle }: BillingAccountInvoicesProps) => {
  return (
    <Box width="100" maxWidth="9xl" gap="4xl" fontSize="sm">
      {billingCycle && <UpcomingInvoiceDetails billingCycle={billingCycle} />}
      <InvoiceList invoices={invoices} />
    </Box>
  );
};
