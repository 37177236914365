import { Box, Button, Card, Icon, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback, useEffect, useMemo } from 'react';
import { Connection } from '../../../types/connections';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Organization } from '../../../services/organization';
import { useDeleteConnectionMutation } from '../../../services/connections';
import { ConfirmModal } from '../../common/ConfirmModal';
import { CONNECTION_NAME_MAP } from '../../../constants/integrations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ConnectionVerificationModal from './ConnectionVerificationModal';
import { useOrganizationAbilityContext } from '../../auth/OrganizationAbilityProvider';

interface ConnectionCardProps {
  connection: Connection;
  details: { label: string; value: string }[];
}

const setErrorMessage = (content: string) => ({ default: `Failed to remove ${content} connection.` });

export const ConnectionDetailsCard = ({ connection, details }: ConnectionCardProps) => {
  const [searchParams] = useSearchParams();
  const { organization = {} as Organization } = useOrganization();
  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();
  const { isOpen: isVerifyModalOpen, handleOpen: openVerifyModal, handleClose: closeVerifyModal } = useOpenClose();
  const [deleteConnection, { isLoading: isDeleting, error }] = useDeleteConnectionMutation();
  const autoOpenVerifyModal = searchParams.has('verify');
  const verificationCode = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    if (autoOpenVerifyModal) {
      openVerifyModal();
    }
  }, [autoOpenVerifyModal, openVerifyModal]);
  const wrappedVerifyOnClose = useCallback(() => {
    searchParams.delete('code');
    searchParams.delete('verify');
    navigate({ search: searchParams.toString() }, { replace: true });
    closeVerifyModal();
  }, [closeVerifyModal, navigate, searchParams]);

  const ability = useOrganizationAbilityContext();
  const canManageThisConnection = ability.can('manage', connection);


  const handleDelete = useCallback(async () => {
    const { error } = await deleteConnection({
      organizationId: organization.id,
      integrationId: connection.organizationIntegration.id,
      connectionId: connection.id,
    });
    if (!error) {
      toast.success('Connection removed!');
      closeRemoveModal();
      navigate(`/${organization.id}/integrations/${connection.organizationIntegration.id}`, { replace: true });
    }
  }, [
    deleteConnection,
    organization.id,
    connection.organizationIntegration.id,
    connection.id,
    closeRemoveModal,
    navigate,
  ]);
  const iconColor = useMemo(() => {
    switch (connection.status) {
      case 'Ready':
        return 'success';
      case 'Pending':
      case 'Verifying':
        return 'warn';
      default:
        return 'danger';
    }
  }, [connection.status]);

  const statusMessage = useMemo(() => {
    switch (connection.status) {
      case 'Ready':
        return 'Connected';
      case 'Pending':
        return 'Pending';
      case 'Verifying':
        return 'Verifying';
      default:
        return 'Error';
    }
  }, [connection.status]);

  return (
    <Card>
      <ConfirmModal
        message={`Are you sure you want to delete this connection?`}
        confirmButtonLabel="Delete"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete Connection`}
        error={error}
        customErrorMessages={setErrorMessage(CONNECTION_NAME_MAP[connection.type])}
      />
      <ConnectionVerificationModal
        isOpen={isVerifyModalOpen}
        onClose={wrappedVerifyOnClose}
        connection={connection}
        code={verificationCode}
      />
      <Card.Section childGap="xl">
        <Box direction="row" justifyContent="space-between" alignItems="center">
          <Box direction="row" gap="xs" alignItems="center">
            <Icon name="circle-filled" color={iconColor} />{' '}
            <Box fontSize="sm">{connection.isRetryable ? 'Retrying' : statusMessage}</Box>
          </Box>
          {canManageThisConnection && (
            <Box direction="row" gap="sm">
              {connection.status === 'Verifying' && (
                <Button size="sm" variant="primary" onClick={openVerifyModal}>
                  Verify
                </Button>
              )}
              <Button
                size="sm"
                variant="danger"
                isLoading={isDeleting}
                iconPrefix="c-remove"
                onClick={openRemoveModal}
                aria-label="Remove Integration Connection"
              />
            </Box>
          )}
        </Box>
      </Card.Section>
      <Card.Section>
        {details.map((detail, index) => (
          <Box
            key={index}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            borderWidth="0 0 xs 0"
            borderColor="default"
            padding="0 0 xs 0"
          >
            <Box fontWeight="medium">{detail.label}</Box>
            <Box>{detail.value}</Box>
          </Box>
        ))}
        {connection.errors && (
          <Box display="block">
            <Box display="block" fontWeight="bold" margin={{ base: '0 0 md 0' }}>
              {connection.isRetryable ? 'Retrying messages' : 'Errors'}
            </Box>
            {connection.errors.map((error, index) => (
              <Box key={index} display="block" fontSize="md">
                {error}
              </Box>
            ))}
          </Box>
        )}
      </Card.Section>
    </Card>
  );
};
