/**
 * This component renders a span that fills the entire ancestor element.
 * It's useful when you want to make the entire Box that contains a link clickable.
 * Place this inside a Link and set the ancestor Box to `position="relative"`.
 *
 * @returns A span element styled to fill its container.
 */
export default function LinkInsetFill() {
  return <span style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }} />;
}
