import { Box } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import ApiKeyList from '../../components/apiKeys/ApiKeyList';
import { useOrganization } from '../../providers/OrganizationProvider';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import { CreateApiKey } from '../../components/apiKeys/CreateApiKey';
import { Skeleton } from '../../components/common/Skeleton';

export default function ApiKeys() {
  const { organization, isLoading } = useOrganization();

  return (
    <Box width="100" maxWidth="10xl" gap="4xl">
      <Helmet>
        <title>API Keys</title>
      </Helmet>
      <PageHeader
        title="API Keys"
        description="Keys generated to authenticate and authorize API requests to your Hyphen organization"
      />
      <BreadCrumbPortal />
      {!organization || isLoading ? (
        <Skeleton width="133px" height="40px" />
      ) : (
        <>
          <CreateApiKey organizationId={organization.id} />
          <ApiKeyList organizationId={organization.id} />
        </>
      )}
    </Box>
  );
}
