// import { EventList } from '../../../components/env/EventList';
import { App, CidrAllowDenyEntry, EnvAccessControls, useUpdateAppSettingsMutation } from '../../../services/apps';
import { Box, Card, Icon, toast } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import { Environment } from '../../../services/environments';
import { useCallback, useMemo } from 'react';
import EnvironmentRules from '../../../components/app/EnvironmentRules';
import EnvironmentRuleList from '../../../components/app/EnvironmentRuleList';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import AddFirewallRuleButton, { EditCidrAccessControlSchema } from '../../../components/app/AddFirewallRuleButton';
import { Project } from '../../../services/projects';
import { Skeleton } from '../../../components/common/Skeleton';

export default function AppFirewall({
  app,
  organizationId,
  isLoading,
  project,
}: {
  app?: App;
  organizationId: string;
  isLoading: boolean;
  project?: Project;
}) {
  const ability = useOrganizationAbilityContext();
  const canUpdateApp = app ? ability.can('update', app) : false;
  const environments = useMemo(() => project?.environments || [], [project]);

  const defaultAllowDenyRules = useMemo(() => {
    if (!app?.settings?.env?.accessControls) return { allow: [], deny: [] };
    const { allow = [], deny = [] } = app.settings.env.accessControls;
    return { allow, deny };
  }, [app]);

  const [updateAppSettings, { error }] = useUpdateAppSettingsMutation();

  const handleSave = useCallback(
    async (data: EditCidrAccessControlSchema): Promise<{ error?: any }> => {
      if (!app) {
        return { error: 'App is not defined' };
      }

      const isDefault = data.environmentId === 'default';
      const accessControlsBefore = getAccessControlsBefore(app, isDefault, data.environmentId);
      const updatedList = [
        ...(accessControlsBefore[data.type] || []),
        { type: 'cidr' as const, description: data.description, cidr: data.cidr },
      ];

      const updatedAccessControls = buildUpdatedAccessControls(
        accessControlsBefore,
        data.type,
        updatedList,
        isDefault,
        data.environmentId,
      );

      try {
        const results = await updateAppSettings({
          organizationId: organizationId,
          appId: app.id,
          body: { settings: { env: { accessControls: updatedAccessControls } } },
        });

        if (!results.error) {
          toast.success('Rule added');
          return { error: undefined };
        }

        return { error: results.error };
      } catch (error) {
        return { error };
      }
    },
    [app, organizationId, updateAppSettings],
  );

  const environmentOptions = useMemo(
    () =>
      [{ label: 'Default (all environments)', value: 'default' }].concat(
        environments.map(({ alternateId, name }) => ({ value: alternateId, label: name })),
      ),
    [environments],
  );

  return (
    <>
      <Helmet title={`${app?.name || 'App'} ENV Firewall`} />
      <Card position="relative">
        <Card.Header
          title="ENV Secrets Allow/Deny Lists"
          description="Allow or deny IP addresses that can access app secrets for each environment"
          alignItems="center"
        >
          {canUpdateApp && (
            <AddFirewallRuleButton onSave={handleSave} environmentOptions={environmentOptions} error={error} />
          )}
        </Card.Header>
        <Box
          width="100"
          borderWidth="sm 0 0 0"
          display="grid"
          style={{ gridTemplateColumns: '1fr 1fr 1fr' }}
          overflow="auto"
        >
          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            <>
              <Box padding="xl 2xl sm 2xl" />
              <Box padding="xl 2xl sm 2xl" direction="row" alignItems="center" gap="xs">
                <Icon name="c-check" color="success" /> Allow
              </Box>
              <Box padding="xl 2xl sm 2xl" direction="row" alignItems="center" gap="xs">
                <Icon name="c-remove" color="danger" /> Deny
              </Box>
              <Box gap="xs" className="grid__item">
                <Box fontWeight="semibold" fontSize="xs" fontFamily="monospace">
                  Default (all)
                </Box>
                <Box fontSize="xs" color="secondary">
                  Applies to all environments
                </Box>
              </Box>
              <Box className="grid__item">
                {app && <EnvironmentRuleList rules={defaultAllowDenyRules.allow} app={app} isDefault type="allow" />}
              </Box>
              <Box className="grid__item">
                {app && <EnvironmentRuleList rules={defaultAllowDenyRules.deny} app={app} isDefault type="deny" />}
              </Box>
              {environments &&
                app &&
                environments.map((environment: Environment) => {
                  const rules = {
                    allow: app.settings?.env?.accessControls?.environments?.[environment.alternateId]?.allow || [],
                    deny: app.settings?.env?.accessControls?.environments?.[environment.alternateId]?.deny || [],
                  };

                  return (
                    <EnvironmentRules
                      key={environment.alternateId}
                      environment={environment}
                      rules={rules}
                      app={app}
                    />
                  );
                })}
            </>
          )}
        </Box>
      </Card>
    </>
  );
}

const LoadingSkeleton = () => (
  <>
    <Box padding="xl 2xl sm 2xl" />
    <Box padding="xl 2xl sm 2xl" direction="row" alignItems="center" gap="xs">
      <Skeleton width="88px" height="14px" />
    </Box>
    <Box padding="xl 2xl sm 2xl" direction="row" alignItems="center" gap="xs">
      <Skeleton width="88px" height="14px" />
    </Box>
    <Box gap="xs" className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box gap="xs" className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box gap="xs" className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
    <Box className="grid__item">
      <Skeleton width="100px" height="16px" />
    </Box>
  </>
);

// These below helpers are exported to be used by add as well.

export const getAccessControlsBefore = (app: App, isDefault: boolean, environmentAlternateId?: string) => {
  if (isDefault) {
    return app.settings?.env?.accessControls || { allow: [], deny: [] };
  }
  return app.settings?.env?.accessControls?.environments?.[environmentAlternateId!] || { allow: [], deny: [] };
};

export const buildUpdatedAccessControls = (
  accessControlsBefore: EnvAccessControls,
  type: 'allow' | 'deny',
  updatedList: Array<CidrAllowDenyEntry>,
  isDefault: boolean,
  environmentAlternateId?: string,
) => {
  if (isDefault) {
    return {
      ...accessControlsBefore,
      [type]: updatedList,
    };
  }

  return {
    environments: {
      [environmentAlternateId!]: {
        ...accessControlsBefore,
        [type]: updatedList,
      },
    },
  };
};
