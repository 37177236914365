import React, { useMemo, useCallback } from 'react';
import {
  Box,
  DrawerProvider,
  Icon,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useBreakpoint,
} from '@hyphen/hyphen-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ToggleTargetDrawer from './ToggleTargetDrawer';
import TargetCard from './TargetCard'; // Updated import
import { ToggleReturnType } from '../../types/toggle';
import { ParsedCondition } from '../../utils/parseJsonLogic';

interface TargetProps {
  targetContext: TargetContextType;
  returnType?: ToggleReturnType;
  isDraggable: boolean;
  handleUpdateItems?: (updatedItems: any) => void;
  isHighlighted: boolean;
}

interface TargetContextType {
  id: string;
  parsedLogic: ParsedCondition[];
  value?: boolean | string | number;
}

export const Target = React.memo(
  ({ targetContext, returnType, isDraggable, handleUpdateItems = () => {}, isHighlighted }: TargetProps) => {
    const { isPhone } = useBreakpoint();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting, newIndex } =
      useSortable({
        id: targetContext.id,
      });

    const style = useMemo(
      () =>
        isDraggable
          ? {
              transform: CSS.Translate.toString(transform),
              transition,
              cursor: 'move',
              touchAction: 'none',
            }
          : {},
      [transform, transition, isDraggable],
    );

    const DisplayIndex = useMemo(
      () =>
        isDragging || isSorting ? (
          <Box
            width="4xl"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            fontSize="md"
            fontWeight="bold"
            color="secondary"
          >
            {newIndex + 1}
          </Box>
        ) : isDraggable ? (
          <Box width="4xl" height="4xl" alignItems="center">
            <Tooltip>
              <TooltipTrigger asChild>
                <Box
                  as="button"
                  aria-label="drag to reorder"
                  width="4xl"
                  height="4xl"
                  borderWidth="0"
                  background="transparent"
                  color="base"
                  cursor="grab"
                  alignItems="center"
                  justifyContent="center"
                  padding="xs"
                  radius="sm"
                  hover={{ background: 'button-tertiary-hover' }}
                >
                  <Icon name="grab" size="xl" />
                </Box>
              </TooltipTrigger>
              <TooltipContent>drag to reorder</TooltipContent>
            </Tooltip>
          </Box>
        ) : null,
      [isDraggable, isDragging, isSorting, newIndex],
    );

    const renderUpdateMenu = useCallback(() => {
      if (!isDraggable) return null;
      if (isDragging || isSorting) {
        return <Box width="36px" height="32px" style={{ flexShrink: '0' }} />;
      }
      return (
        <Box width="36px" alignItems="center">
          <DrawerProvider>
            <ToggleTargetDrawer targetContext={targetContext} onUpdateItems={handleUpdateItems} />
          </DrawerProvider>
        </Box>
      );
    }, [isDraggable, isDragging, isSorting, targetContext, handleUpdateItems]);

    return (
      <Box
        ref={setNodeRef}
        style={style}
        as="li"
        direction="row"
        alignItems="center"
        gap={{ base: 'md', tablet: 'lg' }}
      >
        <Box
          flex="auto"
          {...listeners}
          {...attributes}
          id={targetContext.id}
          direction="row"
          alignItems="center"
          gap="lg"
          position="relative"
        >
          {!isPhone && DisplayIndex}
          <TargetCard
            targetContext={targetContext}
            returnType={returnType}
            isDraggable={isDraggable}
            isHighlighted={isHighlighted}
          />
          {isPhone && (
            <Box
              alignItems="center"
              justifyContent="center"
              position="absolute"
              background="primary"
              padding="2xs"
              borderWidth="sm"
              radius="2xl"
              shadow="xs"
              width="32px"
              height="32px"
              style={{ top: '-12px', right: '-12px' }}
            >
              {DisplayIndex}
            </Box>
          )}
        </Box>
        {renderUpdateMenu()}
      </Box>
    );
  },
);
