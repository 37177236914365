import { Box, Button } from '@hyphen/hyphen-components';
import { Skeleton } from '../common/Skeleton';
import { Link } from 'react-router-dom';
import TooltipIcon from '../common/TooltipIcon';

function ListHeader({
  isLoading,
  canCreate,
  createLabel,
  showHelp,
  helpContent,
}: {
  isLoading: boolean;
  canCreate: boolean;
  createLabel: string;
  showHelp: boolean;
  helpContent: string;
}) {
  if (!canCreate && !showHelp) return null;

  return (
    <Box direction="row" gap="lg" alignItems="center">
      {isLoading ? (
        <Skeleton width="114px" height="32px" />
      ) : canCreate ? (
        <div>
          <Button asChild size="sm">
            <Link to="create">{createLabel}</Link>
          </Button>
        </div>
      ) : null}
      {showHelp && <TooltipIcon name="c-question" size="md" content={helpContent} />}
    </Box>
  );
}

export default ListHeader;
