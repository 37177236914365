import { toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useDeleteSegmentMutation } from '../../services/segments';
import { useNavigate } from 'react-router-dom';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { ConfirmModal } from '../common/ConfirmModal';
import { useSegment } from '../../providers/SegmentProvider';
import { DeleteItemCard } from '../common/DeleteItemCard';
import { SEGMENT_TEXT } from '../../constants/segments';

export default function DeleteSegment() {
  const { segment } = useSegment();
  const { id: orgId } = useOrganization();
  const [deleteSegment, { isLoading: isDeleting, error }] = useDeleteSegmentMutation();
  const ability = useOrganizationAbilityContext();

  const navigate = useNavigate();

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const handleDelete = useCallback(async () => {
    if (!segment) return;

    const {error} = await deleteSegment({
      organizationId: orgId,
      projectId: segment.project.alternateId,
      segmentId: segment.alternateId,
    })
    if(!error) {
      toast.success('Segment deleted');
      closeDeleteModal();
      navigate(`/${orgId}/${segment.project.alternateId}/segments`, { replace: true });
    }
  }, [segment, deleteSegment, orgId, closeDeleteModal, navigate]);

  if (!segment) return null;

  const canDeleteSegment = ability.can('delete', segment);

  if (!canDeleteSegment) return null;

  const toggles = segment?.usages.toggles || [];
  const hasToggles = toggles.length > 0;

  return (
    <>
      <DeleteItemCard itemLabel="Segment" onDelete={openDeleteModal} isDisabled={hasToggles} description={hasToggles ? SEGMENT_TEXT.cannotDelete : 'Once deleted, it will be gone forever. Please be certain.'}/>
      <ConfirmModal
        message={`Are you sure you want to delete ${segment.alternateId}`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        error={error}
        customErrorMessages={{ default: 'Delete segment failed' }}
      />
    </>
  );
}