import { Box } from '@hyphen/hyphen-components';
import { ColorDot } from '../common/ColorDot';

export const ProjectEnvironmentUsage = ({ count, name, color }: { count?: number; name: string; color: string }) => {
  return (
    <Box
      textAlign="left"
      color={count && count > 0 ? 'inverse' : 'disabled'}
      width="100"
      height="100"
      direction="row"
      gap="xs"
      alignItems="center"
      radius="sm"
    >
      <ColorDot color={color} />
      <Box fontFamily="monospace" flex="auto">
        {name}
      </Box>
      {count && <Box>{count.toLocaleString()}</Box>}
    </Box>
  );
};
