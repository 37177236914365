import { Popover, Box, useIsMobile } from '@hyphen/hyphen-components';
import { ProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';
import { ToggleSummary } from '../../types/toggle';
import { ProjectEnvironmentUsage } from './ProjectEnvironmentUsage';

interface UsagePopoverProps {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  toggleData: ToggleSummary['toggles'][string];
  projectEnvironments?: ProjectEnvironments;
  projectId: string;
  toggleKey: string;
}

const UsagePopover = ({
  isOpen,
  handleOpen,
  handleClose,
  toggleData,
  projectEnvironments,
  projectId,
  toggleKey,
}: UsagePopoverProps) => {
  const isMobile = useIsMobile();
  const { count, environments } = toggleData;
  const noUsage = count === 0;

  const popoverContent = (
    <Box alignItems="flex-start" gap="sm">
      <Box color="inverse">Last 30 days usage</Box>
      {environments.length > 0 && (
        <Box
          display="grid"
          flex="auto"
          gap="md"
          fontSize="xs"
          width={{ base: '100', desktop: '70' }}
          direction={{ base: 'column', tablet: 'row' }}
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
          }}
        >
          {environments.map((e) => (
            <ProjectEnvironmentUsage
              key={`${toggleKey}-${e.name}`}
              count={e.count}
              name={e.name}
              color={projectEnvironments?.[projectId]?.[e.name]?.color || '#ffffff'}
            />
          ))}
        </Box>
      )}
      {!noUsage && environments.length > 1 && (
        <Box textAlign="right" width="100">
          Total: {count.toLocaleString('en-US')}
        </Box>
      )}
    </Box>
  );

  return (
    <Box width={isMobile ? '50px' : '70px'}>
      <Popover
        isOpen={isOpen}
        contentContainerProps={{
          background: 'tooltip',
          color: 'tooltip',
          padding: 'lg',
          fontFamily: 'monospace',
        }}
        content={popoverContent}
      >
        <Box
          as="button"
          type="button"
          fontSize="lg"
          fontWeight="bold"
          textAlign="right"
          color={noUsage ? 'disabled' : 'base'}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          onFocus={handleOpen}
          onBlur={handleClose}
          padding="0"
          borderWidth="0"
          background="transparent"
          className="tds-dotted tdl-underline"
          style={{
            textDecorationColor: noUsage ? 'var(--color-font-disabled)' : 'var(--color-font-tertiary)',
          }}
        >
          {toggleData.count.toLocaleString('en-US', {
            notation: isMobile ? 'compact' : 'standard',
            compactDisplay: isMobile ? 'short' : 'long',
          })}
        </Box>
      </Popover>
    </Box>
  );
};

export default UsagePopover;
