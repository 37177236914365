import { Helmet } from 'react-helmet';
import { Box } from '@hyphen/hyphen-components';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { useMemo } from 'react';
import { useProject } from '../../../providers/ProjectProvider';
import { subject } from '@casl/ability';
import { ApiError } from '../../../components/ApiError';
import ListHeader from '../../../components/projects/ListHeader';
import ProjectSegmentList from '../../../components/projects/ProjectSegmentsList';
import { SEGMENT_TEXT } from '../../../constants/segments';

export default function ProjectSegments() {
  const { project, isLoading, error } = useProject();
  const ability = useOrganizationAbilityContext();

  const canCreateSegment = useMemo(() => {
    if (isLoading) return false;

    return project
      ? ability.can(
          'create',
          subject('Segment', {
            project: { id: project.id },
            organization: { id: project.organization.id },
          }),
        )
      : ability.can('create', 'Segment');
  }, [ability, isLoading, project]);

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <>
      <Helmet>
        <title>{project ? `${project.name || 'Loading'} Segments` : 'Segments'}</title>
      </Helmet>
      <Box gap="xl">
        <ListHeader
          isLoading={isLoading}
          createLabel="Create Segment"
          canCreate={canCreateSegment}
          showHelp={(project?.segments?.length ?? 0) > 0}
          helpContent={SEGMENT_TEXT.definition}
        />
        <ProjectSegmentList
          segments={project?.segments || []}
          emptyCopy={{ title: SEGMENT_TEXT.emptyTitle, description: SEGMENT_TEXT.definition }}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
}
