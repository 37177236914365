import { Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';
import { useRevokeAccessMutation } from '../../services/access';
import { parseEntityType } from '@hyphen/nucleus/dist/common/entity';

interface RevokeAccessProps {
  organizationId: string;
  accessId: string;
  entityId: string;
  name: string;
}

export default function RevokeAccess({ organizationId, accessId, entityId, name }: RevokeAccessProps) {
  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();

  const entityType = parseEntityType(entityId) as 'Project' | 'Organization' | 'LinkCode';

  const [revokeAccess, { isLoading: isRevoking }] = useRevokeAccessMutation();

  const handleRemove = useCallback(
    async (accessId: string) => {
      const { error } = await revokeAccess({
        organizationId: organizationId,
        accessId: accessId,
        entity: {
          type: entityType,
          id: entityId,
        },
      });
      if (error) {
        toast.error('unable to revoke Access', { duration: 5000 });
      } else {
        toast.success('Access revoked', { duration: 2000 });
        closeRemoveModal();
      }
    },
    [revokeAccess, organizationId, entityType, entityId, closeRemoveModal],
  );

  return (
    <>
      <ConfirmModal
        message={
          <>
            Revoke <strong>{name}&rsquo;s</strong> access?
          </>
        }
        confirmButtonLabel="Revoke Access"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={() => handleRemove(accessId)}
        isLoading={isRevoking}
      />
      <Button variant="tertiary" iconPrefix="c-remove" aria-label="delete secret" onClick={openRemoveModal} />
    </>
  );
}
