import { Box } from '@hyphen/hyphen-components';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { useState } from 'react';
import { useGetNetInfoRequestsQuery } from '../../services/netInfo';
import { NetInfoTable } from './NetInfoTable';

const PAGE_SIZE = 100;

const SKELETON_DATA = Array(3).fill({
  id: 'skeleton',
  ip: '',
  type: '',
  location: { city: '', region: '', country: '' },
  createdAt: new Date().toISOString(),
});

export const NetInfoList = () => {
  const { organization = {} as Organization } = useOrganization();
  const [nextPageCursor, setNextPageCursor] = useState<string | undefined>(undefined);

  const {
    data: netInfo,
    isLoading,
    isFetching,
  } = useGetNetInfoRequestsQuery({
    organizationId: organization?.id || '',
    pageSize: PAGE_SIZE,
    pageCursor: nextPageCursor,
  });

  return (
    <Box gap="xl" fontSize="sm">
      <NetInfoTable
        netInfo={isLoading ? undefined : netInfo}
        netInfoContent={isLoading ? SKELETON_DATA : (netInfo?.data || [])}
        setNextPageCursor={setNextPageCursor}
        isLoading={isLoading || isFetching}
      />
    </Box>
  );
};
