import { Assignment, Entity } from '../types/access';
import { Role } from '../types/roles';
import { api } from './api';
import { AccessEntry } from './types';

export const accessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    giveAccess: builder.mutation<
      AccessEntry,
      { entity: Entity; assignment: Assignment; organizationId: string; roles: Role[] }
    >({
      query: ({ entity, organizationId, assignment, roles }) => ({
        url: `api/organizations/${organizationId}/access`,
        method: 'POST',
        body: {
          entity: entity,
          roles: roles,
          assignment: assignment,
        },
      }),
      invalidatesTags: (result, _error, { entity, assignment }) => {
        return result ? [{ type: `${entity.type}Access`, id: `${entity.id}-LIST` }] : [];
      },
    }),
    revokeAccess: builder.mutation<AccessEntry, { organizationId: string; accessId: string; entity: Entity }>({
      query: ({ accessId, organizationId }) => ({
        url: `api/organizations/${organizationId}/access/${accessId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _error, { accessId, entity }) => {
        return result
          ? [
              {
                type: `${result.entity.type as 'Project' | 'Organization' | 'LinkCode'}Access`,
                id: `${result.entity.id}-LIST`,
              },
            ]
          : [];
      },
    }),
  }),
});

export const { useGiveAccessMutation, useRevokeAccessMutation } = accessApi;
