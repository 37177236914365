import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useBreakpoint } from '@hyphen/hyphen-components';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useGetTeamQuery } from '../../services/teams';
import { getEntityUrl } from '../../utils/getEntityUrl';
import { useOrganization } from '../../providers/OrganizationProvider';
import { ScrollableMainLayout } from '../ScrollableMainLayout';
import { Skeleton } from '../common/Skeleton';
import PageHeader from '../PageHeader';

export const TeamLoader = ({ entityId, internalPath }: { entityId: string; internalPath?: string }) => {
  const navigate = useNavigate();
  const { id: orgId } = useOrganization();
  const { isPhone, isTablet } = useBreakpoint();

  const { data: team, isLoading, error } = useGetTeamQuery({ id: entityId, orgId }, { skip: !orgId });

  useEffect(() => {
    if (isLoading) return;

    if (!team || error) {
      throw new Response('Not Found', { status: 404 });
    }

    if (team) {
      let url = getEntityUrl(orgId, EntityNames.Team, team.id);
      if (internalPath) url = `${url}/${internalPath}`;
      navigate(url, { replace: true });
    }
  }, [team, navigate, orgId, internalPath, isLoading, error]);

  const pageHeaderProps = {
    title: <Skeleton width="250px" height={isPhone ? '25px' : isTablet ? '35px' : '40px'} />,
    iconUrl: 'loading',
    description: <Skeleton width="150px" height="xl" />,
  };

  return (
    <ScrollableMainLayout>
      <PageHeader {...pageHeaderProps} />
      <Box>
        <Skeleton width="100" height="180px" />
      </Box>
    </ScrollableMainLayout>
  );
};
