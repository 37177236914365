import { ListResponse } from './types';
import { api } from './api';

export interface ApiKeySecret {
  id: string;
  lastUsed: string | null;
  hint: string;
  value?: string;
}

export interface ApiKey {
  id: string;
  name: string;
  createdAt: string;
  secrets: Array<ApiKeySecret>;
}

export const apiKeysApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiKeys: build.query<ListResponse<ApiKey>, string>({
      query: (orgId: string) => ({
        url: `api/organizations/${orgId}/api-keys`,
        method: 'GET',
      }),
      providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }) => [
        ...result.data.map(({ id }) => ({ type: 'ApiKeys' as const, id })),
        { type: 'ApiKeys', id: 'LIST' },
      ],
    }),
    createApiKey: build.mutation<ApiKey, { orgId: string; name: string }>({
      query: ({ orgId, name }) => ({
        url: `api/organizations/${orgId}/api-keys`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: [{ type: 'ApiKeys', id: 'LIST' }],
    }),
    getApiKey: build.query<ApiKey, { orgId: string; apiKeyId: string }>({
      query: ({ orgId, apiKeyId }) => ({
        url: `api/organizations/${orgId}/api-keys/${apiKeyId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { orgId, apiKeyId }) => [{ type: 'ApiKeys', id: apiKeyId }],
    }),
    deleteApiKey: build.mutation<void, { orgId: string; apiKeyId: string }>({
      query: ({ orgId, apiKeyId }) => ({
        url: `api/organizations/${orgId}/api-keys/${apiKeyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { apiKeyId }) => [
        { type: 'ApiKeys', id: apiKeyId },
        { type: 'ApiKeys', id: 'LIST' },
      ],
    }),
    createApiKeySecret: build.mutation<ApiKeySecret, { orgId: string; apiKeyId: string }>({
      query: ({ orgId, apiKeyId }) => ({
        //    api/organizations/org_668417c57ec7512b27b39bc0/api-keys/api_66b6622d08fb506c0bf1fbb6/secrets/
        //    api/organizations/org_668417c57ec7512b27b39bc0/api-keys/api_66b6622d08fb506c0bf1fbb6/secrets
        url: `api/organizations/${orgId}/api-keys/${apiKeyId}/secrets/`,
        method: 'PUT',
      }),
      invalidatesTags: (_result, _error, { apiKeyId }) => [
        { type: 'ApiKeys', id: apiKeyId },
        { type: 'ApiKeys', id: 'LIST' },
      ],
    }),
    deleteApiKeySecret: build.mutation<void, { orgId: string; apiKeyId: string; apiKeyIdSecret: string }>({
      query: ({ orgId, apiKeyId, apiKeyIdSecret }) => ({
        url: `api/organizations/${orgId}/api-keys/${apiKeyId}/secrets/${apiKeyIdSecret}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { apiKeyId }) => [
        { type: 'ApiKeys', id: apiKeyId },
        { type: 'ApiKeys', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetApiKeysQuery,
  useGetApiKeyQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useDeleteApiKeySecretMutation,
  useCreateApiKeySecretMutation,
} = apiKeysApi;
