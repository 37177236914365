import { Tooltip, TooltipTrigger, TooltipContent, Box, Icon, IconProps } from '@hyphen/hyphen-components';

interface TooltipIconProps extends IconProps {
  content: string;
}

export default function TooltipIcon({
  content,
  name = 'c-question',
  color = 'tertiary',
  ...iconProps
}: TooltipIconProps) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Box as="button" background="transparent" borderWidth="0" padding="2xs">
          <Icon name={name} color={color} {...iconProps} />
        </Box>
      </TooltipTrigger>
      <TooltipContent className="mw-8xl lh-heading" collisionPadding={40}>
        {content}
      </TooltipContent>
    </Tooltip>
  );
}
