import { Box, Icon, Tooltip, TooltipContent, TooltipTrigger } from '@hyphen/hyphen-components';
import { formatDateWithTime } from '../utils/dateUtils';

export const DateCreated = ({ dateCreated }: { dateCreated: string }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Box
          as="button"
          borderWidth="0"
          direction="row"
          gap="sm"
          display="inline-flex"
          alignItems="center"
          padding="sm"
          background="secondary"
          radius="md"
          color="secondary"
          fontSize="xs"
        >
          <Icon name="calendar-create" size="md" />
          <Box padding="xs 0 0 0">{formatDateWithTime(dateCreated)}</Box>
        </Box>
      </TooltipTrigger>
      <TooltipContent sideOffset={-2}>date created</TooltipContent>
    </Tooltip>
  );
};
