import { PropsWithChildren } from 'react';
import {
  Card,
  Icon,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
  useIsMobile,
} from '@hyphen/hyphen-components';
import { NavLink } from 'react-router-dom';
import MainNavItem from '../MainNavItem';
import { ScrollableMainLayout } from '../ScrollableMainLayout';
import { useOrganizationsList } from '../../providers/OrganizationsListProvider';
import { useGetBillingAccountsQuery } from '../../services/billingAccount';
import SidebarItem from './SidebarItem';
import { useAuth } from '../auth/authProvider';
import OrganizationSwitcher from '../OrganizationSwitcher';

export const UserSidebar = ({ children }: PropsWithChildren) => {
  const { organizations = [] } = useOrganizationsList();

  const { logout } = useAuth();

  const { data: billingAccounts } = useGetBillingAccountsQuery();

  const isMobile = useIsMobile();

  return (
    <SidebarProvider>
      <Sidebar side="left" collapsible="offcanvas">
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <OrganizationSwitcher organizations={organizations} />
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <NavLink end className="navlink" to="/profile">
                  <MainNavItem iconName="user">Profile</MainNavItem>
                </NavLink>
              </SidebarMenuItem>
              {billingAccounts && billingAccounts.data.length > 0 && (
                <SidebarMenuItem>
                  <SidebarItem
                    label="Billing"
                    icon="chart-bar"
                    items={billingAccounts.data.map((billingAccount) => ({
                      label: billingAccount.name,
                      link: `/billing/${billingAccount.id}`,
                      key: billingAccount.id,
                    }))}
                    onClick={() => {}}
                  ></SidebarItem>
                  {/* <NavLink end className="navlink" to={`/billing/${billId}`} state={{ backOrgId }}>
                    
                  </NavLink> */}
                </SidebarMenuItem>
              )}
            </SidebarMenu>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuButton onClick={logout}>
              <Icon name="logout" />
              <span>Sign out</span>
            </SidebarMenuButton>
          </SidebarMenu>
        </SidebarFooter>
      </Sidebar>
      <SidebarInset>
        {isMobile && <SidebarTrigger />}
        <Card height="100" radius={{ base: '0', tablet: 'md' }}>
          <ScrollableMainLayout>{children}</ScrollableMainLayout>
        </Card>
      </SidebarInset>
    </SidebarProvider>
  );
};
