import { ConnectionType } from '@hyphen/nucleus/dist/types/connections';
import { IntegrationType } from '../types/integrations';

export default function getIntegrationConnectionInputPlaceholder(
  connectionType: ConnectionType,
  integrationType: IntegrationType,
) {
  switch (connectionType) {
    case ConnectionType.User:
      if (integrationType === IntegrationType.aws) {
        return 'e.g. "1111-1111-1111"';
      }
      if (integrationType === IntegrationType.slack) {
        return 'e.g. "slack-user-email"';
      }
      if (integrationType === IntegrationType.github || integrationType === IntegrationType.npm) {
        return 'e.g. "my-username"';
      }
      return 'e.g. "user@domain.com"';
    case ConnectionType.Channel:
      return "e.g. 'existing-channel-name'";
    case ConnectionType.DistributionList:
      return `e.g. 'my-existing-list@domain.com'`;
    case ConnectionType.CodeRepository:
      return `e.g. 'existing-repo-name'`;
    case ConnectionType.CodeRegistry:
      return 'e.g. "existing-package-name"';
    case ConnectionType.ProjectBoard:
      if (integrationType === IntegrationType.jira) {
        return "e.g. 'existing-project-name'";
      }
      return "e.g. '4'";
    case ConnectionType.PermissionGroup:
      if (
        integrationType === IntegrationType.microsoftTeams ||
        integrationType === IntegrationType.azure ||
        integrationType === IntegrationType.office365
      ) {
        return "e.g. '05c64596-015d-47bc-a1ca-f9b3883c45f6'";
      }
      if (integrationType === IntegrationType.npm || integrationType === IntegrationType.github) {
        return 'e.g. "existing-team"';
      }
      // aws, newRelic, jira
      return 'e.g. "existing-group"';
    default:
      return `Unknown`;
  }
}
