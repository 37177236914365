import { Box } from '@hyphen/hyphen-components';
import { NetInfoResponse } from '../../types/netInfo';
import DateTimeDisplay from '../../components/DateTime';
import { NetInfoTableContent } from './NetInfoTableContent';
import { Column } from '@hyphen/hyphen-components/dist/types';
import { Skeleton } from '../../components/common/Skeleton';

interface NetInfoTableProps {
  netInfo?: NetInfoResponse;
  netInfoContent: NetInfoResponse['data'];
  setNextPageCursor: (cursor: string | undefined) => void;
  isLoading: boolean;
}

export const NetInfoTable = ({ netInfo, netInfoContent, setNextPageCursor, isLoading }: NetInfoTableProps) => {
  const columnConfig: Column[] = [
    {
      heading: 'IP Address',
      dataKey: 'ip',
      render: (_cell: any, _row: any) => 
        isLoading ? <Skeleton height="16px" width="120px" /> : _cell,
    },
    {
      heading: 'Type',
      dataKey: 'type',
      render: (_cell: any, _row: any) => 
        isLoading ? <Skeleton height="16px" width="80px" /> : _cell,
    },
    {
      heading: 'Location',
      dataKey: 'location',
      render: (_cell: any, row: any) => 
        isLoading ? (
          <Skeleton height="16px" width="200px" />
        ) : (
          `${row.location.city}, ${row.location.region}, ${row.location.country}`
        ),
    },
    {
      heading: 'Created At',
      dataKey: 'createdAt',
      render: (_cell: any, row: any) => 
        isLoading ? (
          <Skeleton height="16px" width="150px" />
        ) : (
          <DateTimeDisplay value={row.createdAt} />
        ),
    },
  ];

  const tableData = netInfoContent.map((request) => ({
    id: request.id,
    ip: request.ip,
    type: request.type,
    location: request.location,
    createdAt: request.createdAt,
  }));

  return (
    <Box overflow="auto" maxHeight="8xl">
      <NetInfoTableContent
        netInfo={netInfo}
        tableData={tableData}
        columnConfig={columnConfig}
        setNextPageCursor={setNextPageCursor}
        isLoading={isLoading}
      />
    </Box>
  );
};
