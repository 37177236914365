import { EntityNames, EntityTypes } from '@hyphen/nucleus/dist/types';

export const getEntityUrl = (
  organizationId: string,
  entityType: EntityTypes,
  entityId: string,
  projectId?: string,
  integrationId?: string,
): string => {
  if (
    (entityType === EntityNames.Segment ||
      entityType === EntityNames.Toggle ||
      entityType === EntityNames.App ||
      entityType === EntityNames.Project ||
      entityType === EntityNames.ProjectEnvironment) &&
    !projectId
  ) {
    throw new Error(`projectId is required when entityType is ${entityType}`);
  }

  if (entityType === EntityNames.IntegrationConnection && !integrationId) {
    throw new Error('integrationId is required when entityType is IntegrationConnection');
  }

  switch (entityType) {
    case EntityNames.ApiKey:
      return `/${organizationId}/settings/api-keys/${entityId}`;
    case EntityNames.Project:
      return `/${organizationId}/${projectId}`;
    case EntityNames.App:
      return `/${organizationId}/${projectId}/app/${entityId}`;
    case EntityNames.Team:
      return `/${organizationId}/teams/${entityId}`;
    case EntityNames.LinkCode:
      return `/${organizationId}/link/${entityId}`;
    case EntityNames.Organization:
      return `/${organizationId}`;
    case EntityNames.Segment:
      return `/${organizationId}/${projectId}/segments/${entityId}`;
    case EntityNames.Toggle:
      return `/${organizationId}/${projectId}/toggles/${entityId}`;
    case EntityNames.Member:
      return `/${organizationId}/settings/members/${entityId}`;
    case EntityNames.OrganizationIntegration:
      return `/${organizationId}/integrations/${entityId}`;
    case EntityNames.IntegrationConnection:
      return `/${organizationId}/integrations/${integrationId}/connections/${entityId}`;
    case EntityNames.ProjectEnvironment:
      //TODO: fix with project environment page
      return `/${organizationId}/${projectId}`;
    case EntityNames.AppDeployment:
      return `/${organizationId}/${projectId}`;
    case EntityNames.AppDeploymentInstance:
      return `/${organizationId}/${projectId}`;
    default:
      throw new Error(`Unrecognized entityType: ${entityType}`);
  }
};
