import { api } from './api';
import { GetNetInfoRequestsParams, NetInfoResponse } from '../types/netInfo';

export const netInfoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNetInfoRequests: builder.query<NetInfoResponse, GetNetInfoRequestsParams>({
      query: ({ organizationId, ...params }) => ({
        url: `/api/organizations/${organizationId}/net-info/usage`,
        params,
      }),
      providesTags: (result) => (result ? [{ type: 'NetInfo', id: 'LIST' }] : []),
      serializeQueryArgs: ({ queryArgs }) => {
        const { pageCursor, ...rest } = queryArgs;
        return rest;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageCursor) {
          return {
            ...newItems,
            data: [...(currentCache?.data || []), ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.pageCursor !== previousArg?.pageCursor;
      },
    }),
  }),
});

export const { useGetNetInfoRequestsQuery } = netInfoApi;
