import { Box, Button, FormikTextInput, Modal, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { ApiError } from '../ApiError';
import { useCallback } from 'react';
import { useUpdateDomainMutation } from '../../services/link/domains';

const schema = yup.object().shape({
  notFoundRedirectUrl: yup.string().url('Please provide a valid URL'),
});

interface EditDomainNotFoundRedirectUrlModalProps {
  isOpen: boolean;
  onClose: () => void;
  domainId: string;
  organizationId?: string;
  currentNotFoundRedirectUrl?: string | null;
}

const customErrorMessages = {
  default: 'Failed to update Not Found redirect URL. Please try again later.',
};

export const EditDomainNotFoundRedirectUrlModal = ({
  isOpen,
  onClose,
  domainId,
  organizationId,
  currentNotFoundRedirectUrl,
}: EditDomainNotFoundRedirectUrlModalProps) => {
  const [updateRedirectUrl, { error }] = useUpdateDomainMutation();

  const handleSubmit = useCallback(
    async (values: { notFoundRedirectUrl: string }) => {
      if (!organizationId) return;
      const result = await updateRedirectUrl({
        organizationId,
        domainId,
        body: {
          notFoundRedirectUrl: values.notFoundRedirectUrl.length ? values.notFoundRedirectUrl : null,
        },
      });

      if (!result.error) {
        toast.success('Not Found Redirect URL updated successfully');
        onClose();
      }
    },
    [organizationId, domainId, onClose, updateRedirectUrl],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} onDismiss={onClose} title="Edit Not Found Redirect URL" maxWidth="9xl">
      <Formik
        initialValues={{ notFoundRedirectUrl: currentNotFoundRedirectUrl || '' }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Box gap="xl">
              <Field
                type="text"
                label="Link Not Found Redirect URL"
                helpText="If a short link is not valid, redirect to this URL"
                name="notFoundRedirectUrl"
                id="notFoundRedirectUrl"
                component={FormikTextInput}
                isDisabled={isSubmitting}
                placeholder="e.g. https://example.com/code-not-found"
              />
              {error && <ApiError error={error} customMessages={customErrorMessages} />}
              <Box direction="row" gap="md" justifyContent="flex-end">
                <Button variant="tertiary" type="button" onClick={onClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
