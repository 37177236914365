export const RoleDisplayName = (roleName: string) => {
  switch (roleName) {
    case 'OrganizationAdmin':
      return 'Organization Admin';
    case 'OrganizationMember':
      return 'Organization Member';
    case 'ProjectOwner':
      return 'Project Owner';
    case 'ProjectViewer':
      return 'Project Viewer';
    case 'ProjectCollaborator':
      return 'Project Collaborator';
    case 'TeamOwner':
      return 'Team Owner';
    case 'TeamMember':
      return 'Team Member';
    case 'LinkCodeOwner':
      return 'Link Owner';
    default:
      return roleName;
  }
};
