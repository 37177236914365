import { Fragment } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { Box, Button, Heading, toast } from '@hyphen/hyphen-components';
import LogicalOperator from '../toggles/LogicalOperator';
import RuleFields from '../toggles/RuleFields';
import { InferType } from 'yup';
import * as Yup from 'yup';
import { Operator, segmentToJsonLogic } from '../../utils/parseJsonLogic';
import ContextFieldDropdown from '../toggles/ContextFieldDropdown';
import { useSegment } from '../../providers/SegmentProvider';
import { useUpdateSegmentMutation } from '../../services/segments';
import { Segment } from '../../types/segments';
import { ApiError } from '../ApiError';
import { SEGMENT_TEXT } from '../../constants/segments';

const targetFormSchema = Yup.object().shape({
  id: Yup.string(),
  parsedLogic: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required('Key is required'),
        operator: Yup.string()
          .oneOf(Object.values(Operator), 'Operator must be one of ==, !=, in, not in, contains, >=, <=')
          .required('Operator is required'),
        value: Yup.string()
          .required('Value is required')
          .when(['key', 'operator'], {
            is: (key: string, operator: string) => key === 'user.email' && operator === Operator.in,
            then: (schema) =>
              schema.test('is-valid-emails', 'Must be a comma separated list of valid email addresses', (value) =>
                value
                  ? value.split(',').every((email: string) => Yup.string().email().isValidSync(email.trim()))
                  : false,
              ),
            otherwise: (schema) =>
              schema.when(['key', 'operator'], {
                is: (key: string, operator: string) => key === 'user.email' && operator !== Operator.contains,
                then: (schema) => schema.email('Must be a valid email address'),
                otherwise: (schema) => schema,
              }),
          })
          .when('operator', {
            is: (operator: string) => operator === Operator.in,
            then: (schema) =>
              schema.test('is-valid-list', 'Must be a comma separated list of values', (value) =>
                value ? value.split(',').every((item) => item.trim().length > 0) : false,
              ),
          }),
      }),
    )
    .min(1, 'At least one context field is required')
    .required('At least one context is required'),
});

export type SegmentFormSchema = InferType<typeof targetFormSchema>;

interface SegmentFormProps {
  initialValues: SegmentFormSchema;
  onSubmit: (updatedSegment: Segment) => void;
}

const SegmentForm = ({ initialValues, onSubmit }: SegmentFormProps) => {
  const { segment } = useSegment();
  const [updateSegment, { error }] = useUpdateSegmentMutation();

  const handleSubmit = async (values: SegmentFormSchema) => {
    try {
      if (!segment) {
        toast.error('Segment not found');
        return;
      }

      // context make object immutable so we need to copy it
      // this is hacky but fast :)
      const updatedSegment = JSON.parse(JSON.stringify(segment)) as Segment;

      // If this is a net new segment, we need to initialize the parsedLogic object
      if (updatedSegment.parsedLogic && Object.keys(updatedSegment.parsedLogic).length === 0) {
        updatedSegment.parsedLogic = { conditions: [], conjunction: 'or' };
      }

      if (values.id) {
        // existing rule
        if (updatedSegment.parsedLogic) {
          updatedSegment.parsedLogic.conditions[parseInt(values.id)] = values.parsedLogic;
        }
      } else {
        // insert new rule at the beginning
        if (updatedSegment.parsedLogic) {
          updatedSegment.parsedLogic.conditions.unshift(values.parsedLogic);
        }
      }

      // update the stringified logic
      updatedSegment.logic = segmentToJsonLogic(updatedSegment);

      await updateSegment({
        organizationId: segment.organization.id,
        projectId: segment.project.id,
        segmentId: segment.id,
        body: {
          logic: updatedSegment.logic,
        },
      });
      onSubmit(updatedSegment);
    } catch (error) {
      toast.error('Update failed');
    }
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      enableReinitialize
      validationSchema={targetFormSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ values, errors }) => (
        <Form noValidate className="display-flex g-3xl flex-direction-column">
          <Box gap="lg" flex="auto" alignItems="flex-start">
            <Heading as="h2" size="sm">
              {SEGMENT_TEXT.segmentFormHeader}
            </Heading>
            <Box width="100">
              <FieldArray name="parsedLogic">
                {({ remove, push }) => (
                  <Box as="ol" gap="lg" padding="0" width="100" fontSize="sm">
                    {values.parsedLogic.map((_context: any, index: number) => (
                      <Fragment key={`context.${index}`}>
                        <RuleFields index={index} onRemove={() => remove(index)} />
                        {index < values.parsedLogic.length - 1 && (
                          <LogicalOperator isDragging={false} operator="AND" />
                        )}
                      </Fragment>
                    ))}
                    <Box display="block">
                      <ContextFieldDropdown push={push} values={{ ...values, defaultValue: '' }} errors={errors} />
                    </Box>
                  </Box>
                )}
              </FieldArray>
            </Box>
          </Box>
          {error && <ApiError error={error} title="Error updating segment" />}
          <Box alignItems="flex-start">
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SegmentForm;
