import { Box, Card, Table, Heading } from '@hyphen/hyphen-components';
import { formatDateInUTC } from '../../../utils/dateUtils';
import { BillingInvoice, isInvoiceStatus } from '../../../services/billingAccount';
import { Column, Cell } from '@hyphen/hyphen-components/dist/types';
import { formatCurrency } from '../../../utils/currencyUtils';

interface InvoiceListProps {
  invoices?: BillingInvoice[];
}

export const InvoiceList = ({ invoices = [] }: InvoiceListProps) => {
  if (!invoices.length) {
    return (
      <Card padding="xl">
        <Box display="flex" justifyContent="center" alignItems="center" padding="xl">
          <Box color="secondary">No invoices available</Box>
        </Box>
      </Card>
    );
  }

  const columns: Column[] = [
    {
      heading: 'Date',
      dataKey: 'createdDate',
      render: (cell?: Cell) => (cell ? formatDateInUTC(String(cell)) : '-'),
    },
    {
      heading: 'Due Date',
      dataKey: 'dueDate',
      render: (cell?: Cell) => (cell ? formatDateInUTC(String(cell)) : '-'),
    },
    {
      heading: 'Amount',
      dataKey: 'amount',
      render: (cell?: Cell) => (cell ? `${formatCurrency(Number(cell))}` : '-'),
    },
    {
      heading: 'Status',
      dataKey: 'status',
      render: (cell?: Cell) => {
        if (!cell) return '-';
        const status = String(cell);
        return isInvoiceStatus(status) ? `${status}` : '-';
      },
    },
  ];

  return (
    <Box gap="xl">
      <Card padding="xl">
        <Box gap="lg">
          <Heading as="h4" size="sm">
            Invoice History
          </Heading>
          <Table columns={columns} rows={invoices} rowKey="providerId" isStriped align="left" />
        </Box>
      </Card>
    </Box>
  );
};
