import { Button, ButtonProps, Tooltip, TooltipContent, TooltipTrigger } from '@hyphen/hyphen-components';

interface ITooltipButtonProps extends ButtonProps {
  content: string;
}

export default function TooltipButton({ content, ...buttonProps }: ITooltipButtonProps) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button {...buttonProps} />
      </TooltipTrigger>
      <TooltipContent>{content}</TooltipContent>
    </Tooltip>
  );
}
