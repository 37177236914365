import React from 'react';
import { Box, Card } from '@hyphen/hyphen-components';
import InfoBox from '../common/InfoBox';
import ClickToCopy from '../common/ClickToCopy';
import LastEvent from '../toggles/LastEvent';
import CreatedBy from '../common/CreatedBy';
import { EventReferenceType, EventType } from '../../types/events';

interface InfoCardProps {
  isLoading: boolean;
  entity: any;
  entityType: 'segment' | 'toggle';
  keyIdDescription: string;
  eventTypes: EventType[];
}

const InfoCard: React.FC<InfoCardProps> = ({ isLoading, entity, entityType, keyIdDescription, eventTypes }) => {
  const references = entity && [
    {
      id: entityType === 'segment' ? entity.id : entity.key,
      type: entityType === 'segment' ? EventReferenceType.Segment : EventReferenceType.Toggle,
    },
    { id: entity.project.id, type: EventReferenceType.Project },
  ];

  return (
    <Card>
      <Box
        direction={{ base: 'column', tablet: 'row' }}
        fontSize="xs"
        style={{ gap: '1px', backgroundColor: 'var(--color-border-subtle)' }}
      >
        <InfoBox
          title={entityType === 'segment' ? 'Segment Key' : 'Key ID'}
          helpText={keyIdDescription}
          isLoading={isLoading || !entity}
          skeletonWidth="120px"
          skeletonHeight="30px"
          padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
        >
          {entity && <ClickToCopy fontSize="xs" text={entity?.alternateId || entity?.key} />}
        </InfoBox>
        <InfoBox
          title="Created by"
          isLoading={isLoading || !entity}
          skeletonWidth="120px"
          skeletonHeight="30px"
          padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          gap="sm"
        >
          <CreatedBy
            organizationId={entity?.organization.id || ''}
            memberId={entity?.meta?.createdBy || ''}
            createdAt={entity?.meta?.createdAt || ''}
          />
        </InfoBox>
        <InfoBox
          title="Last event"
          isLoading={isLoading || !entity}
          skeletonWidth="120px"
          skeletonHeight="30px"
          padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          gap="sm"
          hover={{ background: 'secondary' }}
        >
          {entity && <LastEvent references={references} eventTypes={eventTypes} />}
        </InfoBox>
      </Box>
    </Card>
  );
};

export default InfoCard;
