import React, { Component, useEffect, useRef } from 'react';
import { Box, Button, Heading } from '@hyphen/hyphen-components';
import { useNavigate, useLocation } from 'react-router-dom';

export class ErrorBoundary extends Component<{ children: React.ReactNode; navigate?: (delta: number) => void }> {
  state = { hasError: false, error: undefined };
  
  reset = () => {
    this.setState({ hasError: false, error: undefined }, () => {
      if (this.state.hasError) {
        this.setState({ hasError: false, error: undefined });
      }
    });
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      return  (
        <Box background="primary" color="base" alignItems="center" justifyContent="center" height="100%">
          <Box margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }} width={{ base: '100', desktop: '80' }}>
            <Box
              padding={{ base: 'lg', desktop: '0' }}
              childGap={{
                base: 'lg',
              }}
              height="100"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Box fontSize={{ base: '2xl', desktop: '3xl' }} color="secondary" aria-hidden="true">
                ¯\_(ツ)_/¯
              </Box>
              <Heading as="h1" size={{ base: 'lg', desktop: 'xl' }}>
                Oops. Something went wrong.
              </Heading>
              <Button variant="primary" size="lg" onClick={() => window.history.back()}>
                Go Back
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryWithNavigate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorBoundaryRef = useRef<ErrorBoundary>(null);
  
  useEffect(() => {    
    const resetError = () => {
      if (errorBoundaryRef.current) {
        errorBoundaryRef.current.reset();
      }
    };
    
    resetError();
    return () => resetError();
  }, [location.pathname]);
  
  return (
    <ErrorBoundary 
      ref={errorBoundaryRef} 
      navigate={navigate}
      key={location.pathname}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWithNavigate;
