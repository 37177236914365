import { Box, Button, FormikSelectInputNative, FormikTextInput, useBreakpoint } from '@hyphen/hyphen-components';
import { Field, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { Operator } from '../../utils/parseJsonLogic';
import { useProject } from '../../providers/ProjectProvider';
import { useParams } from 'react-router-dom';

interface ParsedLogicItem {
  key: string;
  operator: string;
  value: string;
  contextFieldType: '' | 'customAttributes' | 'user.customAttributes' | 'segment';
}

interface RuleFieldsProps {
  index: number;
  onRemove: () => void;
}

function RuleFields({ index, onRemove }: RuleFieldsProps) {
  const { isPhone } = useBreakpoint();
  const { project } = useProject();
  const { segmentId } = useParams<{ segmentId: string }>();

  const segmentOptions = useMemo(() => {
    // Filter out the current segment from the list of segments
    if (segmentId) {
      return (
        project?.segments
          ?.filter((segment) => segment.alternateId !== segmentId)
          .map((segment) => ({
            value: segment.alternateId,
            label: `${segment.name} (${segment.alternateId})`,
          })) || []
      );
    }
    // If there is no segmentId (b/c we are setting a Toggle rule), return all segments
    return (
      project?.segments?.map((segment) => ({
        value: segment.alternateId,
        label: `${segment.name} (${segment.alternateId})`,
      })) || []
    );
  }, [project?.segments, segmentId]);

  const { values, errors, setFieldValue, isSubmitting } = useFormikContext<{
    parsedLogic: ParsedLogicItem[];
  }>();

  const logicItem = values.parsedLogic[index];
  const { operator } = logicItem;
  const { contextFieldType } = logicItem;

  const prefix = contextFieldType === 'user.customAttributes' ? 'user.customAttributes.' : 'customAttributes.';

  const handleCustomKeyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const updatedValue = inputValue.startsWith(prefix) ? inputValue : `${prefix}${inputValue}`;
      setFieldValue(`parsedLogic.${index}.key`, updatedValue);
    },
    [index, prefix, setFieldValue],
  );

  const operatorOptions = useMemo(() => {
    return operator === 'segment'
      ? [
          { value: Operator.in, label: Operator.in },
          { value: Operator.notIn, label: Operator.notIn },
        ]
      : [
          { value: '==', label: Operator.equal },
          { value: '!=', label: Operator.notEqual },
          { value: 'in', label: Operator.in },
          { value: 'contains', label: Operator.contains },
          { value: '>=', label: Operator.greaterEqual },
          { value: '<=', label: Operator.lessEqual },
        ];
  }, [operator]);

  const renderKeyField = useCallback(() => {
    return contextFieldType === 'user.customAttributes' || contextFieldType === 'customAttributes' ? (
      <Field
        name={`parsedLogic.${index}.key`}
        label="Custom Attribute"
        hideLabel
        placeholder={`${prefix}myKey`}
        component={FormikTextInput}
        required
        error={(errors.parsedLogic?.[index] as { key?: string })?.key || ''}
        onChange={handleCustomKeyChange}
        isDisabled={isSubmitting}
      />
    ) : (
      <Box
        borderWidth="sm"
        radius="sm"
        fontFamily="monospace"
        fontSize="xs"
        justifyContent="center"
        padding="xs sm"
        background={isSubmitting ? 'disabled' : 'secondary'}
        flex="auto"
        maxHeight="42px"
        overflow="auto"
        className="scroll-bar-thin"
      >
        {values.parsedLogic[index].key}
      </Box>
    );
  }, [contextFieldType, index, prefix, errors.parsedLogic, handleCustomKeyChange, isSubmitting, values.parsedLogic]);

  const renderOperatorField = useCallback(() => {
    return operator === 'segment' ? (
      <Field
        name={`parsedLogic.${index}.key`}
        label="Operator"
        hideLabel
        placeholder="Select an operator"
        component={FormikSelectInputNative}
        options={operatorOptions}
        error={(errors.parsedLogic?.[index] as { operator?: string })?.operator || ''}
        isDisabled={isSubmitting}
      />
    ) : (
      <Field
        name={`parsedLogic.${index}.operator`}
        label="Operator"
        hideLabel
        placeholder="Select an operator"
        component={FormikSelectInputNative}
        options={operatorOptions}
        error={(errors.parsedLogic?.[index] as { operator?: string })?.operator || ''}
        isDisabled={isSubmitting}
      />
    );
  }, [errors.parsedLogic, index, isSubmitting, operator, operatorOptions]);

  const renderValueField = useCallback(() => {
    return operator === 'segment' ? (
      <Field
        name={`parsedLogic.${index}.value`}
        label="Segment"
        hideLabel
        component={FormikSelectInputNative}
        placeholder="Select a segment"
        options={segmentOptions}
        error={(errors.parsedLogic?.[index] as { value?: string })?.value || ''}
        isDisabled={isSubmitting}
      />
    ) : (
      <Field
        name={`parsedLogic.${index}.value`}
        label="Value"
        hideLabel
        placeholder={values.parsedLogic[index].operator === 'in' ? 'value1, value2' : ''}
        component={FormikTextInput}
        required
        error={(errors.parsedLogic?.[index] as { value?: string })?.value || ''}
        isDisabled={isSubmitting}
      />
    );
  }, [operator, index, segmentOptions, errors.parsedLogic, isSubmitting, values.parsedLogic]);

  if (values.parsedLogic[index].key === 'unknown') {
    return (
      <Box
        width="100"
        flex="auto"
        as="li"
        direction={{ base: 'column', tablet: 'row' }}
        alignItems="stretch"
        gap="lg"
      >
        <Box flex="auto">
          <Box as="code" padding="sm">
            {values.parsedLogic[index].value}
          </Box>
        </Box>
        <Box>
          <Button
            type="button"
            variant="tertiary"
            onClick={onRemove}
            aria-label="remove"
            size="md"
            iconPrefix="c-remove"
          >
            {isPhone && 'Remove'}
          </Button>
        </Box>
      </Box>
    );
  }

  if (operator === 'segment') {
    return (
      <Box
        width="100"
        flex="auto"
        as="li"
        direction={{ base: 'column', tablet: 'row' }}
        alignItems="flex-start"
        gap="lg"
      >
        <Box
          gap="xs"
          fontSize="xs"
          justifyContent="center"
          fontFamily="monospace"
          padding="lg 0"
          alignItems="center"
          minHeight="42px"
        >
          Evaluation Context
        </Box>
        <Box width="84px" minWidth="84px">
          {renderOperatorField()}
        </Box>
        <Box flex="auto">{renderValueField()}</Box>
        <Box>
          <Button
            type="button"
            variant="tertiary"
            onClick={onRemove}
            aria-label="remove"
            size="md"
            iconPrefix="c-remove"
          >
            {isPhone && 'Remove'}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box width="100" flex="auto" as="li" direction={{ base: 'column', tablet: 'row' }} alignItems="stretch" gap="lg">
      <Box gap="xs" justifyContent="stretch" width="100" maxWidth="8xl">
        {renderKeyField()}
      </Box>
      <Box width="6xl" minWidth="6xl">
        {renderOperatorField()}
      </Box>
      <Box flex="auto">{renderValueField()}</Box>
      <Box>
        <Button
          type="button"
          variant="tertiary"
          onClick={onRemove}
          aria-label="remove"
          size="md"
          iconPrefix="c-remove"
        >
          {isPhone && 'Remove'}
        </Button>
      </Box>
    </Box>
  );
}

export default React.memo(RuleFields);
