import { Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';
import { useDeleteTeamMemberMutation } from '../../services/teams';

export default function DeleteTeamMember({
  organizationId,
  teamId,
  memberId,
}: {
  organizationId: string;
  teamId: string;
  memberId: string;
}) {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const [deleteTeamMember, { isLoading: isDeleting }] = useDeleteTeamMemberMutation();

  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteTeamMember({ orgId: organizationId, teamId, memberId });
      if (!result.error) {
        toast.success(`Member removed`);
        closeDeleteModal();
      } else {
        console.error(`Remove team member failed`, result.error);
        toast.error(`Remove team member failed`);
      }
    } catch (e) {
      console.error(`Remove team member failed`, e);
      toast.error(`Remove team member failed`);
    }
  }, [deleteTeamMember, organizationId, teamId, memberId, closeDeleteModal]);

  return (
    <div>
      <ConfirmModal
        message={`Are you sure you want to remove this team member'?`}
        confirmButtonLabel="Remove Team Member"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title="Remove Team Member"
      />
      <Button
        variant="tertiary"
        size="sm"
        iconPrefix="c-remove"
        onClick={openDeleteModal}
        aria-label="remove team member"
      />
    </div>
  );
}
