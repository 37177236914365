import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import ShortLinkOverview from './ShortLinkOverview';
import ShortLinkAccess from './ShortLinkAccess';
import Error from '../../../Error';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useDeleteLinkCodeMutation, useGetLinkCodeQuery } from '../../../services/link/codes';
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  toast,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { Helmet } from 'react-helmet';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import CreateQrCode from '../../../components/link/CreateQrCode';
import PageHeader from '../../../components/PageHeader';
import { useCallback, useMemo } from 'react';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { RedirectLinkModal } from '../../../components/link/RedirectLinkModal';
import { EditTitleModal } from '../../../components/link/EditTitleModal';
import { Skeleton } from '../../../components/common/Skeleton';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import OrgMemberDrawer from '../../../components/members/OrgMemberDrawer';

const deleteCodeMessage = {
  default: 'Failed to remove short link',
};

const ShortLinkContainer = () => {
  const { id } = useParams<{ id: any }>();
  const { id: organizationId } = useOrganization();
  const navigate = useNavigate();

  const [deleteShortLink, { isLoading: isDeleting, error: deleteCodeError }] = useDeleteLinkCodeMutation();

  const { data: code, error, isLoading } = useGetLinkCodeQuery({ id, organizationId });

  const ability = useOrganizationAbilityContext();
  const canEditLink = useMemo(() => (code ? ability.can('update', code) : false), [ability, code]);
  const canDeleteLinkCode = useMemo(() => (code ? ability.can('delete', code) : false), [ability, code]);

  const { isOpen: isRedirectOpen, handleToggle: toggleRedirect, handleClose: closeRedirect } = useOpenClose();
  const { isOpen: isTitleOpen, handleToggle: toggleTitle, handleClose: closeTitle } = useOpenClose();
  const { isOpen: isDeleteOpen, handleOpen: openDelete, handleClose: closeDelete } = useOpenClose();

  const shortLink = useMemo(() => {
    return `${code?.domain}/${code?.code}`;
  }, [code]);

  const handleCopyUrl = useCallback(() => {
    copyToClipboard(`https://${shortLink}`);
  }, [shortLink]);

  const handleDelete = useCallback(async () => {
    const result = await deleteShortLink({ codeId: code?.id ?? '', organizationId: organizationId });
    if (!result.error) {
      toast.success('Short link deleted');
      navigate(`/${organizationId}/link`);
      closeDelete();
    }
  }, [closeDelete, code?.id, deleteShortLink, navigate, organizationId]);

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height="40px" width="200px" />,
        favoriteId: '',
      };
    }
    return {
      title: code?.title,
      favoriteId: code?.id,
      description: (
        <Link to={`https://${shortLink}`} target="_blank" rel="noopener noreferrer">{`https://${shortLink}`}</Link>
      ),
    };
  }, [code, isLoading, shortLink]);

  if (error) return <Error />;

  return (
    <ScrollableMainLayout fontSize={{ base: 'xs', tablet: 'sm' }}>
      <Helmet title={`${code?.title || 'Loading'}`} />
      <BreadCrumbPortal entity={code} />
      <Box gap="lg" alignItems="flex-start">
        <Box
          width="100"
          direction={{ base: 'column', tablet: 'row' }}
          gap={{ base: '2xl', tablet: '5xl' }}
          alignItems={{ tablet: 'center' }}
        >
          <PageHeader flex="auto" {...pageHeaderProps} />

          <Box gap="md" direction="row" alignItems="center">
            {code && (
              <Button onClick={handleCopyUrl} variant="primary" size="sm" iconSuffix="copy-document">
                Copy Link
              </Button>
            )}
            {canEditLink && code && <CreateQrCode code={code} />}
            {(canEditLink || canDeleteLinkCode) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="secondary" size="sm" iconPrefix="dots" aria-label="more actions" />
                </DropdownMenuTrigger>
                <DropdownMenuContent side="bottom" align="end">
                  {canEditLink && <DropdownMenuItem onClick={toggleRedirect}>Redirect</DropdownMenuItem>}
                  {canEditLink && <DropdownMenuItem onClick={toggleTitle}>Edit Label</DropdownMenuItem>}
                  {canDeleteLinkCode && (
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem className="font-color-danger" onClick={openDelete}>
                        Delete
                      </DropdownMenuItem>
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </Box>
        </Box>
      </Box>

      {isLoading ? (
        <Skeleton height="48px" width="182px" />
      ) : (
        <TabNav>
          <TabNavLink to="">Overview</TabNavLink>
          <TabNavLink to="access">Access</TabNavLink>
        </TabNav>
      )}

      {code && (
        <Routes>
          <Route path="/" element={<ShortLinkOverview code={code} toggleRedirect={toggleRedirect} />} />
          <Route path="access/*" element={<ShortLinkAccess code={code} />}>
            <Route path=":memberId/*" element={<OrgMemberDrawer />} />
          </Route>
        </Routes>
      )}
      <ConfirmModal
        message={`Are you sure you want to delete ${code?.title}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteOpen}
        onClose={closeDelete}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete ${code?.title}`}
        description="Once deleted, it will be gone forever. Please be certain."
        error={deleteCodeError}
        customErrorMessages={deleteCodeMessage}
      />

      <RedirectLinkModal
        codeData={code}
        closeRedirect={closeRedirect}
        isRedirectOpen={isRedirectOpen}
        toggleRedirect={toggleRedirect}
        shortLink={shortLink}
      />
      <EditTitleModal codeData={code} closeTitle={closeTitle} isOpen={isTitleOpen} toggleTitle={toggleTitle} />
    </ScrollableMainLayout>
  );
};

export default ShortLinkContainer;
