import { Box, FormikTextInput, FormikSelectInput, Card, Button, toast } from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import { CreateTeamFormSchema, createTeamFormSchema, typeOptions } from '../../schema/teams';
import { ApiError } from '../ApiError';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useCreateMemberMutation, useGetMembersQuery, useLazyGetMembersQuery } from '../../services/member';
import { useGetProjectsQuery } from '../../services/projects';
import { useCreateTeamMutation, useReplaceTeamMembersMutation } from '../../services/teams';
import { TeamType, Team, DynamicTeamCriteriaProperty } from '../../types/teams';
import { MemberSelect, CreateMemberData } from '../types/member';
import TeamTypeToggle from './TeamTypeToggle';
import ProjectAccessField from './ProjectAccessField';
import { useGiveAccessMutation } from '../../services/access';
import TeamProjectAccessDescription from './TeamProjectAccessDescription';
import { ConnectionType } from '../../types/connections';
import { useGetIntegrationsQuery } from '../../services/integrations';
import { useCreateConnectionMutation } from '../../services/connections';
import IntegrationConnectionsField from '../integrations/IntegrationConnectionsField';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames } from '@hyphen/nucleus/dist/types';
import TooltipIcon from '../common/TooltipIcon';

const initialValues: CreateTeamFormSchema = {
  name: '',
  teamType: typeOptions[0].value as TeamType,
  criteria: [
    { type: 'property', field: 'firstName', operator: 'equals', value: '' },
  ] as DynamicTeamCriteriaProperty[],
  members: [] as MemberSelect[],
  projectAccess: [],
  integrations: [],
};

const PAGE_SIZE = 200;

function CreateTeamForm() {
  const { id: organizationId } = useOrganization();
  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery(organizationId);
  const { data: projects, isLoading: isProjectsLoading } = useGetProjectsQuery({
    pageSize: PAGE_SIZE,
    pageNum: 1,
    organizationId,
  });

  const navigate = useNavigate();

  const [CreateTeam, { error }] = useCreateTeamMutation();
  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [replaceTeamMembers, { error: replaceError }] = useReplaceTeamMembersMutation();
  const [giveAccess, { error: giveAccessError }] = useGiveAccessMutation();
  const [CreateConnection, { error: createConnectionError }] = useCreateConnectionMutation();

  const queryParams = { pageNum: 1, pageSize: 50 };
  const { data: initialMembers, isLoading: initialLoading } = useGetMembersQuery({ organizationId, ...queryParams });
  const [trigger] = useLazyGetMembersQuery();

  const [membersListData, setMembersListData] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (!initialLoading && initialMembers) {
      const membersList = initialMembers.data.map((member) => ({
        value: member.id,
        label: member.email,
      }));
      setMembersListData(membersList);
    }
  }, [initialMembers, initialLoading]);

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    const filteredMembers = await trigger({ organizationId: organizationId, search: inputValue }).unwrap();
    return (
      filteredMembers?.data.map((member) => ({
        value: member.id,
        label: member.email,
      })) || []
    );
  };

  const handleInviteMember = useCallback(
    async (newMembers: MemberSelect[]) => {
      const responses = (await Promise.all(
        newMembers.map(
          async (member) =>
            await createMember({
              email: member.label,
              organizationId,
              firstName: '',
              lastName: '',
            }),
        ),
      )) as CreateMemberData[];

      if (responses.length) {
        return responses.map((response) => response.data?.id);
      }

      return [];
    },
    [createMember, organizationId],
  );

  const handleAddMember = useCallback(
    async (values: CreateTeamFormSchema, id: string) => {
      let memberIds =
        values.members
          ?.filter((member: MemberSelect) => !member.__isNew__)
          .map((member: MemberSelect) => member.value) ?? [];

      const newMembers = values.members?.filter((member: MemberSelect) => member.__isNew__);
      const newMemberIds = newMembers?.length ? await handleInviteMember(newMembers) : [];

      if (newMemberIds.length) {
        memberIds = [...memberIds, ...newMemberIds];
      }

      try {
        toast('Adding members...', { isCompact: true });
        const { error } = await replaceTeamMembers({
          orgId: organizationId,
          teamId: id,
          data: { memberIds },
        });
        if (error) {
          toast.error('Failed to add members to the team');
        }
      } catch (error) {
        toast.error('An error occurred while adding members to the team');
      }
    },
    [handleInviteMember, organizationId, replaceTeamMembers],
  );

  const handleGiveAccess = useCallback(
    async (projectAccess: any, teamId: string) => {
      try {
        toast('Applying access...', { isCompact: true });
        // iterate through projectAccess and call giveAccess for each one
        await Promise.all(
          projectAccess.map(async (access: any) => {
            return await giveAccess({
              assignment: { type: 'Team', id: teamId },
              entity: { type: 'Project', id: access.project.id },
              organizationId,
              roles: [access.role],
            });
          }),
        );
      } catch (error) {
        toast.error('Failed to grant access');
      }
    },
    [giveAccess, organizationId],
  );

  const handleCreateIntegrationConnection = async (values: CreateTeamFormSchema, teamId: string) => {
    if (values.integrations) {
      const integrations = values.integrations.map((integration) => {
        if (integration) {
          const [integrationId, connectionType] = integration.id.split('-');
          return { integrationId, connectionType: connectionType, input: integration.input };
        }
        return { integrationId: '', connectionType: '' };
      });

      try {
        toast('Connecting integrations...', { isCompact: true });
        await Promise.all(
          integrations.map((integration) =>
            CreateConnection({
              organizationId: organizationId,
              integrationId: integration.integrationId,
              connection: {
                type: integration.connectionType as ConnectionType,
                entity: {
                  id: teamId,
                },
                input: integration.input,
              },
            }),
          ),
        );
      } catch (error) {
        console.error('Integration connection failed:', error);
        toast.error('Integration connection failed');
      }
    }
  };

  const handleSubmit = async (values: CreateTeamFormSchema) => {
    const team: Omit<Team, 'id' | 'memberCount'> = {
      name: values.name,
      type: values.teamType,
    };

    if (values.teamType === 'dynamic') {
      team.criteria = values.criteria as DynamicTeamCriteriaProperty[];
    }

    toast('Creating team...', { isCompact: true });
    const { data: teamData, error } = await CreateTeam({
      orgId: organizationId,
      team,
    });

    if (!error) {
      try {
        if (values.teamType === 'static' && values.members?.length) {
          await handleAddMember(values, teamData.id);
        }

        if (values.projectAccess?.length) {
          await handleGiveAccess(values.projectAccess, teamData.id);
        }

        if (values.integrations?.length) {
          await handleCreateIntegrationConnection(values, teamData.id);
        }

        navigate(`/${organizationId}/teams/${teamData.id}`);
      } catch (error) {
        toast.error('An error occurred during the team creation process');
      }
    } else {
      toast.error('Create team failed');
    }
  };

  const memoizedIntegrations = useMemo(() => integrations?.data ?? [], [integrations]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createTeamFormSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting, errors, values, setFieldValue }) => {
        return (
          <Form noValidate>
            <Box gap="4xl">
              <Field
                label="Team Name"
                name="name"
                id="name"
                component={FormikTextInput}
                autoComplete="off"
                isDisabled={isSubmitting}
                error={errors.name}
                isRequired
              />

              <TeamTypeToggle values={values} setFieldValue={setFieldValue} isSubmitting={isSubmitting} />

              {values.teamType === 'static' ? (
                <Box direction="row" gap="3xl" alignItems="center">
                  <Field
                    label="Members"
                    name="members"
                    id="members"
                    options={loadOptions}
                    placeholder="Search for members or invite via email..."
                    component={FormikSelectInput}
                    isDisabled={isSubmitting}
                    isMulti
                    isCreatable
                    isAsync
                    cacheOptions
                    error={errors.members}
                    defaultOptions={membersListData}
                  />
                </Box>
              ) : null}

              <Card>
                <Card.Section title="Grant Project Access" gap="xl" fontSize="sm">
                  <TeamProjectAccessDescription />
                  {projects && (
                    <ProjectAccessField
                      values={values}
                      projects={projects.data}
                      isProjectsLoading={isProjectsLoading}
                    />
                  )}
                </Card.Section>
              </Card>

              <Card>
                <Card.Section fontSize="sm" gap="xl">
                  {!isIntegrationsLoading && memoizedIntegrations && (
                    <>
                      <Box gap="sm">
                        <Box direction="row" gap="xs" alignItems="center" fontWeight="semibold">
                          Team Integration Connections
                          <TooltipIcon
                            content="Create connections for the team with available organization integrations."
                            name="c-info"
                          />
                        </Box>

                        <Box as="p" color="secondary">
                          Select which integrations the team should use, and we’ll create an instance that uses the
                          team's name. Optionally, provide a reference to an existing integration and we'll connect
                          to it.
                        </Box>
                      </Box>
                      <Box gap="xs">
                        <IntegrationConnectionsField
                          integrations={memoizedIntegrations}
                          connectionTypes={ENTITY_CONNECTION_TYPE_MAP[EntityNames.Team]}
                          isSubmitting={isSubmitting}
                          errors={errors}
                        />
                      </Box>
                    </>
                  )}
                </Card.Section>
              </Card>

              {(error || createError || replaceError || giveAccessError || createConnectionError) && (
                <ApiError error={error || createError || replaceError || giveAccessError || createConnectionError} />
              )}

              <Box direction="row" gap="sm">
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Create Team
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
export default CreateTeamForm;
