import { Box } from '@hyphen/hyphen-components';

export const ColorDot = ({ color }: { color: string }) => (
  <Box
    style={{ backgroundColor: color, borderColor: 'rgba(0,0,0,.3)' }}
    width="xl"
    height="xl"
    minWidth="xl"
    minHeight="xl"
    radius="xs"
    borderWidth="sm"
    display="inline-block"
  />
);
