import { Helmet } from 'react-helmet';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { useSegment } from '../../../providers/SegmentProvider';
import { EditSegmentForm } from '../../../components/segments/EditSegmentForm';
import DeleteSegment from '../../../components/segments/DeleteSegment';

export const SegmentSettings = () => {
  const { segment } = useSegment();
  const ability = useOrganizationAbilityContext();

  if (!segment) return null;

  const canUpdateSegment = ability.can('update', segment);
  if (canUpdateSegment)
    return (
      <>
        <Helmet title={`${segment?.name} Settings`} />
        <EditSegmentForm />
        <DeleteSegment />
      </>
    );

  return (
    <>
      <Helmet title={`${segment?.name} Settings`} />
      <p>You do not have permission</p>
    </>
  );
};

export default SegmentSettings;
