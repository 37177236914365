import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Spinner } from '@hyphen/hyphen-components';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { getEntityUrl } from '../../utils/getEntityUrl';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetAppsQuery } from '../../services/apps';

export const AppLoader = ({ entityId, internalPath }: { entityId: string; internalPath?: string }) => {
  const navigate = useNavigate();
  const { id: orgId } = useOrganization();

  const {
    data: apps,
    isLoading,
    error,
  } = useGetAppsQuery({ organizationId: orgId, appIds: [entityId] }, { skip: !orgId });

  useEffect(() => {
    if (isLoading) return;

    if (!apps || !apps.data.length || error) {
      throw new Response('Not Found', { status: 404 });
    }

    if (apps?.data.length) {
      const projectAlternateId = apps.data[0].project.alternateId;
      const appAlternateId = apps.data[0].alternateId;

      let url = getEntityUrl(orgId, EntityNames.App, appAlternateId, projectAlternateId);
      if (internalPath) url = `${url}/${internalPath}`;
      navigate(url, { replace: true });
    }
  }, [apps, navigate, orgId, internalPath, isLoading, error]);

  return (
    <Box padding="2xl" alignItems="center" justifyContent="center">
      <Spinner size="lg" />
    </Box>
  );
};
