import { Helmet } from 'react-helmet';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Box } from '@hyphen/hyphen-components';
import { ApiError } from '../../../components/ApiError';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useMemo } from 'react';
import { useProject } from '../../../providers/ProjectProvider';
import { subject } from '@casl/ability';
import ListHeader from '../../../components/projects/ListHeader';
import { TOGGLE_TEXT } from '../../../constants/toggle';
import ProjectTogglesList from '../../../components/projects/ProjectTogglesList';
import ProjectToggleCharts from '../../../components/projects/ProjectTogglesCharts';

export default function ProjectToggles() {
  const { project, isLoading, error } = useProject();
  const { id: orgId } = useOrganization();
  const ability = useOrganizationAbilityContext();

  const canCreateToggle = useMemo(
    () =>
      !isLoading && project
        ? ability.can(
            'create',
            subject(EntityNames.Toggle, {
              project: { id: project.id },
              organization: { id: orgId },
            }),
          )
        : ability.can('create', EntityNames.Toggle),
    [ability, isLoading, orgId, project],
  );

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <>
      <Helmet>
        <title>{`${project?.name || 'Loading'} Feature Toggles`}</title>
      </Helmet>

      <Box direction={{ base: 'column', tablet: 'row' }} gap="3xl" alignItems="flex-start">
        <Box gap="xl" width={{ base: '100', tablet: '40' }} maxWidth={{ desktop: '9xl' }}>
          <ListHeader
            isLoading={isLoading}
            createLabel="Create Toggle"
            canCreate={canCreateToggle}
            showHelp={false}
            helpContent={TOGGLE_TEXT.description}
          />
          {project && <ProjectTogglesList project={project} orgId={orgId} />}
        </Box>
        {project && <ProjectToggleCharts project={project} orgId={orgId} />}
      </Box>
    </>
  );
}
