import { DropdownMenuGroup, DropdownMenuItem, Icon, toast, useOpenClose } from '@hyphen/hyphen-components';
import { BillingAccountOwner, useDeleteOwnerMutation } from '../../../services/billingAccount';
import { ConfirmModal } from '../../common/ConfirmModal';
import { useCallback } from 'react';
import { ListItem } from '../../ListItem';

interface BillingAccountAccessItemProps {
  mode?: 'view' | 'edit';
  billingAccountId: string;
  owner: BillingAccountOwner;
}

export const BillingAccountAccessItem = ({
  mode = 'view',
  billingAccountId,
  owner,
}: BillingAccountAccessItemProps) => {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const [DeleteOwner, { isLoading: isDeleteLoading, error: deleteError }] = useDeleteOwnerMutation();

  const handleDelete = useCallback(async () => {
    const { error } = await DeleteOwner({ id: billingAccountId, userId: owner.userId });

    if (!error) {
      toast.success('Owner removed!');
      closeDeleteModal();
    }
  }, [DeleteOwner, billingAccountId, closeDeleteModal, owner.userId]);

  return (
    <>
      <ListItem
        title={{ label: owner.name }}
        subtitle={owner.email}
        dropdown={
          mode === 'edit' && (
            <DropdownMenuGroup>
              <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                <Icon name="c-remove" /> <span>Delete</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )
        }
      />

      <ConfirmModal
        message={`Are you sure you want to delete this billing account owner?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleteLoading}
        title={`Delete Billing Account Owner`}
        error={deleteError}
      />
    </>
  );
};
