import { Badge, Box, useIsMobile } from '@hyphen/hyphen-components';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Route, Routes, useParams } from 'react-router-dom';
import Error from '../../../Error';
import { TabNavLink, TabNav } from '../../../components/TabNav';
import { useGetTeamQuery } from '../../../services/teams';
import TeamDetail from './TeamDetail';
import TeamSettings from '../TeamSettings';
import { AddMemberModal } from '../../../components/members/AddMemberModal';
import TeamConnections from '../../../components/teams/TeamConnections';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import OrgMemberDrawer from '../../../components/members/OrgMemberDrawer';
import ErrorBoundary from '../../../components/ErrorBoundary';
import TeamAccess from './TeamAccess';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { Skeleton } from '../../../components/common/Skeleton';
import { useMemo } from 'react';
import { useBooleanFlagValue } from '@openfeature/react-sdk';

export default function TeamContainer() {
  const { id: orgId, organization, isLoading: isOrgLoading } = useOrganization();
  const { id } = useParams<{ id: any }>();
  const ability = useOrganizationAbilityContext();
  const isMobile = useIsMobile();
  const showConnectionsTab = useBooleanFlagValue('connections-tab', false);

  const { isLoading: isTeamLoading, error, data: team } = useGetTeamQuery({ orgId: orgId, id });

  const canViewSettings = team ? ability.can('update', team) || ability.can('delete', team) : false;

  const isLoading = isOrgLoading || isTeamLoading;

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height={isMobile ? '25px' : '40px'} width="200px" />,
        favoriteId: '',
      };
    }
    return {
      title: (
        <Box direction="row" gap="md" alignItems="center">
          {team?.name} {team?.type === 'dynamic' && <Badge message="dynamic" variant="purple" size="sm" />}
        </Box>
      ),
      favoriteId: team?.id,
    };
  }, [isLoading, isMobile, team?.id, team?.name, team?.type]);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout fullWidth fontSize={{ base: 'xs', tablet: 'sm' }}>
      <PageHeader {...pageHeaderProps} />
      <BreadCrumbPortal entity={team} />
      {isLoading ? (
        <Skeleton height="48px" width="300px" />
      ) : (
        <TabNav>
          <TabNavLink to="">Overview</TabNavLink>
          {showConnectionsTab && <TabNavLink to="connections">Connections</TabNavLink>}
          <TabNavLink to="access">Access</TabNavLink>
          {canViewSettings && <TabNavLink to="settings">Settings</TabNavLink>}
        </TabNav>
      )}

      <ErrorBoundary>
        {team && (
          <Routes>
            <Route path="/*" element={<TeamDetail team={team} orgId={orgId} />}>
              <Route path="add" element={<AddMemberModal isOpen onClose={() => {}} data={{ organization }} />} />
              <Route path=":memberId/*" element={<OrgMemberDrawer />} />
            </Route>
            <Route path="access/*" element={<TeamAccess team={team} />}>
              <Route path=":memberId/*" element={<OrgMemberDrawer />} />
            </Route>
            <Route path="settings/*" element={<TeamSettings team={team} />} />
            <Route path="connections/*" element={<TeamConnections team={team} orgId={orgId} />} />
            <Route path="*" element={<Error />} />
          </Routes>
        )}
      </ErrorBoundary>
    </ScrollableMainLayout>
  );
}
