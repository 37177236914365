import { Fragment } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { Box, Button, Heading, Spinner, toast } from '@hyphen/hyphen-components';
import LogicalOperator from './LogicalOperator';
import RuleFields from './RuleFields';
import { InferType } from 'yup';
import * as Yup from 'yup';
import ReturnValueField from './ReturnValueField';
import { ToggleType } from '../../types/toggle';
import { Operator, toJsonLogic } from '../../utils/parseJsonLogic';
import ContextFieldDropdown from './ContextFieldDropdown';
import { TargetsUpdate, useUpdateToggleTargetsMutation } from '../../services/toggle';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useToggle } from '../../providers/ToggleProvider';
import { ApiError } from '../ApiError';

const targetFormSchema = Yup.object().shape({
  id: Yup.string(),
  parsedLogic: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required('Key is required'),
        operator: Yup.string()
          .oneOf(Object.values(Operator), 'Operator must be one of ==, !=, in, not in, contains, >=, <=')
          .required('Operator is required'),
        value: Yup.string()
          .required('Value is required')
          .when(['key', 'operator'], {
            is: (key: string, operator: string) => key === 'user.email' && operator === Operator.in,
            then: (schema) =>
              schema.test('is-valid-emails', 'Must be a comma separated list of valid email addresses', (value) =>
                value
                  ? value.split(',').every((email: string) => Yup.string().email().isValidSync(email.trim()))
                  : false,
              ),
            otherwise: (schema) =>
              schema.when(['key', 'operator'], {
                is: (key: string, operator: string) => key === 'user.email' && operator !== Operator.contains,
                then: (schema) => schema.email('Must be a valid email address'),
                otherwise: (schema) => schema,
              }),
          })
          .when('operator', {
            is: (operator: string) => operator === Operator.in,
            then: (schema) =>
              schema.test('is-valid-list', 'Must be a comma separated list of values', (value) =>
                value ? value.split(',').every((item) => item.trim().length > 0) : false,
              ),
          }),
        contextFieldType: Yup.string().oneOf(['', 'customAttributes', 'user.customAttributes', 'segment']),
      }),
    )
    .min(1, 'At least one context field is required')
    .required('At least one context is required'),
  defaultValue: Yup.mixed().required('Return value is required'),
});

export type TargetFormSchema = InferType<typeof targetFormSchema>;

interface TargetFormProps {
  initialValues: TargetFormSchema;
  onSubmit: (updateIndex: number) => void;
}

const TargetForm = ({ initialValues, onSubmit }: TargetFormProps) => {
  const { toggle } = useToggle();
  const { id: orgId } = useOrganization();
  const [updateToggleTargets, { error }] = useUpdateToggleTargetsMutation();

  const handleSubmit = async (values: TargetFormSchema) => {
    if (!toggle) return;

    // RadioGroup only handles values as strings
    // Convert string boolean values back to boolean
    if (toggle.type === ToggleType.Boolean) {
      values.defaultValue = values.defaultValue === 'true';
    }

    const targetToUpdateIndex = toggle.targets.findIndex((target) => target.id === values.id);

    const isNewTargetContext = targetToUpdateIndex === -1;

    const targetUpdate: TargetsUpdate = {
      logic: toJsonLogic(values.parsedLogic),
      value: values.defaultValue as string | number | boolean,
    };

    let targetUpdates: TargetsUpdate[] = toggle.targets.map((target) =>
      target.id === values.id ? targetUpdate : { logic: target.logic, value: target.value },
    );

    if (isNewTargetContext) {
      targetUpdates.push(targetUpdate);
    }

    const { error, data } = await updateToggleTargets({
      organizationId: orgId,
      projectId: toggle.project.alternateId,
      toggleKey: toggle.key,
      body: {
        targets: targetUpdates,
      },
      type: toggle.type,
    });

    if (!error && data) {
      toast.success('Target context updated');
      onSubmit(isNewTargetContext ? data.targets.length - 1 : targetToUpdateIndex);
    }
  };

  if (!toggle) return <Spinner />;

  return (
    <Formik
      initialValues={{
        ...initialValues,
        defaultValue:
          toggle.type === ToggleType.Boolean ? String(initialValues.defaultValue) : initialValues.defaultValue,
      }}
      enableReinitialize
      validationSchema={targetFormSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, isSubmitting }) => (
        <Form noValidate className="display-flex g-3xl flex-direction-column">
          <Box gap="lg" flex="auto" alignItems="flex-start">
            <Heading as="h2" size="sm">
              1. Define target criteria
            </Heading>
            <Box width="100" padding="0 0 0 lg">
              <FieldArray name="parsedLogic">
                {({ remove, push }) => (
                  <Box as="ol" gap="lg" padding="0" width="100" fontSize="sm">
                    {values.parsedLogic.map((_context: any, index: number) => (
                      <Fragment key={`context.${index}`}>
                        <RuleFields index={index} onRemove={() => remove(index)} />
                        {index < values.parsedLogic.length - 1 && (
                          <LogicalOperator isDragging={false} operator="AND" />
                        )}
                      </Fragment>
                    ))}
                    <Box display="block">
                      <ContextFieldDropdown push={push} values={values} errors={errors} />
                    </Box>
                  </Box>
                )}
              </FieldArray>
            </Box>
          </Box>
          <Box gap="lg" flex="auto">
            <Heading as="h2" size="sm">
              2. If all criteria are met, return the following
            </Heading>
            <Box flex="auto" padding="0 0 0 lg">
              <ReturnValueField
                toggleType={toggle.type}
                defaultValue={toggle.type === ToggleType.Boolean ? String(values.defaultValue) : values.defaultValue}
                setFieldValue={setFieldValue}
                errors={errors}
                isSubmitting={false}
              />
            </Box>
          </Box>
          {error && <ApiError error={error} title="Error updating target" />}
          <Box alignItems="flex-start">
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TargetForm;
