import { Box, Card } from '@hyphen/hyphen-components';
import { BillingCycle } from '../../../services/billingAccount';
import { formatCurrency } from '../../../utils/currencyUtils';
import { formatDateInUTC } from '../../../utils/dateUtils';

interface UpcomingInvoiceDetailsProps {
  billingCycle: BillingCycle;
}

export const UpcomingInvoiceDetails = ({ billingCycle }: UpcomingInvoiceDetailsProps) => {
  return (
    <Card>
      <Card.Header
        title="Upcoming Invoice"
        description={`
          ${formatDateInUTC(billingCycle.startDate.toString())} - ${
          billingCycle.endDate.toString().split('T')[0] === new Date().toISOString().split('T')[0]
            ? 'Today'
            : formatDateInUTC(billingCycle.endDate.toString())
        }
          `}
        borderWidth="0 0 sm 0"
      />
      <Card.Section>
        <Box fontSize="sm" color="secondary">
          Estimated Amount Due
        </Box>
        <Box fontSize="2xl" fontWeight="bold">
          {formatCurrency(billingCycle.invoicePreviewAmountDue ?? 0)}
        </Box>
      </Card.Section>
    </Card>
  );
};
