import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerProvider,
  DrawerTitle,
  DrawerTrigger,
  useBreakpoint,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { CreateQRForm } from './CreateQRForm';
import { LinkCode } from '../../types/domain';

export default function CreateQrCode({ code }: { code: LinkCode }) {
  const { isPhone } = useBreakpoint();

  const { isOpen, handleOpen, handleClose, handleToggle } = useOpenClose();

  return (
    <DrawerProvider open={isOpen} onOpenChange={handleToggle}>
      <DrawerTrigger asChild onClick={handleOpen}>
        <Button iconSuffix="qr-code" variant="secondary" size="sm">
          Add QR Code
        </Button>
      </DrawerTrigger>
      <Drawer width={isPhone ? '90%' : '400px'}>
        <DrawerHeader>
          <DrawerTitle>Add QR Code</DrawerTitle>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerContent>
          <CreateQRForm onSubmit={handleClose} codeId={code.id} codeData={code} />
        </DrawerContent>
      </Drawer>
    </DrawerProvider>
  );
}
