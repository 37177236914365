import { Box, DrawerTrigger, Button, useIsMobile } from '@hyphen/hyphen-components';
import { TOGGLE_TEXT } from '../../constants/toggle';

function NoUsageDataOverlay() {
  const isMobile = useIsMobile();

  return (
    <>
      <Box
        position="absolute"
        width="100"
        height="100"
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }}
        className="fade-in"
      />
      <Box
        position="absolute"
        padding={{ base: '2xl', desktop: '4xl' }}
        width="100"
        maxWidth={isMobile ? '300px' : '360px'}
        background="brand-gradient-purple"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        fontSize="sm"
        className="fade-in"
        radius="md"
        shadow="md"
        gap="xl"
        color="white"
      >
        <Box gap="xs">
          <Box as="h4" fontSize="md" fontWeight="semibold">
            {TOGGLE_TEXT.noUsageTitle}
          </Box>
          <Box fontSize={{ base: 'xs', tablet: 'sm' }} className="lh-heading">
            {TOGGLE_TEXT.noUsageBody}
          </Box>
        </Box>
        <DrawerTrigger asChild>
          <Button variant="secondary" iconSuffix="arrow-right">
            SDK Setup Guide
          </Button>
        </DrawerTrigger>
      </Box>
    </>
  );
}

export default NoUsageDataOverlay;
