import {
  Button,
  Card,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  toast,
  useBreakpoint,
  useDrawer,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { useSegment } from '../../providers/SegmentProvider';
import { useCallback, useMemo } from 'react';
import SegmentForm from './SegmentForm';
import { ConfirmModal } from '../common/ConfirmModal';
import { Segment } from '../../types/segments';
import { segmentToJsonLogic } from '../../utils/parseJsonLogic';
import { useUpdateSegmentMutation } from '../../services/segments';

interface SegmentRuleDrawerProps {
  onUpdateItems: (updatedIndex: number) => void;
  rule?: any;
  ruleIndex?: number;
}

export default function SegmentRuleDrawer({ rule, onUpdateItems, ruleIndex }: SegmentRuleDrawerProps) {
  const { segment } = useSegment();
  const { isPhone, isTablet } = useBreakpoint();

  const [updateSegment] = useUpdateSegmentMutation();

  const isLoading = false;

  const isNewTargetContext = useMemo(() => rule === undefined, [rule]);

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const initialValues = useMemo(
    () => ({
      id: ruleIndex?.toString(),
      parsedLogic: rule || [],
    }),
    [rule, ruleIndex],
  );

  const { setOpen } = useDrawer();

  const onSave = useCallback(
    (updatedSegment: Segment) => {
      onUpdateItems(ruleIndex || 0);
      setOpen(false);
    },
    [setOpen, ruleIndex, onUpdateItems],
  );

  const handleDelete = useCallback(async () => {
    try {
      if (!segment) {
        toast.error('Segment not found');
        return;
      }

      // context make object immutable so we need to copy it
      // this is hacky but fast :)
      const updatedSegment = JSON.parse(JSON.stringify(segment)) as Segment;

      updatedSegment.parsedLogic = {
        conjunction: updatedSegment.parsedLogic?.conjunction || 'or',
        conditions: updatedSegment.parsedLogic?.conditions?.filter((_, index) => index !== ruleIndex) || [],
      };

      updatedSegment.logic = segmentToJsonLogic(updatedSegment);

      await updateSegment({
        organizationId: segment.organization.id,
        projectId: segment.project.id,
        segmentId: segment.id,
        body: {
          logic: updatedSegment.logic,
        },
      });

      toast.success('Rule deleted');
      closeDeleteModal();
    } catch (error) {
      toast.error('Update failed');
    }
  }, [closeDeleteModal, ruleIndex, segment, updateSegment]);

  if (!segment) return null;

  return (
    <>
      <Drawer ariaLabel={`${segment.alternateId} segment rules`} width={isPhone ? '90%' : isTablet ? '70%' : '60%'}>
        <DrawerHeader>
          <DrawerTitle gap="sm">{segment.alternateId} Rule</DrawerTitle>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerContent gap={{ base: '2xl', tablet: '3xl' }}>
          <Card style={{ flexShrink: '0' }}>
            <Card.Section background="secondary">
              <SegmentForm initialValues={initialValues} onSubmit={onSave} />
            </Card.Section>
          </Card>
        </DrawerContent>
      </Drawer>
      {!isNewTargetContext && (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="Open menu" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" side="bottom">
              <DrawerTrigger asChild>
                <DropdownMenuItem>Edit</DropdownMenuItem>
              </DrawerTrigger>
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                <Icon name="c-remove" />
                <span>Delete</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <ConfirmModal
            isOpen={isDeleteModalOpen}
            onConfirm={handleDelete}
            onClose={closeDeleteModal}
            title="Delete Rule"
            message="Are you sure you want to delete this rule?"
            confirmButtonLabel="Delete"
            isLoading={isLoading}
          />
        </>
      )}
    </>
  );
}
