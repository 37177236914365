import * as yup from 'yup';
import { EntityNames, EntityTypes } from '@hyphen/nucleus/dist/types';
import { ConnectionType, ConnectionTypeLabel } from '../../../types/connections';
import { Box, Button, Icon, Modal, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCreateConnectionMutation } from '../../../services/connections';
import { Integration } from '../../../types/integrations';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../../auth/OrganizationAbilityProvider';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import IntegrationConnectionCheckbox from '../IntegrationConnectionCheckbox';
import { ApiError } from '../../ApiError';

const setupIntegrationConnectionFormSchema = yup.object().shape({
  integrations: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required('Integration ID is required'),
        input: yup
          .string()
          .default('')
          .when('id', {
            is: (id: string) => id.includes('CodeRegistry') || id.includes('User'),
            then: (schema) => schema.required('Required'),
            otherwise: (schema) => schema,
          }),
      }),
    )
    .min(1, 'At least one integration is required')
    .required('Select an integration to connect'),
});

export type SetupIntegrationConnectionFormSchema = yup.InferType<typeof setupIntegrationConnectionFormSchema>;

interface SetupIntegrationConnectionProps {
  entity: {
    type: EntityTypes;
    id: string;
    name: string;
  };
  connectionType: ConnectionType;
  integrations: Integration[];
  renderProp?: (handleOpen: () => void) => React.ReactNode; // Add renderProp to the props
}

export default function SetupIntegrationConnection({
  entity,
  connectionType,
  integrations,
  renderProp, // Destructure renderProp
}: SetupIntegrationConnectionProps) {
  const { id: orgId } = useOrganization();
  const { isOpen, handleOpen, handleClose } = useOpenClose();
  const [CreateConnection, { error }] = useCreateConnectionMutation();

  const ability = useOrganizationAbilityContext();
  const canManageOrgIntegrations = ability.can('manage', EntityNames.OrganizationIntegration);

  const connectionTypeLabel = ConnectionTypeLabel[connectionType];

  const hasIntegrations = integrations && integrations.length > 0;

  const initialValues: SetupIntegrationConnectionFormSchema = {
    integrations: [],
  };

  const isMemberEntity = entity.type === EntityNames.Member;

  const handleSave = async (values: { integrations: { id: string; input: string }[] }) => {
    if (values.integrations.length) {
      try {
        const results = await Promise.all(
          values.integrations.map(async (integration) => {
            const integrationId = integration.id.includes('-') ? integration.id.split('-')[0] : integration.id;
            return CreateConnection({
              organizationId: orgId,
              integrationId: integrationId,
              connection: {
                type: connectionType,
                entity: {
                  id: entity.id,
                },
                input: integration.input,
              },
            });
          }),
        );
        const hasError = results.some((result) => result.error);
        if (hasError) {
          toast.error(`Connect ${connectionTypeLabel} failed`);
        } else {
          handleClose();
          toast(`Connecting ${connectionTypeLabel}...`);
        }
      } catch (error) {
        toast.error(`Connect ${connectionTypeLabel} failed`);
      }
    }
  };

  if (!hasIntegrations) {
    return (
      <>
        <Modal
          maxWidth="320px"
          ariaLabelledBy="connectionSetupHeader"
          isOpen={isOpen}
          onDismiss={handleClose}
          title={`Connect ${entity.type} to ${connectionTypeLabel}`}
        >
          <Modal.Body>
            <Box gap="xl" alignItems="center" fontSize="sm">
              <Icon name="t-warning" size="4xl" color="warn" />
              <Box as="p" id="connectionSetupHeader">
                No organization integrations are set up to support this type of connection.
              </Box>
              {!canManageOrgIntegrations && (
                <Box as="p">Contact your organization administrator to set up an integration.</Box>
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer justifyContent="center">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {canManageOrgIntegrations && (
              <Button asChild variant="primary">
                <Link to={`/${orgId}/integrations`}>View Integrations</Link>
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        {renderProp ? (
          renderProp(handleOpen) // Use renderProp if provided
        ) : (
          <Button variant="tertiary" size="sm" onClick={handleOpen} aria-label="Setup connection" iconPrefix="add" />
        )}
      </>
    );
  }

  return (
    <>
      <Modal
        maxWidth="9xl"
        ariaLabelledBy="connectionSetupHeader"
        isOpen={isOpen}
        onDismiss={handleClose}
        title="Setup Connection"
      >
        <Modal.Header
          id="connectionSetupHeader"
          title={`Connect ${entity.type} to ${connectionTypeLabel}`}
          onDismiss={handleClose}
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={setupIntegrationConnectionFormSchema}
          onSubmit={handleSave}
          validateOnBlur={false}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Box gap="3xl">
                <Modal.Body fontSize="sm" gap="3xl" overflow="visible">
                  {isMemberEntity ? (
                    <Box as="p" color="secondary" display="block">
                      Select the integration(s) and enter their respective user account(s) to connect with{' '}
                      <strong className="font-color-base">{entity.name}</strong>
                    </Box>
                  ) : (
                    <Box as="ol" padding="0 0 0 xl" gap="sm" color="secondary" fontSize="sm" className="lh-heading">
                      <li>
                        Select the integration(s) you want to connect to <strong>{entity.name}</strong>, and we'll
                        create a {connectionTypeLabel.toLowerCase()} there.
                      </li>
                      <li>
                        Switch the "Use existing" toggle to provide a reference to an existing {connectionTypeLabel}{' '}
                        and we'll connect to it.
                      </li>
                    </Box>
                  )}
                  <Box gap="xs">
                    {integrations.map((integration) => (
                      <IntegrationConnectionCheckbox
                        key={integration.id}
                        integration={integration}
                        connectionType={connectionType}
                      />
                    ))}
                  </Box>

                  {error && <ApiError error={error} />}
                  {errors.integrations && touched.integrations && (
                    <Box color="danger" fontSize="sm">
                      {typeof errors.integrations === 'string' && errors.integrations}
                    </Box>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" type="button" onClick={handleClose} isDisabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" isLoading={isSubmitting}>
                    {`Connect ${entity.type} ${connectionTypeLabel}`}
                  </Button>
                </Modal.Footer>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
      {renderProp ? (
        renderProp(handleOpen) // Use renderProp if provided
      ) : (
        <Button variant="tertiary" size="sm" onClick={handleOpen} aria-label="Setup connection" iconPrefix="add" />
      )}
    </>
  );
}
