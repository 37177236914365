import { useMemo } from 'react';
import { Box, Card } from '@hyphen/hyphen-components';
import { useGetOrganizationalToggleDailySummaryQuery } from '../../services/toggle';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { ApiError } from '../ApiError';
import { ParentSize } from '@visx/responsive';
import { StackedBarChart } from './StackedBarChart';
import { Skeleton } from '../common/Skeleton';
import TooltipIcon from '../common/TooltipIcon';
import { TOGGLE_TEXT } from '../../constants/toggle';

const CHART_HEIGHT = '124px';
const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

interface ToggleOverviewData {
  evalData: Record<string, Record<string, number>>;
  evalTotal: number;
  evalMax: number;
  usageData: Record<string, Record<string, number>>;
  usageTotal: number;
  usageMax: number;
  distinctProjects: string[];
}

export default function ToggleTotalsChart({ orgId }: { orgId: string }) {
  const { data, isLoading, error } = useGetOrganizationalToggleDailySummaryQuery({
    organizationId: orgId,
  });

  const toggleOverviewData: ToggleOverviewData = useMemo(() => {
    let result = {
      evalData: {},
      evalTotal: 0,
      evalMax: 0,
      usageData: {},
      usageTotal: 0,
      usageMax: 0,
      distinctProjects: [],
    } as any;
    if (data) {
      data.projects.forEach((project) => {
        let singleDayTotal = 0;
        if (project.evaluations.dailyUsage.length > 1) {
          result.distinctProjects.push(project.project.alternateId);
        }
        project.evaluations.dailyUsage.forEach((pdu) => {
          if (!result.evalData[pdu.date]) {
            result.evalData[pdu.date] = {};
          }
          result.evalData[pdu.date][project.project.alternateId] = pdu.counts.total;
          result.evalTotal += pdu.counts.total;
          singleDayTotal += pdu.counts.total;
        });
        if (result.evalMax < singleDayTotal) {
          result.evalMax = singleDayTotal;
        }
        singleDayTotal = 0;
        project.usage.dailyUsage.forEach((pdu) => {
          if (!result.usageData[pdu.date]) {
            result.usageData[pdu.date] = {};
          }
          result.usageData[pdu.date][project.project.alternateId] = pdu.counts.total;
          result.usageTotal += pdu.counts.total;
          singleDayTotal += pdu.counts.total;
        });
        if (result.usageMax < singleDayTotal) {
          result.usageMax = singleDayTotal;
        }
      });
    }
    return result;
  }, [data]);

  const dateRange = data?.projects
    ? `${formatDate(data?.projects?.[0]?.usage?.dailyUsage[0]?.date)} - ${formatDate(
        data?.projects?.[0]?.usage?.dailyUsage[data.projects[0].usage.dailyUsage.length - 1]?.date,
      )}`
    : '';

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <ApiError error={error} />;
  }

  return (
    <Card>
      <Card.Section
        title={
          <Box direction="row" alignItems="center" gap="xs" margin="0 0 xs 0">
            <Box fontWeight="semibold" fontSize="sm" as="h4">
              {TOGGLE_TEXT.totalProjectUsage}
            </Box>
            <TooltipIcon name="c-question" size="sm" content={TOGGLE_TEXT.usage} />
          </Box>
        }
        gap="sm"
      >
        <Box direction="row" gap="sm" alignItems="baseline">
          <Box fontSize={{ base: 'lg', tablet: 'xl', desktop: '2xl' }} fontWeight="bold">
            {toggleOverviewData.usageTotal.toLocaleString('en-US')}
          </Box>
          <Box fontSize="xs" color="tertiary" fontWeight="semibold">
            {dateRange}
          </Box>
        </Box>
        <Box width="100" height={CHART_HEIGHT} style={{ minHeight: CHART_HEIGHT }}>
          <ParentSize>
            {({ width, height }) => (
              <StackedBarChart
                width={width}
                height={height}
                keys={toggleOverviewData.distinctProjects}
                maxValue={toggleOverviewData.usageMax}
                data={toggleOverviewData.usageData}
              />
            )}
          </ParentSize>
        </Box>
      </Card.Section>
      <Card.Section
        title={
          <Box direction="row" alignItems="center" gap="xs" margin="0 0 xs 0">
            <Box fontWeight="semibold" fontSize="sm" as="h4">
              {TOGGLE_TEXT.totalProjectEvaluations}
            </Box>
            <TooltipIcon name="c-question" size="sm" content={TOGGLE_TEXT.evaluations} />
          </Box>
        }
        gap="sm"
      >
        <Box direction="row" gap="sm" alignItems="baseline">
          <Box fontSize={{ base: 'lg', tablet: 'xl', desktop: '2xl' }} fontWeight="bold">
            {toggleOverviewData.evalTotal.toLocaleString('en-US')}
          </Box>
          <Box fontSize="xs" color="tertiary" fontWeight="semibold">
            {dateRange}
          </Box>
        </Box>
        <Box width="100" height={CHART_HEIGHT} style={{ minHeight: CHART_HEIGHT }}>
          <ParentSize>
            {({ width, height }) => (
              <StackedBarChart
                width={width}
                height={height}
                keys={toggleOverviewData.distinctProjects}
                maxValue={toggleOverviewData.evalMax}
                data={toggleOverviewData.evalData}
              />
            )}
          </ParentSize>
        </Box>
      </Card.Section>
    </Card>
  );
}

const LoadingSkeleton = () => (
  <Card>
    <Card.Section title={TOGGLE_TEXT.totalProjectUsage} gap="sm">
      <Box direction="row" gap="sm" alignItems="baseline">
        <Skeleton width="100px" height="28px" />
        <Skeleton width="82px" height="14px" />
      </Box>
      <Box width="100" height={CHART_HEIGHT} style={{ minHeight: CHART_HEIGHT }}>
        <Skeleton width="100%" height="100%" />
      </Box>
    </Card.Section>
    <Card.Section title={TOGGLE_TEXT.totalProjectEvaluations} gap="sm">
      <Box direction="row" gap="sm" alignItems="baseline">
        <Skeleton width="100px" height="28px" />
        <Skeleton width="82px" height="14px" />
      </Box>
      <Box width="100" height={CHART_HEIGHT} style={{ minHeight: CHART_HEIGHT }}>
        <Skeleton width="100%" height="100%" />
      </Box>
    </Card.Section>
  </Card>
);
