import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ConfirmModal } from '../common/ConfirmModal';
import { CustomMessages } from '../ApiError';

const QrCodeItem = ({
  qrCode,
  isDeleting,
  handleQrCodeDelete,
  error,
  deleteQrCodeMessage,
  canDeleteQrCode,
}: {
  qrCode: any;
  isDeleting: boolean;
  handleQrCodeDelete: (param: string) => void;
  error: any;
  deleteQrCodeMessage: CustomMessages;
  canDeleteQrCode: boolean;
}) => {
  const { isOpen: isQrDeleteOpen, handleOpen: openQrDelete, handleClose: closeQrDelete } = useOpenClose();
  return (
    <Box key={qrCode.id} width="100px">
      <ConfirmModal
        message={`Are you sure you want to delete the QR code?`}
        confirmButtonLabel="Delete"
        isOpen={isQrDeleteOpen}
        onClose={closeQrDelete}
        onConfirm={() => handleQrCodeDelete(qrCode.id)}
        isLoading={isDeleting}
        title={`Delete QR code`}
        description="Once deleted, it will be gone forever. Please be certain."
        error={error}
        customErrorMessages={deleteQrCodeMessage}
      />
      <Link to={qrCode.qrLink} target="_blank" rel="noopener noreferrer" aria-label="download qr code">
        <Box padding="md" borderWidth="sm" radius="sm" hover={{ borderColor: 'default' }}>
          <img src={qrCode.qrCode} alt="QR Code" width="100%" />
        </Box>
      </Link>
      <Box direction="row" justifyContent="space-between" alignItems="center" gap="xs" padding="2xs 0">
        <Box fontSize="xs" color="secondary" className="text-overflow-clamp" title={qrCode.title || ''}>
          {qrCode.title || '-'}
        </Box>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="qr code options" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem asChild>
              <Link to={qrCode.qrLink} target="_blank" rel="noopener noreferrer">
                Download
              </Link>
            </DropdownMenuItem>
            {canDeleteQrCode && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem onSelect={openQrDelete} className="font-color-danger">
                  Delete
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </Box>
    </Box>
  );
};

export default QrCodeItem;
