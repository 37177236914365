import { Helmet } from 'react-helmet';
import ProjectAccessList from '../../../components/projects/ProjectAccessList';
import PublicKeyList from '../../../components/publicKeys/PublicKeyList';
import { useProject } from '../../../providers/ProjectProvider';
import { Skeleton } from '../../../components/common/Skeleton';
import { Box } from '@hyphen/hyphen-components';
import { Outlet } from 'react-router-dom';

export default function ProjectAccess() {
  const { project, isLoading } = useProject();

  if (isLoading) {
    return (
      <Box>
        <Skeleton width="100" height="20px" />
      </Box>
    );
  }

  if (project) {
    return (
      <>
        <Helmet title={`${project?.name || 'Loading'} Access`} />
        <ProjectAccessList project={project} />
        <PublicKeyList projectId={project.id} projectName={project.name} organizationId={project.organization.id} />
        <Outlet />
      </>
    );
  }

  // !isLoading && !project
  return <Box>No project found</Box>;
}
