import { Box, Card } from '@hyphen/hyphen-components';
import { Project } from '../../services/projects';
import { useGetOrganizationalToggleDailySummaryQuery } from '../../services/toggle';
import { ApiError } from '../ApiError';
import { Skeleton } from '../common/Skeleton';
import ProjectToggleChart from '../toggles/ProjectToggleChart';
import TooltipIcon from '../common/TooltipIcon';
import { TOGGLE_TEXT } from '../../constants/toggle';

export default function ProjectToggleCharts({ project, orgId }: { project: Project; orgId: string }) {
  const {
    data: DailySummaryData,
    isLoading,
    error,
  } = useGetOrganizationalToggleDailySummaryQuery({
    organizationId: orgId,
    projects: [project.alternateId],
  });

  const projectData = DailySummaryData?.projects?.[0];

  const renderLoadingSkeleton = () => (
    <Box gap="xl" alignItems="baseline">
      <Box direction="row" gap="sm" alignItems="baseline">
        <Box fontSize={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}>
          <Skeleton width="32px" height="28px" />
        </Box>
        <Box fontSize="xs" color="tertiary" fontWeight="semibold">
          <Skeleton width="84px" height="14px" />
        </Box>
      </Box>
      <Box as="span" direction="row" gap="sm" fontSize="sm" fontWeight="normal">
        <Skeleton width="100px" height="16px" />
        <Skeleton width="100px" height="16px" />
      </Box>
      <Skeleton width="100%" height="170px" margin="0 0 3xl 0" />
    </Box>
  );

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  const chartTitle = (key: keyof typeof TOGGLE_TEXT) => (
    <Box direction="row" gap="xs" alignItems="center" margin="0 0 sm 0">
      <Box as="h4" fontWeight="semibold" fontSize="sm" className="transform-capitalize">
        {key}
      </Box>
      <TooltipIcon content={TOGGLE_TEXT[key]} name="c-question" size="sm" />
    </Box>
  );

  return (
    <Card width={{ base: '100', tablet: '60' }} flex="auto" position="sticky" style={{ top: '48px' }}>
      <Card.Section title={chartTitle('usage')} padding="2xl 2xl 0 2xl">
        {!isLoading && projectData && project ? (
          <ProjectToggleChart chartData={projectData.usage} projectId={project.id} />
        ) : (
          renderLoadingSkeleton()
        )}
      </Card.Section>
      <Card.Section title={chartTitle('evaluations')} padding="2xl 2xl 0 2xl">
        {!isLoading && projectData && project ? (
          <ProjectToggleChart chartData={projectData.evaluations} projectId={project.id} />
        ) : (
          renderLoadingSkeleton()
        )}
      </Card.Section>
    </Card>
  );
}
