import { Helmet } from 'react-helmet';
import { Box, Spinner } from '@hyphen/hyphen-components';
import { useGetIntegrationsQuery } from '../../../services/integrations';
import { Organization } from '../../../services/organization';
import PageHeader from '../../../components/PageHeader';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { ConnectedIntegrations } from '../../../components/integrations/ConnectedIntegrations';
import './Integrations.css';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { IntegrationType } from '../../../types/integrations';
import { IntegrationCatalogItem } from '../../../components/integrations/catalog/IntegrationCatalogItem';
import EmptyList from '../../../components/common/EmptyList';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { useMemo } from 'react';

const featuredIntegrationTypes = [
  IntegrationType.googleWorkspace,
  IntegrationType.googleCloud,
  IntegrationType.azure,
];

const integrationCatalogTypes = [
  IntegrationType.aws,
  IntegrationType.azure,
  IntegrationType.github,
  IntegrationType.googleCloud,
  IntegrationType.googleWorkspace,
  IntegrationType.slack,
  IntegrationType.office365,
  IntegrationType.incidentIo,
  IntegrationType.npm,
  IntegrationType.newRelic,
  IntegrationType.microsoftTeams,
  IntegrationType.jira,
];

export const IntegrationsOverview = () => {
  const { organization = {} as Organization } = useOrganization();
  const { isLoading, data } = useGetIntegrationsQuery(organization.id);

  const integrationIdByType = useMemo(() => {
    return data?.data.reduce((acc, integration) => {
      acc[integration.type] = integration.id;
      return acc;
    }, {} as Record<IntegrationType, string>) || {} as Record<IntegrationType, string>;
  }, [data]);

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>Integrations</title>
      </Helmet>
      <Box gap="5xl">
        <PageHeader
          title="Integrations"
          description="Supercharge your workflow and connect Hyphen to tools you use everyday"
        />
        <BreadCrumbPortal />
        {isLoading ? (
          <Spinner />
        ) : data && data.data.length > 0 ? (
          <ConnectedIntegrations integrations={data.data} organizationId={organization.id} />
        ) : (
          <EmptyList
            title="No connected integrations"
            description="Browse our integration library below and connect your tools to Hyphen"
          />
        )}
        <Box gap="3xl">
          <Box as="h2" fontSize="md" fontWeight="medium">
            Featured Integrations
          </Box>
          <Box
            display="grid"
            direction="row"
            wrap
            gap={{ base: 'xl', desktop: '3xl' }}
            className="one-column two-column-tablet three-column-desktop"
            margin="0 0 xl 0"
          >
            {featuredIntegrationTypes.map((type) => (
              <IntegrationCatalogItem
                key={type}
                organizationId={organization.id}
                type={type}
                variant="featured"
              />
            ))}
          </Box>

          <Box gap={{ base: 'xl', desktop: '3xl' }} display="grid" className="one-column two-column-tablet">
            {integrationCatalogTypes.map((type) => (
              <IntegrationCatalogItem
                key={type}
                organizationId={organization.id}
                id={integrationIdByType[type]}
                type={type}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </ScrollableMainLayout>
  );
};

export default IntegrationsOverview;
