import { Box } from '@hyphen/hyphen-components';

import { BillingAccountResponse } from '../../services/billingAccount';
import { ProfileDetails } from './profile/ProfileDetails';
import { PaymentMethodDetails } from './paymentMethods/PaymentMethodDetails';

interface BillingAccountDetailsProps {
  mode?: 'view' | 'edit';
  billingAccount: BillingAccountResponse;
}

export const BillingAccountDetails = ({ mode = 'view', billingAccount }: BillingAccountDetailsProps) => {
  return (
    <Box width="100" maxWidth="9xl" gap="4xl" fontSize="sm">
      <ProfileDetails mode={mode} billingAccount={billingAccount} />
      <PaymentMethodDetails
        mode={mode}
        billingAccountId={billingAccount.id}
        paymentMethods={billingAccount.paymentMethods}
      />
    </Box>
  );
};
