import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Box,
  useBreakpoint,
  Spinner,
  DrawerCloseButton,
} from '@hyphen/hyphen-components';
import { EventList } from './EventList';
import { useNavigate, useParams } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { App, useGetAppQuery } from '../../services/apps';
import Error from '../../Error';
import { useGetProjectEnvironmentQuery } from '../../services/environments';
import { EventType } from '../../types/events';

export default function EnvAccessLogDrawer() {
  const { appId, projectId, pevrId } = useParams();
  const { organization = {} as Organization } = useOrganization();
  const navigate = useNavigate();

  const {
    data: app = {} as App,
    error,
    isLoading,
  } = useGetAppQuery({ appId: appId!, organizationId: organization.id });

  const { data: environment, isLoading: environmentIsLoading } = useGetProjectEnvironmentQuery({
    organizationId: organization.id,
    projectId: projectId!,
    environmentId: pevrId!,
  });

  const { isPhone, isTablet } = useBreakpoint();

  const onDismiss = () => {
    navigate(`/${organization.id}/${projectId}/app/${appId}`);
  };

  if (!isLoading && error) return <Error />;

  return (
    <Drawer
      isOpen
      onDismiss={onDismiss}
      ariaLabel="environment details"
      width={isPhone ? '90%' : isTablet ? '70%' : '60%'}
    >
      <DrawerHeader>
        <DrawerTitle direction="row" alignItems="center" gap="sm">
          {app.name}
          <Box
            direction="row"
            gap="sm"
            background="secondary"
            padding="xs sm"
            radius="sm"
            fontFamily="monospace"
            fontSize="sm"
            fontWeight="normal"
          >
            {environment?.color && (
              <Box
                style={{ backgroundColor: environment?.color }}
                width="xl"
                height="xl"
                minWidth="xl"
                minHeight="xl"
                radius="xs"
                borderColor="subtle"
                borderWidth="sm"
              />
            )}
            {environmentIsLoading ? '' : environment?.name || 'Default (all)'}
          </Box>
        </DrawerTitle>
        <DrawerCloseButton onClose={onDismiss} />
      </DrawerHeader>
      <DrawerContent fontSize="sm" gap={{ base: '2xl', tablet: '3xl' }} alignItems="stretch">
        {isLoading || environmentIsLoading ? (
          <Spinner />
        ) : error ? (
          <Error />
        ) : (
          <>
            <EventList
              appId={app.id}
              title="ENV Access Log"
              pevrId={pevrId}
              showMap
              types={[
                EventType.GetEnvData,
                EventType.CreateEnvData,
                EventType.DeleteEnvData,
                EventType.GetEnvDataDeny,
              ]}
            />
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}
