import { Box, useIsMobile } from '@hyphen/hyphen-components';
import { Route, Routes, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useGetAppQuery } from '../../../services/apps';
import AppOverview from './AppOverview';
import AppSettings from './AppSettings';
import Error from '../../../Error';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import EnvAccessLogDrawer from '../../../components/env/EnvAccessLogDrawer';
import AppConnections from '../../../components/app/AppConnections';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { useMemo, useRef } from 'react';
import { Skeleton } from '../../../components/common/Skeleton';
import AccessBanner from '../../../components/common/AccessBanner';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { subject } from '@casl/ability';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import AppFirewall from './AppFirewall';
import { useProject } from '../../../providers/ProjectProvider';
import EnvVersionsDrawer from '../../../components/env/EnvVersionsDrawer';

export default function AppContainer() {
  const layoutRef = useRef<HTMLDivElement>(null);
  const { appId } = useParams<{ appId: string }>();
  const { id: orgId } = useOrganization();
  const {
    data: app,
    error,
    isLoading: isAppLoading,
  } = useGetAppQuery({ appId: appId ?? '', organizationId: orgId });
  const isMobile = useIsMobile();
  const { project, isLoading: isProjectLoading } = useProject();
  const ability = useOrganizationAbilityContext();
  const showConnectionsTab = useBooleanFlagValue('connections-tab', false);

  const isLoading = isAppLoading || isProjectLoading;

  const canUpdateApp = !isLoading && app && ability.can('update', app);
  const canReadConnections = useMemo(
    () =>
      !isLoading && app
        ? ability.can(
            'read',
            subject(EntityNames.IntegrationConnection, {
              app: { id: app.id },
              organization: { id: app.organization.id },
            }),
          )
        : false,
    [ability, isLoading, app],
  );

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height={isMobile ? '25px' : '40px'} width="200px" />,
        favoriteId: '',
      };
    }
    return {
      title: app?.name,
      favoriteId: app?.id,
    };
  }, [isLoading, app?.name, app?.id, isMobile]);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout ref={layoutRef} fullWidth fontSize={{ base: 'xs', tablet: 'sm' }}>
      <Helmet title={`${app?.name}`} />

      {!isLoading && app && (
        <>
          <AccessBanner layoutRef={layoutRef} entity={app} entityType={EntityNames.App} />
          <BreadCrumbPortal entity={app} />
        </>
      )}

      <PageHeader {...pageHeaderProps} />

      <TabNav>
        <TabNavLink to="">Overview</TabNavLink>
        <TabNavLink to="firewall">Firewall</TabNavLink>
        {showConnectionsTab && canReadConnections && <TabNavLink to="connections">Connections</TabNavLink>}
        {canUpdateApp && <TabNavLink to="settings">Settings</TabNavLink>}
      </TabNav>

      <Routes>
        <Route path="/*" element={<AppOverview isLoading={isLoading} app={app} organizationId={orgId} />}>
          <Route path=":pevrId/versions" element={<EnvVersionsDrawer />} />
          <Route path=":pevrId/*" element={<EnvAccessLogDrawer />} />
        </Route>
        <Route
          path="firewall"
          element={<AppFirewall app={app} organizationId={orgId} isLoading={isLoading} project={project} />}
        />
        <Route
          path="connections/*"
          element={
            !isLoading && canReadConnections ? (
              <AppConnections app={app} isLoading={isLoading} />
            ) : (
              <Box fontSize="sm" color="secondary">
                You do not have permission to view this apps integration connections.
              </Box>
            )
          }
        />
        <Route path="settings/*" element={<AppSettings isLoading={isLoading} app={app} />} />
      </Routes>
    </ScrollableMainLayout>
  );
}
