import React, { Fragment } from 'react';
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@hyphen/hyphen-components';
import { Field, FieldArray } from 'formik';
import { FormikSelectInputNative } from '@hyphen/hyphen-components';
import { ProjectRoles } from '../../types/roles';
import { Project } from '../../services/projects';

export const PROJECT_ACCESS_ROLE_OPTIONS = [
  {
    label: 'Project Collaborator',
    value: ProjectRoles.ProjectCollaborator,
  },
  {
    label: 'Project Owner',
    value: ProjectRoles.ProjectOwner,
  },
];

interface ProjectAccessFieldProps {
  values: any;
  projects: Project[];
  isProjectsLoading: boolean;
}

const ProjectAccessField: React.FC<ProjectAccessFieldProps> = ({ values, projects, isProjectsLoading }) => (
  <FieldArray name="projectAccess">
    {({ push, remove }) => (
      <Box gap="md">
        <Box display="block" width="100">
          {values.projectAccess && values.projectAccess.length > 0 ? (
            <Box
              display="grid"
              style={{ gridTemplateColumns: 'minmax(min-content, 200px) 155px 36px' }}
              alignItems="center"
              gap="md"
              background="secondary"
              radius="sm"
              padding="lg"
              fontSize="xs"
            >
              <Box color="secondary">Project</Box>
              <Box color="secondary">Role</Box>
              <Box />
              {values.projectAccess.map((projectAccess: any, index: number) => (
                <Fragment key={index}>
                  <Box fontWeight="semibold">{projectAccess.project.name}</Box>
                  <Box>
                    <Field
                      name={`projectAccess.${index}.role`}
                      component={FormikSelectInputNative}
                      options={PROJECT_ACCESS_ROLE_OPTIONS}
                      label="Role"
                      hideLabel
                      placeholder="Select role"
                      size="sm"
                    />
                  </Box>
                  <Button
                    onClick={() => remove(index)}
                    size="sm"
                    variant="tertiary"
                    iconPrefix="c-remove"
                    aria-label="remove"
                  />
                </Fragment>
              ))}
            </Box>
          ) : (
            <Box
              color="tertiary"
              padding="md"
              background="secondary"
              width="100"
              borderWidth="sm"
              radius="sm"
              fontSize="xs"
            >
              No additional project access.
            </Box>
          )}
        </Box>
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="sm" type="button" iconSuffix="caret-down">
                Select project...
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="overflow-auto mh-8xl">
              {isProjectsLoading ? (
                <Box padding="md">Loading projects&hellip;</Box>
              ) : (
                projects.map((project) => (
                  <DropdownMenuItem
                    disabled={values.projectAccess?.some(
                      (access: { project: { id: string } }) => access.project.id === project.id,
                    )}
                    key={project.id}
                    onSelect={() =>
                      push({
                        project: project,
                        role: ProjectRoles.ProjectCollaborator,
                      })
                    }
                  >
                    {project.name}
                  </DropdownMenuItem>
                ))
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </Box>
    )}
  </FieldArray>
);

export default ProjectAccessField;
