import { Modal, Box, Button, FormikTextInputInset } from '@hyphen/hyphen-components';
import { ApiError } from '../../ApiError';
import { CONNECTION_NAME_MAP } from '../../../constants/integrations';
import { Field, Form, Formik, FormikErrors } from 'formik';
import * as yup from 'yup';
import { useVerifyConnectionMutation } from '../../../services/connections';
import { useCallback, useEffect, useState } from 'react';
import { Connection } from '../../../types/connections';

interface ConnectionVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  connection: Connection;
  code?: string | null;
}

const verifyConnectionSchema = yup.object().shape({
  code: yup
    .string()
    .matches(/^\d{6}$/, 'Invalid verification code')
    .required('Please enter a valid verification code'),
});

type VerifyConnectionSchema = yup.InferType<typeof verifyConnectionSchema>;

export default function ConnectionVerificationModal(props: ConnectionVerificationModalProps) {
  const { isOpen, onClose, connection, code } = props;
  const [verifyConnection, verifyConnectionStatus] = useVerifyConnectionMutation();
  const [apiError, setApiError] = useState<any>(null);
  const [verifiedSuccess, setVerifiedSuccess] = useState(false);

  const handleOnSubmit = useCallback(async (data: VerifyConnectionSchema) => {
    setApiError(null);
    const { error } = await verifyConnection({
      organizationId: connection.organization.id,
      connectionId: connection.id,
      integrationId: connection.organizationIntegration.id,
      code: data.code,
    });

    if (error) {
      setApiError(error);
    } else {
      setVerifiedSuccess(true);
    }
  }, [connection, verifyConnection]);

  // DEVNOTE: Auto-submit if we were deep-linked here
  useEffect(() => {
    if (isOpen && code && connection) {
      handleOnSubmit({ code });
    }
  }, [code, connection, handleOnSubmit, isOpen]);

  useEffect(() => {
    if (connection && connection.status !== 'Verifying') {
      setVerifiedSuccess(true);
    }
  }, [connection]);

  return (
    <Modal ariaLabel="Confirm" isOpen={isOpen} onDismiss={onClose} maxWidth="9xl">
      {verifiedSuccess ? (
        <Box gap={{ base: '2xl', tablet: '4xl' }}>
          <Modal.Header id="verifyConnectionModalHeader" title="Connection Verified" />
          <Modal.Body gap="2xl">
            <Box className="lh-text" display="block">
              The connection has been verified and can now be used.
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onClose} shadow="sm">
              Close
            </Button>
          </Modal.Footer>
        </Box>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{ code: code || '' }}
          validationSchema={verifyConnectionSchema}
          onSubmit={handleOnSubmit}
          validateOnBlur={false}
        >
          {({ isSubmitting, errors, submitForm, values }) => {
            return (
              <ConnectionVerificationForm
                isSubmitting={isSubmitting}
                errors={errors}
                apiError={apiError}
                connection={connection}
                verifyConnectionStatus={verifyConnectionStatus}
                onClose={onClose}
                submitForm={submitForm}
                values={values}
              />
            );
          }}
        </Formik>
      )}
    </Modal>
  );
}

type ConnectionVerificationFormProps = {
  isSubmitting: boolean;
  errors: FormikErrors<VerifyConnectionSchema>;
  apiError: Error | null;
  connection: Connection | undefined;
  verifyConnectionStatus: any;
  onClose: () => void;
  submitForm: () => void;
  values: VerifyConnectionSchema;
}

// DEVNOTE - form is split out to allow for useEffect leveraging Formik provided callback values to auto-submit
function ConnectionVerificationForm(props: ConnectionVerificationFormProps) {
  const { isSubmitting, errors, apiError, connection, verifyConnectionStatus, onClose, submitForm, values } = props;

  useEffect(() => {
    // auto-submit if the user has entered a sixth digit
    if (values.code.toString().length === 6) {
      submitForm();
    }
  }, [submitForm, values.code]);

  return (
    <Form>
      <Box gap={{ base: '2xl', tablet: '4xl' }}>
        <Modal.Header id="verifyConnectionModalHeader" title="Verify Connection" />
        <Modal.Body gap="2xl">
          {!connection ? (
            <ApiError
              error={{ status: 'Error', data: { message: 'Connection not found. It may have been deleted.' } }}
            />
          ) : null}
          {connection && (
            <>
              <Box className="lh-text" childGap="sm" display="block">
                The {CONNECTION_NAME_MAP[connection.type]} already exists. To verify and enable its use, follow these steps:
                <Box as="ol" margin="lg">
                  <li>Check the email for the verification code sent to the {CONNECTION_NAME_MAP[connection.type]} owners.</li>
                  <li>Enter the verification code to complete the connection.</li>
                </Box>
              </Box>
              <Field
                type="number"
                label="Verification Code"
                name="code"
                id="code"
                component={FormikTextInputInset}
                autoComplete="off"
                isDisabled={isSubmitting || verifyConnectionStatus.isLoading}
                error={errors.code}
              />
              {apiError ? <ApiError error={apiError} /> : null}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button isDisabled={isSubmitting || verifyConnectionStatus.isLoading} variant="secondary" onClick={onClose} shadow="sm">
            Cancel
          </Button>
          <Button isLoading={isSubmitting || verifyConnectionStatus.isLoading} type="submit" variant="primary" shadow="sm">
            Verify
          </Button>
        </Modal.Footer>
      </Box>
    </Form>
  );
}