import { Box } from '@hyphen/hyphen-components';
import { ParentSize } from '@visx/responsive';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import DailyUsageBarChart, { BarData } from './DailyUsageBarChart';
import EnvironmentData from './EnvironmentData';

const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

const ProjectToggleChart = ({ chartData, projectId }: { chartData: any; projectId: string }) => {
  const data = chartData.dailyUsage;
  const total30days = chartData.counts.total || 0;

  // the usage total for each environment
  interface EnvironmentCounts {
    [key: string]: number;
  }

  interface DailyUsageEntry {
    date: string;
    counts: {
      environments: EnvironmentCounts;
    };
  }

  const totalSum = useMemo(
    () =>
      data.reduce((acc: EnvironmentCounts, entry: DailyUsageEntry) => {
        Object.entries(entry.counts.environments).forEach(([key, value]) => {
          acc[key] = (acc[key] || 0) + value;
        });
        return acc;
      }, {} as EnvironmentCounts),
    [data],
  );

  const initialBigDisplayValue = useMemo(
    () => ({
      total: total30days,
      dateRange: data[0]?.date
        ? `${formatDate(data[0]?.date)} - ${formatDate(data[data.length - 1]?.date || '')}`
        : '',
      barData: undefined as BarData | undefined,
    }),
    [data, total30days],
  );

  const [bigDisplayValue, setBigDisplayValue] = useState(initialBigDisplayValue);

  useEffect(() => {
    setBigDisplayValue(initialBigDisplayValue);
  }, [initialBigDisplayValue]);

  const resetBigDisplayValue = useCallback(
    () => setBigDisplayValue(initialBigDisplayValue),
    [initialBigDisplayValue],
  );

  return (
    <Box gap="xl">
      <Box gap="xl" alignItems="baseline" fontWeight="bold">
        {bigDisplayValue.barData ? (
          <>
            <Box direction="row" gap="sm" alignItems="baseline">
              <Box fontSize={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}>
                {bigDisplayValue.total.toLocaleString('en-US')}
              </Box>
              <Box fontSize="xs" color="tertiary" fontWeight="semibold">
                {bigDisplayValue.dateRange}
              </Box>
            </Box>
            <Box as="span" display="inline" fontSize="sm" fontWeight="normal">
              <EnvironmentData data={bigDisplayValue.barData.data} projectId={projectId} />
            </Box>
          </>
        ) : (
          <>
            <Box direction="row" gap="sm" alignItems="baseline">
              <Box fontSize={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}>
                {bigDisplayValue.total.toLocaleString('en-US')}
              </Box>
              <Box fontSize="xs" color="tertiary" fontWeight="semibold">
                {bigDisplayValue.dateRange}
              </Box>
            </Box>
            <Box as="span" display="inline" fontSize="sm" fontWeight="normal">
              <EnvironmentData data={totalSum} projectId={projectId} />
            </Box>
          </>
        )}
      </Box>

      <Box width="100" style={{ minHeight: '200px', height: '200px' }}>
        <ParentSize>
          {({ width, height }) => (
            <DailyUsageBarChart
              width={width}
              height={height}
              data={data}
              setBigDisplayValue={setBigDisplayValue}
              resetBigDisplayValue={resetBigDisplayValue}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            />
          )}
        </ParentSize>
      </Box>
    </Box>
  );
};

export default ProjectToggleChart;
