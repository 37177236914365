import { api } from './api';
import { Segment } from '../types/segments';
import { cleanRequest } from '../utils/requestUtils';
import { parseSegmentLogic } from '../utils/parseJsonLogic';

export interface CreateSegmentBody {
  organizationId: string;
  projectId: string;
  name: string;
  alternateId: string;
  description?: string;
  logic?: string;
}
export type UpdateSegmentBody = {
  name?: string;
  description?: string;
  logic?: string;
};

function transformSegment(segment: Segment) {
  const result = {
    ...segment,
    parsedLogic: parseSegmentLogic(segment.logic),
  };

  return result;
}

export const segmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectSegments: builder.query<{ data: Segment[] }, { organizationId: string; projectId: string }>({
      query: ({ organizationId, projectId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/segments/`,
        method: 'GET',
      }),
      transformResponse: (response: { data: Segment[] }) => ({
        data: response.data.map(transformSegment),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Segments' as const, id: id })),
              { type: 'Segments', id: 'Segments-LIST' },
            ]
          : [],
    }),
    createSegment: builder.mutation<Segment, CreateSegmentBody>({
      query: ({ organizationId, projectId, name, alternateId, description }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/segments/`,
        method: 'POST',
        body: {
          name,
          alternateId,
          description,
          logic: '',
          tags: [],
        },
      }),
      invalidatesTags: [{ type: 'Segments', id: 'Segments-LIST' }],
    }),
    getSegment: builder.query<Segment, { organizationId: string; projectId: string; segmentId: string }>({
      query: ({ organizationId, projectId, segmentId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/segments/${segmentId}`,
        method: 'GET',
      }),
      transformResponse: (response: Segment) => transformSegment(response),
      providesTags: (result, _error, { segmentId }) =>
        result ? [{ type: 'Segments', id: result.id || segmentId }] : [],
    }),
    updateSegment: builder.mutation<
      Segment,
      { organizationId: string; projectId: string; segmentId: string; body: UpdateSegmentBody }
    >({
      query: ({ organizationId, projectId, segmentId, body }) => ({
        url: `/api/organizations/${organizationId}/projects/${projectId}/segments/${segmentId}`,
        method: 'PATCH',
        body: cleanRequest(body),
      }),
      invalidatesTags: (result, _error, { segmentId }) => {
        return [
          { type: 'Segments', id: result?.id || segmentId },
          { type: 'Segments', id: 'Segments-LIST' },
        ];
      },
    }),
    deleteSegment: builder.mutation<void, { organizationId: string; projectId: string; segmentId: string }>({
      query: ({ organizationId, projectId, segmentId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/segments/${segmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _error, { segmentId }) => [
        { type: 'Segments', id: segmentId },
        { type: 'Segments', id: 'Segments-LIST' },
      ],
    }),
  }),
});

export const {
  useGetProjectSegmentsQuery,
  useCreateSegmentMutation,
  useGetSegmentQuery,
  useLazyGetSegmentQuery,
  useUpdateSegmentMutation,
  useDeleteSegmentMutation
} = segmentApi;
