import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerProvider,
  DrawerTitle,
  DrawerTrigger,
  toast,
  useIsMobile,
} from '@hyphen/hyphen-components';
import { useGetEventsQuery } from '../../services/events';
import { EventTypeLabel } from '@hyphen/nucleus/dist/types';
import DateTimeDisplay from '../DateTime';
import { useOrganization } from '../../providers/OrganizationProvider';
import { EventReferenceType, EventType, ReferenceOperation } from '../../types/events';
import { Skeleton } from '../common/Skeleton';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-tomorrow.min.css';
import LinkInsetFill from '../common/LinkInsetFill';

export interface EventReference {
  type: EventReferenceType;
  id: string;
}

interface LastEventProps {
  references?: EventReference[];
  eventTypes: EventType[];
}

const LastEvent = ({ references, eventTypes }: LastEventProps) => {
  const { id: orgId } = useOrganization();
  const isMobile = useIsMobile();
  const [retryAttempted, setRetryAttempted] = useState(false);

  const filters = useMemo(
    () => ({
      pageSize: 1,
      types: eventTypes,
      references,
      referenceOperation: ReferenceOperation.And,
    }),
    [eventTypes, references],
  );

  const { data, isLoading, error, refetch } = useGetEventsQuery({
    orgId: orgId || '',
    body: filters,
  });

  const lastEvent = data?.data[0];

  const handleCopyEventData = () => {
    if (lastEvent) {
      navigator.clipboard
        .writeText(JSON.stringify(lastEvent, null, 2))
        .then(() => {
          toast.success('Copied to clipboard');
        })
        .catch(() => {
          toast.error('Failed to copy event data');
        });
    }
  };

  // For new toggle creations, sometimes the page loads before a create event has been written to the database
  useEffect(() => {
    if (!retryAttempted && !isLoading && !error && data?.data?.length === 0) {
      const timer = setTimeout(() => {
        setRetryAttempted(true);
        refetch();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [data, isLoading, error, refetch, retryAttempted]);

  useEffect(() => {
    Prism.highlightAll();
  }, [lastEvent]);

  if (isLoading || !references) {
    return (
      <Box width="100" gap="md">
        <Skeleton height="14px" width="120px" />
        <Skeleton height="16px" width="100px" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box width="100" gap="md">
        Unable to fetch last event
      </Box>
    );
  }

  if (!isLoading && lastEvent === undefined) {
    return (
      <Box gap="md">
        <Box fontSize="xs" color="secondary">
          Last event
        </Box>
        <Box>No event found</Box>
      </Box>
    );
  }

  return (
    <DrawerProvider>
      <Drawer width={isMobile ? '90%' : '60%'}>
        <DrawerHeader>
          <DrawerTitle>Last Event</DrawerTitle>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerContent fontSize="sm">
          <Card style={{ flexShrink: 0 }}>
            <Card.Section gap="md">
              <div>
                {lastEvent && (
                  <>
                    {lastEvent.member.name}
                    {` `}
                    <span className="transform-lowercase">
                      {EventTypeLabel[lastEvent.type as keyof typeof EventTypeLabel]}
                    </span>
                  </>
                )}
              </div>
              <Box fontSize="xs" display="block">
                <DateTimeDisplay value={lastEvent?.timestamp || ''} />
              </Box>
            </Card.Section>
          </Card>
          <Box fontSize="xs"></Box>
          <Box margin="xl 0 0 0" direction="row" gap="md" alignItems="center">
            <Box>Event Data</Box>
            <Button variant="secondary" iconPrefix="copy-document" size="sm" onClick={handleCopyEventData}>
              Copy
            </Button>
          </Box>
          <Box fontSize="xs" background="black" padding="md" alignItems="flex-start" width="100" overflow="auto">
            <pre>
              <code
                className="language-json bw-0"
                dangerouslySetInnerHTML={{
                  __html: lastEvent?.data
                    ? Prism.highlight(JSON.stringify(lastEvent, null, 2), Prism.languages.json, 'json')
                    : '',
                }}
              />
            </pre>
          </Box>
        </DrawerContent>
      </Drawer>
      <Box
        gap="xs"
        alignItems={{ base: 'flex-end', tablet: 'flex-start' }}
        textAlign={{ base: 'right', tablet: 'left' }}
      >
        <DrawerTrigger asChild>
          <Box
            display="block"
            className="tdl-underline"
            cursor="pointer"
            as="button"
            padding="0"
            borderWidth="0"
            background="transparent"
            textAlign="left"
            color="base"
            style={{ textDecorationColor: 'var(--color-font-tertiary)' }}
          >
            <LinkInsetFill />
            {lastEvent && (
              <>
                {lastEvent.member.name}
                {` `}
                <span className="transform-lowercase">
                  {EventTypeLabel[lastEvent.type as keyof typeof EventTypeLabel]}
                </span>
              </>
            )}
          </Box>
        </DrawerTrigger>
        <DateTimeDisplay value={lastEvent?.timestamp || ''} />
      </Box>
    </DrawerProvider>
  );
};

export default LastEvent;
