import { Box, useOpenClose } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ToggleSummary } from '../../types/toggle';
import { ProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';
import UsagePopover from './UsagePopover';
import ToggleUsageStatus from './ToggleUsageStatus';

interface ToggleUsageItemProps {
  orgId: string;
  project: ToggleSummary['project'];
  toggleKey: string;
  toggleData: ToggleSummary['toggles'][string];
  projectEnvironments: ProjectEnvironments;
}

const ToggleUsageItem = ({ orgId, project, toggleKey, toggleData, projectEnvironments }: ToggleUsageItemProps) => {
  const { isOpen, handleOpen, handleClose } = useOpenClose();

  return (
    <Box
      flex="auto"
      className="row-item"
      direction="row"
      borderWidth="sm 0 0 0"
      padding="lg"
      gap="xl"
      alignItems={{ base: 'flex-start', tablet: 'center' }}
    >
      <Box flex="auto" justifyContent="space-between" alignItems="center" gap="md" direction="row">
        <Box direction="row" gap="sm" wrap fontWeight="semibold" alignItems="center" flex="auto">
          <Link to={`/${orgId}/${project.alternateId}/toggles/${toggleKey}`}>{toggleKey}</Link>
          <Box
            as="span"
            fontFamily="monospace"
            fontSize="2xs"
            fontWeight="normal"
            display="inline-block"
            padding="xs"
            color="secondary"
            background="secondary"
            radius="xs"
          >
            {toggleData.type}
          </Box>
        </Box>
        <ToggleUsageStatus toggleData={toggleData} />
      </Box>
      {projectEnvironments && (
        <UsagePopover
          isOpen={isOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          toggleData={toggleData}
          projectEnvironments={projectEnvironments}
          projectId={project.id}
          toggleKey={toggleKey}
        />
      )}
    </Box>
  );
};

export default ToggleUsageItem;
