import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Spinner } from '@hyphen/hyphen-components';
import { useGetIntegrationsQuery } from '../../services/integrations';
import { useOrganization } from '../../providers/OrganizationProvider';
import { getEntityUrl } from '../../utils/getEntityUrl';
import { EntityNames } from '@hyphen/nucleus/dist/types';

export const IntegrationLoader = ({ entityId }: { entityId: string }) => {
  const navigate = useNavigate();
  const { id: orgId } = useOrganization()

  const { data, isLoading, error } = useGetIntegrationsQuery(orgId, { skip: !orgId });

  useEffect(() => {
    if(isLoading) return;

    if (error || !data || !data.data.find(integration => integration.id === entityId)) {
      throw new Response('Not Found', { status: 404 });
    }
    
    if (data?.data.length) {
      const url = getEntityUrl(orgId, EntityNames.OrganizationIntegration, entityId);
      navigate(url, { replace: true });
    }
  }, [data, navigate, orgId, entityId, isLoading, error]);

  return (
    <Box padding="2xl" alignItems="center" justifyContent="center">
      <Spinner size="lg" />
    </Box>
  );
};
