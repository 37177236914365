import { toast, useOpenClose } from '@hyphen/hyphen-components';
import { ConfirmModal } from '../common/ConfirmModal';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteItemCard } from '../common/DeleteItemCard';
import { useProject } from '../../providers/ProjectProvider';
import { Environment } from 'prismjs';
import { useDeleteProjectEnvironmentMutation } from '../../services/environments';

export default function DeleteProjectEnvironment({ environment }: { environment: Environment }) {
  const { project } = useProject();
  const [deleteProjectEnvironment, { error: deleteError, isLoading: isDeleting }] =
    useDeleteProjectEnvironmentMutation();

  const navigate = useNavigate();

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const handleDelete = useCallback(async () => {
    if (!project) return;
    const { error } = await deleteProjectEnvironment({
      projectId: project?.alternateId,
      organizationId: environment.organization.id,
      environmentId: environment.id,
    });
    if (!error) {
      toast.success('Environment deleted');
      navigate(`/${environment.organization.id}/${project?.alternateId}/settings`, { replace: true });
    }
  }, [project, deleteProjectEnvironment, environment.organization.id, environment.id, navigate]);

  return (
    <>
      <DeleteItemCard itemLabel="Project Environment" onDelete={openDeleteModal} />
      <ConfirmModal
        title={`Delete project environment ${environment.name}?`}
        message={
          <>
            <p>The following apps may be impacted. Please be sure.</p>
            <ul>
              {project?.apps?.map((app) => (
                <li key={app.id}>{app.name}</li>
              ))}
            </ul>
          </>
        }
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        error={deleteError}
        customErrorMessages={{ default: 'Delete failed' }}
      />
    </>
  );
}
