import React, { createContext, PropsWithChildren, useContext } from 'react';
import { Environment, useGetProjectEnvironmentsQuery } from '../services/environments';
import { ListResponse } from '../services/types';
import { useOrganization } from './OrganizationProvider';
import { useParams } from 'react-router-dom';
import { App, useGetAppsQuery } from '../services/apps';
import { Project, useGetProjectQuery } from '../services/projects';
import { useGetProjectSegmentsQuery } from '../services/segments';

interface IProjectContext {
  isLoading: boolean;
  error: any;
  project?: Project;
}

const ProjectContext = createContext<IProjectContext>({
  isLoading: true,
  error: undefined,
  project: undefined,
});

export interface ProjectProviderProps extends PropsWithChildren {}

export const ProjectProvider = ({ children }: ProjectProviderProps) => {
  const { id: orgId } = useOrganization();
  const { projectId } = useParams<{ projectId: string }>();

  const {
    data: project = { alternateId: projectId } as Project,
    error: projectError,
    isLoading: projectIsLoading,
  } = useGetProjectQuery({ projectId: projectId ?? '', organizationId: orgId ?? '' });

  const {
    data: apps = {} as ListResponse<App>,
    error: appsError,
    isLoading: appsIsLoading,
  } = useGetAppsQuery({ organizationId: orgId, projectIds: projectId }) || {};

  const {
    data: environments = {} as ListResponse<Environment>,
    error: environmentsError,
    isLoading: environmentsIsLoading,
  } = useGetProjectEnvironmentsQuery({
    organizationId: orgId,
    projectId: projectId,
  }) || {};
  const environmentsData = environments?.data || [];

  const {
    data: segmentsData,
    isLoading: isSegmentsLoading,
    error: segmentsError,
  } = useGetProjectSegmentsQuery(
    {
      organizationId: orgId,
      projectId: project?.alternateId || '',
    },
    { skip: projectIsLoading },
  );

  const projectData = {
    ...project,
    apps: apps.data,
    environments: environmentsData,
    segments: segmentsData?.data,
  };

  const isLoading = projectIsLoading || appsIsLoading || environmentsIsLoading || isSegmentsLoading;
  const error = projectError || appsError || environmentsError || segmentsError;

  return (
    <ProjectContext.Provider
      value={{
        isLoading,
        error,
        project: projectData,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProject must be used within a ProjectProvider');
  }
  return context;
};
