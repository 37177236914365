import { Box, Button } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { MemberList } from '../../components/members/MemberList';
import { InviteMemberModal } from '../../components/members/InviteMemberModal';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import OrgMemberDrawer from '../../components/members/OrgMemberDrawer';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import { OrganizationRoles } from '../../types/roles';
import { useAuth } from '../../components/auth/authProvider';
import { useMemo } from 'react';

export default function Members() {
  const { organization = {} as Organization } = useOrganization();
  const { user } = useAuth();

  const isOrgAdmin = useMemo(() => {
    return user?.memberships
      .find((m) => m.organization.id === organization.id)
      ?.organizationRoles.includes(OrganizationRoles.OrganizationAdmin);
  }, [user, organization.id]);

  return (
    <Box width="100" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Members</title>
      </Helmet>
      <PageHeader title="Members" description={`Who has access to the organization`} />
      <BreadCrumbPortal />
      {isOrgAdmin && (
        <Button asChild variant="primary" iconPrefix="add">
          <Link to="invite">Invite Member</Link>
        </Button>
      )}

      <Routes>
        <Route path="/*" element={<MemberList />}>
          {isOrgAdmin && <Route path="invite" element={<InviteMemberModal organization={organization} />} />}
          <Route path=":memberId/*" element={<OrgMemberDrawer />} />
        </Route>
      </Routes>
    </Box>
  );
}
