import { Box } from '@hyphen/hyphen-components';

export default function DynamicTeamCriteria({ criteria }: { criteria: any[] }) {
  const content = criteria.map((criteria, index: number) => (
    <Box key={index} direction="row" gap="xl" alignItems="center">
      {index > 0 && (
        <Box width="4xl" padding="0 lg" fontFamily="monospace" color="secondary">
          AND
        </Box>
      )}
      <Box
        padding={index === 0 && criteria!.length > 1 ? 'md md md 5xl' : 'md xl'}
        direction={{ base: 'column', tablet: 'row' }}
        flex="auto"
        gap="md"
        background="secondary"
        radius="md"
        fontFamily="monospace"
      >
        <Box>{criteria.type === 'property' ? criteria.field : criteria.type}</Box>
        <Box fontFamily="monospace" style={{ textTransform: 'uppercase' }}>
          {criteria.type === 'property'
            ? criteria.operator
            : criteria.conjunction === 'and'
            ? 'includes'
            : 'is either'}
        </Box>
        <Box flex="auto">{criteria.type === 'property' ? criteria.value : criteria.values.join(', ')}</Box>
      </Box>
    </Box>
  ));

  return <Box gap="sm">{content}</Box>;
}
