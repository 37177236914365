import { Helmet } from 'react-helmet';
import { Card, Pagination } from '@hyphen/hyphen-components';
import { TeamDetails } from '../../../types/teams';
import useQueryStringState from '../../../hooks/useQueryStringState';
import { useGetTeamAccessQuery } from '../../../services/teams';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { ApiError } from '../../../components/ApiError';
import { useCallback, useMemo } from 'react';
import { AccessEntry } from '../../../services/types';
import AccessList from '../../../components/access/AccessList';
import { Outlet } from 'react-router-dom';

export default function TeamAccess({ team }: { team: TeamDetails }) {
  const { id: orgId } = useOrganization();
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });

  const {
    data: access,
    isLoading,
    error,
  } = useGetTeamAccessQuery({
    teamId: team.id,
    organizationId: orgId,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize,
  });

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState({ pageNum, pageSize: qsState.pageSize });
    },
    [qsState.pageSize, setQsState],
  );

  const computedTotalItems = useMemo(() => (access?.total ? access.total - 1 : 0), [access]);

  const accessWithType = useMemo(() => {
    return (
      (access?.data || [])
        .map((item: AccessEntry) => ({
          ...item,
          accessType: item.entity.type === 'Organization' ? 'inherited' : 'direct',
        }))
        // don't show the team (itself) in the list
        .filter((item: AccessEntry) => item.assignment.id !== team.id)
        .sort((a: any, b: any) => (a.accessType === 'direct' ? -1 : 1))
    );
  }, [access, team.id]);

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <>
      <Helmet title={`${team.name || 'Loading'} Access`} />
      <Card>
        <Card.Header
          title="Access"
          description="Organization members who can manage or view the team, but aren't necessarily members of the team."
          alignItems="center"
        />
        <Card.Section padding="0" position="relative">
          {!isLoading && access && <AccessList access={accessWithType} />}
        </Card.Section>
        {computedTotalItems > qsState.pageSize && (
          <Card.Section color="secondary">
            <Pagination
              activePage={qsState.pageNum}
              itemsPerPage={qsState.pageSize}
              onChange={handlePageNumberChange}
              totalItemsCount={computedTotalItems}
              numberOfPagesDisplayed={3}
              isCompact
            />
          </Card.Section>
        )}
      </Card>
      <Outlet />
    </>
  );
}
