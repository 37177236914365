import { Box, Icon } from '@hyphen/hyphen-components';
import React from 'react';
import { ParsedCondition } from '../../utils/parseJsonLogic';

export const ContextList: React.FC<{ contexts: ParsedCondition[] }> = React.memo(({ contexts }) => (
  <Box as="ol" flex="auto" width="100" padding="0" radius="sm">
    {contexts.map(({ key, operator, value }, index) => {
      if (operator === 'segment') {
        return <SegmentContextListItem key={index} contextKey={key} operator={operator} value={value} />;
      }
      return <ContextListItem key={index} contextKey={key} operator={operator} value={value} />;
    })}
  </Box>
));

const SegmentContextListItem: React.FC<any> = React.memo(({ contextKey, value }) => (
  <Box
    as="li"
    direction="row"
    gap="sm"
    alignItems="center"
    padding="sm md"
    className="row-item scroll-bar-thin"
    borderColor="subtle"
    borderWidth="sm 0 0 0"
    overflow="auto"
    background="secondary"
    radius="xs"
    style={{ whiteSpace: 'nowrap' }}
  >
    <Icon name="path-intersect" color="secondary" size="md" style={{ flexShrink: '0' }} />
    <Box display="block">
      <span className="font-color-info">{contextKey.charAt(0).toUpperCase() + contextKey.slice(1)}</span> segment{' '}
      {value}
    </Box>
  </Box>
));

const ContextListItem: React.FC<any> = React.memo(({ contextKey, operator, value }) => {
  return (
    <Box
      as="li"
      direction="row"
      gap="sm"
      alignItems="baseline"
      padding="sm md"
      className="row-item scroll-bar-thin"
      borderColor="subtle"
      borderWidth="sm 0 0 0"
      overflow="auto"
      background="secondary"
      radius="xs"
      style={{ whiteSpace: 'nowrap' }}
    >
      {contextKey === 'unknown' ? (
        <Box gap="sm" direction="column">
          <Box>unknown JSON logic</Box>
          <code>{value}</code>
        </Box>
      ) : (
        <>
          <Box display="block">
            {contextKey}
            {` `}
            <span className="font-color-info">{operator}</span>
            {` `}
            {value}
          </Box>
        </>
      )}
    </Box>
  );
});
