import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Box, Button, Card, FormikSelectInputNative, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateAppMutation } from '../../services/apps';
import { DependentFormikField } from '../common/form/DependentFormikField';
import { ApiError } from '../ApiError';
import { useGetProjectsQuery } from '../../services/projects';
import { generateSlug } from '../../utils/generateSlug';
import { forbiddenWords } from '../../utils/validations';
import { useGetIntegrationsQuery } from '../../services/integrations';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames } from '@hyphen/nucleus/dist/types';
import { useCallback, useMemo } from 'react';
import TooltipIcon from '../common/TooltipIcon';
import IntegrationConnectionsField from '../integrations/IntegrationConnectionsField';
import { useCreateConnectionMutation } from '../../services/connections';
import { ConnectionType } from '@hyphen/nucleus/dist/types/connections';

const createAppSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Alternate ID')
    .notOneOf(forbiddenWords, `Can not contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
  projectId: yup.string().required('Project is required'),
  integrations: yup.array().of(
    yup.object().shape({
      id: yup.string().required('Integration ID is required'),
      input: yup.string().when('id', {
        is: (id: string) => id.includes('CodeRegistry'),
        then: (schema) => schema.required('Name of existing package is required'),
        otherwise: (schema) => schema,
      }),
    }),
  ),
});

type CreateAppSchema = InferType<typeof createAppSchema>;

export const CreateAppForm = ({ organizationId }: { organizationId: string }) => {
  const navigate = useNavigate();
  const [createEnvApp, { error }] = useCreateAppMutation();
  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery(organizationId);
  const [CreateConnection, { error: createConnectionError }] = useCreateConnectionMutation();
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get('projectId') ?? undefined;

  const { data, isLoading } = useGetProjectsQuery({ pageSize: 100, pageNum: 1, organizationId: organizationId });

  const handleCreateIntegrationConnection = useCallback(
    async (values: CreateAppSchema, appId: string) => {
      if (values.integrations) {
        const integrations = values.integrations.map((integration) => {
          if (integration) {
            const [integrationId, connectionType] = integration.id.split('-');
            return { integrationId, connectionType: connectionType, input: integration.input };
          }
          return { integrationId: '', connectionType: '' };
        });

        try {
          await Promise.all(
            integrations.map((integration) =>
              CreateConnection({
                organizationId: organizationId,
                integrationId: integration.integrationId,
                connection: {
                  type: integration.connectionType as ConnectionType,
                  entity: {
                    id: appId,
                  },
                  input: integration.input,
                },
              }),
            ),
          );
        } catch (error) {
          toast.error('Integration connection failed');
        }
      }
    },
    [CreateConnection, organizationId],
  );

  const handleCreateApp = useCallback(
    async (values: CreateAppSchema) => {
      try {
        const body = {
          name: values.name,
          alternateId: values.alternateId,
        };
        toast('Creating app...', { isCompact: true });
        const { error, data } = await createEnvApp({ organizationId, projectId: values.projectId, body });
        if (!error && data) {
          if (values.integrations?.length) {
            toast('Connecting integrations...', { isCompact: true });
            await handleCreateIntegrationConnection(values, data.id);
          }
          navigate(`/${organizationId}/${data.project.alternateId}/app/${data.alternateId}`);
          toast.success('App created', { duration: 5000 });
        } else {
          toast.error('Create app failed');
        }
      } catch (e) {
        toast.error('Create app failed');
      }
    },
    [createEnvApp, handleCreateIntegrationConnection, navigate, organizationId],
  );

  const memoizedIntegrations = useMemo(() => integrations?.data ?? [], [integrations]);

  const projectOptions =
    data?.data.map((project) => ({
      label: project.name,
      value: project.id,
    })) ?? [];

  const initialValues: CreateAppSchema = {
    projectId: projectId ?? data?.data[0].id ?? '',
    name: '',
    alternateId: '',
    integrations: [],
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={createAppSchema}
      onSubmit={handleCreateApp}
    >
      {({ errors, isSubmitting }) => (
        <Form noValidate>
          <Box gap="4xl">
            <Field
              label="Project"
              name="projectId"
              id="projectId"
              options={projectOptions}
              component={FormikSelectInputNative}
              error={errors.projectId}
              isRequired
              isDisabled={isLoading}
            />
            <Field id="name" name="name" label="Name" component={FormikTextInput} error={errors.name} isRequired />
            <DependentFormikField
              id="alternateId"
              helpText="Used as the name on the command line, alphanumeric and dashes only, no spaces"
              name="alternateId"
              label="Alternate ID"
              component={FormikTextInput}
              error={errors.alternateId}
              isRequired
              dependentField="name"
              targetField="alternateId"
              generateValue={generateSlug}
            />
            <Card>
              <Card.Section fontSize="sm" gap="xl">
                {!isIntegrationsLoading && memoizedIntegrations && (
                  <>
                    <Box gap="sm">
                      <Box direction="row" gap="xs" alignItems="center">
                        App Integrations
                        <TooltipIcon
                          content="Create integration connections for the app with available organization integrations."
                          name="c-info"
                        />
                      </Box>
                      <Box as="p" color="secondary">
                        Select which integrations the app should use, and we’ll create an instance that uses the
                        app's name. Optionally, provide a reference to an existing integration and we'll connect to
                        it.
                      </Box>
                    </Box>
                    <Box gap="xs">
                      <IntegrationConnectionsField
                        integrations={memoizedIntegrations}
                        connectionTypes={ENTITY_CONNECTION_TYPE_MAP[EntityNames.App]}
                        isSubmitting={isSubmitting}
                        errors={errors}
                      />
                    </Box>
                  </>
                )}
              </Card.Section>
            </Card>
            <Box direction="row" gap="sm">
              <Button variant="primary" isLoading={isSubmitting} type="submit">
                Create App
              </Button>
              <Button variant="tertiary" isDisabled={isSubmitting} onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Box>
            {(error || createConnectionError) && <ApiError error={error || createConnectionError} />}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
