import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Helmet } from 'react-helmet';

import { Environment } from '../../services/environments';

interface EnvironmentConnectionsProps {
  environment: Environment;
  orgId: string;
}

export default function EnvironmentConnections({ environment, orgId }: EnvironmentConnectionsProps) {
  return (
    <>
      <Helmet title={`${environment.name} Integration Connections`} />
      <ConnectionsList
        organizationId={orgId}
        filter={{
          entity: {
            type: 'ProjectEnvironment',
            id: environment.id,
            name: environment.name,
          },
          entityType: 'ProjectEnvironment',
        }}
      />
    </>
  );
}
