import { Helmet } from 'react-helmet';

import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import CreateTeamForm from '../../components/teams/CreateTeamForm';

export const CreateTeam = () => {
  return (
    <ScrollableMainLayout>
      <Helmet title="Create Team" />
      <PageHeader
        title="Create Team"
        description="Use teams to group organization members together to manage access to resources and for sending notifications"
      />
      <BreadCrumbPortal />
      <CreateTeamForm />
    </ScrollableMainLayout>
  );
};
