import { Box, Card } from '@hyphen/hyphen-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { ToggleType } from '../../types/toggle';

const DefaultReturnValueCard = React.memo(
  ({
    returnType = ToggleType.Boolean,
    defaultReturnValue,
    canUpdateToggle = false,
  }: {
    returnType?: ToggleType;
    defaultReturnValue: any;
    canUpdateToggle?: boolean;
  }) => (
    <Box as="li" direction="row" alignItems="center" gap="lg">
      <Card shadow="0" radius="sm">
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          alignItems={{ tablet: 'center' }}
          padding="xl"
          gap={{ base: 'md', tablet: '3xl', desktop: '4xl' }}
        >
          <Box
            display="inline-block"
            radius="xs"
            background="tertiary"
            padding="sm"
            className="transform-uppercase"
            fontFamily="monospace"
            style={{ whiteSpace: 'nowrap' }}
          >
            {canUpdateToggle ? (
              <Link to="settings" className="tdl-underline">
                Default Return Value
              </Link>
            ) : (
              'Default Return Value'
            )}
          </Box>
          <Box
            padding="md"
            as="code"
            background="primary"
            flex="auto"
            fontFamily="monospace"
            overflow="auto"
            color={
              returnType === ToggleType.Boolean && defaultReturnValue === true
                ? 'success'
                : returnType === ToggleType.Boolean && defaultReturnValue === false
                ? 'danger'
                : 'base'
            }
          >
            {defaultReturnValue !== undefined ? defaultReturnValue.toString() : 'N/A'}
          </Box>
        </Box>
      </Card>
    </Box>
  ),
);

export default DefaultReturnValueCard;
