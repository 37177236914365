import { useDeleteTeamMutation } from '../../services/teams';
import {
  Badge,
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  toast,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ConfirmModal } from '../common/ConfirmModal';
import { useCallback } from 'react';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { TeamDetails } from '../../types/teams';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

export const TeamItem = ({ team }: { team: TeamDetails }) => {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const [deleteTeam, { isLoading: isDeleting, error }] = useDeleteTeamMutation();
  const { organization = {} as Organization } = useOrganization();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteTeam({ orgId: organization.id, id: team.id });
    if (!error) {
      toast.success('Team removed!');
      closeDeleteModal();
    }
  }, [closeDeleteModal, organization.id, team.id, deleteTeam]);

  const ability = useOrganizationAbilityContext();
  const canManageThisTeam = ability.can('manage', team);
  const canManageAnyTeam = ability.can('manage', EntityNames.Team);

  const isDynamicTeam = team.type === 'dynamic';

  return (
    <>
      <Box className="row-item" borderWidth="sm 0 0 0" borderColor="subtle">
        <Box direction="row" alignItems="center" padding="sm 2xl" gap="2xl">
          <Box
            flex="auto"
            direction={{ base: 'column', tablet: 'row' }}
            gap={{ base: 'sm', tablet: '2xl' }}
            alignItems={{ base: 'flex-start', tablet: 'center' }}
            justifyContent="space-between"
          >
            <Box gap="sm" flex="auto" alignItems="flex-start">
              <Box fontSize="sm" fontWeight="bold" alignItems="center" direction="row" gap="lg">
                <Link to={team.id}>{team.name.replace(/\s/g, '-')}</Link>
                {isDynamicTeam && <Badge message="dynamic" variant="purple" size="sm" />}
              </Box>
            </Box>
            <Box
              fontSize="xs"
              direction="row"
              gap="2xl"
              justifyContent={{ tablet: 'space-between' }}
              width={{ base: '100', tablet: '7xl' }}
            >
              <Box color="secondary">
                {`${team.memberCount.toString()} member${team.memberCount !== 1 ? 's' : ''}`}
              </Box>
            </Box>
          </Box>
          {canManageThisTeam ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="open menu" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" side="bottom">
                <DropdownMenuGroup>
                  <DropdownMenuItem asChild>
                    <Link to={`${team.id}/settings`}>
                      <Icon name="settings" /> <span>Settings</span>
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                    <Icon name="c-remove" /> <span>Delete</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            canManageAnyTeam && <Box width="4xl"></Box>
          )}
        </Box>
      </Box>
      <ConfirmModal
        message={`Are you sure you want to delete ${team.name}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete ${team.name}`}
        error={error}
      />
    </>
  );
};
