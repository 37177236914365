import { useOrganization } from '../../providers/OrganizationProvider';
import { App } from '../../services/apps';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { subject } from '@casl/ability';
import { EntityNames } from '../../types/executionContext';
import { useMemo } from 'react';
import Environments from './Environments';
import EnvironmentsWithSecrets from './EnvironmentsWithSecrets';

export const EnvsList = ({ app, isLoading }: { app?: App; isLoading: boolean }) => {
  const { id: orgId } = useOrganization();
  const ability = useOrganizationAbilityContext();

  const canReadEnv = useMemo<boolean>(() => {
    if (app) {
      return ability.can(
        'read',
        subject(EntityNames.Env, { project: { id: app.project.id }, organization: { id: orgId } }),
      );
    }
    return false;
  }, [ability, app, orgId]);

  return canReadEnv ? (
    <EnvironmentsWithSecrets isLoading={isLoading} app={app} organizationId={orgId} />
  ) : (
    <Environments app={app} organizationId={orgId} isLoading={isLoading} />
  );
};
