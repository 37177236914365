import { forwardRef } from 'react';
import { Box, BoxProps } from '@hyphen/hyphen-components';
import { ReactNode } from 'react';

interface ScrollableMainLayoutProps extends BoxProps {
  fullWidth?: boolean;
  children: ReactNode;
}

export const ScrollableMainLayout = forwardRef<HTMLDivElement, ScrollableMainLayoutProps>(
  (
    {
      fullWidth = false,
      children,
      width = '100',
      padding = { base: '3xl', tablet: '5xl' },
      gap = { base: '2xl', tablet: '4xl' },
      ...restProps
    },
    ref,
  ) => {
    return (
      <Box width="100" height="100" overflow="auto" className="scroll-bar-thin">
        <Box
          ref={ref}
          maxWidth={fullWidth ? '100%' : '10xl'}
          width={width}
          padding={padding}
          gap={gap}
          margin="0 auto"
          position="relative"
          {...restProps}
        >
          {children}
        </Box>
      </Box>
    );
  },
);
