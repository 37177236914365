import { Box, Modal, FormikSelectInputNative, Button } from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import { ProjectRoles } from '../../types/roles';
import { ApiError } from '../ApiError';
import { PROJECT_ACCESS_ROLE_OPTIONS } from './ProjectAccessField';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  projectId: Yup.string().required('Project is required'),
  role: Yup.mixed<ProjectRoles>().required('Role is required'),
});

export type AddProjectAccessFormSchema = Yup.InferType<typeof validationSchema>;

export interface ProjectAccessFormProps {
  initialValues: AddProjectAccessFormSchema;
  onSubmit: (values: AddProjectAccessFormSchema) => Promise<void>;
  projectOptions: { value: string; label: string }[];
  giveAccessError: any;
  handleClose: () => void;
  isSubmitting: boolean;
  isLoading: boolean;
  teamName: string;
}

export const ProjectAccessForm: React.FC<ProjectAccessFormProps> = ({
  initialValues,
  onSubmit,
  projectOptions,
  giveAccessError,
  handleClose,
  isSubmitting,
  isLoading,
  teamName,
}) => (
  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ errors }) => (
      <Form noValidate>
        <Box gap="2xl">
          <Modal.Header id="addAccessHeader" onDismiss={handleClose} title="Assign Project Access" />
          <Modal.Body overflow="visible">
            <Box gap="2xl">
              <Box display="block">
                Team: <strong>{teamName}</strong>
              </Box>
              <Field
                name="projectId"
                component={FormikSelectInputNative}
                options={projectOptions}
                label="Project"
                placeholder="Select a project"
                error={errors.projectId}
              />
              <Field
                name="role"
                component={FormikSelectInputNative}
                options={PROJECT_ACCESS_ROLE_OPTIONS}
                label="Access Role"
                placeholder="Select a role"
                error={errors.role}
              />
              {giveAccessError && <ApiError error={giveAccessError} />}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button isDisabled={isLoading} variant="secondary" onClick={handleClose} shadow="sm">
              Cancel
            </Button>
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Box>
      </Form>
    )}
  </Formik>
);
