import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useGetMemberQuery } from '../services/member';
import { useOrganization } from './OrganizationProvider';
import { useGetExecutionContextQuery } from '../services/executionContext';
import { Organization } from '../services/organization';
import { Member } from '../types/members';
import { OpenFeature } from '@openfeature/react-sdk';
import { HyphenEvaluationContext } from '@hyphen/openfeature-web-provider';
import { useAuth } from '../components/auth/authProvider';

interface IOrganizationMemberContext {
  isLoading: boolean;
  member: Member | undefined;
}

interface MemberState {
  id: string | null;
  orgId: string | null;
}

export const OrganizationMemberContext = createContext<IOrganizationMemberContext>({
  isLoading: true,
  member: undefined,
});

export const OrganizationMemberProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const { organization = {} as Organization } = useOrganization();
  const { user } = useAuth();
  const [memberState, setMemberState] = useState<MemberState>({ id: null, orgId: null });

  const { data: executionContextData, isLoading: isExecutionContextLoading } = useGetExecutionContextQuery(
    organization.id,
    {
      skip: !organization.id,
    },
  );

  useEffect(() => {
    if (executionContextData?.member.id) {
      setMemberState({
        id: executionContextData.member.id,
        orgId: executionContextData.member.organization.id,
      });
    }
  }, [executionContextData]);


  useEffect(() => {
    if (!executionContextData || !user) return;
    const membership = user?.memberships.find((m) => m.organization.id === executionContextData.member.organization.id);
    const currentToggleContext = OpenFeature.getContext() as HyphenEvaluationContext;
    OpenFeature.setContext({
      ...currentToggleContext,
      targetingKey: membership?.id || 'unknown',
      user: {
        id: membership?.id,
        email: membership?.email || '',
        customAttributes: {
          organization: membership?.organization,
        }
      }
    } as any);
  }, [executionContextData, user]);

  const { data: member, isLoading: isMemberLoading } = useGetMemberQuery(
    { memberId: memberState.id || '', organizationId: organization.id },
    { skip: !memberState.id || memberState.orgId !== organization.id },
  );

  const value = useMemo(() => {
    return {
      isLoading: isExecutionContextLoading || isMemberLoading,
      member,
    };
  }, [isExecutionContextLoading, isMemberLoading, member]);

  return <OrganizationMemberContext.Provider value={value}>{children}</OrganizationMemberContext.Provider>;
};

export const useOrganizationMember = () => useContext(OrganizationMemberContext);
