import { Box, Button, Card, Icon } from '@hyphen/hyphen-components';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '../common/Skeleton';
import { useSegment } from '../../providers/SegmentProvider';
import { SEGMENT_TEXT } from '../../constants/segments';

const INITIAL_MAX_ITEMS = 10;

function SegmentUsageList() {
  const { segment, isLoading } = useSegment();
  const [showAll, setShowAll] = useState(false);
  const segmentInUse = useMemo(() => segment && segment.usages?.toggles.length > 0, [segment]);

  const visibleToggles = useMemo(
    () =>
      segment && segmentInUse && showAll
        ? segment.usages.toggles
        : segment && segmentInUse
        ? segment.usages?.toggles.slice(0, INITIAL_MAX_ITEMS)
        : [],
    [segment, segmentInUse, showAll],
  );

  const headerDescription = useMemo(() => {
    if (isLoading) return <Skeleton width="200px" height="20px" />;

    if (segmentInUse) return SEGMENT_TEXT.segmentInUse;

    return (
      <Box color="tertiary" fontSize="sm" display="block">
        {`${SEGMENT_TEXT.segmentNotInUse} `}
        <Link to={`/${segment?.organization.id}/${segment?.project.alternateId}/toggles`}>Project Toggles</Link>
      </Box>
    );
  }, [isLoading, segmentInUse, segment]);

  return (
    <Card>
      <Card.Header description={headerDescription} padding={{ base: 'lg', tablet: '2xl' }} borderWidth="0 0 sm 0" />
      {!isLoading && segmentInUse && (
        <>
          <Card.Section padding="0" className="fade-in">
            {visibleToggles.map((toggle) => (
              <Box
                key={toggle.key}
                flex="auto"
                className="row-item"
                direction={{ base: 'column', tablet: 'row' }}
                borderWidth="sm 0 0 0"
                padding={{ base: 'md lg', tablet: 'md 2xl' }}
                gap="2xl"
                alignItems={{ base: 'flex-start', tablet: 'center' }}
                fontSize="sm"
              >
                <Box direction="row" gap="md" wrap fontWeight="medium" alignItems="center">
                  <Icon name="logo-toggle" size="md" color="tertiary" />
                  <Link to={`/${segment?.organization.id}/${segment?.project.alternateId}/toggles/${toggle.key}`}>
                    {toggle.key}
                  </Link>
                  <Box
                    as="span"
                    fontFamily="monospace"
                    fontSize="xs"
                    fontWeight="normal"
                    display="inline-block"
                    padding="xs"
                    color="secondary"
                    background="secondary"
                    radius="xs"
                  >
                    {toggle.type}
                  </Box>
                </Box>
              </Box>
            ))}
          </Card.Section>
          {segment?.usages?.toggles && segment.usages.toggles.length > INITIAL_MAX_ITEMS && (
            <Card.Section display="flex" alignItems="flex-start" direction="column">
              <Button onClick={() => setShowAll(!showAll)} variant="secondary" size="sm">
                {showAll ? 'Show Less' : `Show All (${segment?.usages?.toggles.length})`}
              </Button>
            </Card.Section>
          )}
        </>
      )}
    </Card>
  );
}

export default SegmentUsageList;
