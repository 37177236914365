import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  toast,
  useBreakpoint,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { ConfirmModal } from '../common/ConfirmModal';
import { App, useUpdateAppSettingsMutation } from '../../services/apps';
import { buildUpdatedAccessControls, getAccessControlsBefore } from '../../pages/Organization/Apps/AppFirewall';
import { Environment } from '../../services/environments';
import { ApiError } from '../ApiError';
import FirewallRuleModal from './FirewallRuleModal';
import { EditCidrAccessControlSchema } from './AddFirewallRuleButton';

interface EnvironmentRuleListProps {
  rules: { description: string; cidr: string; type?: string }[];
  app: App;
  environment?: Environment;
  isDefault: boolean;
  type: 'allow' | 'deny';
}

const EnvironmentRuleList: React.FC<EnvironmentRuleListProps> = ({ rules, app, isDefault, environment, type }) => {
  return (
    <Box gap="xs" padding="md 0">
      {rules.length > 0 ? (
        rules.map((rule, index) => (
          <EnvironmentRuleListItem
            key={index}
            rule={rule}
            app={app}
            isDefault={isDefault}
            environment={environment}
            index={index}
            type={type}
          />
        ))
      ) : (
        <Box color="disabled">&mdash;</Box>
      )}
    </Box>
  );
};

export default EnvironmentRuleList;

interface EnvironmentRuleListItemProps {
  rule: { description: string; cidr: string; type?: string };
  app: App;
  environment: Environment | undefined;
  isDefault: boolean;
  index: number;
  type: 'allow' | 'deny';
}

function EnvironmentRuleListItem(props: EnvironmentRuleListItemProps) {
  const { rule, app, isDefault, index, environment, type } = props;
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();
  const [updateAppSettings, { isLoading: appUpdateLoading, error }] = useUpdateAppSettingsMutation();
  const [isUpdated, setIsUpdated] = useState(false);
  const { isPhone } = useBreakpoint();

  const handleEdit = useCallback(
    async (data: EditCidrAccessControlSchema): Promise<{ error?: any }> => {
      const isDefault = data.environmentId === 'default';
      const accessControlsBefore = getAccessControlsBefore(app, isDefault, data.environmentId);
      const listBefore = accessControlsBefore[data.type] || [];
      const updatedList = [
        ...listBefore.slice(0, index),
        { type: 'cidr' as const, description: data.description, cidr: data.cidr },
        ...listBefore.slice(index + 1),
      ];

      const updatedAccessControls = buildUpdatedAccessControls(
        accessControlsBefore,
        data.type,
        updatedList,
        isDefault,
        data.environmentId,
      );

      try {
        const results = await updateAppSettings({
          organizationId: app.organization.id,
          appId: app.id,
          body: { settings: { env: { accessControls: updatedAccessControls } } },
        });

        if (!results.error) {
          toast.success('Rule updated');
          setIsUpdated(true);
          setTimeout(() => setIsUpdated(false), 3000); // Remove highlight after 3 seconds
          closeEditModal();
          return { error: undefined };
        }

        return { error: results.error };
      } catch (error) {
        return { error };
      }
    },
    [app, closeEditModal, index, updateAppSettings],
  );

  const handleDelete = useCallback(async () => {
    const accessControlsBefore = getAccessControlsBefore(app, isDefault, environment?.alternateId);
    const listBefore = accessControlsBefore[type] || [];
    const updatedList = [...listBefore.slice(0, index), ...listBefore.slice(index + 1)];

    const updatedAccessControls = buildUpdatedAccessControls(
      accessControlsBefore,
      type,
      updatedList,
      isDefault,
      environment?.alternateId,
    );

    try {
      const results = await updateAppSettings({
        organizationId: app.organization.id,
        appId: app.id,
        body: { settings: { env: { accessControls: updatedAccessControls } } },
      });

      if (!results.error) {
        toast.success('Rule removed');
        closeDeleteModal();
        return { error: undefined };
      }

      return { error: results.error };
    } catch (error) {
      return { error };
    }
  }, [app, closeDeleteModal, environment?.alternateId, index, isDefault, type, updateAppSettings]);

  const selectedEnvironmentId = environment?.alternateId || 'default';

  return (
    <>
      <Box
        className={`group ${isUpdated ? 'update-highlight' : ''}`}
        direction="row"
        gap="lg"
        justifyContent="flex-start"
        alignItems="center"
        cursor="pointer"
        hover={{ background: 'secondary' }}
        padding="2xs"
        radius="xs"
      >
        <Box
          as="button"
          type="button"
          onClick={openEditModal}
          padding="0"
          background="transparent"
          borderWidth="0"
          textAlign="left"
          color="base"
          flex="auto"
          alignContent="center"
          cursor="pointer"
        >
          {rule.description} - {rule.cidr}
        </Box>
        <Box
          display="block"
          width="36px"
          height="32px"
          {...(!isPhone ? { className: 'opacity-0 group-hover:opacity-100 transition-opacity' } : {})}
        >
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="sm" variant="tertiary" iconPrefix="dots" aria-label="open menu" />
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="end">
              <DropdownMenuItem onSelect={openEditModal}>Edit</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={openDeleteModal}>Delete</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </Box>
      </Box>
      <FirewallRuleModal
        isOpen={isEditModalOpen}
        handleClose={closeEditModal}
        selectedType={type}
        selectedEnvironmentId={selectedEnvironmentId}
        error={error}
        handleSave={handleEdit}
        handleCancel={closeEditModal}
        initialValues={{
          description: rule.description,
          cidr: rule.cidr,
          environmentId: selectedEnvironmentId,
          type: type,
        }}
      />
      <ConfirmModal
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={appUpdateLoading}
        title="Delete Firewall Rule?"
        message={
          <>
            {isDefault ? 'This will also delete the rule from all environments' : undefined}
            {error && <ApiError error={error} title="Error updating access control rules" />}
          </>
        }
      />
    </>
  );
}
