import { Box, BoxProps } from '@hyphen/hyphen-components';
import { ReactNode } from 'react';
import { Skeleton } from './Skeleton';
import TooltipIcon from './TooltipIcon';

interface InfoBoxProps extends BoxProps {
  title: string;
  children?: ReactNode;
  isLoading: boolean;
  skeletonWidth: string;
  skeletonHeight: string;
  helpText?: string;
  onEdit?: () => void;
}

const InfoBox = ({
  title,
  children,
  isLoading,
  skeletonWidth,
  skeletonHeight,
  helpText,
  onEdit,
  ...boxProps
}: InfoBoxProps) => (
  <Box
    background="primary"
    alignItems={{ base: 'center', tablet: 'flex-start' }}
    justifyContent={{ base: 'space-between', tablet: 'flex-start' }}
    direction={{ base: 'row', tablet: 'column' }}
    gap={{ base: 'lg', tablet: 'sm' }}
    width="100"
    position="relative"
    {...boxProps}
  >
    <Box
      direction={{ base: 'column', tablet: 'row' }}
      gap="xs"
      alignItems={{ base: 'flex-start', tablet: 'center' }}
      width={{ tablet: '100' }}
    >
      <Box fontWeight="semibold" color="tertiary" fontSize="xs" direction="row" alignItems="center" gap="xs">
        {title}
        {helpText && <TooltipIcon content={helpText} name="c-question" color="tertiary" style={{ zIndex: '1' }} />}
      </Box>
      {onEdit && (
        <Box flex="auto" alignItems="flex-end" style={{ zIndex: '1' }}>
          <Box
            as="button"
            type="button"
            padding="0"
            fontSize="sm"
            borderWidth="0"
            background="transparent"
            color="secondary"
            cursor="pointer"
            hover={{ color: 'base' }}
            onClick={onEdit}
          >
            Edit
          </Box>
        </Box>
      )}
    </Box>
    {isLoading ? <Skeleton width={skeletonWidth} height={skeletonHeight} /> : children}
  </Box>
);

export default InfoBox;
