import { Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { ApiKeySecret, useDeleteApiKeySecretMutation } from '../../services/apiKeys';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';
import { ApiError } from '../ApiError';

export default function DeleteApiKeySecret({
  organizationId,
  apiKeyId,
  secret,
}: {
  organizationId: string;
  apiKeyId: string;
  secret: ApiKeySecret;
}) {
  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();

  const [deleteApiKeySecret, { isLoading: isDeleting, error }] = useDeleteApiKeySecretMutation();

  const handleRemove = useCallback(async () => {
    try {
      const result = await deleteApiKeySecret({ orgId: organizationId, apiKeyId, apiKeyIdSecret: secret.id });
      if (!result.error) {
        toast.success(`Secret '${secret.hint}' removed!`);
        closeRemoveModal();
      } else {
        toast.error(`Remove secret '${secret.hint}' failed`);
      }
    } catch (e) {
      toast.error(`Remove secret '${secret.hint}' failed`);
    }
  }, [apiKeyId, closeRemoveModal, deleteApiKeySecret, organizationId, secret.hint, secret.id]);

  return (
    <>
      <ConfirmModal
        message="Any services using this secret will no longer be able to authenticate with the Hyphen API."
        confirmButtonLabel="Delete Secret"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={handleRemove}
        isLoading={isDeleting}
        title={`Delete secret '${secret.hint}'`}
        description={error && <ApiError error={error} />}
      />
      <Button
        size="sm"
        variant="tertiary"
        iconPrefix="c-remove"
        aria-label="delete secret"
        onClick={openRemoveModal}
      />
    </>
  );
}
