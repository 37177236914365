import * as yup from 'yup';
import {
  DynamicTeamCriteria,
  DynamicTeamCriteriaConjunction,
  DynamicTeamCriteriaOperator,
  DynamicTeamCriteriaProperty,
  TeamType,
} from '../types/teams';
import { ProjectRoles } from '../types/roles';
import { ProjectReference } from '../types/projects';

export const typeOptions = [
  { label: 'Static', value: 'static' },
  { label: 'Dynamic', value: 'dynamic' },
];

export const criteriaOptions = [
  { label: 'Property', value: 'property' },
  // { label: 'Team', value: 'team' },
  // { label: 'Role', value: 'role' },
];

export const propertyFieldOptions = [
  { label: 'First Name', value: 'firstName' as DynamicTeamCriteriaProperty['field'] },
  { label: 'Last Name', value: 'lastName' as DynamicTeamCriteriaProperty['field'] },
  { label: 'Email', value: 'email' as DynamicTeamCriteriaProperty['field'] },
];

export const propertyOperatorOptions = [
  { label: 'equals', value: DynamicTeamCriteriaOperator.equals },
  { label: 'regex', value: DynamicTeamCriteriaOperator.regex },
];

export const dynamicTeamOperatorOptions = (prefix: string) => [
  { id: `${prefix}And`, label: 'includes', value: DynamicTeamCriteriaConjunction.and },
  { id: `${prefix}Or`, label: 'is either', value: DynamicTeamCriteriaConjunction.or },
];

export const generateCriteriaSchema = (action: 'UPDATE' | 'CREATE', dataTeamType?: 'static' | 'dynamic') => {
  return {
    criteria: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.mixed<DynamicTeamCriteria['type']>().oneOf(['property', 'role', 'team']).required('Required'),
          value: yup.string().test('value-required', 'Required', function (value) {
            if (action === 'CREATE') {
              const teamType = this.options.context?.teamType;
              const { type } = this.parent;
              return !(teamType === 'dynamic' && type === 'property') || !!value;
            }
            if (action === 'UPDATE') {
              const { type } = this.parent;
              return !(dataTeamType === 'dynamic' && type === 'property') || !!value;
            }
          }),
          values: yup.array().of(
            yup.string().test('values-item-required', 'Required', function (value) {
              const teamType = this.options.context?.teamType;
              const { type } = this.parent;
              return (teamType === 'dynamic' && (type === 'role' || type === 'team')) || !!value;
            }),
          ),
          conjunction: yup
            .mixed<DynamicTeamCriteriaConjunction>()
            .oneOf(Object.values(DynamicTeamCriteriaConjunction)),
        }),
      )
      .test('criteria-required', 'At least one criteria is required', function (value) {
        if (action === 'UPDATE') {
          return !(dataTeamType === 'dynamic') || !!value?.length;
        } else {
          const teamType = this.options.context?.teamType;
          return !(teamType === 'dynamic') || !!value?.length;
        }
      }),
  };
};

export const createTeamFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
  teamType: yup.mixed<TeamType>().oneOf(['static', 'dynamic']).required('Required'),
  ...generateCriteriaSchema('CREATE', 'dynamic'),
  members: yup.array().of(
    yup.object().shape({
      value: yup.string().required(),
      label: yup.string().email('Invalid email').required(),
    }),
  ),
  projectAccess: yup.array().of(
    yup.object().shape({
      project: yup.mixed<ProjectReference>().required(),
      role: yup
        .mixed<ProjectRoles>()
        .oneOf([ProjectRoles.ProjectCollaborator, ProjectRoles.ProjectOwner])
        .required(),
    }),
  ),
  integrations: yup.array().of(
    yup.object().shape({
      id: yup.string().required('Integration ID is required'),
      input: yup.string(),
    }),
  ),
});

export type CreateTeamFormSchema = yup.InferType<typeof createTeamFormSchema>;
