import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Spinner } from '@hyphen/hyphen-components';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useGetProjectQuery } from '../../services/projects';
import { getEntityUrl } from '../../utils/getEntityUrl';
import { useOrganization } from '../../providers/OrganizationProvider';

export const ProjectLoader = ({ entityId, internalPath }: { entityId: string; internalPath?: string }) => {
  const navigate = useNavigate();
  const { id: orgId } = useOrganization();

  const {
    data: project,
    isLoading,
    error,
  } = useGetProjectQuery({ projectId: entityId, organizationId: orgId }, { skip: !orgId });

  useEffect(() => {
    if (isLoading) return;

    if (!project || error) {
      throw new Response('Not Found', { status: 404 });
    }

    if (project) {
      let url = getEntityUrl(orgId, EntityNames.Project, project.id, project.alternateId);
      if (internalPath) url = `${url}/${internalPath}`;
      navigate(url, { replace: true });
    }
  }, [project, navigate, orgId, internalPath, isLoading, error]);

  return (
    <Box padding="2xl" alignItems="center" justifyContent="center">
      <Spinner size="lg" />
    </Box>
  );
};
