import { Box } from '@hyphen/hyphen-components';
import { useSegment } from '../../providers/SegmentProvider';
import { EventType } from '../../types/events';
import { SEGMENT_TEXT } from '../../constants/segments';
import SegmentUsageList from './SegmentUsageList';
import SegmentRules from './SegmentRules';
import InfoCard from '../common/InfoCard';

function SegmentDetails() {
  const { segment, isLoading } = useSegment();

  return (
    <Box gap="4xl">
      <InfoCard
        isLoading={isLoading}
        entity={segment}
        entityType="segment"
        keyIdDescription={SEGMENT_TEXT.segmentKeyDescription}
        eventTypes={[EventType.CreateSegment, EventType.UpdateSegment, EventType.DeleteSegment]}
      />
      <Box gap="4xl" direction={{ base: 'column', hd: 'row' }} alignItems={{ hd: 'flex-start' }}>
        <Box width={{ hd: '30' }} style={{ flexShrink: 0 }} maxWidth={{ hd: '9xl' }}>
          <SegmentUsageList />
        </Box>
        <SegmentRules />
      </Box>
    </Box>
  );
}

export default SegmentDetails;
