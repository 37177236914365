import { Link, useParams } from 'react-router-dom';
import { Alert, Box, Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetOrganizationBillingAccountQuery } from '../../services/organization';
import { BillingAccountResponse, BillingCycle, SubscriptionStatus, useGetBillingAccountByIdQuery, useUpdateSubscriptionStatusMutation } from '../../services/billingAccount';
import { useAuth } from '../auth/authProvider';
import { formatDate } from '../../utils/dateUtils';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';

interface BillingAlertContentProps {
  billingAccount: BillingAccountResponse;
  user: { userId?: string } | undefined;
}

interface CancellingAlertProps {
  endDate: Date;
  billingAccountId: string;
  isBillingOwner: boolean;
}

const CancellingAlert = ({ endDate, billingAccountId, isBillingOwner }: CancellingAlertProps) => {
  const [updateStatus, { isLoading, error }] = useUpdateSubscriptionStatusMutation();
  const { isOpen, handleOpen, handleClose } = useOpenClose();
    
  const handleUncancel = useCallback(
    async () => {
      const { data } = await updateStatus({
        id: billingAccountId,
        status: SubscriptionStatus.Active
      });
      if(data) {
        toast.success('Subscription reactivated');
        handleClose();
      }
    },
    [billingAccountId, updateStatus, handleClose],
  );

  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleUncancel}
        isLoading={isLoading}
        message="Are you sure you want to reactivate your subscription?"
        confirmButtonLabel="Reactivate"
        confirmButtonVariant="primary"
        error={error}
      />
      <Alert
        hasIcon
        variant="warning"
        padding="xl"
        alignItems="center"
        radius="0"
        className='alert-end-button'
        message={
          <Box display="flex" direction="row" alignItems="center" gap="md">
            <Box as="span" fontWeight="bold">
              Your subscription will be canceled on {formatDate(endDate)}
            </Box>
            {isBillingOwner &&
              <Button
                variant="primary"
                size="sm"
                onClick={handleOpen}
                isLoading={isLoading}
              >
                Reactivate
              </Button>   
            }
          </Box>
        }
      />
    </>
  );
};

const CancelledAlert = ({ billingAccountId, isBillingOwner }: CancellingAlertProps) => {
  const [updateStatus, { isLoading, error }] = useUpdateSubscriptionStatusMutation();
  const { isOpen, handleOpen, handleClose } = useOpenClose();

  const handleUncancel = useCallback(
    async () => {
      const { data } = await updateStatus({
        id: billingAccountId,
        status: SubscriptionStatus.Active
      });
      if(data) {
        toast.success('Subscription reactivated');
        handleClose();
      }
    },
    [billingAccountId, updateStatus, handleClose],
  );

  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleUncancel}
        isLoading={isLoading}
        message="Are you sure you want to reactivate your subscription?"
        confirmButtonLabel="Reactivate"
        confirmButtonVariant="primary"
        error={error}
      />
      <Alert
        hasIcon
        variant="danger"
        padding="xl"
        alignItems="center"
        radius="0"
        className='alert-end-button'
        message={
          <Box display="flex" direction="row" alignItems="center" gap="md">
            <Box as="span" fontWeight="bold">
              Your subscription has been canceled.
            </Box>
            {isBillingOwner &&
              <Button
                variant="primary"
                size="sm"
                onClick={handleOpen}
                isLoading={isLoading}
              >
                Reactivate
              </Button>
            }
          </Box>
        }
      />
    </>
  );
};

const PaymentMethodWarningAlert = ({ billingAccountId }: { billingAccountId: string }) => (
  <Alert 
    hasIcon
    variant="warning"
    padding="2xl xl"
    radius="0"
    message={
      <Box as="span" fontWeight="bold">
        Your next invoice is due soon. In order to maintain your subscription, please{' '}
        <Link
          to={`/billing/${billingAccountId}`}
          style={{
            color: 'inherit',
            textDecoration: 'underline',
          }}
        >
          add a payment method
        </Link>
      </Box>
    }
  />
);

const shouldShowPaymentWarning = (
  currentCycle: BillingCycle,
  paymentMethodsCount: number
): boolean => {
  const today = new Date();
  const endDate = new Date(currentCycle.endDate);
  const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

  return (
    daysUntilEnd <= 10 && 
    (currentCycle.invoicePreviewAmountDue ?? 0) > 0 && 
    paymentMethodsCount === 0
  );
};

const BillingAlertContent = ({ billingAccount, user }: BillingAlertContentProps) => {
  const currentCycle = billingAccount.subscription?.cycles?.[billingAccount.subscription.cycles.length - 1];
  const status = billingAccount.subscription?.status;    

  if (!currentCycle || !status) return null;
  
  const isBillingOwner = billingAccount.owners.some(owner => owner.userId === user?.userId) ?? false;
  const deletionDate = new Date(currentCycle.endDate);

  switch (status) {
    case SubscriptionStatus.Cancelling:
      return <CancellingAlert endDate={deletionDate} billingAccountId={billingAccount.id} isBillingOwner={isBillingOwner} />;
    
    case SubscriptionStatus.Cancelled:
      return <CancelledAlert endDate={deletionDate} billingAccountId={billingAccount.id} isBillingOwner={isBillingOwner} />;
    
    default:
      if (shouldShowPaymentWarning(currentCycle, billingAccount.paymentMethods.length)) {
        return <PaymentMethodWarningAlert billingAccountId={billingAccount.id} />;
      }
      return null;
  }
};

export const BillingAccountBanner = () => {
  const { user } = useAuth();
  const { billingAccountId } = useParams();
  
  const { isLoading, data: billingAccount } = useGetBillingAccountByIdQuery(billingAccountId!);

  if (isLoading || !billingAccount) return null;

  return <BillingAlertContent billingAccount={billingAccount} user={user} />;
};

export const BillingOrganizationBanner = () => {
  const { user } = useAuth();
  const { id: orgId } = useOrganization();
  
  const { isLoading, data: billingAccount } = useGetOrganizationBillingAccountQuery(orgId);

  if (isLoading || !billingAccount) return null;

  return <BillingAlertContent billingAccount={billingAccount} user={user} />;
};
