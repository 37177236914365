import { Badge, BadgeProps, Box } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { IntegrationType } from '../../../types/integrations';
import { ReactSVG } from 'react-svg';
import {
  INTEGRATION_DESCRIPTION_MAP,
  INTEGRATION_FEATURED_BACKGROUND_MAP,
  INTEGRATION_ICON_MAP,
  INTEGRATION_NAME_MAP,
  INTEGRATION_SLUG_MAP,
} from '../../../constants/integrations';
import ComingSoon from '../../ComingSoon';

type IntegrationItemVariant = 'featured' | 'row';
type IntegrationItemProps = {
  id?: string;
  organizationId: string;
  type: IntegrationType;
  variant?: IntegrationItemVariant;
  badge?: BadgeProps;
  hideDescription?: boolean;
  comingSoon?: boolean;
  onClick?: () => void;
};
export const IntegrationCatalogItem = ({
  id,
  organizationId,
  type,
  variant = 'row',
  badge,
  hideDescription,
  comingSoon,
  onClick,
}: IntegrationItemProps) => {
  const link = id
    ? `/${organizationId}/integrations/${id}`
    : `/${organizationId}/integrations/setup/${INTEGRATION_SLUG_MAP[type]}`;
  const icon = INTEGRATION_ICON_MAP[type];
  const name = INTEGRATION_NAME_MAP[type];
  const description = INTEGRATION_DESCRIPTION_MAP[type];

  const item =
    variant === 'row' ? (
      <Box
        borderWidth="sm"
        borderColor="subtle"
        radius="md"
        height="100"
        padding="lg"
        direction="row"
        gap="lg"
        alignItems={badge || hideDescription || comingSoon ? 'center' : 'flex-start'}
        hover={comingSoon ? undefined : { borderColor: 'default' }}
      >
        <Box
          background="primary"
          width="48px"
          height="48px"
          minWidth="48px"
          borderWidth="sm"
          borderColor="subtle"
          alignItems="center"
          justifyContent="center"
          shadow="xs"
          radius="md"
          padding="sm"
        >
          <ReactSVG
            src={icon}
            beforeInjection={(svg) => svg.setAttribute('style', 'width: 100%; height: 100%; display: block')}
          />
        </Box>
        <Box padding="xs 0 0 0" gap="xs" flex="auto">
          <Box fontSize="sm">{name}</Box>
          {!badge && !hideDescription && (
            <Box fontSize="xs" color="secondary">
              {description}
            </Box>
          )}
        </Box>
        {badge && <Badge {...badge} />}
        {comingSoon && <ComingSoon />}
      </Box>
    ) : (
      <Box
        borderWidth="sm"
        borderColor="subtle"
        height="100"
        radius="md"
        overflow="hidden"
        hover={comingSoon ? undefined : { borderColor: 'default' }}
      >
        <Box
          height="7xl"
          background="primary"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundImage: `url(${INTEGRATION_FEATURED_BACKGROUND_MAP[type]})`,
            backgroundSize: 'cover',
          }}
        >
          <Box
            width="80px"
            minWidth="80px"
            height="80px"
            alignItems="center"
            justifyContent="center"
            radius="md"
            padding="sm"
          >
            <img src={icon} alt={name} width="100%" />
          </Box>
        </Box>
        <Box padding="2xl" direction="row" gap="lg" alignItems="flex-start">
          <Box padding="xs 0 0 0" gap="xs" flex="auto">
            <Box fontSize="sm">{name}</Box>
            {!hideDescription && (
              <Box fontSize="xs" color="secondary">
                {description}
              </Box>
            )}
          </Box>
          {badge && <Badge {...badge} />}
          {comingSoon && <ComingSoon />}
        </Box>
      </Box>
    );

  if (comingSoon) {
    return item;
  }

  return (
    <Link to={link} style={{ textDecoration: 'none' }} onClick={onClick}>
      {item}
    </Link>
  );
};
