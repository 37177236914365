import { Button, Modal, toast, useBreakpoint, useOpenClose } from '@hyphen/hyphen-components';
import { TeamDetails } from '../../types/teams';
import { PROJECT_ACCESS_ROLE_OPTIONS } from './ProjectAccessField';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetProjectsQuery } from '../../services/projects';
import { useGiveAccessMutation } from '../../services/access';
import { useMemo } from 'react';
import TooltipButton from '../common/TooltipButton';
import { AddProjectAccessFormSchema, ProjectAccessForm } from './ProjectAccessForm';

// Currently, it's unlikely that an Org will have more than 50 projects
const PAGE_SIZE = 50;

export default function AddProjectAccess({ team, accessProjects }: { team: TeamDetails; accessProjects: any }) {
  const { id: organizationId } = useOrganization();
  const { isPhone } = useBreakpoint();
  const { isOpen, handleOpen, handleClose } = useOpenClose();
  const [giveAccess, { error: giveAccessError }] = useGiveAccessMutation();

  const { data: projects, isLoading } = useGetProjectsQuery({
    pageSize: PAGE_SIZE,
    pageNum: 1,
    organizationId,
  });

  // only allow user to select projects that the team does not already have some access to
  const projectsWithoutTeamAccess = useMemo(
    () =>
      projects
        ? projects.data.filter((project) => !accessProjects.some((access: any) => access.entity.id === project.id))
        : [],
    [projects, accessProjects],
  );

  const projectOptions = useMemo(
    () =>
      projectsWithoutTeamAccess.map((project) => ({
        value: project.id,
        label: project.name,
      })),
    [projectsWithoutTeamAccess],
  );

  const allProjectsAssignedRole = useMemo(() => projectsWithoutTeamAccess.length === 0, [projectsWithoutTeamAccess]);

  const handleSubmit = async (values: AddProjectAccessFormSchema) => {
    try {
      await giveAccess({
        assignment: { type: 'Team', id: team.id },
        entity: { type: 'Project', id: values.projectId },
        organizationId,
        roles: [values.role],
      });
      toast.success('Access granted');
      handleClose();
    } catch (error) {
      toast.error('Access grant failed');
    }
  };

  const renderButton = allProjectsAssignedRole ? (
    <TooltipButton
      style={{ whiteSpace: 'nowrap' }}
      size="sm"
      disabled
      iconPrefix="add"
      variant="secondary"
      content="All projects have already been assigned a role"
    >
      {!isPhone && 'Access'}
    </TooltipButton>
  ) : (
    <Button
      variant="secondary"
      size="sm"
      type="button"
      onClick={handleOpen}
      iconPrefix="add"
      isDisabled={isLoading || allProjectsAssignedRole}
      style={{ whiteSpace: 'nowrap' }}
      aria-label="Add access"
    >
      {!isPhone && 'Access'}
    </Button>
  );

  return (
    <>
      {projects && (
        <Modal isOpen={isOpen} maxWidth="9xl" onDismiss={handleClose} ariaLabelledBy="addAccessHeader">
          <ProjectAccessForm
            teamName={team.name}
            initialValues={{ role: PROJECT_ACCESS_ROLE_OPTIONS[0].value, projectId: '' }}
            onSubmit={handleSubmit}
            projectOptions={projectOptions}
            giveAccessError={giveAccessError}
            handleClose={handleClose}
            isSubmitting={false}
            isLoading={isLoading}
          />
        </Modal>
      )}
      {renderButton}
    </>
  );
}
