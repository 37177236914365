import { Alert, Box, Button } from '@hyphen/hyphen-components';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useCallback, useState } from 'react';
import { useCreatePaymentMethodMutation } from '../../../services/billingAccount';
import { ApiError } from '../../ApiError';

interface CreatePaymentMethodFormProps {
  variant?: 'registration' | 'billing';
  billingAccountId: string;
  onComplete: () => void;
}

export const CreatePaymentMethodForm = ({
  variant = 'registration',
  billingAccountId,
  onComplete,
}: CreatePaymentMethodFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(0);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [apiError, setApiError] = useState<any | undefined>(undefined);

  const [CreatePaymentMethod] = useCreatePaymentMethodMutation();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsSubmitting(true);

      await elements.submit();
      const confirmationTokenResult = await stripe.createConfirmationToken({
        elements,
      });

      if (confirmationTokenResult.error) {
        setFormError(confirmationTokenResult.error.message);
        setIsSubmitting(false);
        return;
      }

      const { error } = await CreatePaymentMethod({
        id: billingAccountId,
        confirmationToken: confirmationTokenResult.confirmationToken.id,
      });
      if (error) {
        setApiError(error);
        setIsSubmitting(false);
        return;
      }

      setFormError(undefined);
      setApiError(undefined);
      setKey((prev) => prev + 1);
      onComplete();
      setIsSubmitting(false);
    },
    [stripe, elements, CreatePaymentMethod, billingAccountId, onComplete],
  );
  return (
    <Box width="100" height="100" gap="4xl" alignItems="center" justifyContent="center">
      <Box width="100" maxWidth="9xl" padding={{ base: '2xl', tablet: '0' }} gap="4xl" className="fade-in">
        <Box as="h1" fontSize={{ base: 'lg', tablet: 'xl' }} textAlign="center" fontWeight="bold">
          Add a payment method
        </Box>
        <form onSubmit={handleSubmit}>
          <PaymentElement key={key} />
          {formError && <Alert variant="danger" message={formError} hasIcon style={{ wordBreak: 'break-word' }} />}
          {apiError && <ApiError error={apiError} />}
          <Box margin="lg 0">
            <Button
              fullWidth
              size="md"
              variant="primary"
              type="submit"
              isLoading={isSubmitting}
              className="registration-button"
            >
              {variant === 'registration' ? 'Complete Setup' : 'Add Payment Method'}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
