import React from 'react';
import { Box, Card } from '@hyphen/hyphen-components';
import { ToggleReturnType } from '../../types/toggle';
import { ParsedCondition } from '../../utils/parseJsonLogic';
import { ContextList } from './ContextList';

interface TargetCardProps {
  targetContext: {
    id: string;
    parsedLogic: ParsedCondition[];
    value?: boolean | string | number;
  };
  returnType?: ToggleReturnType;
  isDraggable: boolean;
  isHighlighted: boolean;
}

const TargetCard = React.memo(({ targetContext, returnType, isDraggable, isHighlighted }: TargetCardProps) => {
  const { parsedLogic, value } = targetContext;

  return (
    <Card
      className={isHighlighted ? 'update-card-highlight' : ''}
      hover={isDraggable ? { borderColor: 'info', shadow: 'sm' } : undefined}
      radius="sm"
    >
      <Box
        direction={{ base: 'column', tablet: 'row' }}
        gap={{ base: 'sm', tablet: 'xl' }}
        alignItems={{ base: 'stretch', tablet: 'center' }}
        padding="sm"
        fontFamily="monospace"
        overflow="auto"
      >
        <ContextList contexts={parsedLogic} />

        {/* sometimes the value is false, so we check for undefined */}
        {value !== undefined && (
          <>
            <Box
              display="inline-block"
              radius="xs"
              background="tertiary"
              padding="sm"
              alignSelf={{ base: 'flex-start', tablet: 'center' }}
            >
              RETURNS
            </Box>
            <Box
              radius="xs"
              maxWidth={{ base: '8xl', hd: '9xl' }}
              minWidth="6xl"
              borderColor="subtle"
              borderWidth="sm"
              padding="sm lg"
              justifyContent="center"
              color={returnType === 'boolean' ? (value ? 'success' : 'danger') : 'base'}
            >
              {String(value)}
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
});

export default TargetCard;
