import { forwardRef, useMemo } from 'react';
import { Badge, BadgeProps, BadgeVariant } from '@hyphen/hyphen-components';
import { Connection } from '../../../types/connections';

interface ConnectionStatusBadgeProps extends BadgeProps {
  status: Connection['status'];
}

const ConnectionStatusBadge = forwardRef<HTMLDivElement, ConnectionStatusBadgeProps>(
  ({ status, ...restProps }, ref) => {
    const badgeVariant: BadgeVariant = useMemo(() => {
      switch (status) {
        case 'Ready':
          return 'green';
        case 'Pending':
        case 'Verifying':
          return 'yellow';
        default:
          return 'red';
      }
    }, [status]);

    const badgeMessage = useMemo(() => {
      switch (status) {
        case 'Ready':
          return 'Connected';
        case 'Pending':
          return 'Pending';
        case 'Verifying':
          return 'Verifying';
        default:
          return 'Error';
      }
    }, [status]);

    return <Badge ref={ref} variant={badgeVariant} message={badgeMessage} size="sm" {...restProps} />;
  },
);

export default ConnectionStatusBadge;
