import { Button, toast, Modal, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGiveAccessMutation } from '../../services/access';
import { api } from '../../services/api';
import { OrganizationRoles } from '../../types/roles';
import { Member } from '../../types/members';
import { Organization } from '../../services/organization';

interface OrgAdminButtonProps {
  member: Member;
  organization: Organization;
}

export default function OrgAdminButton({ member, organization }: OrgAdminButtonProps) {
  const [giveAccess, { isLoading }] = useGiveAccessMutation();
  const dispatch = useDispatch();
  const { isOpen, handleOpen, handleClose } = useOpenClose();

  const makeAdmin = useCallback(async () => {
    try {
      if (!member) return;

      const { error: assignError } = await giveAccess({
        entity: { type: 'Organization', id: organization.id },
        assignment: { type: 'Member', id: member?.id },
        organizationId: organization.id,
        roles: [OrganizationRoles.OrganizationAdmin],
      });

      if (assignError) {
        toast.error('Role assignment failed');
        throw new Error('Role assignment failed');
      }

      handleClose();
      toast.success('Admin role assigned', { duration: 5000 });
      // invalidate the cache to refetch the member data
      dispatch(api.util.invalidateTags([{ type: 'Members', id: member.id }]));
    } catch (error) {
      toast.error('Role assignment failed');
    }
  }, [dispatch, giveAccess, handleClose, member, organization.id]);

  const handleConfirm = useCallback(() => {
    makeAdmin();
  }, [makeAdmin]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        onDismiss={handleClose}
        maxWidth="30rem"
        ariaLabelledBy="orgAdminHeader"
      >
        <Modal.Header title="Assign Organization Admin Role" id="orgAdminHeader" onDismiss={handleClose} />
        <p>
          Are you sure you want to make <strong>{member.email}</strong> an admin of this organization?
        </p>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm} isLoading={isLoading}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="secondary" size="sm" onClick={handleOpen} style={{ whiteSpace: 'nowrap' }}>
        Make Admin
      </Button>
    </>
  );
}
