import { ListResponse, QueryParams } from './types';
import { api } from './api';

export interface Environment {
  id: string;
  alternateId: string;
  color: string;
  name: string;
  organization: {
    id: string;
    name: string;
  };
  project: {
    id: string;
    alternateId: string;
    name: string;
  };
  secrets?: {
    countVariables: number;
    id: string;
    published: string;
    size: string;
    version: number;
  };
}

export const environmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnvironments: builder.query<ListResponse<Environment>, QueryParams>({
      query: ({ pageSize, pageNum, organizationId }) => {
        const params = new URLSearchParams();
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (pageSize) params.append('pageSize', pageSize.toString());
        return {
          url: `api/organizations/${organizationId}/projects/environments/?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Environments' as const, id: id })),
              { type: 'Environments', id: 'ENVIRONMENTS-LIST' },
            ]
          : [],
    }),
    getProjectEnvironments: builder.query<ListResponse<Environment>, QueryParams>({
      query: ({ pageSize, pageNum, organizationId, projectId }) => {
        const params = new URLSearchParams();
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (pageSize) params.append('pageSize', pageSize.toString());
        return {
          url: `api/organizations/${organizationId}/projects/${projectId}/environments?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, { projectId }) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Environments' as const, id: id })),
              { type: 'Environments', id: `${projectId}-ENVIRONMENTS-LIST` },
            ]
          : [],
    }),
    getProjectEnvironment: builder.query<
      Environment,
      { organizationId: string; projectId: string; environmentId: string }
    >({
      query: ({ organizationId, projectId, environmentId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/environments/${environmentId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { projectId, environmentId }) =>
        result
          ? [
              { type: 'Environments', id: `${projectId}-ENVIRONMENTS-LIST` },

              {
                type: 'Environments',
                id: result.id,
              },
            ]
          : [],
    }),
    createProjectEnvironment: builder.mutation<
      Environment,
      {
        organizationId: string;
        projectId: string;
        data: Pick<Environment, 'name' | 'alternateId' | 'color'>;
      }
    >({
      query: ({ organizationId, projectId, data }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/environments`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        {
          type: 'Environments',
          id: `${projectId}-ENVIRONMENTS-LIST`,
        },
      ],
    }),
    updateProjectEnvironment: builder.mutation<
      Environment,
      {
        organizationId: string;
        projectId: string;
        environmentId?: string;
        data: Partial<Environment>;
      }
    >({
      query: ({ organizationId, projectId, environmentId, data }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/environments/${environmentId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, { environmentId, projectId }) => [
        {
          type: 'Environments',
          id: `${projectId}-ENVIRONMENTS-LIST`,
        },
        {
          type: 'Environments',
          id: environmentId,
        },
      ],
    }),
    deleteProjectEnvironment: builder.mutation<
      void,
      {
        organizationId: string;
        projectId: string;
        environmentId?: string;
      }
    >({
      query: ({ organizationId, projectId, environmentId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/environments/${environmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        {
          type: 'Environments',
          id: `${projectId}-ENVIRONMENTS-LIST`,
        },
      ],
    }),
  }),
});

export const {
  useGetEnvironmentsQuery,
  useGetProjectEnvironmentsQuery,
  useGetProjectEnvironmentQuery,
  useCreateProjectEnvironmentMutation,
  useUpdateProjectEnvironmentMutation,
  useDeleteProjectEnvironmentMutation,
} = environmentsApi;
