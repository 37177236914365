export const TOGGLE_TEXT = {
  title: 'Toggle',
  createTitle: 'Create Feature Toggle',
  totalProjectUsage: 'Usage by Project',
  totalProjectEvaluations: 'Evaluations by Project',
  keyIdDescription: 'Unique identifier used to reference the toggle in your application code',
  description:
    'A feature toggle allows you to enable, disable or change the behavior of features or code paths in your app or service, without modifying source code',
  targetsDescription:
    'The first matching target will return its value. If there are no matches, the default is returned.',
  usage:
    'Usage telemetry tracks when a toggle value is consumed in an evaluation, giving you an understanding of toggle activity, with collection managed by SDK options. Organizations are not billed based on usage metrics.',
  evaluations:
    'An evaluation is the result of applying a context to all toggles in a project, optimized with caching for performance. Evaluations are the metric on which an organization is billed.',
  noUsageTooltip: 'No usage in the past 30 days',
  activeTooltip: 'Used in the past 30 days',
  noUsageTitle: 'No Usage Data',
  noUsageTitle30Days: 'No Usage Data in the Last 30 Days',
  noUsageBody: 'Add the Hyphen Provider to your application to start requesting evaluations',
};
