import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  SidebarMenuButton,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { OrganizationAvatar } from '../components/organization/OrganizationAvatar';
import { Organization } from '../services/organization';
import { routes } from '..';

interface OrganizationSwitcherProps {
  organizations: Organization[];
  currentOrg?: Organization;
  onOrgSelect?: (organization: Organization) => void;
}

const OrganizationSwitcher: React.FC<OrganizationSwitcherProps> = ({ organizations, currentOrg, onOrgSelect }) => {
  const renderCurrentOrgButton = () => (
    <SidebarMenuButton>
      <Box flex="auto" direction="row" gap="sm" alignItems="center">
        {currentOrg ? (
          <>
            <OrganizationAvatar name={currentOrg.name || 'Unknown Organization'} logoUrl={currentOrg.logoUrl} />
            <span className="font-weight-semibold">{currentOrg.name}</span>
          </>
        ) : (
          <span className="truncate">Your Organizations</span>
        )}
      </Box>
      <Icon name="caret-up-down" />
    </SidebarMenuButton>
  );

  const renderOrganizationList = () => (
    <Box overflow="auto" maxHeight="8xl" className="scroll-bar-thin">
      {organizations.map((org) => (
        <DropdownMenuItem asChild key={org.id} onSelect={() => onOrgSelect?.(org)}>
          <Link to={`/${org.id}`} className="navlink">
            <OrganizationAvatar name={org.name} logoUrl={org.logoUrl} />
            <span>{org.name}</span>
            {currentOrg?.id === org.id && <Icon name="check" size="sm" />}
          </Link>
        </DropdownMenuItem>
      ))}
    </Box>
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{renderCurrentOrgButton()}</DropdownMenuTrigger>
      <DropdownMenuContent align="start" side="bottom" sideOffset={4}>
        {organizations?.length === 0 ? (
          <Box fontSize="xs" padding="xs sm">
            You are not a member of any organization
          </Box>
        ) : (
          <>
            <DropdownMenuLabel>Organizations</DropdownMenuLabel>
            {renderOrganizationList()}
          </>
        )}

        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to={`/${routes.getStarted}`} className="navlink">
            <Icon name="add" />
            <span>Create Organization</span>
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default OrganizationSwitcher;
