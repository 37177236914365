import React from 'react';
import { Box } from '@hyphen/hyphen-components';
import { ColorDot } from '../common/ColorDot';
import { Environment } from '../../services/environments';
import EnvironmentRuleList from './EnvironmentRuleList';
import { App } from '../../services/apps';

interface EnvironmentRulesProps {
  app: App;
  environment: Environment;
  rules: {
    allow: { description: string; cidr: string }[];
    deny: { description: string; cidr: string }[];
  };
}

const EnvironmentRules: React.FC<EnvironmentRulesProps> = ({ app, environment, rules }) => (
  <>
    <Box fontSize="xs" fontFamily="monospace" className="grid__item">
      <Box gap="xs" direction="row">
        {environment.color && <ColorDot color={environment.color} />}
        <Box fontFamily="monospace" flex="auto" alignItems="flex-start">
          {environment.name}
        </Box>
      </Box>
    </Box>
    <Box className="grid__item">
      <EnvironmentRuleList rules={rules.allow} app={app} environment={environment} isDefault={false} type="allow" />
    </Box>
    <Box className="grid__item">
      <EnvironmentRuleList rules={rules.deny} app={app} environment={environment} isDefault={false} type="deny" />
    </Box>
  </>
);

export default EnvironmentRules;
