import { Alert, Button, Card, Modal, useOpenClose } from '@hyphen/hyphen-components';
import { BillingAccountPaymentMethodResponse } from '../../../services/billingAccount';
import { PaymentMethodItem } from './PaymentMethodItem';
import { CreatePaymentMethod } from './CreatePaymentMethod';

interface PaymentMethodDetailsProps {
  mode: 'view' | 'edit';
  billingAccountId: string;
  paymentMethods: BillingAccountPaymentMethodResponse[];
}

export const PaymentMethodDetails = ({ mode, billingAccountId, paymentMethods }: PaymentMethodDetailsProps) => {
  const { isOpen, handleClose, handleOpen } = useOpenClose();

  return (
    <>
      <Card>
        <Card.Header title="Payment Methods" borderWidth="0 0 sm 0" alignItems="center">
          {mode === 'edit' && (
            <>
              <Button onClick={handleOpen} size="sm">
                Add
              </Button>
              <Modal isOpen={isOpen} onClose={handleClose} onDismiss={handleClose} maxWidth="9xl">
                <Modal.Header id="payment_method" onDismiss={handleClose} />
                <Modal.Body>
                  <CreatePaymentMethod
                    billingAccountId={billingAccountId}
                    variant="billing"
                    onComplete={handleClose}
                  />
                </Modal.Body>
              </Modal>
            </>
          )}
        </Card.Header>

        {paymentMethods.length ? (
          paymentMethods.map((paymentMethod) => (
            <Card.Section gap="xl" key={paymentMethod.providerId}>
              <PaymentMethodItem mode={mode} billingAccountId={billingAccountId} paymentMethod={paymentMethod} />
            </Card.Section>
          ))
        ) : (
          <Card.Section>
            <Alert hasIcon variant="warning" title="No payment method found" />
          </Card.Section>
        )}
      </Card>
    </>
  );
};
