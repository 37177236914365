import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { Button } from '@hyphen/hyphen-components';
import { GithubSetupForm } from './GithubSetupForm';

interface GithubSetupGuideProps {
  organizationId: string;
}

const GithubAppUrl = process.env.REACT_APP_GITHUB_APP_URL || '';

export const GithubSetupGuide = ({ organizationId }: GithubSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Add Hyphen to your Github organization">
        <Button asChild variant="primary" iconSuffix="launch-app">
          <a target="_blank" href={GithubAppUrl} rel="noreferrer">
            Install the App
          </a>
        </Button>
      </GuideStep>
      <GuideStep title="Connect GitHub">
        <p>Submit your GitHub Installation ID to connect it to Hyphen</p>
        <GithubSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
