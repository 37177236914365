import { Box, useBreakpoint } from '@hyphen/hyphen-components';
import { useGetTeamMembersQuery } from '../../services/teams';
import { Skeleton } from '../common/Skeleton';
import React, { Fragment } from 'react';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { TeamDetails } from '../../types/teams';
import TeamMemberItem from './TeamMemberItem';

interface TeamMembersListProps {
  organizationId: string;
  team: TeamDetails;
  teamType?: string;
}

export const TeamMembersList = ({ organizationId, team, teamType }: TeamMembersListProps) => {
  const { isPhone } = useBreakpoint();

  const ability = useOrganizationAbilityContext();
  const canUpdateTeam = ability.can('update', team);

  const { isLoading, data: members } = useGetTeamMembersQuery({
    orgId: organizationId,
    id: team.id,
  });

  const isStaticTeam = teamType === 'static';

  if (isLoading) {
    return <TeamMembersSkeleton />;
  }

  if (!isLoading && members?.data.length === 0) {
    return (
      <Box
        width="100"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        gap="2xl"
        className="fade-in"
        padding="4xl 2xl"
        borderWidth="sm 0 0 0"
      >
        <Box gap="xs" alignItems="center">
          <Box fontSize="sm" fontWeight="bold" as="h3">
            No members
          </Box>
          <Box display="block" fontSize="xs" color="secondary" padding="0 4xl">
            This team has no members
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        display="grid"
        style={{
          gridTemplateColumns: isPhone ? 'minmax(100px, 1fr) 72px' : '32px minmax(100px, 1fr) 72px',
        }}
        overflow="hidden"
        width="100%"
      >
        {members?.data.map((member: any) => (
          <Fragment key={member.id}>
            <TeamMemberItem
              organizationId={organizationId}
              team={team}
              member={member}
              isStaticTeam={isStaticTeam}
              canUpdateTeam={canUpdateTeam}
            />
          </Fragment>
        ))}
      </Box>
    </>
  );
};

const TeamMembersSkeleton = React.memo(() => (
  <Box display="grid" style={{ gridTemplateColumns: 'minmax(100px, 1fr) 1fr' }} overflow="hidden" width="100">
    <Box padding="lg 2xl" className="grid__item">
      <Box direction="row" gap="sm" alignItems="center">
        <Skeleton width="16px" height="16px" />
        <Skeleton width="140px" height="16px" />
      </Box>
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Skeleton width="120px" height="16px" />
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Box direction="row" gap="sm" alignItems="center">
        <Skeleton width="16px" height="16px" />
        <Skeleton width="140px" height="16px" />
      </Box>
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Skeleton width="120px" height="16px" />
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Box direction="row" gap="sm" alignItems="center">
        <Skeleton width="16px" height="16px" />
        <Skeleton width="140px" height="16px" />
      </Box>
    </Box>
    <Box padding="lg 2xl" className="grid__item">
      <Skeleton width="120px" height="16px" />
    </Box>
  </Box>
));
