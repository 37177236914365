import { Helmet } from 'react-helmet';
import { useGetBillingAccountByIdQuery } from '../../services/billingAccount';
import { useNavigate, useParams } from 'react-router-dom';
import { CreatePaymentMethod } from '../../components/billing/paymentMethods/CreatePaymentMethod';
import { Box, Spinner } from '@hyphen/hyphen-components';
import { useEffect, useState } from 'react';
import { useGetOrganizationQuery } from '../../services/organization';

export function OrganizationSubscription() {
  const { orgId } = useParams();
  const [activeSubscription, setActiveSubscription] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    data: organization,
    error: organizationError,
    isLoading: isOrganizationLoading,
  } = useGetOrganizationQuery(orgId!);
  const {
    data: billingAccount,
    isLoading: isBillingAccountLoading,
    isFetching: isBillingAccountFetching,
    error: billingAccountError,
  } = useGetBillingAccountByIdQuery(organization?.billingAccount?.id || '', {
    skip: !organization?.billingAccount.id,
  });

  useEffect(() => {
    setActiveSubscription(billingAccount?.subscription?.status === 'Active');

    if (activeSubscription) {
      setTimeout(() => {
        navigate(`/${orgId}`);
      }, 2000);
    }
  }, [billingAccount, activeSubscription, navigate, orgId]);

  const isLoading = isBillingAccountLoading || isOrganizationLoading || isBillingAccountFetching;

  if (billingAccountError || organizationError) {
    return (
      <div>
        {billingAccountError ? 'Error Fetching Billing Account' : ''}
        {organizationError ? 'Error Fetching Organization Details' : ''}
      </div>
    );
  }

  const pageTitle = isLoading
    ? '...'
    : activeSubscription
    ? 'Activating your organization...'
    : 'Add a payment method';

  const renderContent = () => {
    if (!isLoading && !activeSubscription && !!billingAccount?.providerId) {
      return <CreatePaymentMethod billingAccountId={billingAccount.id} onComplete={() => {}} />;
    }

    return (
      <Box
        width="100"
        maxWidth="100"
        height="100"
        gap="4xl"
        alignItems="center"
        justifyContent="center"
        className="fade-in"
      >
        {isLoading ? (
          <Spinner className="fade-in" />
        ) : (
          <Box as="h1" fontSize={{ base: 'sm', tablet: 'sm' }} textAlign="center" fontWeight="medium">
            Activating your organization&hellip;
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {renderContent()}
    </>
  );
}
