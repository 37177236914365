import React, { useCallback } from 'react';
import { Box, DrawerProvider } from '@hyphen/hyphen-components';
import { ParsedCondition } from '../../utils/parseJsonLogic';
import SegmentRuleDrawer from './SegmentRuleDrawer';
import RuleCard from './RuleCard';

interface TargetProps {
  condition: ParsedCondition[];
  ruleIndex: number;
  handleUpdateItems?: (updatedIndex: number) => void;
  isHighlighted: boolean;
}

const SegmentRule = React.memo(
  ({ condition, ruleIndex, handleUpdateItems = () => {}, isHighlighted }: TargetProps) => {
    const renderUpdateMenu = useCallback(() => {
      return (
        <Box width="44px" alignItems="center">
          <DrawerProvider>
            <SegmentRuleDrawer rule={condition} ruleIndex={ruleIndex} onUpdateItems={handleUpdateItems} />
          </DrawerProvider>
        </Box>
      );
    }, [condition, handleUpdateItems, ruleIndex]);
    return (
      <Box as="li" direction="row" alignItems="center" gap="lg">
        <Box flex="auto" direction="row" alignItems="center" gap="lg">
          <RuleCard rule={condition} isHighlighted={isHighlighted} />
        </Box>
        {renderUpdateMenu()}
      </Box>
    );
  },
);

export default SegmentRule;
