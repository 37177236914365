import {
  CloudProvider,
  Integration,
  IntegrationType,
  ProductivitySuite,
  SlackIntegrationInvite,
  IntegrationSetup,
} from '../types/integrations';
import { ListResponse } from './types';
import { api } from './api';

export const integrationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIntegration: build.query<Integration, { organizationId: string; integrationId: string }>({
      query: ({ organizationId, integrationId }) => ({
        url: `api/organizations/${organizationId}/integrations/${integrationId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { integrationId }) =>
        result ? [{ type: 'Integrations', id: integrationId }] : [],
    }),
    getIntegrations: build.query<ListResponse<Integration> & { integrationSetup: IntegrationSetup }, string>({
      query: (orgId: string) => ({ url: `api/organizations/${orgId}/integrations` }),
      transformResponse(
        baseQueryReturnValue: ListResponse<Integration>,
        meta = undefined,
        arg: string,
      ):
        | (ListResponse<Integration> & { integrationSetup: IntegrationSetup })
        | Promise<ListResponse<Integration> & { integrationSetup: IntegrationSetup }> {
        const integrationSetup: IntegrationSetup = {
          productivitySuite: false,
          cloudProvider: false,
          github: false,
          slack: false,
          npm: false,
          newRelic: false,
          jira: false,
        };

        baseQueryReturnValue.data.forEach((integration) => {
          if (Object.values(ProductivitySuite).includes(integration.type as any)) {
            integrationSetup.productivitySuite = true;
          }
          if (Object.values(CloudProvider).includes(integration.type as any)) {
            integrationSetup.cloudProvider = true;
          }
          if (integration.type === IntegrationType.github) {
            integrationSetup.github = true;
          }
          if (integration.type === IntegrationType.slack) {
            integrationSetup.slack = true;
          }
        });

        return {
          ...baseQueryReturnValue,
          data: [...baseQueryReturnValue.data],
          integrationSetup,
        };
      },
      providesTags: (result, _error, orgId) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Integrations' as const, id })),
              { type: 'Integrations', id: `${orgId}-LIST` },
            ]
          : [],
    }),
    createIntegration: build.mutation<Integration, { orgId: string; integration: Omit<Integration, 'id'> }>({
      query: ({ orgId, integration }) => ({
        url: `api/organizations/${orgId}/integrations`,
        method: 'POST',
        body: integration,
      }),
      invalidatesTags: (_result, _error, { orgId }) => [{ type: 'Integrations', id: `${orgId}-LIST` }],
    }),
    testIntegration: build.mutation<unknown, { orgId: string; integration: Omit<Integration, 'id'> }>({
      query: ({ orgId, integration }) => ({
        url: `api/organizations/${orgId}/integrations/test`,
        method: 'POST',
        body: integration,
      }),
    }),
    deleteIntegration: build.mutation<void, { orgId: string; integrationId: string }>({
      query: ({ orgId, integrationId }) => ({
        url: `api/organizations/${orgId}/integrations/${integrationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { orgId, integrationId }) => [
        { type: 'Integrations', id: integrationId },
        { type: 'Integrations', id: `${orgId}-LIST` },
      ],
    }),
    createIntegrationInvite: build.mutation<
      SlackIntegrationInvite,
      { organizationId: string; integration: Omit<SlackIntegrationInvite, 'url'> }
    >({
      query: ({ organizationId, integration }) => ({
        url: `api/organizations/${organizationId}/integration-invites`,
        method: 'POST',
        body: integration,
      }),
    }),
  }),
});

export const {
  useGetIntegrationQuery,
  useGetIntegrationsQuery,
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
  useTestIntegrationMutation,
  useCreateIntegrationInviteMutation,
} = integrationsApi;
