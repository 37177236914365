import { Badge, Box, Card, Heading, Pagination, Table } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import useQueryStringState from '../../../hooks/useQueryStringState';
import { Connection, ConnectionType } from '../../../types/connections';
import { useGetConnectionsAccessQuery } from '../../../services/connections';
import { ApiError } from '../../ApiError';
import { Link, useLocation, useParams } from 'react-router-dom';
import { transformToTitleCase } from '../../../utils/stringUtils';
import EmptyList from '../../common/EmptyList';
import { CONNECTION_NAME_MAP } from '../../../constants/integrations';
import { Skeleton } from '../../common/Skeleton';
import { EntityNames } from '@hyphen/nucleus/dist/types';

export default function ConnectionAccessList({ connection }: { connection: Connection }) {
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const location = useLocation();
  const { id } = useParams();

  const isMembersTab = location.pathname.includes('/members');
  const isAccessTab = location.pathname.includes('/access');
  const {
    data: accessData,
    isLoading,
    error,
  } = useGetConnectionsAccessQuery({
    organizationId: connection.organization.id,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize,
    ...(isAccessTab && { assignmentConnectionIds: [connection.id] }),
    ...(isMembersTab && { entityConnectionIds: [connection.id] }),
  });

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState({ pageNum, pageSize: qsState.pageSize });
    },
    [qsState.pageSize, setQsState],
  );

  // TODO: Remove this type check when project environments have a page
  const isProjectEnvironment = (connection: any) => {
    return connection?.entity.type === EntityNames.ProjectEnvironment;
  };

  const columnConfig = [
    {
      heading: 'Status',
      width: 100,
      dataKey: 'status',
      render: (status: any) => {
        switch (status) {
          case 'Ready':
            return <Badge size="sm" variant="green" message="Ready" />;
          case 'Pending':
            return <Badge size="sm" variant="yellow" message="Pending" />;
          case 'CreationError':
          case 'DeletingError':
            return <Badge size="sm" variant="red" message="Error" />;
          case 'Deleting':
            return <Badge size="sm" variant="red" message="Deleting" />;
          case 'Deleted':
            return <Badge size="sm" variant="red" message="Deleted" />;
          default:
            return <Badge size="sm" variant="light-grey" message="global" />;
        }
      },
    },
    {
      heading: 'Name',
      dataKey: isAccessTab ? 'entityConnection' : 'assignmentConnection',
      render: (_connection: any) => {
        const content = `${_connection.entity.name} (${transformToTitleCase(_connection.entity.type)})`;
        if (isProjectEnvironment(_connection)) {
          return content;
        }
        return <Link to={`/${id}/ldr/${_connection.entity.id}?p=connections`}>{content}</Link>;
      },
    },
    {
      heading: 'Connection',
      dataKey: isAccessTab ? 'entityConnection' : 'assignmentConnection',
      render: (_connection: any) => {
        return (
          <Link to={`/${id}/ldr/${_connection.id}`}>{CONNECTION_NAME_MAP[_connection.type as ConnectionType]}</Link>
        );
      },
    },
  ];

  if (!isLoading && error) return <ApiError error={error} />;

  if (!isLoading && accessData?.total === 0) return <EmptyList title={`No ${isAccessTab ? 'access' : 'members'}`} />;

  if (isLoading) return <Skeleton width="100" height="192px" />;

  return (
    <Card fontSize="sm">
      <Card.Section>
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          gap={{ base: 'lg', tablet: '2xl' }}
          justifyContent="space-between"
          alignItems={{ base: 'stretch', tablet: 'center' }}
        >
          <Box gap="2xs">
            <Heading level={1} size="sm">
              {isAccessTab ? 'Access' : 'Members'}
            </Heading>
          </Box>
        </Box>
      </Card.Section>
      <Card.Section padding="lg 0" gap="lg">
        <Table
          rowKey="id"
          isLoading={isLoading}
          columns={columnConfig}
          rows={accessData?.data || []}
          isScrollable={{ x: true, y: false }}
        />
        {accessData?.total && accessData?.total > qsState.pageSize ? (
          <Box padding="2xl 2xl md 2xl" borderWidth="sm 0 0 0">
            <Pagination
              activePage={qsState.pageNum}
              itemsPerPage={qsState.pageSize}
              onChange={handlePageNumberChange}
              totalItemsCount={accessData?.total ?? 0}
              numberOfPagesDisplayed={3}
              isCompact
              arePagesVisible
            />
          </Box>
        ) : null}
      </Card.Section>
    </Card>
  );
}
