import { Helmet } from 'react-helmet';
import { Box } from '@hyphen/hyphen-components';
import { useConnection } from '../../../../providers/ConnectionProvider';
import { Skeleton } from '../../../../components/common/Skeleton';
import { ConnectionDetailsCard } from '../../../../components/integrations/connections/ConnectionDetailsCard';
import { transformToTitleCase } from '../../../../utils/stringUtils';

export default function ConnectionOverview() {
  const { connection, isLoading } = useConnection();

  if (isLoading) {
    return (
      <Box>
        <Skeleton width="100" height="180px" />
      </Box>
    );
  }

  if (connection) {
    return (
      <>
        <Helmet title={`${transformToTitleCase(connection.type) || 'Loading'} Overview`} />
        <Box gap="5xl">
          <ConnectionDetailsCard
            connection={connection}
            details={transformConfigToDetails({
              ...connection.config,
              ...(connection.input && { input: connection.input }),
            })}
          />
        </Box>
      </>
    );
  }

  return <Box>No connection found</Box>;
}

function transformConfigToDetails(obj: object): { label: string; value: string }[] {
  return Object.entries(obj).map(([key, value]) => ({
    label: transformToTitleCase(key),
    value,
  }));
}
