import { useCallback } from 'react';
import { Box } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import {
  CreateOrganizationFormSchema,
  CreateOrganizationForm,
} from '../../components/billing/CreateNewOrganizationForm';
import { useNavigate } from 'react-router-dom';
import { routes } from '../..';
import { useCreateOrganizationAndBillingAccountMutation } from '../../services/organization';

export function OrganizationCreate() {
  const [createOrganization, { error }] = useCreateOrganizationAndBillingAccountMutation();
  const navigate = useNavigate();

  const handleCompanySubmit = useCallback(
    async (values: CreateOrganizationFormSchema, { setSubmitting }: any) => {
      const { data, error } = (await createOrganization({
        name: values.name,
        billingAccount: values.billingAccountId?.value === 'new'
          ? {
              name: values.name,
              email: values.email,
              promoCodes: values.promoCode ? [values.promoCode] : [],
            }
          : {
              id: values.billingAccountId?.value || '',
              promoCodes: values.promoCode ? [values.promoCode] : [],
            },
      }));
      if (!error && data) {
        navigate(`/${routes.getStarted}/${data.id}`);
      }
      setSubmitting(false);
    },
    [createOrganization, navigate],
  );

  return (
    <>
      <Helmet>
        <title>Activate your organization</title>
      </Helmet>
      <Box width="100" height="100" gap="4xl" alignItems="center" justifyContent="center">
        <CreateOrganizationForm handleSubmit={handleCompanySubmit} error={error} />
      </Box>
    </>
  );
}
