import { Box, Button, FormikTextInputInset, toast } from '@hyphen/hyphen-components';
import { useCreateOwnerMutation } from '../../../services/billingAccount';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useCallback } from 'react';
import { ApiError } from '../../ApiError';

interface CreateBillingAccountOwnerFormProps {
  billingAccountId: string;
  onComplete: () => void;
}

const createOwnerSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
});

type CreateOwnerForm = yup.InferType<typeof createOwnerSchema>;

export const CreateBillingAccountOwnerForm = ({
  billingAccountId,
  onComplete,
}: CreateBillingAccountOwnerFormProps) => {
  const initialValues = {
    email: '',
  };

  const [createOwner, { error }] = useCreateOwnerMutation();

  const handleOnSubmit = useCallback(
    async (values: CreateOwnerForm) => {
      const { error } = await createOwner({
        id: billingAccountId,
        email: values.email,
      });
      if (!error) {
        toast.success('Owner Added');
        onComplete();
      }
    },
    [billingAccountId, createOwner, onComplete],
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={createOwnerSchema}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ errors, isSubmitting }) => (
        <Form noValidate>
          <Box gap="2xl">
            <Field
              type="text"
              label="Email"
              name="email"
              id="email"
              autoComplete="off"
              component={FormikTextInputInset}
              error={errors.email}
              isDisabled={isSubmitting}
            />
            {error && <ApiError error={error} title="Error adding owner" />}
            <Box display="block">
              <Button size="sm" variant="primary" type="submit" isLoading={isSubmitting}>
                Add
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
