import React from 'react';
import { Card } from '@hyphen/hyphen-components';
import { ParsedCondition } from '../../utils/parseJsonLogic';
import { ContextList } from '../toggles/ContextList';

const RuleCard = React.memo(({ rule, isHighlighted }: { rule: ParsedCondition[]; isHighlighted: boolean }) => {
  return (
    <Card className={isHighlighted ? 'update-card-highlight' : ''} radius="sm">
      <Card.Section
        direction={{ base: 'column', tablet: 'row' }}
        gap={{ base: 'sm', tablet: 'xl' }}
        alignItems={{ base: 'stretch', tablet: 'center' }}
        padding="sm"
        fontFamily="monospace"
        overflow="auto"
      >
        <ContextList contexts={rule} />
      </Card.Section>
    </Card>
  );
});

export default RuleCard;
