import { Box } from '@hyphen/hyphen-components';
import BadgeTooltip from '../../components/BadgeTooltip';
import { RoleDisplayName } from '../../utils/roles';
import { OrganizationRoles } from '../../types/roles';
import { AccessWithType } from './AccessList';
import { EntityIcon } from '../common/EntityIcon';
import { getEntityUrl } from '../../utils/getEntityUrl';
import { Link } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import RevokeAccess from '../projects/RevokeAccess';

interface AccessListItemProps {
  item: AccessWithType;
  isPhone: boolean;
  revokeAccess?: boolean;
}

const AccessListItem = ({ item, isPhone, revokeAccess }: AccessListItemProps) => {
  const { id: orgId } = useOrganization();
  const isInheritedAccess = item.accessType === 'inherited';
  const isOrgAdmin = item.roles.includes(OrganizationRoles.OrganizationAdmin);

  const accessBadge = (
    <BadgeTooltip
      size="sm"
      variant={isInheritedAccess || isOrgAdmin ? 'light-grey' : 'blue'}
      message={isInheritedAccess || isOrgAdmin ? 'global' : 'direct'}
      tooltipContent={
        isInheritedAccess || isOrgAdmin ? 'Access set at the organization level' : 'Access was applied directly'
      }
    />
  );

  const isApiKey = item.assignment.name.includes('(API Key)');

  const formattedRoles = item.roles.map(RoleDisplayName).join(', ');

  // For apiKeys, APIX returns a Member entity type with name that includes '(API Key)',
  // so we don't know actual api key id.
  // We link to the api keys list instead of a detail page.
  const assignmentUrl = isApiKey
    ? `/${orgId}/settings/api-keys/`
    : item.assignment.type === EntityNames.Member
    ? `${item.assignment.id}`
    : getEntityUrl(orgId, item.assignment.type, item.assignment.id);

  return (
    <>
      <Box className="grid__item" style={{ paddingRight: isPhone ? '0' : undefined }}>
        {accessBadge}
      </Box>
      <Box
        className="grid__item"
        fontWeight={item.accessType === 'inherited' ? 'medium' : 'semibold'}
        color={item.accessType === 'inherited' ? 'secondary' : 'base'}
      >
        <Link to={assignmentUrl} className="display-flex flex-direction-row g-xs align-items-center">
          <Box direction="row" gap="sm" alignItems="center">
            <EntityIcon type={isApiKey ? EntityNames.ApiKey : item.assignment.type} color="tertiary" size="md" />
            <Box>
              {item.assignment.name}
              {isPhone && (
                <Box fontSize="xs" color="secondary" fontWeight="medium">
                  {formattedRoles}
                </Box>
              )}
            </Box>
          </Box>
        </Link>
      </Box>
      {!isPhone && (
        <Box className="grid__item" fontSize="xs" color="secondary">
          {formattedRoles}
        </Box>
      )}
      {revokeAccess && (
        <Box className="grid__item" style={{ paddingLeft: '0' }}>
          {item.accessType === 'direct' && (
            <Box alignItems="flex-end">
              <RevokeAccess
                name={item.assignment.name}
                entityId={item.assignment.id}
                accessId={item.id}
                organizationId={orgId}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default AccessListItem;
