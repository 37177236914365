import React, { useCallback } from 'react';
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  Modal,
  toast,
  useBreakpoint,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { RoleDisplayName } from '../../utils/roles';
import { ConfirmModal } from '../common/ConfirmModal';
import { useRevokeAccessMutation } from '../../services/access';
import { ApiError } from '../ApiError';
import { AccessEntry } from '../../services/types';
import { AddProjectAccessFormSchema, ProjectAccessForm } from './ProjectAccessForm';
import { ProjectRoles } from '../../types/roles';
import { useUpdateTeamProjectAccessMutation } from '../../services/teams';

interface TeamProjectAccessItemProps {
  item: AccessEntry;
  orgId: string;
  canEdit: boolean;
}

const TeamProjectAccessItem: React.FC<TeamProjectAccessItemProps> = ({ item, orgId, canEdit }) => {
  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();
  const { isPhone } = useBreakpoint();

  const [updateAccess, { error: updateAccessError, isLoading: isUpdating }] = useUpdateTeamProjectAccessMutation();
  const [revokeAccess, { error: revokeAccessError, isLoading: isRevoking }] = useRevokeAccessMutation();

  const handleRemove = useCallback(
    async (accessId: string) => {
      const { error } = await revokeAccess({
        organizationId: orgId,
        accessId: accessId,
        entity: {
          type: 'Project',
          id: item.entity.id,
        },
      });
      if (!error) {
        toast.success('access revoked');
        closeRemoveModal();
      }
    },
    [orgId, item.entity.id, closeRemoveModal, revokeAccess],
  );

  const handleEditSubmit = async (values: AddProjectAccessFormSchema) => {
    const { error } = await updateAccess({
      accessId: item.id,
      organizationId: orgId,
      roles: [values.role],
    });
    if (!error) {
      toast.success('Access updated');
      closeEditModal();
    }
  };

  return (
    <>
      {!isPhone && (
        <Box className="grid__item">
          <Icon name="blocks" color="tertiary" />
        </Box>
      )}
      <Box alignItems="flex-start" fontWeight="semibold" className="grid__item">
        <Link to={`/${orgId}/${item.entity.id}`}>
          <Box direction="row" gap="sm" alignItems="center">
            {item.entity.name}
          </Box>
        </Link>
      </Box>
      <Box color="secondary" className="grid__item" fontSize="xs">
        {item.roles.map((role: any) => RoleDisplayName(role)).join(', ')}
      </Box>
      <Box className="grid__item">
        {canEdit && (
          <>
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="Open menu" />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" side="bottom">
                  <DropdownMenuItem onSelect={openEditModal}>Edit</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem className="font-color-danger" onSelect={openRemoveModal}>
                    <Icon name="c-remove" /> <span>Revoke Access</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <ConfirmModal
              message={
                <>
                  Revoke team project access to <strong>{item.entity.name}</strong>?
                </>
              }
              description={revokeAccessError ? <ApiError error={revokeAccessError} /> : undefined}
              confirmButtonLabel="Revoke Access"
              isOpen={isRemoveModalOpen}
              onClose={closeRemoveModal}
              onConfirm={() => handleRemove(item.id)}
              isLoading={isRevoking}
            />
            <Modal
              isOpen={isEditModalOpen}
              maxWidth="9xl"
              onDismiss={closeEditModal}
              ariaLabelledBy="editAccessHeader"
            >
              <ProjectAccessForm
                initialValues={{ role: item.roles[0] as ProjectRoles, projectId: item.entity.id }}
                onSubmit={handleEditSubmit}
                projectOptions={[{ value: item.entity.id, label: item.entity.name }]}
                giveAccessError={updateAccessError}
                handleClose={closeEditModal}
                isSubmitting={isUpdating}
                isLoading={isUpdating}
                teamName={item.assignment.name}
              />
            </Modal>
          </>
        )}
      </Box>
    </>
  );
};

export default TeamProjectAccessItem;
