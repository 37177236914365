import { Box, Button, Icon, Modal, useOpenClose } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { INTEGRATION_ICON_MAP, INTEGRATION_SLUG_MAP } from '../constants/integrations';
import { IntegrationCatalogItem } from './integrations/catalog/IntegrationCatalogItem';
import { IntegrationType } from '../types/integrations';
import { ReactSVG } from 'react-svg';
import { useMemo } from 'react';

type Integrations = 'aws' | 'googleCloud' | 'googleWorkspace' | 'github' | 'azure' | 'slack' | 'office365';

export default function FirstRunCard({
  name,
  description,
  options,
  integrationSlug,
  variant = 'card',
  organizationId,
  onClick,
}: {
  name: string;
  description: string;
  options: Integrations[];
  integrationSlug?: string;
  variant?: 'card' | 'hero';
  organizationId: string;
  onClick?: () => void;
}) {
  const { isOpen, handleToggle, handleClose } = useOpenClose();

  const modalContent = useMemo(
    () => (
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        title={`Select ${name.toLowerCase()}`}
        onDismiss={handleClose}
        maxWidth="9xl"
      >
        <Modal.Body gap="lg">
          <Box as="h4" fontSize="md">
            {`Select ${name.toLowerCase()}`}
          </Box>
          <Box as="p" color="secondary">
            {description}
          </Box>
          <Box gap="lg" margin="lg 0">
            {options.map((option, index: number) => (
              <IntegrationCatalogItem
                organizationId={organizationId}
                onClick={handleClose}
                type={IntegrationType[option]}
                hideDescription
                key={index}
              />
            ))}
          </Box>
        </Modal.Body>
      </Modal>
    ),
    [isOpen, handleClose, name, description, options, organizationId],
  );

  if (variant === 'card') {
    return (
      <div>
        {modalContent}
        <Box
          style={{ flex: '1 1 0' }}
          radius="lg"
          shadow="xs"
          borderWidth="sm"
          className="frosted-glass"
          position="relative"
          padding="3xl"
        >
          <Box gap={{ base: 'lg', desktop: 'md', hd: 'lg' }}>
            <Box gap="sm" direction="row">
              {options.map((option) => (
                <Link to={`integrations/setup/${INTEGRATION_SLUG_MAP[option]}`} key={option}>
                  <Box
                    background="primary"
                    width="48px"
                    minWidth="48px"
                    height="48px"
                    borderWidth="sm"
                    borderColor="subtle"
                    alignItems="center"
                    justifyContent="center"
                    shadow="xs"
                    radius="md"
                    padding="sm"
                  >
                    <ReactSVG
                      src={INTEGRATION_ICON_MAP[option]}
                      beforeInjection={(svg) => {
                        svg.setAttribute('style', 'width: 100%; height: 100%; display: block;');
                      }}
                    />
                  </Box>
                </Link>
              ))}
            </Box>
            <Box gap="sm" padding="sm 0 0 0">
              {integrationSlug ? (
                <Link to={`integrations/setup/${integrationSlug}`}>
                  <Box direction="row" gap="sm" alignItems="center">
                    <Box fontSize="sm" fontWeight="bold">
                      Connect to {name}
                    </Box>
                    <Icon name="arrow-right" color="secondary" />
                  </Box>
                </Link>
              ) : (
                <Box
                  as="button"
                  type="button"
                  borderWidth="0"
                  background="transparent"
                  padding="0"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="base"
                  onClick={handleToggle}
                  variant="primary"
                  cursor="pointer"
                  className="link-hovered"
                  textAlign="left"
                  display="flex"
                  direction="row"
                  alignItems="center"
                  gap="sm"
                >
                  Connect to {name}
                  <Icon name="arrow-right" color="secondary" />
                </Box>
              )}
              <Box as="p" color="secondary" fontSize={{ base: 'xs', hd: 'sm' }}>
                {description}
              </Box>
            </Box>
          </Box>
          <Box
            position="absolute"
            style={{ top: 'var(--size-dimension-sm)', right: 'var(--size-dimension-sm)' }}
            display="none"
          >
            <Button iconPrefix="remove" aria-label="dismiss slack app" variant="tertiary" size="sm"></Button>
          </Box>
        </Box>
      </div>
    );
  }

  // HERO FIRST CARD
  // this is hardcoded for slack. we'll need to refactor when we add more chat integrations like MS Teams
  return (
    <Box
      radius="lg"
      padding={{ base: '3xl', tablet: '4xl', desktop: '5xl' }}
      position="relative"
      borderWidth="sm"
      shadow="xs"
      className="frosted-glass"
    >
      <Box justifyContent="center" height="100" gap="3xl" alignItems="flex-start">
        <Link to="integrations/setup/slack">
          <Box
            width="5xl"
            height="5xl"
            minWidth="5xl"
            borderWidth="sm"
            borderColor="subtle"
            background="primary"
            alignItems="center"
            justifyContent="center"
            shadow="xs"
            radius="md"
            padding="sm"
          >
            <img src="images/slack.svg" alt="" />
          </Box>
        </Link>
        <Box gap="sm">
          <Box fontSize={{ base: 'md', tablet: 'lg', desktop: 'lg', hd: '2xl' }} fontWeight="bold">
            {name}
          </Box>
          <Box as="p" color="secondary" fontSize={{ base: 'xs', tablet: 'sm', hd: 'md' }}>
            {description}
          </Box>
        </Box>
        <Button asChild variant="primary" iconSuffix="arrow-right">
          <Link to="integrations/setup/slack">Add To Your Slack</Link>
        </Button>
      </Box>
      <Box
        position="absolute"
        style={{ top: 'var(--size-dimension-sm)', right: 'var(--size-dimension-sm)' }}
        display="none"
      >
        <Button iconPrefix="remove" aria-label="dismiss slack app" variant="tertiary" size="sm"></Button>
      </Box>
    </Box>
  );
}
