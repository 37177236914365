import { Alert, Box, Card, Icon, useBreakpoint } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ApiKeySecret, useGetApiKeysQuery } from '../../services/apiKeys';
import DateTime from '../DateTime';
import EmptyList from '../common/EmptyList';
import GridCell from '../common/GridCell';
import { Fragment } from 'react/jsx-runtime';
import DateTimeDisplay from '../DateTime';
import GridHeader from '../common/GridHeader';
import { useMemo } from 'react';
import { ApiError } from '../ApiError';
import { Skeleton } from '../common/Skeleton';

interface ApiKeyListProps {
  organizationId: string;
}

const getGridTemplate = (isPhone: boolean) =>
  isPhone ? 'minmax(100px, 1fr) fit-content(200px)' : '32px minmax(100px, 1fr) 1fr 1fr';

export default function ApiKeys({ organizationId }: ApiKeyListProps) {
  const { isLoading, data, error } = useGetApiKeysQuery(organizationId);
  const { isPhone } = useBreakpoint();

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <ApiError title="Unable to retrieve API keys" error={error} />;
  }

  if (!data) return <Alert variant="danger" title="Unable to retrieve API keys" />;

  const { data: apiKeys } = data;

  if (apiKeys.length === 0) {
    return (
      <EmptyList
        title="No API Keys"
        description="Use API keys to authenticate an external service with the Hyphen API"
        iconName="key"
      />
    );
  }

  return (
    <Card>
      <Card.Section padding="0">
        <Box display="grid" style={{ gridTemplateColumns: getGridTemplate(isPhone) }}>
          {!isPhone && <Box />}
          <GridHeader>Name</GridHeader>
          {!isPhone && <GridHeader>Created</GridHeader>}
          <GridHeader>Last Used</GridHeader>
          {apiKeys.map(({ id, name, createdAt, secrets }) => (
            <Fragment key={id}>
              {!isPhone && (
                <GridCell>
                  <Icon name="key" color="tertiary" size="sm" />
                </GridCell>
              )}
              <GridCell fontWeight="semibold">
                <Link to={`/${organizationId}/settings/api-keys/${id}`}>{name}</Link>
              </GridCell>
              {!isPhone && (
                <GridCell fontSize="sm" alignItems="flex-start">
                  <DateTimeDisplay value={createdAt} />
                </GridCell>
              )}
              <GridCell fontSize="sm" color="secondary" fontFamily="monospace">
                <LastUsed secrets={secrets} />
              </GridCell>
            </Fragment>
          ))}
        </Box>
      </Card.Section>
    </Card>
  );
}

const LastUsed = ({ secrets }: { secrets: ApiKeySecret[] }) => {
  const lastUsedDate = useMemo(() => {
    if (!Array.isArray(secrets) || secrets.length === 0) return null;

    const sorted = secrets
      .filter((s) => s.lastUsed)
      .sort((a, b) => Date.parse(b.lastUsed!) - Date.parse(a.lastUsed!));

    return sorted.length > 0 ? sorted[0].lastUsed : null;
  }, [secrets]);

  return lastUsedDate ? <DateTime relative value={lastUsedDate} /> : <>never</>;
};

const LoadingSkeleton = () => {
  const { isPhone } = useBreakpoint();

  return (
    <Card>
      <Card.Section padding="0">
        <Box display="grid" style={{ gridTemplateColumns: getGridTemplate(isPhone) }}>
          {!isPhone && <Box />}
          <GridHeader>
            <Skeleton width="50px" height="14px" />
          </GridHeader>
          {!isPhone && (
            <GridHeader>
              <Skeleton width="70px" height="14px" />
            </GridHeader>
          )}
          <GridHeader>
            <Skeleton width="70px" height="14px" />
          </GridHeader>
          {Array.from({ length: 3 }).map((_, i) => (
            <Fragment key={i}>
              {!isPhone && (
                <GridCell>
                  <Skeleton width="16px" height="14px" />
                </GridCell>
              )}
              <GridCell fontWeight="semibold">
                <Skeleton width="100px" height="14px" />
              </GridCell>
              {!isPhone && (
                <GridCell alignItems="flex-start">
                  <Skeleton width="160px" height="14px" />
                </GridCell>
              )}
              <GridCell>
                <Skeleton width="100px" height="14px" />
              </GridCell>
            </Fragment>
          ))}
        </Box>
      </Card.Section>
    </Card>
  );
};
