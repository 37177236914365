import { Box, Button, Card } from '@hyphen/hyphen-components';
import { useGetConnectionsQuery } from '../../services/connections';
import { useEffect, useMemo, useState } from 'react';
import { Connection, ConnectionType } from '../../types/connections';
import { ApiError } from '../ApiError';
import ConnectionSummaryItem from '../integrations/connections/ConnectionSummaryItem';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import SetupIntegrationConnection from '../integrations/connections/SetupIntegrationConnection';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames, EntityTypes } from '@hyphen/nucleus/dist/types';
import { useGetIntegrationsQuery } from '../../services/integrations';
import { useAuth } from '../auth/authProvider';
import { OrganizationRoles } from '../../types/roles';

// Currently, it's unlikely that an Org member will have more than 50 connections
const PAGE_SIZE = 50;
const PAGE_NUM = 1;
const POLL_COUNT_LIMIT = 20;

interface MemberConnectionsSummaryProps {
  entity: {
    id: string;
    name: string;
  };
  orgId: string;
  entityType: EntityTypes;
}

export default function MemberConnectionsSummary({ entity, orgId, entityType }: MemberConnectionsSummaryProps) {
  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery(orgId);
  const [pollCount, setPollCount] = useState(0);
  const ability = useOrganizationAbilityContext();
  const { user } = useAuth();

  const isOrgAdmin = useMemo(() => {
    return user?.memberships
      .find((m) => m.organization.id === orgId)
      ?.organizationRoles.includes(OrganizationRoles.OrganizationAdmin);
  }, [user, orgId]);

  const {
    data: connections,
    isLoading: isConnectionsLoading,
    error,
    refetch,
  } = useGetConnectionsQuery({
    organizationId: orgId,
    entityIds: [entity.id],
    types: ENTITY_CONNECTION_TYPE_MAP[EntityNames.Member],
    integrationTypes: undefined,
    pageSize: PAGE_SIZE,
    pageNum: PAGE_NUM,
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (
      connections?.data.some((connection) => connection.status === 'Pending' || connection.isRetryable) &&
      pollCount < POLL_COUNT_LIMIT
    ) {
      interval = setInterval(() => {
        refetch();
        setPollCount((prev) => prev + 1);
      }, 3000);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [connections, pollCount, refetch]);

  const isLoading = isIntegrationsLoading || isConnectionsLoading;

  const canUpdateEntityConnections = isOrgAdmin || ability.can('manage', entity);

  // we show the setup button even if there are no integrations so we can show the user
  // a message to contact their org admin to setup an integration
  const showSetup = canUpdateEntityConnections && (connections?.data.length ?? 0) < (integrations?.data.length || 1);

  const usedIntegrationIds = new Set(connections?.data.map((c) => c.organizationIntegration.id));
  const remainingIntegrations =
    integrations?.data.filter((integration) => !usedIntegrationIds.has(integration.id)) || [];

  const hasConnections = connections && connections?.data.length > 0;

  return (
    <Card width="100" style={{ flexShrink: '0' }}>
      <Card.Header
        title="Member Account Connections"
        description="Connect Hyphen members to their respective accounts on other integration services."
        borderWidth="0 0 sm 0"
        alignItems="center"
        gap="5xl"
      >
        {showSetup && integrations && (
          <SetupIntegrationConnection
            entity={{
              id: entity.id,
              type: entityType,
              name: entity.name,
            }}
            connectionType={ConnectionType.User}
            integrations={remainingIntegrations}
            renderProp={(handleOpen) => (
              <Button variant="primary" size="sm" onClick={handleOpen} iconPrefix="add">
                Connect
              </Button>
            )}
          />
        )}
      </Card.Header>
      <Card.Section>
        {!isLoading && error && <ApiError error={error} />}
        {hasConnections ? (
          connections.data.map((connection: Connection) => (
            <ConnectionSummaryItem connection={connection} key={connection.id} />
          ))
        ) : (
          <Box color="secondary">No account connections.</Box>
        )}
      </Card.Section>
    </Card>
  );
}
