import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { Box, Card, Heading, Icon } from '@hyphen/hyphen-components';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { useGetOrganizationUsageQuery } from '../../../services/organization';
import { Skeleton } from '../../../components/common/Skeleton';
import { NetInfoList } from '../../../components/netInfo/NetInfoList';
import { ReactSVG } from 'react-svg';

export const NetInfo = () => {
  const { id: orgId } = useOrganization();
  const { isLoading, data: usage } = useGetOrganizationUsageQuery({ organizationId: orgId }, { skip: !orgId });

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>net.info Geo-Location Service</title>
      </Helmet>
      <PageHeader
        title={
          <Box maxHeight="5xl" width="6xl" direction="row" alignItems="center" gap="sm">
            <ReactSVG
              src="/images/netinfo-icon.svg"
              beforeInjection={(svg) => svg.setAttribute('style', 'width: 24px; height: auto')}
            />
            <Box as="span">net.info</Box>
          </Box>
        }
        description="net.info is a core service for cost-effective and scalable geo-location services"
      />
      <BreadCrumbPortal />
      <Card>
        <Box
          minHeight="7xl"
          width="100"
          height="100"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          gap="2xl"
        >
          <Box gap="xs" alignItems="center">
            <Heading size="sm" as="h2" color="secondary">
              net.info
            </Heading>
            <Box
              className="lh-heading"
              display="block"
              fontSize="xs"
              color="tertiary"
              padding="0 4xl"
              maxWidth="9xl"
            >
              This service provided an easy-to-use IP address to location API.
            </Box>
          </Box>
          <Box
            fontSize="xs"
            background="secondary"
            padding={{ base: 'lg', tablet: 'xl', desktop: 'xl 2xl' }}
            gap="md"
            radius="md"
          >
            <Box
              direction="row"
              gap={{ base: 'xl', tablet: '2xl', desktop: '3xl' }}
              alignItems="center"
              style={{ lineHeight: 'var(--size-line-height-none)' }}
            >
              <Icon name="document" color="tertiary" size="md" />
              <a href="https://docs.hyphen.ai/update/docs/netinfo-quickstart" target="_blank" rel="noreferrer">
                Quickstart Guide
              </a>
              <a href="https://docs.hyphen.ai/reference/get_ip-ipaddress" target="_blank" rel="noreferrer">
                API Reference
              </a>
            </Box>
          </Box>
        </Box>
      </Card>
      <Box gap={{ base: '2xl', tablet: '5xl' }}>
        <Box direction="column" gap="lg" flex="auto">
          <Box padding="lg 0 0 0" alignItems="flex-start">
            <Heading as="h2" size="sm">
              Current billing cycle usage
            </Heading>
          </Box>
          {isLoading && (
            <Card>
              <Box
                className="row-item"
                borderWidth="sm 0 0 0"
                padding="xl lg"
                gap="sm"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Skeleton height="16px" width="200px" />
              </Box>
            </Card>
          )}
          {usage && (
            <Card>
              <Box
                flex="auto"
                className="row-item"
                direction="row"
                borderWidth="sm 0 0 0"
                padding="lg"
                gap="xl"
                alignItems={{ base: 'flex-start', tablet: 'center' }}
              >
                {usage.total.netInfo.requests} request{usage.total.netInfo.requests !== 1 ? 's' : ''}
              </Box>
            </Card>
          )}
        </Box>
        <Box direction="column" gap="lg" flex="auto">
          <Box minWidth="7xl" width="7xl" padding="lg 0 0 0" alignItems="flex-start">
            <Heading as="h2" size="sm">
              History
            </Heading>
          </Box>
          <Card>
            <Box padding="lg">
              <NetInfoList />
            </Box>
          </Card>
        </Box>
      </Box>
    </ScrollableMainLayout>
  );
};

export default NetInfo;
