import { Box, Card } from '@hyphen/hyphen-components';
import { ReactNode } from 'react';
import { Segment } from '../../types/segments';
import ClickToCopy from '../common/ClickToCopy';
import EmptyList from '../common/EmptyList';
import { ListItem } from '../ListItem';
import { Skeleton } from '../common/Skeleton';

function ProjectSegmentList({
  segments,
  emptyCopy,
  isLoading,
}: {
  segments: Segment[];
  emptyCopy: { title: string; description: ReactNode };
  isLoading: boolean;
}) {
  if (isLoading) {
    return <SegmentListSkeleton />;
  }

  if (segments.length === 0) {
    return <EmptyList title={emptyCopy.title} description={emptyCopy.description} iconName="path-intersect" />;
  }

  return (
    <Card className="fade-in" flex="auto" fontSize="sm">
      {segments.map(({ id, name, description, alternateId }) => (
        <ListItem key={id} title={{ label: name, link: alternateId }} subtitle={description}>
          <ClickToCopy text={alternateId} fontSize="xs" alignSelf="flex-start" />
        </ListItem>
      ))}
    </Card>
  );
}

export default ProjectSegmentList;

function SegmentListSkeleton() {
  return (
    <Card>
      {Array.from({ length: 3 }).map((_, index) => (
        <Box
          key={index}
          className="row-item"
          borderWidth="sm 0 0 0"
          padding="xl lg"
          gap="sm"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton height="16px" width="200px" />
          <Skeleton height="16px" width="160px" />
        </Box>
      ))}
    </Card>
  );
}
