import { Box, Card, Button } from '@hyphen/hyphen-components';
import { useState, useMemo } from 'react';
import ToggleUsageItem from './ToggleUsageItem';
import { ToggleSummary } from '../../types/toggle';
import { Skeleton } from '../common/Skeleton';
import EmptyList from '../common/EmptyList';
import { TOGGLE_TEXT } from '../../constants/toggle';
import { useProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';

const INITIAL_MAX_ITEMS = 10;

interface ProjectToggleUsageListProps {
  orgId: string;
  project: ToggleSummary['project'] | undefined;
  toggles: ToggleSummary['toggles'] | undefined;
  showAllToggles?: boolean;
  isLoading: boolean;
}

const ProjectToggleUsageList = ({
  orgId,
  project,
  toggles,
  showAllToggles = false,
  isLoading,
}: ProjectToggleUsageListProps) => {
  const [showAll, setShowAll] = useState(showAllToggles);

  const toggleKeys = useMemo(() => (!isLoading && toggles ? Object.keys(toggles) : undefined), [isLoading, toggles]);
  const visibleToggles = useMemo(
    () => (showAll ? toggleKeys : toggleKeys?.slice(0, INITIAL_MAX_ITEMS)),
    [showAll, toggleKeys],
  );

  if (isLoading) {
    return (
      <Card>
        {Array.from({ length: INITIAL_MAX_ITEMS }).map((_, index) => (
          <Box
            key={index}
            className="row-item"
            borderWidth="sm 0 0 0"
            padding="xl lg"
            gap="sm"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton height="16px" width="200px" />
            <Skeleton height="12px" width="50px" />
            <Skeleton height="20px" width="60px" alignSelf="flex-end" />
          </Box>
        ))}
      </Card>
    );
  }

  if (toggleKeys && toggleKeys.length > 0) {
    return (
      <Card className="fade-in" flex="auto" fontSize="xs">
        {project && toggles && (
          <ToggleUsageItems
            orgId={orgId}
            project={project}
            visibleToggles={visibleToggles || []}
            toggles={toggles}
          />
        )}
        {!showAllToggles && toggleKeys && toggleKeys.length > INITIAL_MAX_ITEMS && (
          <Box borderWidth="sm 0 0 0" alignItems="flex-start" padding="lg">
            <Button onClick={() => setShowAll(!showAll)} variant="secondary" size="sm">
              {showAll ? 'Show Less' : `Show All (${toggleKeys?.length})`}
            </Button>
          </Box>
        )}
      </Card>
    );
  }

  return <EmptyList title="No feature toggles" iconName="logo-toggle" description={TOGGLE_TEXT.description} />;
};

export default ProjectToggleUsageList;

interface ToggleUsageItemsProps {
  orgId: string;
  project: ToggleSummary['project'];
  visibleToggles: string[];
  toggles: ToggleSummary['toggles'];
}

const ToggleUsageItems = ({ orgId, project, visibleToggles, toggles }: ToggleUsageItemsProps) => {
  const { projectEnvironments } = useProjectEnvironments();

  if (!projectEnvironments) return null;

  return (
    <>
      {visibleToggles.map((toggleKey) => (
        <ToggleUsageItem
          key={toggleKey}
          orgId={orgId}
          project={project}
          toggleKey={toggleKey}
          toggleData={toggles[toggleKey]}
          projectEnvironments={projectEnvironments}
        />
      ))}
    </>
  );
};
