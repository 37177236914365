import { useEffect, useState } from 'react';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  SidebarMenuButton,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { User } from './auth/authProvider';
import { Organization, useGetOrganizationBillingAccountQuery } from '../services/organization';

interface UserMenuProps {
  user: User;
  organizationId: string;
  logout: () => void;
  onSelect: () => void;
  organizations: Organization[];
}

const UserMenu: React.FC<UserMenuProps> = ({ user, organizationId, logout, onSelect, organizations }) => {
  const billId = organizations && organizations.find((org) => org.id === organizationId)?.billingAccount?.id;
  const { data: billingData, error: billingError } = useGetOrganizationBillingAccountQuery(organizationId);

  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (billingData) {
      const isOwner = billingData.owners.some((owner) => owner.userId === user?.userId);
      setIsOwner(isOwner);
    }
  }, [billingData, user?.userId]);

  useEffect(() => {
    if (billingError) {
      setIsOwner(false);
    }
  }, [billingError]);

  useEffect(() => {
    const orgId = localStorage.getItem('orgId');
    if (organizationId && orgId !== organizationId) {
      localStorage.setItem('orgId', organizationId);
    }
  }, [organizationId]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton>
          <Box flex="auto" direction="column" gap="xs">
            <span className="truncate">{user?.email}</span>
          </Box>
          <Icon name="caret-up-down" />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent side="bottom" align="end" sideOffset={4}>
        <DropdownMenuGroup>
          <DropdownMenuItem asChild onSelect={onSelect}>
            <Link to="/profile" state={{ backOrgId: organizationId }} className="navlink">
              <Icon name="user" />
              <span>Profile</span>
            </Link>
          </DropdownMenuItem>
          {isOwner && (
            <DropdownMenuItem asChild onSelect={onSelect}>
              <Link to={`/billing/${billId}`} state={{ backOrgId: organizationId }} className="navlink">
                <Icon name="chart-bar" />
                <span>Billing</span>
              </Link>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onSelect={logout}>
          <Icon name="logout" />
          <span>Sign out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserMenu;
