import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { useOrganization } from '../../providers/OrganizationProvider';
import { TeamsList } from '../../components/teams/TeamsList';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';

export const Teams = () => {
  const { id: orgId } = useOrganization();

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <BreadCrumbPortal />
      <PageHeader title="Teams" description="Intelligently provide members access to your resources" />
      <TeamsList organizationId={orgId} />
    </ScrollableMainLayout>
  );
};

export default Teams;
