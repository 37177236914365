import { Box, Button, toast, FormikTextInput, Card } from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useCallback, useMemo } from 'react';
import { useCreateProjectMutation } from '../../services/projects';
import { DependentFormikField } from '../common/form/DependentFormikField';
import { ApiError } from '../ApiError';
import { useNavigate } from 'react-router-dom';
import { generateSlug } from '../../utils/generateSlug';
import { forbiddenWords } from '../../utils/validations';
import { useGetIntegrationsQuery } from '../../services/integrations';
import TooltipIcon from '../common/TooltipIcon';
import IntegrationConnectionsField from '../integrations/IntegrationConnectionsField';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames } from '@hyphen/nucleus/dist/types';
import { ConnectionType } from '@hyphen/nucleus/dist/types/connections';
import { useCreateConnectionMutation } from '../../services/connections';

const createProjectSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Alternate ID')
    .notOneOf(forbiddenWords, `Can not contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
  integrations: yup.array().of(
    yup.object().shape({
      id: yup.string().required('Integration ID is required'),
      input: yup.string(),
    }),
  ),
});

type CreateProjectSchema = InferType<typeof createProjectSchema>;

const initialValues = {
  name: '',
  alternateId: '',
  integrations: [],
};

export const CreateProjectForm = ({ organizationId }: { organizationId: string }) => {
  const navigate = useNavigate();

  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery(organizationId);
  const [createProject, { error }] = useCreateProjectMutation();
  const [CreateConnection, { error: createConnectionError }] = useCreateConnectionMutation();

  const memoizedIntegrations = useMemo(() => integrations?.data ?? [], [integrations]);

  const handleCreateIntegrationConnection = useCallback(
    async (values: CreateProjectSchema, projectId: string) => {
      if (values.integrations) {
        const integrations = values.integrations.map((integration) => {
          if (integration) {
            const [integrationId, connectionType] = integration.id.split('-');
            return { integrationId, connectionType: connectionType, input: integration.input };
          }
          return { integrationId: '', connectionType: '' };
        });

        try {
          await Promise.all(
            integrations.map((integration) =>
              CreateConnection({
                organizationId: organizationId,
                integrationId: integration.integrationId,
                connection: {
                  type: integration.connectionType as ConnectionType,
                  entity: {
                    id: projectId,
                  },
                  input: integration.input,
                },
              }),
            ),
          );
        } catch (error) {
          toast.error('Integration connection failed');
        }
      }
    },
    [CreateConnection, organizationId],
  );

  const handleCreateProject = useCallback(
    async (values: CreateProjectSchema) => {
      try {
        const body = {
          name: values.name,
          alternateId: values.alternateId,
          organizationId,
        };
        toast('Creating project...', { isCompact: true });
        const { error, data } = await createProject(body);
        if (!error && data) {
          if (values.integrations?.length) {
            toast('Connecting integrations...', { isCompact: true });
            await handleCreateIntegrationConnection(values, data.id);
          }
          navigate(`/${organizationId}/${data.alternateId}`);
          toast.success('Project created');
        } else {
          toast.error('Create project failed');
        }
      } catch (e) {
        toast.error('Create project failed');
      }
    },
    [createProject, handleCreateIntegrationConnection, navigate, organizationId],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createProjectSchema}
      onSubmit={handleCreateProject}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, values, isSubmitting }) => (
        <Form noValidate>
          <Box gap="4xl">
            <Field
              id="name"
              name="name"
              label="Name"
              component={FormikTextInput}
              error={errors.name}
              isRequired
              autoFocus
            />
            <DependentFormikField
              id="alternateId"
              helpText="Used as the name on the command line, alphanumeric and dashes only, no spaces"
              name="alternateId"
              label="Alternate ID"
              component={FormikTextInput}
              error={errors.alternateId}
              isRequired
              dependentField="name"
              targetField="alternateId"
              generateValue={generateSlug}
            />

            <Card>
              <Card.Section fontSize="sm" gap="xl">
                {!isIntegrationsLoading && memoizedIntegrations && (
                  <>
                    <Box gap="sm">
                      <Box direction="row" gap="xs" alignItems="center">
                        Project Integrations
                        <TooltipIcon
                          content="Create integration connections for the project with available organization integrations."
                          name="c-info"
                        />
                      </Box>
                      <Box as="p" color="secondary">
                        Select which integrations the project should use, and we’ll create an instance that uses the
                        project's name. Optionally, provide a reference to an existing integration and we'll connect
                        to it.
                      </Box>
                    </Box>
                    <Box gap="xs">
                      <IntegrationConnectionsField
                        integrations={memoizedIntegrations}
                        connectionTypes={ENTITY_CONNECTION_TYPE_MAP[EntityNames.Project]}
                        isSubmitting={isSubmitting}
                        errors={errors}
                      />
                    </Box>
                  </>
                )}
              </Card.Section>
            </Card>

            {(error || createConnectionError) && <ApiError error={error || createConnectionError} />}
            <Box direction="row" gap="sm">
              <Button variant="primary" isLoading={isSubmitting} type="submit">
                Create Project
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
