import { Box, Button, Card, Heading, useBreakpoint } from '@hyphen/hyphen-components';
import { Outlet } from 'react-router-dom';
import { TeamMembersList } from '../../../components/teams/TeamMembersList';
import DynamicTeamCriteria from '../../../components/teams/DynamicTeamCriteria';
import { TeamDetails } from '../../../types/teams';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import TeamProjectAccessList from '../../../components/teams/TeamProjectAccessList';
import ConnectionsSummary from '../../../components/integrations/connections/ConnectionsSummary';
import TooltipIcon from '../../../components/common/TooltipIcon';
import { ENTITY_CONNECTION_TYPE_MAP, EntityNames } from '@hyphen/nucleus/dist/types';

export const TeamDetail = ({ team, orgId }: { team: TeamDetails; orgId: string }) => {
  const ability = useOrganizationAbilityContext();

  const canUpdateTeam = ability.can('update', team);

  const { isPhone } = useBreakpoint();

  return (
    <>
      <Helmet title={`${team.name} Members`} />

      <Box
        gap={{ base: '2xl', tablet: '4xl' }}
        alignItems="flex-start"
        display="grid"
        style={{ gridTemplateColumns: isPhone ? '1fr' : '2fr minmax(200px, var(--size-dimension-8xl))' }}
      >
        <Box gap={{ base: '2xl', tablet: '4xl' }}>
          {team.channels?.length! > 0 && (
            <Box gap="2xl">
              <Heading as="h2" size="sm">
                Channels <span className="font-size-md font-color-secondary">({team.channels!.length})</span>
              </Heading>
              <div>
                {team.channels?.map((channel) => (
                  <span key={channel.id}>
                    {channel.type} - {channel.id},{' '}
                  </span>
                ))}
              </div>
            </Box>
          )}

          <Card>
            <Card.Header
              title={`Team Members (${team.memberCount})`}
              description="Members of this team will inherit any access granted to the team"
            >
              {canUpdateTeam && team?.type !== 'dynamic' && (
                <Button asChild size="sm" iconPrefix="add" variant="secondary">
                  <Link to="add">{!isPhone && 'Member'}</Link>
                </Button>
              )}
            </Card.Header>
            <Card.Section padding="0">
              <TeamMembersList organizationId={orgId} team={team} teamType={team.type} />
            </Card.Section>
          </Card>

          <TeamProjectAccessList team={team} orgId={orgId} />
        </Box>

        <Box gap="2xl">
          {team.type === 'dynamic' && (
            <Card fontSize="xs">
              <Box
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                padding={{ base: '2xl', tablet: 'lg' }}
              >
                <Box
                  color={isPhone ? 'base' : 'secondary'}
                  fontSize={{ base: 'md', tablet: 'xs' }}
                  direction="row"
                  gap="xs"
                  alignItems="center"
                >
                  <div className="font-weight-semibold">Membership Criteria</div>
                  <TooltipIcon
                    content="Rules that determine who is a member of this team"
                    size="sm"
                    name="c-question"
                  />
                </Box>
                {canUpdateTeam && (
                  <Button asChild size="sm" variant="tertiary">
                    <Link to="settings">edit</Link>
                  </Button>
                )}
              </Box>

              <Card.Section padding={{ base: '2xl', tablet: 'lg' }} borderWidth="sm 0 0 0">
                <DynamicTeamCriteria criteria={team.criteria || []} />
              </Card.Section>
            </Card>
          )}

          <ConnectionsSummary
            entity={team}
            entityType={EntityNames.Team}
            orgId={orgId}
            connectionTypes={ENTITY_CONNECTION_TYPE_MAP[EntityNames.Team]}
          />
        </Box>
      </Box>

      <Outlet />
    </>
  );
};

export default TeamDetail;
