import { Box, Tooltip, TooltipContent, TooltipTrigger } from '@hyphen/hyphen-components';
import { ReactSVG } from 'react-svg';
import { INTEGRATION_ICON_MAP, INTEGRATION_NAME_MAP } from '../../../constants/integrations';
import { IntegrationType } from '../../../types/integrations';

const IntegrationIcon = ({ type, isMobile }: { type: IntegrationType; isMobile: boolean }) => (
  <Box direction="row" alignItems="center" gap="xs">
    <Tooltip>
      <TooltipTrigger asChild>
        <Box
          as="button"
          padding="0"
          borderWidth="0"
          width="xl"
          height="xl"
          minWidth="xl"
          minHeight="xl"
          background="transparent"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
        >
          <ReactSVG
            src={INTEGRATION_ICON_MAP[type]}
            beforeInjection={(svg) => svg.setAttribute('style', 'width: 100%; height: 100%; display: block;')}
          />
        </Box>
      </TooltipTrigger>
      <TooltipContent>{INTEGRATION_NAME_MAP[type]}</TooltipContent>
    </Tooltip>

    {!isMobile && INTEGRATION_NAME_MAP[type]}
  </Box>
);

export default IntegrationIcon;
