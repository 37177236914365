import { Box, Button, FormikTextInput, Icon, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { forbiddenWords } from '../../utils/validations';
import { ApiError } from '../ApiError';
import { SEGMENT_TEXT } from '../../constants/segments';
import { DependentFormikField } from '../common/form/DependentFormikField';
import { generateSlug } from '../../utils/generateSlug';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateSegmentMutation } from '../../services/segments';

export const createSegmentSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Key ID')
    .notOneOf(forbiddenWords, `Cannot contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
  description: yup.string(),
});

type CreateSegmentSchema = yup.InferType<typeof createSegmentSchema>;

function CreateSegmentForm({ organizationId, projectId }: { organizationId: string; projectId: string }) {
  const navigate = useNavigate();
  const [createSegment, { error }] = useCreateSegmentMutation();

  const initialValues: CreateSegmentSchema = {
    name: '',
    alternateId: '',
    description: '',
  };

  const handleSubmit = useCallback(
    async (values: CreateSegmentSchema) => {
      try {
        const body = {
          organizationId,
          projectId,
          name: values.name,
          alternateId: values.alternateId,
          description: values.description,
        };
        const { error, data } = await createSegment(body);

        if (!error && data) {
          navigate(`/${organizationId}/${projectId}/segments/${data.alternateId}`);
          toast.success('Segment created');
        } else {
          toast.error('Create segment failed');
        }
      } catch (e) {
        console.error(`Failed to create segment`, e);
        toast.error('Failed to create segment');
      }
    },
    [createSegment, navigate, organizationId, projectId],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createSegmentSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form noValidate>
          <Box gap="4xl">
            <Field id="name" name="name" label="Name" component={FormikTextInput} error={errors.name} autoFocus />
            <Box gap="sm">
              <DependentFormikField
                id="alternateId"
                helpText="Alphanumeric and dashes only, no spaces"
                name="alternateId"
                label="Alternate ID"
                component={FormikTextInput}
                error={errors.alternateId}
                dependentField="name"
                targetField="alternateId"
                generateValue={generateSlug}
              />
              <Box direction="row" gap="sm" fontSize="sm" alignItems="baseline">
                <Box width="md">
                  <Icon name="c-warning" color="info" />
                </Box>
                <p>{SEGMENT_TEXT.alternateIdDescription}</p>
              </Box>
            </Box>

            <Field
              component={FormikTextInput}
              id="description"
              name="description"
              label="Description (optional)"
              placeholder="e.g. 'Beta dashboard is visible to end users'"
              disabled={isSubmitting}
              errors={errors.description}
            />

            {error ? <ApiError error={error} /> : null}
            <Box direction="row" gap="sm">
              <Button variant="primary" isLoading={isSubmitting} type="submit">
                Create Segment
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default CreateSegmentForm;
