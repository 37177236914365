import { Box } from '@hyphen/hyphen-components';
import { BillingAccountPaymentMethodResponse } from '../../../services/billingAccount';
import { ReactSVG } from 'react-svg';

interface CreditCardDetailsProps {
  paymentMethod: BillingAccountPaymentMethodResponse;
}

export const CreditCardDetails = ({ paymentMethod }: CreditCardDetailsProps) => {
  return (
    <Box direction="column" gap="sm" alignItems={{ base: 'flex-start', tablet: 'flex-end' }}>
      <Box direction="row" alignItems="center" gap="md">
        <ReactSVG
          src={`/images/payment-methods/${
            paymentMethod.cardType ? paymentMethod.cardType.toLowerCase() : 'generic'
          }.svg`}
          title={paymentMethod.cardType}
          beforeInjection={(svg) => svg.setAttribute('style', 'width: 24px; height: 24px; display: block;')}
        />
        <Box>************{paymentMethod.last4}</Box>
      </Box>
      {paymentMethod.expiration && <Box>Exp: {paymentMethod.expiration}</Box>}
    </Box>
  );
};
