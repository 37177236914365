import React from 'react';
import { Field } from 'formik';
import { Card, FormikToggle } from '@hyphen/hyphen-components';
import { DynamicTeamsForm } from './DynamicTeamsForm';
import { CreateTeamFormSchema } from '../../schema/teams';

interface TeamTypeToggleProps {
  isSubmitting: boolean;
  values: CreateTeamFormSchema;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const TeamTypeToggle: React.FC<TeamTypeToggleProps> = ({ isSubmitting, values, setFieldValue }) => (
  <Card>
    <Card.Section>
      <Field
        label="Dynamic Team"
        helpText="Automatically assign membership to the team based on criteria you specify"
        name="teamType"
        id="teamType"
        isChecked={values.teamType === 'dynamic'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('teamType', e.target.checked ? 'dynamic' : 'static');
        }}
        component={FormikToggle}
      />
    </Card.Section>
    {values.teamType === 'dynamic' && (
      <Card.Section padding={{ base: '2xl', tablet: '2xl 2xl 2xl 5xl' }} gap="xl">
        <DynamicTeamsForm values={values} isSubmitting={isSubmitting} />
      </Card.Section>
    )}
  </Card>
);

export default TeamTypeToggle;
